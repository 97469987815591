/*
	DEV notes for SearchTripsDataHolder

	The SearchTripsDataHolder is the client trips processor.
	It converts the DynaTrip to DynaTripProposal.
	Some additional calculations, like the index aggregation is done here (and not on server side).
	In general we tend to make all calcs on server side and not here!
 */

import {round} from "dyna-loops";
import {ETripQualityIndex, IDynaTrip, IDynaTripProposal, IDynaTripsExtendedInfo, ITripQualityData} from "dyna-travel-interfaces";

import {currencies} from "../../../utils/currenciesConverter";

export interface IGetTripProposalsConfig {
	listCountProposals?: number;
	sortByTripQualityIndex?: ETripQualityIndex;
}

export interface IGetTripProposalsResults {
	tripProposals: IDynaTripProposal[];
	tripProposalsAllCount: number;
	tripProposalsFilteredCount: number;
	moreTripProposalsCount: number;
	tripQualityBest: ITripQualityData;
	tripQualityCheap: ITripQualityData;
	tripQualityFast: ITripQualityData;
}

export class SearchTripsDataHolder {
	private tripProposals: IDynaTripProposal[] = [];
	private filteredProposals: IDynaTripProposal[] = null;
	private tripProposalsDic: { [tpid: string]: IDynaTripProposal } = {};
	private maxIndexBest: number;
	private minIndexBest: number;
	private maxIndexCheap: number;
	private minIndexCheap: number;
	private maxIndexFast: number;
	private minIndexFast: number;
	private cachePriceConversion: { [tripId: string]: number } = {};

	constructor() {
		this.getTripProposals = this.getTripProposals.bind(this);
	}

	public addTrips(addTrips: IDynaTrip[]): void {
		addTrips.forEach((trip: IDynaTrip, index: number) => {
			let proposal: IDynaTripProposal = this.tripProposalsDic[trip.tpid ];

			if (!proposal) {
				proposal = {
					tpid: trip.tpid,
					trip,
					trips: [],
					tripExtendedInfo: {},
				};
				this.tripProposals.push(proposal);
				this.tripProposalsDic[trip.tpid] = proposal;
			}

			proposal.trips.push(trip);
			proposal.trips.sort((tripA: IDynaTrip, tripB: IDynaTrip) => this.getTripPriceInEur(tripA) - this.getTripPriceInEur(tripB));
			proposal.trip = proposal.trips[0];

			proposal.tripExtendedInfo[trip.id] = this.getTripExtendedInfo(trip);

			if (!this.maxIndexBest || this.maxIndexBest < trip.indices.best) this.maxIndexBest = trip.indices.best;
			if (!this.maxIndexCheap || this.maxIndexCheap < trip.indices.cheap) this.maxIndexCheap = trip.indices.cheap;
			if (!this.maxIndexFast || this.maxIndexFast < trip.indices.fast) this.maxIndexFast = trip.indices.fast;
			if (!this.minIndexBest || this.minIndexBest > trip.indices.best) this.minIndexBest = trip.indices.best;
			if (!this.minIndexCheap || this.minIndexCheap > trip.indices.cheap) this.minIndexCheap = trip.indices.cheap;
			if (!this.minIndexFast || this.minIndexFast > trip.indices.fast) this.minIndexFast = trip.indices.fast;
		});
	}

	public setFilteredTripProposals(filteredProposals: IDynaTripProposal[]): void {
		this.filteredProposals = filteredProposals;
	}

	public get hasTrips(): boolean {
		return !!this.tripProposals.length;
	}

	public reset(): void {
		this.tripProposals = [];
		this.filteredProposals = null;
		this.tripProposalsDic = {};
		this.maxIndexBest = undefined;
		this.minIndexBest = undefined;
		this.maxIndexCheap = undefined;
		this.minIndexCheap = undefined;
		this.maxIndexFast = undefined;
		this.minIndexFast = undefined;
		this.cachePriceConversion = {};
	}

	public getAllTripProposals(): IDynaTripProposal[] {
		return this.tripProposals.concat();
	}

	// getTripProposals
	// Main function the gets part from the already fetched proposals by filters etc.
	// It uses the already filtered results from the DynaTravelFilterTrips component
	// So there is no need to filter again, since this component did it for it's own needs!
	// dev note: IMPORTANT: this runs on api's trips import, is should be fast, no heavy calcs here
	public getTripProposals(config: IGetTripProposalsConfig): IGetTripProposalsResults {
		const tripProposals: IDynaTripProposal[] = this.internalGetTripProposal();
		const output: IGetTripProposalsResults = {
			tripProposals: null,
			tripProposalsAllCount: null,
			tripProposalsFilteredCount: null,
			moreTripProposalsCount: null,
			tripQualityBest: null,
			tripQualityCheap: null,
			tripQualityFast: null,
		};

		output.tripProposals = tripProposals
			.sort((tripA: IDynaTripProposal, tripB: IDynaTripProposal) => this.sortTrip(tripA.trip, tripB.trip, config.sortByTripQualityIndex))
			.slice(0, config.listCountProposals);

		output.tripProposalsAllCount = this.tripProposals.length;
		output.tripProposalsFilteredCount = this.tripProposals.length - (this.filteredProposals && this.filteredProposals.length || 0);
		output.moreTripProposalsCount = tripProposals.length - output.tripProposals.length;

		output.tripQualityBest = this.getTripQualityData(ETripQualityIndex.BEST);
		output.tripQualityCheap = this.getTripQualityData(ETripQualityIndex.CHEAP);
		output.tripQualityFast = this.getTripQualityData(ETripQualityIndex.FAST);

		return output;
	}

	private getTripQualityData(tripQualityIndex: ETripQualityIndex): ITripQualityData {
		const tripProposals: IDynaTripProposal[] = this.internalGetTripProposal();
		const output: ITripQualityData = {
			topProposal: null,
			avgPrice: {
				value: null,
				currency: null,
			},
			avgDurationInMin: null,
		};

		if (!tripProposals.length) return output; // exit

		const usePartOfTotal: number = 5;
		let totalPrice: number = 0;
		let totalDuration: number = 0;

		tripProposals
			.sort((tripA: IDynaTripProposal, tripB: IDynaTripProposal) => this.sortTrip(tripA.trip, tripB.trip, tripQualityIndex))
			.slice(0, usePartOfTotal)
			.forEach((tripProposal: IDynaTripProposal, index: number) => {
				if (index === 0) output.topProposal = tripProposal;
				totalPrice += this.getTripPriceInEur(tripProposal.trip);
				totalDuration += tripProposal.trip.stats.durationInMin;
			});

		output.avgPrice.value = totalPrice / usePartOfTotal;
		output.avgPrice.currency = "eur";
		output.avgDurationInMin = totalDuration / usePartOfTotal;

		return output;
	}

	// Return the trip proposals, the fetched one or the filtered.
	// The DynaTravelFilterTrips component, filters the trip proposals anyway for it's own needs.
	// Once the trip proposals are filtered are saved in this component.
	// The filter might be not instant but it always happen even if the filter component is not activated by the user.
	private internalGetTripProposal(): IDynaTripProposal[] {
		return (
			(this.filteredProposals && this.filteredProposals.concat())
			|| this.tripProposals.concat()
		);
	}

	private filterTrip(trip: IDynaTrip, config: IGetTripProposalsConfig): boolean {
		// todo, implement the filters here
		return true;
	}

	private sortTrip(tripA: IDynaTrip, tripB: IDynaTrip, sortByTripQualityIndex: ETripQualityIndex): number {
		switch (sortByTripQualityIndex) {
			case ETripQualityIndex.BEST:
				return tripA.indices.best - tripB.indices.best;
			case ETripQualityIndex.CHEAP:
				return tripA.indices.cheap - tripB.indices.cheap;
			case ETripQualityIndex.FAST:
				return tripA.indices.fast - tripB.indices.fast;
			default:
				return 0;
		}
	}

	private getTripExtendedInfo(trip: IDynaTrip): IDynaTripsExtendedInfo {
		return {
			indices: {
				bestIndexPercent: this.calcPercent(this.minIndexBest, this.maxIndexBest, trip.indices.best, true),
				cheapIndexPercent: this.calcPercent(this.minIndexCheap, this.maxIndexCheap, trip.indices.cheap, true),
				fastIndexPercent: this.calcPercent(this.minIndexFast, this.maxIndexFast, trip.indices.fast, true),
			},
		};
	}

	private calcPercent(min: number, max: number, current: number, reverse: boolean = false): number {
		max -= min;
		current -= min;
		return (reverse ? 100 : 0) - round(current * 100 / max, 1);
	}

	private getTripPriceInEur(trip: IDynaTrip): number {
		let value: number = this.cachePriceConversion[trip.id];
		if (value === undefined) {
			value = currencies.convertDynaPrice(trip.price, "eur").value;
			this.cachePriceConversion[trip.id] = value;
		}
		return value;
	}

	// getTripProposalByTpid

	public getTripProposalByTpid(tpid: string): IDynaTripProposal {
		return this.tripProposalsDic[tpid];
	}
}

