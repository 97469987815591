import {EColor as EButtonColor} from "dyna-ui-button";
import {EColor as ETripTimelineColor} from "dyna-travel-ui-trip-timeline";

import {EProduct} from "./interfaces";

export interface IMixerProps {
  selectButtonColor: EButtonColor;
  loadMoreButtonColor: EButtonColor;
  tripTimelineColor: ETripTimelineColor;
  selectTodayButton: EButtonColor;
  selectNextWeekendButton: EButtonColor;
}

interface IMixerSetup {
  [product: string]: IMixerProps;
}

const mixerSetup: IMixerSetup = {
  [EProduct.IM_HOLIDAY]: {
    selectButtonColor: EButtonColor.ORANGE_WHITE,
    loadMoreButtonColor: EButtonColor.ORANGE_WHITE,
    tripTimelineColor: ETripTimelineColor.ORANGE,
    selectTodayButton: EButtonColor.ORANGE_WHITE,
    selectNextWeekendButton: EButtonColor.ORANGE_WHITE,
  },
  [EProduct.DIE_TO_FLY]: {
    selectButtonColor: EButtonColor.RED_WHITE,
    loadMoreButtonColor: EButtonColor.RED_WHITE,
    tripTimelineColor: ETripTimelineColor.RED,
    selectTodayButton: EButtonColor.RED_WHITE,
    selectNextWeekendButton: EButtonColor.RED_WHITE,
  },
};

export const styleMixer = (product: EProduct): IMixerProps => mixerSetup[product];
