import * as React from "react";
import {DynaSvg} from "dyna-svg";
import {dynaClassName} from "dyna-class-name";

import "./ImHolidayLogo.less";

const imHolidaySvgSquare: string = require("./imholidays_square.svg");
const imHolidaySvgLandscape: string = require("./imholidays_landscape.svg");

export enum ELogoType {
	SQUARE = "SQUARE",
	LANDSCAPE = "LANDSCAPE",
}

export interface IImHolidayLogoProps {
	className?: string;
	type: ELogoType;
}

export class ImHolidayLogo extends React.Component<IImHolidayLogoProps> {
	private readonly className = dynaClassName("dyna-travel-imHoliday-product-logo");

	public render(): JSX.Element {
		const {
			className: userClassName,
			type,
		} = this.props;

		let logoSvg: string;
		switch (type) {
			case ELogoType.SQUARE:
				logoSvg = imHolidaySvgSquare;
				break;
			case ELogoType.LANDSCAPE:
				logoSvg = imHolidaySvgLandscape;
				break;
		}

		return (
			<div className={this.className("", userClassName && "/" + userClassName)}>
				<DynaSvg src={logoSvg}/>
			</div>
		);
	}
}