import {connect, Dispatch} from "react-redux";
import {IAppStore} from "../../../redux/reducersConfig";

import component, {IReduxIntlProviderProps} from './ReduxIntlProviderComponent';

const mapStateToProps = (state: IAppStore): IReduxIntlProviderProps => ({
  locale: state.app.locale,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): IReduxIntlProviderProps => {
  return {
    locale: 'en',
  };
};

export const ReduxIntlProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(component);

