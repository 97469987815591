import * as React from "react";
import {round} from "dyna-loops";
import {dynaClassName} from "dyna-class-name";

import {LoadingInLine} from "../../../../../components/ui/loadingInLine";
import {IFlightProposalDetailsMessages} from "../messages";

import "./FlightProposalsDetailsIsloading.css";

export interface IFlightProposalsDetailsIsloadingProps {
	className?: string;
	loadingProgressPercent: number;
	messages: IFlightProposalDetailsMessages;
}

export class FlightProposalsDetailsIsloading extends React.Component<IFlightProposalsDetailsIsloadingProps> {
	private readonly className = dynaClassName("dyna-travel-flight-proposal-details-is-loading");

	constructor(props: IFlightProposalsDetailsIsloadingProps, context: any) {
		super(props, context);
	}

	public render(): JSX.Element {
		const {
			className: userClassName,
			loadingProgressPercent,
			messages: {
				searchingForThisFlight,
				pleaseWait,
			},
		} = this.props;

		const className: string = this.className("", userClassName && '/' + userClassName);

		return (
			<div className={className}>
				<div className={this.className('__m1')}>{searchingForThisFlight}</div>
				{LoadingInLine()}
				{loadingProgressPercent != null ? <div className={this.className('__progress-number')}>{round(loadingProgressPercent, 0)}%</div> : null}
				<div className={this.className('__m2')}>{pleaseWait}</div>
			</div>
		);
	}
}