import * as moment from "moment";
import {count} from "dyna-count";

export const scanDates = (startDate: any, endDate: any, cbScan: (date: moment.Moment) => void): void => {
  const [_start, _end] =
    [moment(startDate), moment(endDate)]
      .sort((dA, dB) => dA.valueOf() - dB.valueOf());
  const scanDate = _start;
  const days = Math.abs(_end.diff(_start, 'days')) + 1;
  count(days).for(index => cbScan(moment(scanDate.add(!!index ? 1 : 0, 'days'))));
};
