import * as React from "react";
import {dynaClassName, DynaClassName} from "dyna-class-name";
import {RefreshBalloon} from "dyna-travel-ui-components";
import {DynaTravelFilterTrips, IDynaTripFilter} from "dyna-travel-ui-filter-trips";
import {ETripQualityIndex, IDynaPassenger, IDynaPrice, IDynaTripProposal, IDynaTripRequest, ITripQualityData} from "dyna-travel-interfaces";

import {EProduct} from "./interfaces";
import {IDynaTravelListProposalsMessages} from "./messages";

import {LoadingProgressBar} from "./components/LoadingProgressBar/LoadingProgressBar";
import {SortByTripQualityIndex} from "./components/SortByTripQualityIndex/SortByTripQualityIndex";
import {ListTripProposals} from "./components/ListTripProposals/ListTripProposals";

import "./DynaTravelListProposals.less";

export interface IDynaTravelListProposalsProps {
  className?: string;
  product: EProduct;
  animationEnabled: boolean;
  currency: string;
  messages: IDynaTravelListProposalsMessages;

  passengers: IDynaPassenger[];

  isLoading: boolean;
  isLoadingProgressMessage: string;
  loadProgressPercentValue: number; // null to hide it
  inLoadingError: boolean;
  hasMoreProposals: number;
  noTripsFound: boolean;
  isLoadingMore: boolean;
  proposalsExpired: boolean;
  showExpiredBalloon: boolean;
  requestTripDirectFlightsOnly: boolean;
  tripRequest: IDynaTripRequest;

  sortByTripQuality: ETripQualityIndex;
  tripQualityBest: ITripQualityData;
  tripQualityCheap: ITripQualityData;
  tripQualityFast: ITripQualityData;

  tripProposals: IDynaTripProposal[];
  allTripProposals: IDynaTripProposal[];
  tripsFilter: IDynaTripFilter;

  convertPrice: (price: IDynaPrice, currency: string) => number;
  formatPrice: (price: IDynaPrice, currency: string) => string;
  formatTimeFromString: (timeHHMM: string) => string;
  formatDate: (date: Date) => string;
  formatFullDateFromString: (dateYYYYMMDD: string) => string;
  formatDuration: (minutes: number) => string;
  isLoadingIcon: JSX.Element;

  onLoadingCancelClick: () => void;
  onSortByQualityClick: (qualityTripIndex: ETripQualityIndex) => void;
  onTripProposalSelectClick: (proposal: IDynaTripProposal) => void;
  onLoadMoreProposals: () => void;
  onFilterTripsChange: (filter: IDynaTripFilter) => void;

  onSelectTodayClick: () => void;
  onSelectNextWeekendClick: () => void;

  onFilteredTripProposals: (tripProposals: IDynaTripProposal[]) => void;

  onRefreshLaterClick: () => void;
  onRefreshNowClick: () => void;
  onFiltersClose: () => void;
}

export class DynaTravelListProposals extends React.Component<IDynaTravelListProposalsProps> {
  private readonly cn: DynaClassName = dynaClassName("dyna-travel-list-proposals");

  public render(): JSX.Element {
    const {
      product,
      animationEnabled,
      currency,
      passengers,
      messages,
      messages: {
        refreshBalloonTitle,
        refreshBalloonDescription,
        refreshBalloonLaterButton,
        refreshBalloonRefreshButton,
      },

      isLoading,
      isLoadingProgressMessage,
      loadProgressPercentValue,
      inLoadingError,
      isLoadingIcon,
      hasMoreProposals,
      noTripsFound,
      isLoadingMore,
      proposalsExpired,
      showExpiredBalloon,
      requestTripDirectFlightsOnly,
      tripRequest,

      sortByTripQuality,
      tripQualityBest,
      tripQualityCheap,
      tripQualityFast,

      convertPrice,
      formatTimeFromString,
      formatDate,
      formatFullDateFromString,
      formatPrice,
      formatDuration,

      tripProposals,
      allTripProposals,
      tripsFilter,

      onLoadingCancelClick,
      onSortByQualityClick,
      onTripProposalSelectClick,
      onLoadMoreProposals,
      onSelectTodayClick,
      onSelectNextWeekendClick,
      onRefreshLaterClick,
      onRefreshNowClick,
      onFilteredTripProposals,
      onFilterTripsChange,
      onFiltersClose,
    } = this.props;

    const classNames: string[] = [
      `--product-${product}`,
    ];

    return (
      <div className={this.cn.root(this.props, classNames)}>
        <LoadingProgressBar
          product={product}
          animationEnabled={animationEnabled}
          show={loadProgressPercentValue != null}
          messages={messages}
          isLoading={isLoading}
          isLoadingMessage={isLoadingProgressMessage}
          inError={inLoadingError}
          loadProgressPercentValue={loadProgressPercentValue}
          onCancelClick={onLoadingCancelClick}
        />
        <DynaTravelFilterTrips
          className={this.cn("__filters")}
          product={product}
          messages={messages}
          show={!!(allTripProposals && allTripProposals.length)}
          showNoFilterLabel={false}
          currency={currency}
          convertPrice={convertPrice}
          formatDate={formatDate}
          formatPrice={formatPrice}
          formatDuration={formatDuration}
          tripRequest={tripRequest}
          tripProposals={allTripProposals}
          value={tripsFilter}
          onFilteredTripProposals={onFilteredTripProposals}
          onChange={onFilterTripsChange}
          onClose={onFiltersClose}
        />
        <SortByTripQualityIndex
          product={product}
          currency={currency}
          messages={messages}
          show={!!tripProposals.length}
          sortByTripQuality={sortByTripQuality}
          tripQualityBest={tripQualityBest}
          tripQualityCheap={tripQualityCheap}
          tripQualityFast={tripQualityFast}
          formatPrice={formatPrice}
          formatDuration={formatDuration}
          onClick={onSortByQualityClick}
        />
        <ListTripProposals
          product={product}
          animationEnabled={animationEnabled}
          currency={currency}
          passengers={passengers}
          isLoading={isLoading || isLoadingMore}
          hasMoreProposals={hasMoreProposals}
          noTripsFound={noTripsFound}
          requestTripDirectFlightsOnly={requestTripDirectFlightsOnly}
          messages={messages}
          isLoadingIcon={isLoadingIcon}
          tripProposals={tripProposals}
          proposalsExpired={proposalsExpired}
          formatTimeFromString={formatTimeFromString}
          formatDate={formatDate}
          formatFullDateFromString={formatFullDateFromString}
          formatPrice={formatPrice}
          formatDuration={formatDuration}
          onSelectProposalClick={onTripProposalSelectClick}
          onLoadMoreProposals={onLoadMoreProposals}
          onSelectTodayClick={onSelectTodayClick}
          onSelectNextWeekendClick={onSelectNextWeekendClick}
        />
        <RefreshBalloon
          product={product}
          show={showExpiredBalloon}
          title={refreshBalloonTitle}
          description={refreshBalloonDescription}
          laterButtonLabel={refreshBalloonLaterButton}
          refreshButtonLabel={refreshBalloonRefreshButton}
          onLaterClick={onRefreshLaterClick}
          onRefreshClick={onRefreshNowClick}
        />
      </div>
    );
  }
}
