import * as React from 'react';
import {InjectedIntl, injectIntl} from "react-intl";
import {dynaClassName, DynaClassName} from "dyna-class-name";
import {ShareButtons} from "dyna-travel-ui-components";

import {appConfig} from "../../../../../settings/app";
import {formatMessage} from "../../../../../utils/formatMessage";

import "./ShareButtons.css";

const cs: DynaClassName = dynaClassName("dyna-app-share-buttons");

export interface IAppShareButtonsProps {
	intl?: InjectedIntl;
}

const _AppShareButtons: React.SFC<IAppShareButtonsProps> = (props) => {
	const {intl} = props;
	return (
		<ShareButtons
			className={cs.root(props)}
			label={formatMessage({intl, id: 'MSG_0799'})}
			webSiteName={appConfig.productInfo.name}
			webSiteDescription={appConfig.productInfo.description}
			websiteUrl={appConfig.productInfo.url}
			websiteLogoPreview={appConfig.productInfo.logoPreview}
			websiteTwitterAccount={appConfig.productInfo.twitterAccount}
			showFacebook
			showGooglePlus
			showTwitter
			showLinkedIn
			showPinboard
			showPinterest
			showPocket
			showReddit
			showTumblr
			showWordpress
		/>
	);
};

export const AppShareButtons = injectIntl(_AppShareButtons);