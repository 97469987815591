import {
  COMMAND_searchFlights,
  ICOMMAND_searchFlights_data,
  ICOMMAND_searchFlightsUpdateTrips_data,

  COMMAND_updateCurrencies,
  ICOMMAND_updateCurrencies_data,

  COMMAND_searchFlightsUpdateProgress,
  ICOMMAND_searchFlightsUpdateProgress_data,

  COMMAND_searchFlightsCompleted,    // todo add the data/args interfaces
  ICOMMAND_searchFlightsCompleted_data,

  COMMAND_searchFlightsUpdateTrips,  // todo add the data/args interfaces

  COMMAND_searchFlightsUpdateOperationId,
  ICOMMAND_searchFlightsUpdateOperationId_args,

  COMMAND_userCancelFlightSearchByOperationId,
  ICOMMAND_userCancelFlightSearchByOperationId_args,
} from "dyna-travel-services/dist/esNext/searchFlights/client";

import {EventEmitter}                         from 'eventemitter3';
import {DynaNodeClient, DynaNodeMessage}     from "dyna-node/dist/commonJs/web";
import {DynaCurrencies}                       from "dyna-currencies";
import {EErrorType, IError, IInfo, IResponse} from "dyna-interfaces";
import {IDynaTrip, IDynaTripRequest}          from "dyna-travel-interfaces";

import {debug_requestDevDemoConfig} from "../../../../../settings/debug_requestDevDemoConfig";
import {ILoadFlightsCompleteInfo} from "../interfaces";

export interface IEvents {
  progress: string;
  load: string;
  loadCompleted: string;
  error: string;
}

export interface IFetchDynaTripsHandlerConfig {
  currencies: DynaCurrencies;
  tripRequest: IDynaTripRequest;
  nodeDevice: DynaNodeClient;
  serviceDNA: string;
  replyTimeoutInMs: number;
}

export class FetchDynaTripsHandler extends EventEmitter {
  private trips: IDynaTrip[] = [];

  constructor(private readonly config: IFetchDynaTripsHandlerConfig) {
    super();
    setTimeout(this.requestTrip.bind(this), 0); // give a tick to bind the events
  }

  public events: IEvents = {
    load: 'LOAD',
    loadCompleted: 'LOAD_FINISH',
    progress: 'PROGRESS',
    error: 'ERROR',
  };

  private userCancel: boolean = false;
  private worker_address: string;                 // the worker's that is serving as
  private worker_searchFlightOperationId: string; // the worker's searchFlightOperationId

  private requestTrip(): void {
    const replyTimeoutInMs: number = this.config.replyTimeoutInMs || 5 * 60000;
    let sourceMessage: DynaNodeMessage<any, any>;

    if (debug_requestDevDemoConfig.requestDevDemoTripFilename) {
      // todo, implement this
      // command = 'getDemoTripFlights';
      // data = {
      // 	requestDevDemoTripFilename: debug_requestDevDemoConfig.requestDevDemoTripFilename,
      // 	requestDevDemoWithResponseDelay: debug_requestDevDemoConfig.requestDevDemoWithResponseDelay,
      // }
    }

    this.config.nodeDevice.send<null, ICOMMAND_searchFlights_data, any, any>({
      to: this.config.serviceDNA,
      command: COMMAND_searchFlights,
      data: {
        tripRequest: this.config.tripRequest,
      },
      multiReplies: true,
      replyTimeoutInMs,
      onReply: (response: DynaNodeMessage<any, any>) => {
        if (this.userCancel) return; // ignore them

        // check for error
        if (response.command.slice(0, "error".length) === 'error') {
          this.loadError(response.data);
          this.config.nodeDevice.stopMultipleReplies(sourceMessage);    // close the stream
          return;
        }

        switch (response.command) {
          case COMMAND_searchFlightsUpdateOperationId:
            (() => {
              const {searchFlightOperationId} = (response.args as ICOMMAND_searchFlightsUpdateOperationId_args);
              this.worker_address = response.from;
              this.worker_searchFlightOperationId = searchFlightOperationId;
            })();
            break;
          case COMMAND_updateCurrencies:
            (() => {
              const {currencyRates} = (response.data as ICOMMAND_updateCurrencies_data);
              this.config.currencies.update(currencyRates);
            })();
            break;
          case COMMAND_searchFlightsUpdateProgress:
            (() => {
              const {progressMessage, progressPercent} = response.data as ICOMMAND_searchFlightsUpdateProgress_data;
              this.loadProgress({
                progress: progressPercent,
                message: progressMessage,
              } as IInfo);
            })();
            break;
          case COMMAND_searchFlightsUpdateTrips:
            (() => {
              const {trips, progressPercent} = response.data as ICOMMAND_searchFlightsUpdateTrips_data;
              this.loadTrips(trips);
              this.loadProgress({
                progress: progressPercent,
                message: 'loading...',
              } as IInfo);
            })();
            break;
          case COMMAND_searchFlightsCompleted:
            (() => {
              const {
                userErrorMessages,
              } = response.data as ICOMMAND_searchFlightsCompleted_data;
              this.loadFlightsComplete({userErrorMessages});
              this.config.nodeDevice.stopMultipleReplies(sourceMessage);    // close the stream
            })();
            break;
          default:
            (() => {
              this.loadError({
                code: 1912101934,
                section: 'DynaTripProviderHandler/client/requestTrip',
                message: `Response Command from service is not supported [${response.command}]`,
                data: {message: response},
              } as IError);
              this.config.nodeDevice.stopMultipleReplies(sourceMessage);    // close the stream
            })();
        }
      },
      onReplyTimeout: (message: DynaNodeMessage<any, any>) => {
        if (this.userCancel) return; // ignore them

        this.loadError({
          code: 1807201948,
          errorType: EErrorType.HW,
          message: "Server is late, this is not network error but not response from the service, probably is overloaded and it needs more instances",
          data: {replyTimeoutInMs},
        }as IError);

        // cancel the trip request, is too late
        this.cancelTripRequest();
      },
    })
      .then((message: DynaNodeMessage<any, any>) => {
        sourceMessage = message;
      })
      .catch((error: IError) => {
        if (this.userCancel) return;   // ignore them

        this.loadError({
          code: 1804011112,
          errorType: EErrorType.HW,
          section: 'DynaTripProviderHandler/client/requestTrip',
          message: `Cannot send request`,
          error,
        } as IError);
      });
  }

  public cancelTripRequest(): void {
    this.userCancel = true;

    this.config.nodeDevice.sendReceive<ICOMMAND_userCancelFlightSearchByOperationId_args, null, null, null>({
      to: this.worker_address,
      command: COMMAND_userCancelFlightSearchByOperationId,
      args: {
        searchFlightOperationId: this.worker_searchFlightOperationId,
      },
    })
      .catch((error: IError) => console.warn('DynaTripProviderHandler: cannot cancel the trip (this is not error if this post is too old)', error));
  }

  public clear(): void {
    this.trips = [];
  }

  // methods for the API

  private loadTrips(trips: IDynaTrip[]): void {
    this.trips = this.trips.concat(trips);
    this.emit(this.events.load, trips);
  }

  private loadFlightsComplete(completeInfo: ILoadFlightsCompleteInfo): void {
    this.emit(this.events.loadCompleted, completeInfo);
  }

  private loadProgress(progress: IInfo): void {
    this.emit(this.events.progress, progress);
  }

  private loadError(error: IError): void {
    this.emit(this.events.error, error);
  }
}
