import {
  IFlightInfoState,
  IDayFlightsViewData,
} from "../interfaces";
import { EQualityIndex } from "dyna-travel-ui-components";

export const getDayFlightsViewData = (state: IFlightInfoState, yyyymmdd: string, roundTrip: boolean): IDayFlightsViewData => {
  const output: IDayFlightsViewData = {
    dateNumber: (new Date(yyyymmdd)).getDate(),
    qualityIndex: state.qualityIndex,

    showPriceQualityIndex: state.qualityIndex !== EQualityIndex.NONE,
    priceQualityIndex: {
      best: null,
      cheapest: null,
      fastest: null,
    },

    showPriceValues: state.qualityIndex !== EQualityIndex.NONE && state.showPrices,
    priceValues: {
      best: "noData",
      cheapest: "noData",
      fastest: "noData",
    },
  };

  if (state.isClientLoading) output.priceValues.best = output.priceValues.cheapest = output.priceValues.fastest = "isFrontendLoading";

  if (!state.currencies.count) {
    output.priceValues.best = output.priceValues.cheapest = output.priceValues.fastest = "noCurrencies";
    return output;
  }

  const roundTripStoreProperty = roundTrip ? 'roundTrip' : 'oneWay';
  const dayFlightsInfo = state.daysFlightsInfo[roundTripStoreProperty][yyyymmdd];

  if (!dayFlightsInfo) return output;

  if (dayFlightsInfo.isBackendLoading) {
    output.priceValues.best = output.priceValues.cheapest = output.priceValues.fastest = "isBackendLoading";
    return output;
  }

  output.priceQualityIndex.best = dayFlightsInfo.priceQualityIndex.best;
  output.priceQualityIndex.cheapest = dayFlightsInfo.priceQualityIndex.cheapest;
  output.priceQualityIndex.fastest = dayFlightsInfo.priceQualityIndex.fastest;

  output.priceValues.best = dayFlightsInfo.bestPrice.label;
  output.priceValues.cheapest = dayFlightsInfo.cheapestPrice.label;
  output.priceValues.fastest = dayFlightsInfo.fastestPrice.label;

  return output;
};
