export class ExpireProposalsManager {
	private store: {
		[searchId: string]: {
			timeoutHandler: any;
		},
	} = {};

	public addTimer(searchJobId: string, cb: Function, timeout: number): void {
		this.store[searchJobId] = {
			timeoutHandler: setTimeout(() => {
				cb();
				this.removeTimer(searchJobId);
			}, timeout),
		};
	}

	public removeTimer(searchJobId: string): void {
		if (!this.store[searchJobId]) return;
		clearTimeout(this.store[searchJobId].timeoutHandler);
		delete this.store[searchJobId];
	}
}

export const expireProposalsManager = new ExpireProposalsManager();