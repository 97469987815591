import * as React from 'react';
import {dynaClassName} from "dyna-class-name";
import {CompanyLogo} from "../../../../../components/ui/companyLogo/CompanyLogo";

import "./DieToFlyLogo.less";

const className = dynaClassName("die-to-fly-logo");

export const DieToFlyLogo: React.SFC<{}> = (props) => (
	<CompanyLogo className={className()}>
			<span>
				<span className={className("__die")}>die</span>
				<span className={className("__to")}>to</span>
				<span className={className("__fly")}>fly</span>
				<span className={className("__dot-com")}>.com</span>
				</span>
	</CompanyLogo>
);