import * as React from "react";
import { cn } from "../utils/cn";
import { EQualityIndex } from "./interfaces";
import { Option } from "./components/Option";

import * as styles from "./QualityIndexSwitch.module.less";
import { ShowPriceCheckBox } from "./components/ShowPriceCheckBox";

export interface IQualityIndexSwitchProps {
  className?: string;
  value: IQualityIndexSwitchValue;
  messages: {
    none: string;
    best: string;
    cheapest: string;
    fastest: string;
  };
  onChange: (value: IQualityIndexSwitchValue) => void;
}

export interface IQualityIndexSwitchValue {
  qualityIndex: EQualityIndex;
  showPriceValues: boolean;
}

export const QualityIndexSwitch = (props: IQualityIndexSwitchProps): JSX.Element => {
  const {
    className,
    value,
    messages,
    onChange,
  } = props;

  const handleQualityIndexChange = (qualityIndex: EQualityIndex): void => {
    onChange({...value, qualityIndex});
  };

  const handleQualityIndexVhange = (showPriceValues: boolean): void => {
    onChange({...value, showPriceValues});
  };

  return (
    <div className={cn(className, styles.root)}>
      {Object.keys(EQualityIndex).map((qualityIndex: EQualityIndex) => (
        <Option
          key={qualityIndex}
          qualityIndex={qualityIndex}
          label={messages[qualityIndex.toLowerCase()]}
          selected={value.qualityIndex === qualityIndex}
          onClick={handleQualityIndexChange}
        />
      ))}
      <ShowPriceCheckBox
        disabled={value.qualityIndex === EQualityIndex.NONE}
        value={value.showPriceValues}
        onChange={handleQualityIndexVhange}
      />
    </div>
  );
};
