import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {IDynaTime, IDynaTrip} from "dyna-travel-interfaces";

import "./ShortTripOverview.less";

export interface IShortTripOverviewProps {
	className?: string;
	trip: IDynaTrip;
	formatDate?: (date: IDynaTime) => string;
}

export class ShortTripOverview extends React.Component<IShortTripOverviewProps> {
	static defaultProps: IShortTripOverviewProps = {
		className: "",
		trip: null,
		formatDate: (date: IDynaTime) => (new Date(date.localDate)).toLocaleDateString(),
	};

	private readonly className = dynaClassName("dyna-travel-short-trip-overview");

	private renderSegment = (segment: IDynaTrip, index: number): JSX.Element => {
		const {
			formatDate,
		} = this.props;
		return (
			<tr key={index}>
				<td>{formatDate(segment.start)}</td>
				<td>{segment.segments[0].origin.name.codeName}</td>
				<td>{segment.segments[segment.segments.length - 1].destination.name.codeName}</td>
				<td>{formatDate(segment.end)}</td>
			</tr>
		);
	};

	public render(): JSX.Element {
		const {
			className: userClassName,
			trip,
		} = this.props;

		const className: string = this.className("", userClassName && "/" + userClassName);

		if (!trip) return null;

		return (
			<div className={className}>
				<table>
					<tbody>
					{trip.segments.map(this.renderSegment)}
					</tbody>
				</table>
			</div>
		);
	}
}