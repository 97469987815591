import * as React from 'react';
import {dynaClassName, DynaClassName} from "dyna-class-name";

import {AdBlockNotificationBanner} from "../../../banners/adblockNotificationBanner/AdBlockNotificationBanner";
import {IE11IsNotSupportedBanner} from "../../../banners/IE11IsNotSupportedBanner/IE11IsNotSupportedBanner";

import "./header-banners.css";

const className: DynaClassName = dynaClassName("header-banners");

export const HeaderBanners: React.SFC<{}> = (props) => (
  <div className={className()}>
    <AdBlockNotificationBanner/>
    <IE11IsNotSupportedBanner/>
  </div>
);
