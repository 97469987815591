import {EDeviceCategory, hasEnv} from "dyna-detect-env";
// @ts-ignore
import * as _scrollToElement from "scroll-to-element"; // help: https://www.npmjs.com/package/scroll-to-element

const DEFAULT_SCROLL_DURATION: number = 500;

export function scrollToTop(duration: number = 500, noAnimationOnMobileTablet: boolean = true): void {
  if (noAnimationOnMobileTablet && !hasEnv(EDeviceCategory.desktop)) {
    if (hasContentToScroll() > 500) {
      duration = 0;
    }
  }
  _scrollToElement(document.body, {
    duration,
    offset: 0,
    align: "top",
    ease: "out-circ",
  });
}

export function scrollToElement(element: Element | string, noAnimationOnMobileTablet: boolean = true, duration: number = DEFAULT_SCROLL_DURATION): void {
  if (noAnimationOnMobileTablet && !hasEnv(EDeviceCategory.desktop)) {
    duration = 0;
  }

  _scrollToElement(element, {
    duration,
    offset: -150,
    align: "top",
    ease: "out-circ",
  });
}


function hasContentToScroll() {
  const diff: number = document.body.scrollHeight - window.innerHeight;
  if (diff < 0) return 0; else return diff;
}

(window as any)._scrollToElement = _scrollToElement;

