import {InjectedIntl} from "react-intl";
import {round} from "dyna-loops";
import {IDynaPrice} from "dyna-travel-interfaces";

import {currencies} from "./currenciesConverter";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
// https://www.iso.org/iso-4217-currency-codes.html
// https://github.com/globalizejs/globalize#currency-module

export const formatPrice = (intl: InjectedIntl, price: IDynaPrice, currency?: string): string => {
	// https://github.com/yahoo/react-intl/wiki/API#formatnumber
	if (!price) return "---";
	if (price.value == null) return "---";
	if (price.currency == null) return round(price.value, 2).toString();  // the currency might be unknown, so return draftly the value
	const targetCurrency: string = currency || price.currency;

	return intl.formatNumber(
		currencies.convert(price.value, price.currency, targetCurrency),
		{
			style: 'currency',
			currency: targetCurrency,
		},
	);
};

