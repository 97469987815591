(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-highlight-text"), require("react"), require("dyna-ui-autocomplete"), require("lodash.isequal"), require("dyna-travel-interfaces"), require("dyna-guid"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-travel-ui-select-airport", ["dyna-highlight-text", "react", "dyna-ui-autocomplete", "lodash.isequal", "dyna-travel-interfaces", "dyna-guid"], factory);
	else if(typeof exports === 'object')
		exports["dyna-travel-ui-select-airport"] = factory(require("dyna-highlight-text"), require("react"), require("dyna-ui-autocomplete"), require("lodash.isequal"), require("dyna-travel-interfaces"), require("dyna-guid"));
	else
		root["dyna-travel-ui-select-airport"] = factory(root["dyna-highlight-text"], root["react"], root["dyna-ui-autocomplete"], root["lodash.isequal"], root["dyna-travel-interfaces"], root["dyna-guid"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_highlight_text__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_autocomplete__, __WEBPACK_EXTERNAL_MODULE_lodash_isequal__, __WEBPACK_EXTERNAL_MODULE_dyna_travel_interfaces__, __WEBPACK_EXTERNAL_MODULE_dyna_guid__) {
return 