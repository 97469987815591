import * as React from "react";

import {EColor} from "dyna-ui-styles";
import {IDynaPlace, IDynaTripRequestRoute} from "dyna-travel-interfaces";
import {
  DynaDatePickerFlightsInfo,
  EDynaDatePickerColor,
  EDatePickerStyle,
} from "dyna-travel-ui-date-info-pickers";

import {
  ESize,
  EMode,
  DynaTravelSelectAirport,
  EStyle as ESelectAirportStyle,
} from "dyna-travel-ui-select-airport";

import {EProduct} from "../interfaces";
import {DirectionExchangeIcon} from "../ui/DirectionExchangeIcon";
import {getDate0} from "../utils/getDate0";
import {faIcon} from "../utils/faIcon";
import {copyRoutes} from "../utils/copyTripRequest";
import { IDatePickerFlightInfoBasicConfig } from "../DynaSelectAirTrip";
import {renderDay, renderMonthYear} from "../utils/renderCalendarContent";

import {getOneFieldSizeUp} from "../utils/getButtonOneSizeUp";

import "./SelectRoutesMulti.less";

export {ESize};

export interface ISelectRoutesMultiProps {
  product: EProduct;
  mode: EMode;
  size: ESize;
  fieldsColor: EColor;
  fieldDatePickerColor: EDynaDatePickerColor;
  datePickerFlightInfoConfig: IDatePickerFlightInfoBasicConfig;
  formatDate: (date: Date) => string;
  fetchDebounceTimeout: number;
  routes: IDynaTripRequestRoute[];
  onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange: (routes: IDynaTripRequestRoute[]) => void;
  messages: {
    originLabel: string;
    originPlaceholder: string;
    originValidationOnEmptyForField: string;
    originValidationOnEmptyForValidationMessages: string;
    destinationLabel: string;
    destinationPlaceholder: string;
    destinationValidationOnEmptyForField: string;
    destinationValidationOnEmptyForValidationMessages: string;
    departureDateLabel: string;
    returnDateLabel: string;
    weekDaysShortNames: string;
    monthLongNames: string;
    datePickerTodayButton: string;
    datePickerCloseButton: string;
    calendarPickerBest: string;
    calendarPickerCheapest: string;
    calendarPickerFastest: string;
    calendarPickerNone: string;
  };
  _debug_calendarPickerStoreName: string;
}

export class SelectRoutesMulti extends React.Component<ISelectRoutesMultiProps> {
  private components: {
    [key: string]: any,
    selectOriginAirport: { [index: string]: DynaTravelSelectAirport };
    selectDestinationAirport: { [index: string]: DynaTravelSelectAirport };
    directionExchangeIcon: { [index: string]: DirectionExchangeIcon };
  } = {
    selectOriginAirport: null,
    selectDestinationAirport: null,
    directionExchangeIcon: {},
  };

  private get routes(): IDynaTripRequestRoute[] {
    const {routes} = this.props;
    return copyRoutes(routes);
  }

  private handleOriginChange(index: number, name: string, airport: IDynaPlace): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[index].origin = airport;
    onChange(routes);
  }

  private handleDestinationChange(index: number, name: string, airport: IDynaPlace): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[index].destination = airport;
    onChange(routes);
  }

  private handleDepartDateChange(index: number, name: string, date: Date): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[index].departDate = {date};

    // check the previous
    for (let iRoute: number = index - 1; iRoute >= 0; iRoute--) {
      if (routes[iRoute].departDate.date > routes[iRoute + 1].departDate.date) routes[iRoute].departDate.date = routes[iRoute + 1].departDate.date;
    }

    // check the next
    for (let iRoute: number = index + 1; iRoute < routes.length; iRoute++) {
      if (routes[iRoute].departDate.date < routes[iRoute - 1].departDate.date) routes[iRoute].departDate.date = routes[iRoute - 1].departDate.date;
    }

    onChange(routes);
  }

  public validate(): string[] {
    return this.routes.reduce((acc: string[], route: IDynaTripRequestRoute, index: number) => {
      acc = acc.concat(this.components.selectOriginAirport[index].validate());
      acc = acc.concat(this.components.selectDestinationAirport[index].validate());
      return acc;
    }, []);
  }

  private handleAddLine(index: number): void {
    const {mode, onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;

    if (mode === EMode.VIEW) return;

    routes.splice(index + 1, 0, {
      origin: routes[index].destination,
      destination: null,
      departDate: {...routes[index].departDate},
    }as IDynaTripRequestRoute);
    onChange(routes);
  }

  private renderAddButton(index: number): JSX.Element {
    const {mode} = this.props;
    const hide: boolean = mode === EMode.VIEW;
    const className: string = [
      'line-button',
      hide ? 'line-button--hide' : '',
    ].join(' ').trim();

    return (
      <div
        className={className}
        onClick={this.handleAddLine.bind(this, index)}
      >{faIcon('plus-circle')}</div>
    );
  }

  private handleToggleOriginDestination(index: number): void {
    const {mode, onChange} = this.props;
    if (mode !== EMode.EDIT) return;
    const routes: IDynaTripRequestRoute[] = this.routes;

    this.components.directionExchangeIcon[index].flip();

    let helper: IDynaPlace = routes[index].origin;
    routes[index].origin = routes[index].destination;
    routes[index].destination = helper;

    onChange(routes);
  }

  private handleRemoveLine(index: number): void {
    const {mode, onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    if (mode === EMode.VIEW) return;
    if (routes.length === 1) return;

    routes.splice(index, 1);
    onChange(routes);
  }

  private renderRemoveButton(index: number): JSX.Element {
    const {mode} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    const hide: boolean = mode === EMode.VIEW || routes.length === 1;
    const className: string = [
      'line-button',
      hide ? 'line-button--hide' : '',
    ].join(' ').trim();

    return (
      <div
        className={className}
        onClick={this.handleRemoveLine.bind(this, index)}
      >{faIcon('minus-circle')}</div>
    );
  }

  private renderRoute(index: number): JSX.Element {
    const {
      mode,
      size,
      fieldsColor,
      fieldDatePickerColor,
      datePickerFlightInfoConfig,
      formatDate,
      fetchDebounceTimeout,
      onAirportSearch,
      messages: {
        originLabel,
        originPlaceholder,
        originValidationOnEmptyForField,
        originValidationOnEmptyForValidationMessages,
        destinationLabel,
        destinationPlaceholder,
        destinationValidationOnEmptyForField,
        destinationValidationOnEmptyForValidationMessages,
        departureDateLabel,
        weekDaysShortNames,
        monthLongNames,
        datePickerTodayButton,
        datePickerCloseButton,
        calendarPickerBest,
        calendarPickerCheapest,
        calendarPickerFastest,
        calendarPickerNone
      },
      _debug_calendarPickerStoreName,
    } = this.props;

    const route: IDynaTripRequestRoute = this.routes[index];

    let minDepartDate = getDate0();
    if (index > 0) minDepartDate = this.routes[index - 1].departDate.date as Date;

    const setRef = (name: string, index: number, ref: any): void => {
      if (!this.components[name]) this.components[name] = {};
      this.components[name][index] = ref;
    };

    return (
      <div className="dyna-travel-select-air-trip-multi__line dyna-travel-route-container" key={index}>
        <DynaTravelSelectAirport
          className="select-origin"
          ref={setRef.bind(this, 'selectOriginAirport', index)}
          mode={mode}
          size={size}
          name="origin"
          label={originLabel}
          style={ESelectAirportStyle.WIDE_LABEL_ROUNDED}
          color={fieldsColor}
          inputProps={{
            placeholder: originPlaceholder,
          }}
          validationMessageOnEmptyForField={originValidationOnEmptyForField}
          validationMessageOnEmptyForValidationMessages={originValidationOnEmptyForValidationMessages}
          value={route.origin}
          fetchDebounceTimeout={fetchDebounceTimeout}
          onChange={this.handleOriginChange.bind(this, index)}
          onAirportSearch={onAirportSearch}
        />
        <div className="destination-container">
          <div className={`trip-change-directions mode-${mode}`} onClick={this.handleToggleOriginDestination.bind(this, index)}>
            <DirectionExchangeIcon ref={setRef.bind(this, 'directionExchangeIcon', index)}/>
          </div>
          <DynaTravelSelectAirport
            className="select-destination"
            ref={setRef.bind(this, 'selectDestinationAirport', index)}
            mode={mode}
            size={size}
            name="destination"
            label={destinationLabel}
            style={ESelectAirportStyle.WIDE_LABEL_ROUNDED}
            color={fieldsColor}
            inputProps={{
              placeholder: destinationPlaceholder,
            }}
            validationMessageOnEmptyForField={destinationValidationOnEmptyForField}
            validationMessageOnEmptyForValidationMessages={destinationValidationOnEmptyForValidationMessages}
            value={route.destination}
            fetchDebounceTimeout={fetchDebounceTimeout}
            onChange={this.handleDestinationChange.bind(this, index)}
            onAirportSearch={onAirportSearch}
          />
        </div>
        <DynaDatePickerFlightsInfo
          className="select-depart-date"
          mode={mode}
          style={EDatePickerStyle.WIDE_LABEL_ROUNDED}
          color={fieldDatePickerColor}
          size={size}
          pickerSize={getOneFieldSizeUp(size)}
          name="departDate"
          min={minDepartDate}
          label={departureDateLabel}
          todayButtonLabel={datePickerTodayButton}
          closeButtonLabel={datePickerCloseButton}
          renderPickerWeekDay={(weekDay: number) => renderDay(weekDaysShortNames, weekDay)}
          renderPickerMonthYear={(month: number, year: number) => renderMonthYear(monthLongNames, month, year)}
          renderInputDate={formatDate}
          value={route.departDate.date as Date}
          flightsInfoConfig={{
            ...datePickerFlightInfoConfig,
            roundTrip: false,
            origin: route.origin && route.origin.name.codeName,
            destination: route.destination && route.destination.name.codeName,
            messages: {
              best: calendarPickerBest,
              cheapest: calendarPickerCheapest,
              fastest: calendarPickerFastest,
              none: calendarPickerNone,
            },
            _debug_dynaduxDebugGlobalVarName: _debug_calendarPickerStoreName && _debug_calendarPickerStoreName + `__routeMulti__${index}` ,
          }}
          onChange={this.handleDepartDateChange.bind(this, index)}
        />
        <div className="multi-trip-line-buttons-container">
          {this.renderAddButton(index)}
          {this.renderRemoveButton(index)}
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="dyna-travel-select-air-trip-multi">
        {this.routes.map((route: IDynaTripRequestRoute, index: number) => this.renderRoute(index))}
      </div>
    );
  }
}
