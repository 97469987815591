import * as React from "react";
import {EDynaPassengerType, EDynaTripClass, IDynaPassenger} from "dyna-travel-interfaces";
import {EColor} from "dyna-ui-styles";
import {DynaFieldWrapper, ESize, EStyle} from "dyna-ui-field-wrapper";
import {DynaNumber} from "dyna-ui-number";
import {DynaPlainRadioButton} from "dyna-ui-radiobutton";
import {DynaButton} from "dyna-ui-button";
import {DynaPickerContainer, EColor as EPickerColor} from "dyna-ui-picker-container";

import {EMode} from "../interfaces";

import {faIcon} from "../utils/faIcon";

import "./SelectTripMisc.less";

export {
  EColor,
  EPickerColor,
}

export interface ISelectTripMiscProps {
  mode: EMode;

  color: EColor;

  pickerColor: EPickerColor;
  fieldsColor: EColor;
  closeButtonColor: EColor;

  messages: {
    tripClassEconomy: string;
    tripClassBusiness: string;
    passengerAdultsLabel: string;
    passengerChildrenLabel: string;
    passengerInfantsLabel: string;
    passengerSelectionInfo: string;
  },

  data: IMiscData;

  onChange: (data: IMiscData) => void;
}

export interface IMiscData {
  tripClass: EDynaTripClass,
  passengers: Array<IDynaPassenger>;
}

export interface ISelectTripMiscState {
  pop: boolean;
}

export class SelectTripMisc extends React.Component<ISelectTripMiscProps, ISelectTripMiscState> {
  constructor(props: ISelectTripMiscProps) {
    super(props);
    this.state = {
      pop: false,
    }
  }

  private handleClassTripChange(tripClass: EDynaTripClass): void {
    const {data, onChange} = this.props;
    onChange({
      ...data,
      tripClass,
    })
  }

  private handlePassengerTypeChange(passengerType: EDynaPassengerType, name: string, value: number): void {
    const {data, onChange} = this.props;

    let passengers: IDynaPassenger[] = data.passengers
      .filter((passenger: IDynaPassenger) => passenger.type !== passengerType)
      .concat(Array(value).fill({type: passengerType}as IDynaPassenger));

    if (passengers.length === 0) {
      passengers.push({type: EDynaPassengerType.ADULT});
    }

    onChange({
      ...data,
      passengers,
    });
  }

  private handleCloseButtonClick(): void {
    this.setState({pop: false});
  }

  private handlePickerContainerClick(event: Event): void {
    // block the events otherwise the handleFieldWrapperClick will close it
    event.preventDefault();
    event.stopPropagation();
  }

  private countPassengers(passengers: IDynaPassenger[]): {
    adults: number;
    children: number;
    infants: number;
  } {
    return {
      adults: passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.ADULT).length,
      children: passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.CHILD).length,
      infants: passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.INFANT).length,
    }
  }

  private renderPicker(): JSX.Element {
    const {
      pickerColor,
      messages: {
        tripClassEconomy,
        tripClassBusiness,
        passengerAdultsLabel,
        passengerChildrenLabel,
        passengerInfantsLabel,
        passengerSelectionInfo,
      },
      data: {
        tripClass,
        passengers,
      },
      fieldsColor,
      closeButtonColor,
    } = this.props;

    const adults: number = passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.ADULT).length;
    const children: number = passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.CHILD).length;
    const infants: number = passengers.filter((passenger: IDynaPassenger) => passenger.type === EDynaPassengerType.INFANT).length;

    const {pop} = this.state;

    return (
      <DynaPickerContainer
        show={pop}
        color={pickerColor}
      >
        <div className="select-trip-misc--pick-container" onClick={this.handlePickerContainerClick.bind(this)}>

          <div className="class-type-container">
            <div className="class-type-option">
              <DynaPlainRadioButton
                label={tripClassEconomy}
                checked={tripClass === EDynaTripClass.ECONOMY}
                onChange={(checked: boolean) => checked && this.handleClassTripChange(EDynaTripClass.ECONOMY)}
              />
            </div>
            <div className="class-type-option">
              <DynaPlainRadioButton
                label={tripClassBusiness}
                checked={tripClass === EDynaTripClass.BUSINESS}
                onChange={(checked: boolean) => checked && this.handleClassTripChange(EDynaTripClass.BUSINESS)}
              />
            </div>
          </div>

          <div className="select-trip-misc--content-container">

            <div className="select-trip-misc--fields-container">
              <DynaNumber
                color={fieldsColor}
                size={ESize.SMALL}
                min={1}
                max={8}
                label={passengerAdultsLabel}
                value={adults}
                onChange={this.handlePassengerTypeChange.bind(this, EDynaPassengerType.ADULT)}
              />
              <DynaNumber
                color={fieldsColor}
                size={ESize.SMALL}
                min={0}
                max={8}
                label={passengerChildrenLabel}
                value={children}
                onChange={this.handlePassengerTypeChange.bind(this, EDynaPassengerType.CHILD)}
              />
              <DynaNumber
                color={fieldsColor}
                size={ESize.SMALL}
                min={0}
                max={8}
                label={passengerInfantsLabel}
                value={infants}
                onChange={this.handlePassengerTypeChange.bind(this, EDynaPassengerType.INFANT)}
              />
            </div>

            <div className="info-container">
              <div className="info-container--icon">{faIcon('info-circle')}</div>
              <div className="info-container--text">{passengerSelectionInfo}</div>
            </div>

          </div>

          <DynaButton
            className="close-button"
            size={ESize.LARGE}
            color={closeButtonColor}
            onClick={this.handleCloseButtonClick.bind(this)}
          >{faIcon('check')} ok</DynaButton>

        </div>
      </DynaPickerContainer>
    );
  }

  private renderInput(): JSX.Element {
    const {
      data: {
        tripClass,
        passengers,
      },
      messages: {
        passengerAdultsLabel,
        passengerChildrenLabel,
        passengerInfantsLabel,
        tripClassEconomy,
        tripClassBusiness,
      },
    } = this.props;
    const {
      adults, children, infants,
    } = this.countPassengers(passengers);

    return <div className="select-misc-sum-container">
      <div className={`trip-class trip-class-${tripClass}`}>
        {tripClass === EDynaTripClass.ECONOMY ? <span>{faIcon('money')} {tripClassEconomy}</span> : null}
        {tripClass === EDynaTripClass.BUSINESS ? <span>{faIcon('glass')} {tripClassBusiness}</span> : null}
      </div>
      <div className="passengers-sum">
        {adults ? <span>{adults} {passengerAdultsLabel}</span> : null}
        {children ? <span>{children} {passengerChildrenLabel}</span> : null}
        {infants ? <span>{infants} {passengerInfantsLabel}</span> : null}
      </div>
      <input hidden/>
    </div>
  }

  private handlerFieldClick(): void {
    const {
      mode,
    } = this.props;

    if (mode !== EMode.EDIT) return;

    this.setState({pop: !this.state.pop});
  }

  private handlerFieldOutsideClick(): void {
    this.setState({pop: false});
  }

  public render(): JSX.Element {
    const {
      mode,
      color,
    } = this.props;

    return (
      <DynaFieldWrapper
        className="select-misc-control"
        mode={mode}
        label={<span>{faIcon('users')}</span>}
        style={EStyle.INLINE_ROUNDED}
        color={color}
        size={ESize.SMALL}
        inputElementSelector="input"
        footer={this.renderPicker()}
        onClick={this.handlerFieldClick.bind(this)}
        onOutsideClick={this.handlerFieldOutsideClick.bind(this)}
      >{this.renderInput()}</DynaFieldWrapper>
    );
  }

}
