
// Locale: syntax:
//      <language (2 chars in lowercase)><COUNTRY (2 chars in uppercase)><Language variation (optional)>
//      <ISO 639-1><ISO Alpha-2><string>
// Info for ISO 639-1 --> https://www.loc.gov/standards/iso639-2/php/code_list.php
// Info for ISO Alpha-2 --> http://www.nationsonline.org/oneworld/country_code_list.htm
export type TLocale = string;

export interface IDynaTranslations {
	defaultLocale: TLocale;
	fallbacks: {
		[failedLocale: string]: TLocale; // source of the failed locale
	};
	localeOptions: IDynaTranslationLocaleOption[];
	messages: IDynaTranslationMessages;
}

export interface IDynaTranslation {
	locales: IDynaTranslationLocaleOption[];
	messages: IDynaTranslationMessages;
}

export interface IDynaTranslationMessages {
	[messageId: string]: {
		[locale: string]: string;     // the intl format translated text
	};
}

export interface IIntlMessage {
	[messageId: string]: string;
}

export interface IDynaTranslationLocaleOption {
	locale: string;
	label: string;
}

export class DynaTranslationManager {
	private supportedLocales: { [locale: string]: boolean } = {};

	private translations: IDynaTranslations;

	public loadTranslations(translations: IDynaTranslations): void {
		this.supportedLocales = {};
		this.translations = translations;
		Object.keys(this.translations.messages)
			.forEach((messageId) => {
				Object.keys(this.translations.messages[messageId])
					.forEach((locale: TLocale) => {
						this.supportedLocales[locale] = true;
					});
			});
	}

	public isLocaleSupported(locale: TLocale): boolean {
		return !!this.supportedLocales[locale];
	}

	public getIntlMessages(locale: TLocale): IIntlMessage {
		const output = {};
		Object.keys(this.translations.messages)
			.forEach((messageId) => {
				const idMessages = this.translations.messages[messageId];
				output[messageId] = idMessages[locale];
				if (!output[messageId]) output[messageId] = idMessages[this.translations.fallbacks[locale]];
				if (!output[messageId]) output[messageId] = idMessages[this.translations.defaultLocale];
			});

		return output;
	}

	public getBrowserLocale(): string {
		return ((window as any).navigator.userLanguage || window.navigator.language).replace(/-/g, '');
	}
}
