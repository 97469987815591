import * as React from "react";
import {IDynaPlace, IDynaTime, IDynaTrip} from "dyna-travel-interfaces";

import {faIcon} from "../../utils/faIcon";

import "./DateTimeTripOverview.less";

export interface IDateTimeTripOverviewProps {
  className?: string;
  trip: IDynaTrip;
  formatTimeFromString: (timeHHMM: string) => string;
  formatDate: (date: Date) => string;
  formatDuration?: (minutes: number) => string;
  messages: {
    longDayNames: string;
    totalTravelDuration: string;
    waitDuration: string;
    longWait: string;
    stops: string;
    direct: string;
  };
}

enum EPlaceType {
  ORIGIN = "ORIGIN",
  DESTINATION = "DESTINATION",
}

export class DateTimeTripOverview extends React.Component<IDateTimeTripOverviewProps> {
  private readonly className: string = "data-time-trip-overview";

  private renderDateTimePlace(time: IDynaTime, place: IDynaPlace, placeType: EPlaceType): JSX.Element {
    const {formatDate, formatTimeFromString} = this.props;
    const getClassName = (subClassName: string = "") => `${this.className}__date-time-place${subClassName}`;
    const className: string = [
      getClassName(),
      getClassName(`--type-${placeType}`),
    ].join(' ').trim();
    return (
      <div className={className}>
        <div className={getClassName("__time")}>{formatTimeFromString(time.localTime)}</div>
        <div className={getClassName("__day")}>{this.getWeekDayName(time.localDate)}</div>
        <div className={getClassName("__date")}>{formatDate(new Date(time.localDate))}</div>
        <div className={getClassName("__place-code-name")}>{place.name.codeName}</div>
        <div className={getClassName("__place-city")}>{place.city.name.text}</div>
      </div>
    );
  }

  private renderDurations(trip: IDynaTrip): JSX.Element {
    const {
      formatDuration,
      messages: {
        totalTravelDuration, waitDuration, longWait,
        stops: stopsLabel,
        direct,
      },
    } = this.props;

    const getClassName = (subClassName: string = "") => `${this.className}__durations${subClassName}`;
    const className: string = [
      getClassName(),
    ].join(' ').trim();

    const moons: JSX.Element[] =
      Array(trip.stats.nightLongWaitingTimes || 0)
        .fill(null)
        .map((v: any, index: number) => faIcon('moon-o', '', index) || v as any);

    return (
      <div className={className}>
        <div className={getClassName("__travel-duration-value")}>
          <span>{formatDuration(trip.stats.durationInMin)}</span>
          {moons.length ? <span className={getClassName("__night-long-waiting")}>{moons}</span> : null}
        </div>
        <div className={getClassName("__travel-duration-label")}>
          <span>{totalTravelDuration}</span>
        </div>
        {trip.stats.waitTimeInMin ? <div className={getClassName("__wait-duration-value")}>
          <span>{formatDuration(trip.stats.waitTimeInMin)}</span>
        </div> : null}
        {trip.stats.waitTimeInMin ? <div className={getClassName("__wait-duration-label")}>
          {trip.stats.longWaitTime
            ? <span className={getClassName("__wait-duration-label--long-wait")}>{faIcon('exclamation-circle')} {longWait}</span>
            : <span>{waitDuration}</span>
          }
        </div> : null}
        <div className={getClassName("__stops")}>
          {trip.stats.changes
            ? <span><span className={getClassName("__stops-value")}>{trip.stats.changes} </span>{stopsLabel}</span>
            : <span className={getClassName("__stops-direct")}>{direct}</span>
          }
        </div>
      </div>
    );
  }

  private getWeekDayName(date: string): string {
    const {messages: {longDayNames}} = this.props;
    return longDayNames
      .split(" ")
      .filter((d: string) => !!d)
      [(new Date(date)).getDay()];
  }

  private getOrigin(trip: IDynaTrip): IDynaPlace {
    return trip.segments[0].origin;
  }

  private getDestination(trip: IDynaTrip): IDynaPlace {
    return trip.segments[trip.segments.length - 1].destination;
  }

  public render(): JSX.Element {
    const {
      className: userClassName,
      trip,
    } = this.props;

    const className: string = [
      userClassName,
      this.className,
    ].join(' ').trim();

    if (!trip) return null;

    return (
      <div className={className}>
        {this.renderDateTimePlace(trip.start, this.getOrigin(trip), EPlaceType.ORIGIN)}
        {this.renderDurations(trip)}
        {this.renderDateTimePlace(trip.end, this.getDestination(trip), EPlaceType.DESTINATION)}
      </div>
    );
  }
}
