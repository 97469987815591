import * as React from "react";
import {EDynaTransport, IDynaPlace, IDynaTime, IDynaTrip} from "dyna-travel-interfaces";
import {DynaTooltip, ETooltipDirection} from "dyna-ui-tooltip";
import {PlaceInfo, TimeInfo} from "dyna-travel-ui-components";

import {EColor, EViewType} from "../DynaTravelTripTimeline";

import {isDesktop} from "../utils/isDesktop";
import {styleMixer} from "../styleMixer";

import "./PlaceTime.less";

export enum ESegmentPlace {
	ORIGIN = "ORIGIN",
	DESTINATION = "DESTINATION",
}

export interface IPlaceTimeProps {
	viewType: EViewType,
	color: EColor;
	segment: IDynaTrip;
	segmentPlace: ESegmentPlace; // nullable
	dayIsChanged: boolean;
	isFirst: boolean;
	isLast: boolean;
	formatTime: (time: string) => string;
	formatFullDate: (dateIso: string) => string;
	messages: {
		nextDay: string;
		nextDateTooltip: string;
	};
}

export class PlaceTime extends React.Component<IPlaceTimeProps> {
	private readonly className: string = "dt-trip-timeline__place-time";

	private getPlace(): IDynaPlace {
		const {segment, segmentPlace} = this.props;
		if (segmentPlace === ESegmentPlace.ORIGIN && segment.transport.type === EDynaTransport.WAIT) {
			return segment.destination;
		}
		switch (segmentPlace) {
			case ESegmentPlace.ORIGIN:
				return segment.origin;
			case ESegmentPlace.DESTINATION:
				return segment.destination;
			default:
				return null;
		}
	}

	private getTime(): IDynaTime {
		const {segment, segmentPlace} = this.props;
		if (segmentPlace === ESegmentPlace.ORIGIN && segment.transport.type === EDynaTransport.WAIT) {
			return segment.end;
		}
		switch (segmentPlace) {
			case ESegmentPlace.ORIGIN:
				return segment.start;
			case ESegmentPlace.DESTINATION:
				return segment.end;
			default:
				return null;
		}
	}

	private renderTooltip(): JSX.Element {
		const {
			dayIsChanged,
			messages,
			formatTime,
			formatFullDate,
		} = this.props;
		return (
			<div>
				<TimeInfo
					className="dttl-point-time-tooltip"
					time={this.getTime()}
          inOneLine={false}
          showNextDayIndication={dayIsChanged}
					messages={messages}
					formatTime={formatTime}
					formatFullDate={formatFullDate}
				/>
				<PlaceInfo place={this.getPlace()}/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {
			viewType,
			color,
			isFirst, isLast,
			messages,
			formatTime,
			formatFullDate,
		} = this.props;

		const containerClassName: string = [
			this.className,
			'dt-trip-timeline__label_content',
			`${this.className}--view-type-${viewType}`,
			`${this.className}--color-${color}`,
			isFirst ? `${this.className}--is-first`: '',
			isLast ? `${this.className}--is-last`: '',
		].join(' ').trim();

		let place: IDynaPlace = this.getPlace();
		if (!place) return null;

		return (
			<div className={containerClassName}>
				<DynaTooltip
					enabled={isDesktop}
					color={styleMixer(color).tooltipColor}
					delayCreationMs={1000}
					tooltipDirection={ETooltipDirection.NORTH}
					tooltipContent={this.renderTooltip()}
					_debug_doNotHide={false}
				>
					<TimeInfo
						className="dttl-point-time"
						time={this.getTime()}
            inOneLine={false}
						formatTime={formatTime}
						formatFullDate={formatFullDate}
						messages={messages}
					/>
					<div className={`${this.className}__name`}>{place.city.name.text}</div>
					<div className={`${this.className}__code-name`}>{place.name.codeName}</div>
				</DynaTooltip>
			</div>
		);
	}
}
