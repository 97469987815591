import * as React from "react";

import {DynaAnimation3dFlip, EAnimationDuration, EOrientation} from "dyna-animation";

import {IDynaPassenger, IDynaPrice, IDynaTripProposal} from "dyna-travel-interfaces";

import {EProduct} from "../../interfaces";
import {IDynaTravelListProposalsMessages} from "../../messages";
import {LoadMoreButton} from "../LoadMoreButton/LoadMoreButton";
import {NoTripFound} from "../NoTripFound/NoTripFound";

import {calcTripProposalHeight, TripProposalItem} from "../TripProposalItem/TripProposalItem";
import {styleMixer} from "../../styleMixer";

import "./ListTripProposals.less";

const eDuration: EAnimationDuration = EAnimationDuration.MS500;

export interface IListTripProposalsProps {
  className?: string;
  animationEnabled: boolean;
  currency: string;
  passengers: IDynaPassenger[];
  isLoading: boolean;
  hasMoreProposals?: number;
  noTripsFound: boolean;
  product: EProduct;
  messages: IDynaTravelListProposalsMessages;
  tripProposals: IDynaTripProposal[];
  proposalsExpired: boolean;
  requestTripDirectFlightsOnly: boolean;
  formatDate: (date: Date) => string;
  formatTimeFromString: (timeHHMM: string) => string;
  formatFullDateFromString: (dateYYYYMMDD: string) => string;
  formatPrice: (price: IDynaPrice, currency: string) => string;
  formatDuration?: (minutes: number) => string;
  isLoadingIcon: JSX.Element;
  onSelectProposalClick: (tripProposal: IDynaTripProposal) => void;
  onLoadMoreProposals: () => void;
  onSelectTodayClick: () => void;
  onSelectNextWeekendClick: () => void;
}

export class ListTripProposals extends React.Component<IListTripProposalsProps> {
  public render(): JSX.Element {
    const {
      product,
      animationEnabled,
      currency,
      isLoading,
      passengers,
      hasMoreProposals,
      noTripsFound,
      messages,
      messages: {
        loadMoreProposals,
        isLoadingMoreProposals,
      },
      className: userClassName,
      tripProposals,
      proposalsExpired,
      requestTripDirectFlightsOnly,
      formatDate,
      formatTimeFromString,
      formatFullDateFromString,
      formatPrice,
      formatDuration,
      isLoadingIcon,
      onSelectProposalClick,
      onLoadMoreProposals,
      onSelectTodayClick,
      onSelectNextWeekendClick,
    } = this.props;

    const className: string = [
      userClassName,
      "dtlp-trip-proposal-list",
      !noTripsFound && !!!tripProposals.length ? `dtlp-trip-proposal-list--has-no-items` : "",
    ].join(' ').trim();

    const showLoadMoreButton: boolean = !!hasMoreProposals;

    return (
      <div className={className}>
        {tripProposals.map((tripProposal: IDynaTripProposal, index: number) => {
          return (
            <DynaAnimation3dFlip
              animationEnabled={animationEnabled}
              key={index}
              show={true}
              direction={EOrientation.VERTICAL}
              duration={eDuration}
              perspective={600}
              height={calcTripProposalHeight(tripProposal)}
            >
              <div className="dtlp-trip-proposal-list__item-wrapper">
                <TripProposalItem
                  product={product}
                  currency={currency}
                  passengers={passengers}
                  messages={messages}
                  tripProposal={tripProposal}
                  proposalsExpired={proposalsExpired}
                  formatDate={formatDate}
                  formatTimeFromString={formatTimeFromString}
                  formatFullDateFromString={formatFullDateFromString}
                  formatPrice={formatPrice}
                  formatDuration={formatDuration}
                  onSelectClick={onSelectProposalClick}
                />
              </div>
            </DynaAnimation3dFlip>
          );
        })}
        <LoadMoreButton
          show={showLoadMoreButton}
          color={styleMixer(product).loadMoreButtonColor}
          isLoading={isLoading}
          labelLoadMore={loadMoreProposals}
          labelIsLoading={isLoadingMoreProposals}
          isLoadingIcon={isLoadingIcon}
          listedProposals={tripProposals.length}
          hasMoreProposals={hasMoreProposals}
          onClickLoadMore={onLoadMoreProposals}
        />
        {noTripsFound ? <NoTripFound
          product={product}
          requestTripDirectFlightsOnly={requestTripDirectFlightsOnly}
          messages={messages}
          onSelectTodayClick={onSelectTodayClick}
          onSelectNextWeekendClick={onSelectNextWeekendClick}
        /> : null}
      </div>
    );
  }
}
