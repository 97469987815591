import { DynaNodeClient } from "dyna-node/dist/commonJs/DynaNodeClient/DynaNodeClient";
import * as moment from "moment";

import {
  COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP,
  ICOMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_request_args,
  ICOMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_response_data,
} from "dyna-travel-services/dist/commonJs/web-client";

export interface IFlightMonthInfoApiData {
  flightPrices: ICOMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_response_data;
}

const client = new DynaNodeClient();

export const getFlightMonthlyInfo = async (
  {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
    yyyymmdd,
    origin,
    destination,
    roundTrip,
    loadMonths,
    updatedWeeksAgo,
  }: {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: string;
    yyyymmdd: string;
    origin: string;
    destination: string;
    roundTrip: boolean;
    loadMonths: number;
    updatedWeeksAgo: number
  }
): Promise<IFlightMonthInfoApiData> => {
  const flightPrices = await client.fetch<ICOMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_request_args, null, ICOMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_response_data>({
    to: COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
    command: COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP,
    args: {
      depart: yyyymmdd,
      origin,
      destination,
      loadMonths,
      roundTrip,
      updatedAfterTimestamp: moment().subtract(updatedWeeksAgo, 'week').valueOf(),
    },
  });

  return {
    flightPrices,
  };
};
