import {addLocaleData} from 'react-intl';

import * as enLocaleData from 'react-intl/locale-data/en';
import * as noLocaleData from 'react-intl/locale-data/no';
import * as frLocaleData from 'react-intl/locale-data/fr';
import * as elLocaleData from 'react-intl/locale-data/el';
import * as zhLocaleData from 'react-intl/locale-data/zh';
import * as ukLocaleData from 'react-intl/locale-data/uk';
import * as deLocaleData from 'react-intl/locale-data/de';
import * as esLocaleData from 'react-intl/locale-data/es';
import * as itLocaleData from 'react-intl/locale-data/it';
import * as hiLocaleData from 'react-intl/locale-data/hi';

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);
addLocaleData(elLocaleData);
addLocaleData(noLocaleData);
addLocaleData(frLocaleData);
addLocaleData(zhLocaleData);
addLocaleData(ukLocaleData);
addLocaleData(deLocaleData);
addLocaleData(esLocaleData);
addLocaleData(itLocaleData);
addLocaleData(hiLocaleData);

import {
  IDynaTranslationLocaleOption,
  IDynaTranslations,
} from "../packages/dyna-translation-manager/DynaTranslationManager";

// Locale: syntax: <ISO 639-1><ISO Alpha-2>
// Info for ISO 639-1 --> https://www.loc.gov/standards/iso639-2/php/code_list.php
// Info for ISO Alpha-2 --> http://www.nationsonline.org/oneworld/country_code_list.htm
// Also useful

export const appTranslations: IDynaTranslations = {
  defaultLocale: 'enUS',
  fallbacks: {
    en: 'enUS',
    enUK: 'enUS',
    deDE: 'deAT',
  },
  localeOptions: [],
  messages: {}, // den note: do not load messages here, but on each module!
};

export const addTranslationMessage = (section: string, key: string, locale: string, message: string): void => {
  section; // 4ts
  if (!key || !locale || !message) return;
  if (!appTranslations.messages[key]) appTranslations.messages[key] = {};
  appTranslations.messages[key][locale] = message;
};
(window as any).addTranslationMessage = addTranslationMessage;

export const appTranslationLocaleOptions: IDynaTranslationLocaleOption[] =
  [
    // dev note: Modules might support many languages but might support completely different.
    // Here we define which languages the application mainly supports.
    // Is a message doesn't exist to a language the `fallbacks` or the `defaultLocale` will be used.
    {locale: 'enUS', label: 'English, US'},
    {locale: 'enGB', label: 'English, UK'},
    // {locale: 'frFR', label: 'French'},
    {locale: 'deDE', label: 'German, Germany'},
    {locale: 'deAT', label: 'German, Austria'},
    // {locale: 'noNO', label: 'Norwegian'},
    {locale: 'itIT', label: 'Italian'},
    // {locale: 'esES', label: 'Spanish'},
    {locale: 'elGR', label: 'Greek'},
    // {locale: 'zhCN', label: 'Chinese'},
    {locale: 'enUSCodes', label: 'English (with §codes)'},
    {locale: 'hiIN', label: 'Hindi'},
  ]
    .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

export const loadAppTranslations = (): void => {
  // then load the messages, to override the messages of the modules
  require('./../locales/load-script-translations.js');

  // create the enUSCodes
  Object.keys(appTranslations.messages).forEach((messageKey) => {
    appTranslations.messages[messageKey].enUSCodes = `§${messageKey.substr(4)} ${appTranslations.messages[messageKey].enUS}`;
  });
};
