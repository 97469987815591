import {DynaCurrencies, ICurrency} from "dyna-currencies";
import {DynaLocalStorageData} from "dyna-local-storage";
import {IDynaTranslationLocaleOption} from "../../../packages/dyna-translation-manager/DynaTranslationManager";

import {IReduxAction} from "../../../redux/interfaces";

import {appConfig} from "../../../settings/app";

import {IMenu} from "../../../interfaces/menu";

import {DYNA_TRAVEL_MODULE_ROUTES} from "../../DynaTravel/routes/dynaTravelModuleRoutes";

import {appTranslationLocaleOptions} from "../../../locales/translations";

import {
  APP_CHANGE_LOCALE,
  APP_CHANGE_CURRENCY,
  APP_UPDATE_ROUTE_INFO,
  APP_REQUEST_METADATA,
  APP_RECEIVE_METADATA,
  APP_VIEW_CENTER,
  APP_MENU_UPDATE_IS_LOADING,
  APP_IS_LOADING_FULL_SCREEN,
  APP_SHOW_ENGLISH_WITH_CODES_OPTION,
} from './actions';

export interface IAppState {
  path: string;

  isLoadingFullScreen: boolean;
  isLoadingFullScreenMessage: string;

  locale: string;
  localeOptions: IDynaTranslationLocaleOption [];
  showEnglishWithCodesOption: boolean;

  currency: string;
  currencies: ICurrency[];

  menus: {
    mainMenu: IMenu[];
  };
  viewCenter: boolean;
}

interface IMemory {
  locale: string;
  currency: string;
}

const initialMemory: IMemory = {
  locale: null,
  currency: null,
};

const memory: DynaLocalStorageData<IMemory> = new DynaLocalStorageData('dyna-client-app-data', initialMemory);

/**
 * Returns the browser local in "llcc" format, (language country).
 * help: https://www.metamodpro.com/browser-language-codes
 */
const getBrowserLocale = (): string => {
  const localeMap = {
    nb: "nbNO",
    el: "elGR",
  };
  if (memory.data.locale) {
    return memory.data.locale;
  }
  else {
    let locale: string;
    const browserLocale: string = ((window as any).navigator.userLanguage || window.navigator.language);
    if (browserLocale.indexOf('-') > 1) {
      locale = browserLocale.replace(/-/g, '');
    }
    else if (localeMap[browserLocale.toLowerCase()]) {
      locale = localeMap[browserLocale.toLowerCase()];
    }
    else if (browserLocale.length === 2) {
      locale = `${browserLocale.toLowerCase()}${browserLocale.toUpperCase()}`;
    }
    else {
      console.warn('Cannot standardise this locale [', browserLocale, '] the [enUS] is applied');
      locale = "enUS";
    }
    memory.data.locale = locale;
    memory.save();
    return locale;
  }
};

let dynaCurrencies = new DynaCurrencies();

const getDefaultCurrency = (): string => {
  let currency: string;
  if (memory.data.currency) {
    currency = memory.data.currency;
  }
  else {
    const locale: string = getBrowserLocale();
    const country: string = locale.substr(2, 2);
    currency =
      (
        dynaCurrencies.getCurrencyByCountry(country)
        || dynaCurrencies.getCurrencyByCountry("us")
      ).code;
  }
  memory.data.currency = currency;
  memory.save();
  return currency;
};

const getCurrencies = (): ICurrency[] => {
  let currencies: ICurrency[] = [].concat(dynaCurrencies.getCurrencies());
  const mainCurrencies: string[] = appConfig.mainCurrencies;
  const mainCurrenciesItems: ICurrency[] = mainCurrencies.map((currencyCode) => currencies.find((c) => c.code === currencyCode));
  currencies = currencies.filter((currency: ICurrency) => appConfig.mainCurrencies.indexOf(currency.code) === -1);
  return [].concat(mainCurrenciesItems, currencies);
};

const initialState: IAppState = {
  locale: getBrowserLocale(),
  localeOptions: appTranslationLocaleOptions,
  showEnglishWithCodesOption: false,
  isLoadingFullScreen: false,
  isLoadingFullScreenMessage: null,
  currency: getDefaultCurrency(),
  currencies: getCurrencies(),
  path: '',
  menus: {
    mainMenu: [
      {
        caption: 'MSG_0614', // Flights
        path: DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_SEARCH_FLIGHTS.link(),
        appSection: null,
        isLoading: false,
        tags: ["flight-search-process-id-main"],
      },
      // {
      // 	caption: 'MSG_0616', // My trips
      // 	path: DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_MY_TRIPS.link(),
      // 	appSection: null,
      // 	isLoading: false,
      // 	tags: [],
      // },
      // {
      // 	caption: 'MSG_0612', // Users
      // 	path: USER_MANAGEMENT_ROUTES.USERS.link(),
      // 	appSection: EAppSection.USERS,
      // 	isLoading: false,
      // 	tags: [],
      // },
      // {
      // 	caption: 'MSG_0613', // About
      // 	path: APP_ROUTES.ABOUT.link(),
      // 	appSection: null,
      // 	isLoading: false,
      // 	tags: [],
      // },
    ],
  },
  viewCenter: true,
};

dynaCurrencies = null;

export const appReducer = (state: IAppState = initialState, action: IReduxAction): IAppState => {
  switch (action.type) {

    case APP_CHANGE_LOCALE:
      memory.data.locale = action.payload.locale;
      memory.save();
      return {
        ...state,
        locale: action.payload.locale,
      };

    case APP_CHANGE_CURRENCY:
      memory.data.currency = action.payload.currency;
      memory.save();
      return {
        ...state,
        currency: action.payload.currency,
      };

    case APP_UPDATE_ROUTE_INFO:
      return {
        ...state,
        path: action.payload.currentAppPath,
      };

    case APP_SHOW_ENGLISH_WITH_CODES_OPTION:
      return {
        ...state,
        showEnglishWithCodesOption: true,
      };

    case APP_REQUEST_METADATA:
      return state; // todo, (framework need) implement

    case APP_RECEIVE_METADATA:
      return state; // todo, (framework need) implement

    case APP_VIEW_CENTER:
      return {
        ...state,
        viewCenter: action.payload.viewCenter,
      };

    case APP_MENU_UPDATE_IS_LOADING:
      return {
        ...state,
        menus: {
          ...state.menus,
          mainMenu: state.menus.mainMenu.map((menu: IMenu) => {
            if (menu.tags.indexOf(action.payload.menuTag) > -1) {
              return {
                ...menu,
                isLoading: action.payload.isLoading,
              };
            }
            else {
              return menu;
            }
          }),
        },
      };

    case APP_IS_LOADING_FULL_SCREEN:
      return {
        ...state,
        isLoadingFullScreen: action.payload.isLoading,
        isLoadingFullScreenMessage: typeof action.payload.message === "undefined" ? state.isLoadingFullScreenMessage : action.payload.message,
      };

    default:
      return state;
  }
};
