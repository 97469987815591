(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("dyna-ui-tooltip"), require("dyna-travel-interfaces"), require("dyna-travel-ui-components"), require("dyna-detect-env"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-travel-ui-trip-timeline", ["react", "dyna-ui-tooltip", "dyna-travel-interfaces", "dyna-travel-ui-components", "dyna-detect-env"], factory);
	else if(typeof exports === 'object')
		exports["dyna-travel-ui-trip-timeline"] = factory(require("react"), require("dyna-ui-tooltip"), require("dyna-travel-interfaces"), require("dyna-travel-ui-components"), require("dyna-detect-env"));
	else
		root["dyna-travel-ui-trip-timeline"] = factory(root["react"], root["dyna-ui-tooltip"], root["dyna-travel-interfaces"], root["dyna-travel-ui-components"], root["dyna-detect-env"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_tooltip__, __WEBPACK_EXTERNAL_MODULE_dyna_travel_interfaces__, __WEBPACK_EXTERNAL_MODULE_dyna_travel_ui_components__, __WEBPACK_EXTERNAL_MODULE_dyna_detect_env__) {
return 