import * as React from "react";
import {IDynaBaggage} from "dyna-travel-interfaces";

import {EBaggageType} from "../interfaces";
import {getBaggageIcon, getBaggageCountLabel} from "../utils/baggage";

import "./BaggageInfo.less";

export interface IBaggageInfoProps {
	className?: string;
	baggage: IDynaBaggage;
	baggageType: EBaggageType,
	messages: {
		baggageTypeBaggage: string;
		baggageTypeHandBag: string;
		perBag: string;
		noBags: string;
		unknownBags: string;
		totalMax: string;
	};
}

export class BaggageInfo extends React.Component<IBaggageInfoProps> {

	private readonly baseClassName: string = "dyna-transport-baggage-info";

	private className(subClassName = ""): string {
		return `${this.baseClassName}${subClassName || ""}`;
	}

	private renderUnknownBags(): JSX.Element {
		const {messages:{unknownBags}} = this.props;
		return (
			<div className={this.className("__unknown-bags")}>
				<span><i className="fas fa-question-circle"/> {unknownBags}</span>
			</div>
		);
	}

	private renderNoBags(): JSX.Element {
		const {messages:{noBags}} = this.props;
		return (
			<div className={this.className("__no-bags")}>
				<span><i className="fas fa-ban"/> {noBags}</span>
			</div>
		);
	}

	private renderBags(): JSX.Element {
		const {
			baggage,
			baggageType,
			messages: {perBag, totalMax},
		} = this.props;
		return (
			<div>
				<div className={this.className("__count-container")}>
					<span className={this.className("__count")}>{getBaggageCountLabel(baggage)}x </span>
					<span>{getBaggageIcon(this.className("__icon"), baggageType, 32)}</span>
				</div>
				<div className={this.className("__weight-info")}>
					{baggage && baggage.weightPerBag ? <div><span className="fab fa-cloudscale"/> {baggage.weightPerBag}kg {perBag}</div> : null}
					{baggage && baggage.maxTotalWeight ? <div><span className="fab fa-cloudscale"/> {baggage.maxTotalWeight}kg {totalMax}</div> : null}
				</div>
			</div>
		);
	}

	private getBaggageTypeLabel():JSX.Element{
		const {
			baggageType,
			messages:{
				baggageTypeBaggage,
				baggageTypeHandBag,
			},
		}= this.props;
		let message:string;
		switch (baggageType){
			case EBaggageType.BAGGAGE:
				message= baggageTypeBaggage;
				break;
			case EBaggageType.HAND_BAG:
				message= baggageTypeHandBag;
				break;
		}
		return (
			<div className={this.className("__baggage-type-label")}>{message}</div>
		);
	}

	public render(): JSX.Element {
		const {
			className: userClassName,
			baggage,
		} = this.props;
		let content: JSX.Element;

		const className:string=[
			this.className(),
			userClassName,
		].join(' ').trim();

		if (!baggage || baggage.bags == null)
			content = this.renderUnknownBags();
		else if (baggage.bags == 0)
			content = this.renderNoBags();
		else
			content = this.renderBags();

		return (
			<div className={className}>
				{this.getBaggageTypeLabel()}
				{content}
			</div>
		);
	}
}
