import * as React from 'react';
import {InjectedIntl} from "react-intl";
import {dynaClassName} from "dyna-class-name";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {IDynaPrice, IDynaTrip, IDynaTripProposal} from "dyna-travel-interfaces";
import {DynaModalContainer} from "dyna-ui-modal-container";
import {EHeaderNavButtonType, Header} from "dyna-travel-ui-components";

import {DynaTravelTripVerticalTimeline} from "dyna-travel-ui-trip-vertical-timeline";
import {DynaTravelListAgentOffers} from "dyna-travel-ui-list-agent-offers";

import {appConfig, EProduct} from "../../../../settings/app";
import {IFlightProposalDetailsMessages} from "./messages";
import {FlightProposalsDetailsIsloading} from "./components/FlightProposalsDetailsIsloading";
import {FlightProposalsDetailsNotFound} from "./components/FlightProposalsDetailsNotFound";
import {FlightProposalRedirectToAgentModal} from "./components/FlightProposalRedirectToAgentModal";
import {formatPrice} from "../../../../utils/formatPrice";
import {getQrBarcodeImageUrl} from "../../../qrBarocdeGenerator/getQrBarcodeImageUrl";
import {
  formatDateWithDayName,
  formatDateWithDayNameFromString,
  formatDuration,
  formatTime,
  formatTimeFromString,
} from "../../../../utils/formatDate";
import {analytics} from "../../../../libs/analytics";

import './FlightProposalDetailsComponent.css';

export interface IFlightProposalDetailsComponentDataProps {
  product: EProduct;
  intl: InjectedIntl;
  locale: string;
  currency: string;
  messages: IFlightProposalDetailsMessages;
  show: boolean;
  isLoading: boolean;
  loadingProgressPercent: number;
  tripProposal: IDynaTripProposal;
  proposalsExpired: boolean;
}

export interface IFlightProposalDetailsComponentEventsProps {
  onClose?: () => void;
  onRefreshClick: () => void;
}

export interface IFlightProposalDetailsComponentProps extends IFlightProposalDetailsComponentDataProps, IFlightProposalDetailsComponentEventsProps {
}

interface IFlightProposalDetailsComponentState {
  showRedirectModal: boolean;
  redirectToTrip: IDynaTrip;
  transferQrBarcodeImage: string;
  transferQrBarcodeTPID: string;
}

export class FlightProposalDetailsComponent extends React.Component<IFlightProposalDetailsComponentProps, IFlightProposalDetailsComponentState> {
  constructor(props: IFlightProposalDetailsComponentProps, context: any) {
    super(props, context);
    this.state = {
      showRedirectModal: false,
      redirectToTrip: null,
      transferQrBarcodeImage: null,
      transferQrBarcodeTPID: null,
    };
  }

  private readonly className = dynaClassName("dyna-travel-flight-proposal-details");
  private formatTime = formatTime.bind(null, this.props.intl);
  private formatTimeStringFromString = formatTimeFromString.bind(null, this.props.intl);
  private formatDateWithDayName = formatDateWithDayName.bind(null, this.props.intl);
  private formatDateWithDayNameFromString = formatDateWithDayNameFromString.bind(null, this.props.intl);
  private formatDuration = formatDuration.bind(null, this.props.intl);

  public componentDidUpdate(prevProps: Readonly<IFlightProposalDetailsComponentProps>, prevState: Readonly<IFlightProposalDetailsComponentState>, snapshot?: any): void {
    if (prevProps.tripProposal && prevProps.tripProposal.tpid !== this.state.transferQrBarcodeTPID) {
      this.setState({
        transferQrBarcodeImage: null,
        transferQrBarcodeTPID: prevProps.tripProposal.tpid,
      });
      getQrBarcodeImageUrl(location.href)
        .then((imageUrl: string) => this.setState({transferQrBarcodeImage: imageUrl}))
        .catch(() => undefined);
    }
  }

  private showRedirectModal(trip: IDynaTrip): void {
    this.setState({
      showRedirectModal: true,
      redirectToTrip: trip,
    });
  }

  private handleRefresh(): void {
    window.location.reload();
  }

  private handleCloseRedirectModal(): void {
    this.setState({
      showRedirectModal: false,
      redirectToTrip: null,
    });
  }

  private formatPrice(price: IDynaPrice): string {
    const {intl, currency} = this.props;
    return formatPrice(intl, price, currency);
  }

  private sendToAnalyticsTheBookedTrip(trip: IDynaTrip): void {
    const agentId = trip.agent.id !== undefined && String(trip.agent.id || "unknown") || "unknown";
    analytics.sendEvent('Flights', 'Booking - Agent id', agentId);

    const iataCodes = [].concat(trip.agent.iataCode, trip.agent.iataCodes).filter(Boolean).join() || "none";
    analytics.sendEvent('Flights', 'Booking - Agent Name', `${trip.agent.name.text} (${trip.agent.type.toLocaleLowerCase()}), iata: ${iataCodes}`);

    const origins: string[] = [];
    const destinations: string[] = [];
    const originDestinations: string[] = [];
    trip.segments
      .forEach((segment) => {
        const origin = segment.segments[0].origin.name.codeName;
        const destination = segment.segments[segment.segments.length - 1].destination.name.codeName;
        origins.push(origin);
        destinations.push(destination);
        originDestinations.push(`${origin}-${destination}`);
      });
    origins.forEach(origin => analytics.sendEvent('Flights', 'Booking - Origin', origin));
    destinations.forEach(destination => analytics.sendEvent('Flights', 'Booking - Destination', destination));
    originDestinations.forEach(originDestination => analytics.sendEvent('Flights', 'Booking - Origin/Destination', originDestination));
  }

  private handleOfferClick(trip: IDynaTrip): void {
    this.showRedirectModal(trip);
    this.sendToAnalyticsTheBookedTrip(trip);
  }

  private renderContent(): JSX.Element {
    const {
      intl,
      product,
      show,
      messages,
      messages: {
        yourTrip,
        theOffers,
        transferItTitle,
        transferItDescription,
        isLoading: isLoadingLabel,
        youCanBookmarkTitle,
        youCanBookmarkDescription,
      },
      isLoading,
      loadingProgressPercent,
      tripProposal,
      proposalsExpired,
      onClose,
      onRefreshClick,
    } = this.props;
    const {
      redirectToTrip,
      transferQrBarcodeImage,
    } = this.state;

    if (show) {
      if (isLoading && !(tripProposal && tripProposal.trips.length)) return (
        <FlightProposalsDetailsIsloading
          loadingProgressPercent={loadingProgressPercent}
          messages={messages}
        />
      );
      if (!tripProposal) return (
        <FlightProposalsDetailsNotFound
          messages={messages}
          onClickForSimilarFlights={onClose}
        />
      );
    } else {
      // this component is always rendered because of the animation
      // if it is not shown... it is possible to called with none tripProposal
      // in this case we have to return null, since we have nothing to render and in real, the component is hidden because the show is false
      if (!tripProposal) return null;
    }

    return (
      <div className={this.className("__container")}>
        <div className={this.className("__content")}>

          <div className={this.className("__bookmark_tip")}>
            <div className={this.className("__bookmark_tip__icon")}>
              <i className="fas fa-bookmark"/>
            </div>
            <div className={this.className("__bookmark_tip__content")}>
              <div className={this.className("__bookmark_tip__title")}>{youCanBookmarkTitle}</div>
              <div className={this.className("__bookmark_tip__description")}>{youCanBookmarkDescription}</div>
            </div>
          </div>

          <div className={this.className("__container-resbig")}>
            <div className={this.className("__container-resbig__label")}>{yourTrip}</div>
            <div className={this.className("__container-resbig__content")}>
              <DynaTravelTripVerticalTimeline
                product={appConfig.product}
                trip={tripProposal.trip}
                messages={messages}
                formatTime={this.formatTime}
                formatTimeString={this.formatTimeStringFromString}
                formatShortDate={this.formatDateWithDayName}
                formatFullDateString={this.formatDateWithDayNameFromString}
                formatDuration={this.formatDuration}
              />
            </div>
          </div>

          <div className={this.className("__container-resbig")}>
            <div className={this.className("__container-resbig__label")}>{theOffers}</div>
            <div className={this.className("__container-resbig__content")}>
              <DynaAnimationVerticalContainer
                className={this.className("__list-offers-is-loading")}
                show={isLoading}
              >
                {appConfig.loadingSpinnerComponent} {Math.round(loadingProgressPercent)}% {isLoadingLabel}
              </DynaAnimationVerticalContainer>
              <DynaTravelListAgentOffers
                product={appConfig.product}
                offers={tripProposal.trips}
                proposalsExpired={proposalsExpired}
                messages={messages}
                formatPrice={this.formatPrice.bind(this)}
                onOfferClick={this.handleOfferClick.bind(this)}
                onRefreshClick={onRefreshClick}
              />
            </div>
          </div>

          <div className={this.className("__container-resbig")}>
            <div className={this.className("__container-resbig__label")}>{transferItTitle}</div>
            <div className={this.className("__container-resbig__content")}>
              {transferQrBarcodeImage ?
                <div className={this.className("__transfer-it")}>
                  <div className={this.className("__transfer-it__description")}>{transferItDescription}</div>
                  <div className={this.className("__transfer-it__barcode-container")}><img
                    src={transferQrBarcodeImage}/></div>
                </div>
                : appConfig.loadingSpinnerComponent
              }
            </div>
          </div>

          <FlightProposalRedirectToAgentModal
            product={product}
            messages={messages}
            show={this.state.showRedirectModal}
            redirectToTrip={redirectToTrip}
            onRefresh={this.handleRefresh.bind(this)}
            onClose={this.handleCloseRedirectModal.bind(this)}
          />

        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    const {
      product,
      show,
      messages,
      messages: {
        detailsHeader,
      },
      tripProposal,
      onClose,
    } = this.props;

    return (
      <DynaModalContainer
        className={this.className()}
        show={show}
      >
        <Header
          className={this.className("__header")}
          product={product}
          title={detailsHeader}
          messages={messages}
          navButton={EHeaderNavButtonType.GO_BACK}
          trip={tripProposal && tripProposal.trip}
          showCloseButton={false}
          onBackClick={onClose}
          onCloseClick={onClose}
        />
        {this.renderContent()}
      </DynaModalContainer>
    );
  }
}

