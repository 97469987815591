import {dynaDebounce} from "dyna-debounce";

import {DynaTravelClient} from "../api/DynaTravelClient/DynaTravelClient";
import {IDynaPlace, IDynaTrip} from "dyna-travel-interfaces";
import {SearchTripsManager} from "./SearchTripsManager";
import {IDynaTravelModuleConfig} from "../interfaces";

export class DynaTravelModule {
	constructor(private readonly dynaTravelClientConfig: IDynaTravelModuleConfig) {
		this.searchAirport = dynaDebounce(this.searchAirport.bind(this), 200);
	}

	private dynaTravelClient = new DynaTravelClient(this.dynaTravelClientConfig);

	public searchTripManager = new SearchTripsManager(this.dynaTravelClient);

  public searchAirport(searchText: string, language: string, count: number = 10): Promise<IDynaPlace[]> {
    return this.dynaTravelClient.searchAirport(searchText, language, count);
	}

	public getAgentTripOfferRedirectionLink(trip: IDynaTrip): Promise<string> {
		return this.dynaTravelClient.getAgentTripOfferRedirectionLink(trip);
	}
}