exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".qualityIndexButtons-module-qualityIndexButtons--2IWCbe42faX0 {\n  font-size: 13px;\n}\n@media (max-width: 768px) {\n  .qualityIndexButtons-module-qualityIndexButtons--2IWCbe42faX0 {\n    font-size: 28px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"qualityIndexButtons": "qualityIndexButtons-module-qualityIndexButtons--2IWCbe42faX0"
};