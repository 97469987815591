import {EMode} from "dyna-travel-ui-select-air-trip";
import {IDynaTripFilter} from "dyna-travel-ui-list-proposals";

import {IReduxAction} from "../../../../redux";
import {dynaTravelModuleConfig} from "../../../../settings/dynaTravelModuleConfig";
import {ILoadFlightsCompleteInfo} from "../../api/DynaTravelClient/interfaces";

import {dynaTravelModule} from "../../logic";
import {dynaTravelLocalStorage} from "../../utils/dynaTravelClientStorage";

import {IGetTripProposalsConfig, IGetTripProposalsResults} from "../../logic/SearchTripsDataHolder";

import {tripRequestDateRebase} from "../../utils/tripRequestDateRebase";
import {getDate0, getNextWeekendDepartDate} from "../../utils/getDate";

import {
	IDynaTripRequest,
	ERoundTripType,
	EDynaTransport,
	EDynaTripClass,
	EDynaPassengerType,
	ETripQualityIndex,
	IDynaTripProposal,
	ITripQualityData,
} from "dyna-travel-interfaces";

import {
	DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP,
	DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_CLEAR_FORM,
	DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_NEW_SEARCH,
	DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_TODAY,
	DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_NEXT_WEEKEND,
	DynaTravel_SearchFlights_REQUEST_SEARCH_AIR_TRIP,
	DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP,
	DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_PROGRESS,
	DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_COMPLETED,
	DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_ERROR,
	DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_CANCEL,
	DynaTravel_SearchFlights_REFRESH_DUE_TO_NEW_FILTERED_PROPOSALS,
	DynaTravel_SearchFlights_PROPOSALS_EXPIRED,
	DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON,
	DynaTravel_SearchFlights_LIST_VIEW_SORT_BY_TRIP_QUALITY_INDEX,
	DynaTravel_SearchFlights_LIST_VIEW_LOAD_MORE,
	DynaTravel_SearchFlights_LIST_VIEW_PROPOSAL_SELECTED,
	DynaTravel_SearchFlights_IS_LOADING_MORE,
	DynaTravel_SearchFlights_ON_FILTER_TRIPS_CHANGE,
} from './actions';
import {appConfig, EAppMode} from "../../../../settings/app";

export interface ISearchFlightsState {
	searchFlightProcesses: {
		[searchFlightProcessId: string]: ISearchFlightProcess;
	};
}

export interface ISearchFlightProcess {
	// WARN: this interface should be compatible with IGetTripProposalsConfig
	searchJobId?: string;

	selectTripFormMode?: EMode;
	isLoading?: boolean;

	tripRequestIdLastSearched?: string;
	loadingProgressPercent?: number;    // null to hide, 100 for completed
	loadingProgressMessageId?: string;  // the last loading progress message
	requestIsCanceled?: boolean;
	requestTrip?: IDynaTripRequest;

	errorMessageId?: string;
	errorMessages?: string[];

	tripQualityBest?: ITripQualityData;
	tripQualityCheap?: ITripQualityData;
	tripQualityFast?: ITripQualityData;

	sortByTripQualityIndex?: ETripQualityIndex;
	tripsFilter?: IDynaTripFilter;
	listCountProposals?: number;
	tripProposals?: IDynaTripProposal[];
	tripProposalsAllCount?: number;
	tripProposalsFilteredCount?: number;
	tripProposalsExpired?: boolean;
	showExpiredBalloon?: boolean;
	moreTripProposalsCount?: number;
	noTripsFound?: boolean;
	isLoadingMore?: boolean;
	viewProposalDetailsTpid?: string;
}

export interface ITripsQualityAverage {
	best: ITripStatsAverage;
	cheap: ITripStatsAverage;
	fast: ITripStatsAverage;
}

export interface ITripStatsAverage {
	price: number;
	durationInMin: number;
}

const getDefaultRequestTrip = (): IDynaTripRequest => {
	const departDate: Date = dynaTravelLocalStorage.getDefaultDepartDate('depart-date');
	const returnDate: Date = dynaTravelLocalStorage.getDefaultReturnDate('return-date', departDate);
	return {
		roundTripType: ERoundTripType.RETURN,
		routes: [
			{
				origin: null,
				destination: null,
				departDate: {date: departDate},
			},
			{
				origin: null,
				destination: null,
				departDate: {date: returnDate},
			},
		],
		directOnly: false,
		transports: [EDynaTransport.AIRPLANE],
		tripClass: EDynaTripClass.ECONOMY,
		passengers: [{type: EDynaPassengerType.ADULT}], // default values are filled from the control
	};
};

export const getSearchFlightProcessInitialState = (searchFlightProcessId: string): ISearchFlightProcess => {
	return {
		searchJobId: null,
		selectTripFormMode: EMode.EDIT,
		isLoading: false,
		requestIsCanceled: false,
		tripRequestIdLastSearched: null,
		loadingProgressPercent: null,
		loadingProgressMessageId: null,
		requestTrip: dynaTravelLocalStorage.getLastRequestTrip(searchFlightProcessId, getDefaultRequestTrip()),
		sortByTripQualityIndex: ETripQualityIndex.BEST,
		tripQualityBest: null,
		tripQualityCheap: null,
		tripQualityFast: null,
		tripsFilter: null,
		tripProposals: [],
		tripProposalsAllCount: 0,
		tripProposalsFilteredCount: 0,
		tripProposalsExpired: false,
		showExpiredBalloon: false,
		moreTripProposalsCount: 0,
		noTripsFound: false,
		listCountProposals: dynaTravelModuleConfig.listTripProposalsStep,
		errorMessageId: null,
		errorMessages: [],
		isLoadingMore: false,
	};
};

// helper function in order to update a search flight process by searchFlightProcessId
const updateFlightProcessState = (searchFlightProcessId: string,
                                  state: ISearchFlightsState,
                                  cbUpdate: (searchFlightProcess: ISearchFlightProcess) => ISearchFlightProcess): ISearchFlightsState => {

	const currentSearchFlightProcess: ISearchFlightProcess = state.searchFlightProcesses[searchFlightProcessId] || getSearchFlightProcessInitialState(searchFlightProcessId);
	const updatedSearchFlightProcess: ISearchFlightProcess = {
		...currentSearchFlightProcess,
		...(cbUpdate(currentSearchFlightProcess) || {}),
	};

	setTimeout(() => {
		dynaTravelLocalStorage.setLastRequestTrip(searchFlightProcessId, updatedSearchFlightProcess.requestTrip);
	}, 100); // save it a little bit later to do not block the ui for small devices

	return {
		...state,
		searchFlightProcesses: {
			...state.searchFlightProcesses,
			[searchFlightProcessId]: updatedSearchFlightProcess,
		},
	};
};

export const searchFlightInitialState: ISearchFlightsState =
	updateFlightProcessState(
		'main',
		{searchFlightProcesses: {}},
		() => undefined,
	);

export const searchFlightsReducer = (state: ISearchFlightsState = searchFlightInitialState, action: IReduxAction): ISearchFlightsState => {
	switch (action.type) {

		case DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				requestTrip: action.payload.requestTrip,
			}));

		case DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_NEW_SEARCH:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				selectTripFormMode: EMode.EDIT,
				tripRequestIdLastSearched: null,
				loadingProgressPercent: null,
				loadingProgressMessageId: null,
				tripProposals: [],
				tripProposalsExpired: false,
				tripsFilter: null,
				tripQualityBest: null,
				tripQualityCheap: null,
				tripQualityFast: null,
				listCountProposals: dynaTravelModuleConfig.listTripProposalsStep,
				showExpiredBalloon: false,
				errorMessageId: null,
				errorMessages: [],
				tripProposalsAllCount: 0,
				tripProposalsFilteredCount: 0,
				moreTripProposalsCount: 0,
				noTripsFound: false,
			}));

		case DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_CLEAR_FORM:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				...getSearchFlightProcessInitialState("not-saved-is-new"), // we will override it anyway
				requestTrip: getDefaultRequestTrip(),
				tripsFilter: null,
			}));

		case DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_TODAY:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
				tripRequestDateRebase(searchFlightProcess.requestTrip, getDate0());
				searchFlightProcess.selectTripFormMode = EMode.EDIT;
				searchFlightProcess.noTripsFound = false;
				return searchFlightProcess;
			});

		case DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_NEXT_WEEKEND:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
				tripRequestDateRebase(searchFlightProcess.requestTrip, getNextWeekendDepartDate());
				searchFlightProcess.selectTripFormMode = EMode.EDIT;
				searchFlightProcess.noTripsFound = false;
				return searchFlightProcess;
			});

		case DynaTravel_SearchFlights_REQUEST_SEARCH_AIR_TRIP:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				searchJobId: action.payload.searchJobId,
				selectTripFormMode: EMode.VIEW,
				isLoading: true,
				requestIsCanceled: false,
				tripRequestIdLastSearched: action.payload.tripRequestIdLastSearched,
				loadingProgressPercent: 0, // show the progress bar
				loadingProgressMessageId: "MSG_0732",
				tripProposalsExpired: false,
				showExpiredBalloon: false,
				tripsFilter: null,
				tripProposals: [],
				errorMessageId: null,
				errorMessages: [],
				tripProposalsAllCount: 0,
				tripProposalsFilteredCount: 0,
				moreTripProposalsCount: 0,
				noTripsFound: false,
			}));

		case DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP:
		case DynaTravel_SearchFlights_REFRESH_DUE_TO_NEW_FILTERED_PROPOSALS:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
				if (searchFlightProcess.requestIsCanceled) return null;
				return {
					...getTripProposalsResults(action.payload.searchFlightProcessId, state),
				};
			});

		case DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_PROGRESS:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
        if (searchFlightProcess.requestIsCanceled) return null;
				return {
					loadingProgressPercent: action.payload.progressPercent,
					loadingProgressMessageId: "MSG_0644",
				};
			});

		case DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_COMPLETED:
			const tripProposals: IGetTripProposalsResults = dynaTravelModule.searchTripManager.getTripProposals(action.payload.searchFlightProcessId, {
				listCountProposals: 9999999,
				sortByTripQualityIndex: ETripQualityIndex.BEST,
			});
			if (appConfig.mode === EAppMode.DEBUG) console.log('(only on debug mode) loading completed', 'trips loaded', tripProposals.tripProposals.length);
			const loadCompleteInfo: ILoadFlightsCompleteInfo = action.payload.loadCompleteInfo;
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
        if (searchFlightProcess.requestIsCanceled) return null;
        return {
          selectTripFormMode: EMode.VIEW,
          isLoading: false,
          noTripsFound: searchFlightProcess.tripProposals.length === 0,
          loadingProgressPercent: 100,
          loadingProgressMessageId: "MSG_0642",
	        errorMessages: loadCompleteInfo.userErrorMessages,
        };
			});

		case DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_ERROR:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
        if (searchFlightProcess.requestIsCanceled) return null;
        return {
          selectTripFormMode: EMode.EDIT,
          isLoading: false,
          loadingProgressPercent: 100,
          loadingProgressMessageId: null,
          errorMessageId: "MSG_0643",
        };
			});

		case DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_CANCEL:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				selectTripFormMode: EMode.EDIT,
				isLoading: false,
				tripRequestIdLastSearched: null,
				requestIsCanceled: true,
				errorMessageId: "MSG_0737", // The search is canceled
				loadingProgressPercent: 100,
				loadingProgressMessageId: null,
			}));

		case DynaTravel_SearchFlights_PROPOSALS_EXPIRED:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
				if (action.payload.searchJobId !== searchFlightProcess.searchJobId) return null;
				return {
					tripProposalsExpired: true,
				}as ISearchFlightProcess;
			});

		case DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => {
				if (action.payload.searchJobId !== searchFlightProcess.searchJobId) return null;
				return {
					showExpiredBalloon: action.payload.show,
				}as ISearchFlightProcess;
			});

		case DynaTravel_SearchFlights_LIST_VIEW_SORT_BY_TRIP_QUALITY_INDEX:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				sortByTripQualityIndex: action.payload.tripQualityIndex,
				...getTripProposalsResults(action.payload.searchFlightProcessId, state, {
					listCountProposals: dynaTravelModuleConfig.listTripProposalsStep,
					sortByTripQualityIndex: action.payload.tripQualityIndex,
				}),
				listCountProposals: dynaTravelModuleConfig.listTripProposalsStep,
			}));

		case DynaTravel_SearchFlights_LIST_VIEW_LOAD_MORE:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				listCountProposals: action.payload.listCountProposals,
				isLoadingMore: false,
				...getTripProposalsResults(action.payload.searchFlightProcessId, state, {
					listCountProposals: action.payload.listCountProposals,
				}),
			}));

		case DynaTravel_SearchFlights_IS_LOADING_MORE:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				isLoadingMore: action.payload.isLoadingMore,
			}));

		case DynaTravel_SearchFlights_LIST_VIEW_PROPOSAL_SELECTED:
			// todo: implement, a proposal is clicked, --> delete this... links are used for navigation
			return state;

		case DynaTravel_SearchFlights_ON_FILTER_TRIPS_CHANGE:
			return updateFlightProcessState(action.payload.searchFlightProcessId, state, (searchFlightProcess: ISearchFlightProcess) => ({
				tripsFilter: action.payload.filter as IDynaTripFilter,
			}));

		default:
			return state;
	}
};

const getTripProposalsResults = (searchFlightProcessId: string,
                                 searchFlightsState: ISearchFlightsState,
                                 getTripProposalsConfig: IGetTripProposalsConfig = {},
): ISearchFlightProcess => {
	const {
		listCountProposals,
		sortByTripQualityIndex,
	} = getSearchFlightProcess(searchFlightProcessId, searchFlightsState);

	// create result state
	const result: ISearchFlightProcess = dynaTravelModule.searchTripManager.getTripProposals(
		searchFlightProcessId,
		{
			listCountProposals,
			sortByTripQualityIndex,
			...getTripProposalsConfig,
		},
	);

	return result;
};

const getSearchFlightProcess = (searchFlightProcessId: string, searchFlightsState: ISearchFlightsState): ISearchFlightProcess => {
	return searchFlightsState.searchFlightProcesses[searchFlightProcessId];
};
