import * as moment from "moment";
import {IDynaTripRequest, IDynaTripRequestRoute} from "dyna-travel-interfaces";

import {getDate0} from "./getDate";

export const tripRequestDateRebase = (requestTrip: IDynaTripRequest, departDate: Date): void => {
	const baseOldDate: Date = new Date(requestTrip.routes[0].departDate.date);
	const baseNewDate: Date = departDate;
	requestTrip.routes.forEach((route: IDynaTripRequestRoute) => {
		route.departDate.date = rebaseDate(baseOldDate, baseNewDate, new Date(route.departDate.date));
	});
};

const getDaysDiff = (dateA: Date, dateB: Date): number => moment(dateA).diff(dateB, "days");

const rebaseDate = (baseOldDate: Date, baseNewDate: Date, date: Date): Date => {
	return getDate0(
		moment(date).add({days: getDaysDiff(baseNewDate, baseOldDate)}).toDate(),
	);
};