import {IDynaTrip} from "dyna-travel-interfaces";
import {DynaNodeClient} from "dyna-node/dist/commonJs/web";
import {
  COMMAND_searchFlightsRequestAgentLink,
  ICOMMAND_searchFlightsRequestAgentLink_args,
  ICOMMAND_searchFlightsResponseAgentLink_data,
} from "dyna-travel-services/dist/esNext/searchFlights/client";

export const getAgentTripOfferRedirectionLink = (dynaTravelServiceNodeAddress: string, nodeDevice: DynaNodeClient, trip: IDynaTrip): Promise<string> => {
  return nodeDevice.fetch<ICOMMAND_searchFlightsRequestAgentLink_args, null, ICOMMAND_searchFlightsResponseAgentLink_data>({
    to: dynaTravelServiceNodeAddress,
    command: COMMAND_searchFlightsRequestAgentLink,
    args: {requestAgentLinkConfig: trip.requestAgentLinkConfig},
  })
    .then(response => response.agentLink);
};
