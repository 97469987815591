import * as React from 'react';

import {faIcon} from "../../components/ui/faIcon/faIcon";

import "./DynaNotifications.css";
import {dynaClassName} from "dyna-class-name";

export interface IDynaNotificationsProps {
	className?: string;
	notifications: IDynaNotification[];
}

export interface IDynaNotification {
	type: EDynaNotificationType;
	label: string;
}

export enum EDynaNotificationType {
	INFO = "INFO",
	ERROR = "ERROR",
	WARN = "WARN",
}

export class DynaNotifications extends React.Component<IDynaNotificationsProps> {
	private readonly className = dynaClassName("dyna-notifications");

	private getIcon(notificationType: EDynaNotificationType): JSX.Element {
		switch (notificationType) {
			case EDynaNotificationType.ERROR:
				return faIcon('times-circle');
			case EDynaNotificationType.WARN:
				return faIcon('exclamation-triangle');
			default:
			case EDynaNotificationType.INFO:
				return faIcon('info-circle');
		}
	}

	private renderItems(): JSX.Element[] {
		const {notifications} = this.props;
		return notifications.map((notification: IDynaNotification, index: number) => {
			const className: string = [
				"dyna-notification-item",
				`dyna-notification-item--type-${notification.type}`,
			].join(' ').trim();
			return (
				<div className={className} key={index}>
					<span>{this.getIcon(notification.type)} {notification.label}</span>
				</div>
			);
		});
	}

	public render(): JSX.Element {
		const {className: userClassName} = this.props;
		const className: string = this.className(
			"",
			userClassName && "/" + userClassName,
		);

		return (
			<div className={className}>
				{this.renderItems()}
			</div>
		);
	}
}
