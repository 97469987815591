import * as React from "react";

import { ICurrencyRates } from "dyna-currencies";

import {ERoundTripType, IDynaTripRequest, IDynaTripRequestRoute, IDynaPlace} from "dyna-travel-interfaces";

import {EColor} from "dyna-ui-styles";
import { ESize as EFieldSize } from "dyna-ui-field-wrapper";
import {DynaPlainRadioButton} from "dyna-ui-radiobutton";

import {
  EDynaDatePickerColor,
} from "dyna-travel-ui-date-info-pickers";

import {SelectRoundTripType} from "./components/SelectRoundTripType";
import {SelectRouteOneWay} from "./components/SelectRouteOneWay";
import {SelectRoutesReturn} from "./components/SelectRoutesReturn";
import {SelectRoutesMulti} from "./components/SelectRoutesMulti";
import {SelectAirTripFooter} from "./components/SelectAirTripFooter";
import {SelectTripMisc, EPickerColor as EMiscPickerColor, IMiscData} from "./components/SelectTripMisc";

import {EMode, EProduct} from "./interfaces";
import {getDate0} from "./utils/getDate0";
import {copyTripRequest} from "./utils/copyTripRequest";

import "./DynaSelectAirTrip.less";

export interface IDynaSelectAirTripProps {
  product: EProduct;
  mode: EMode;
  isLoading: boolean;
  fieldSize?: EFieldSize;
  tripRequest: IDynaTripRequest;
  iconClear?: string;
  iconSearch?: string;
  iconNewSearch?: string;
  iconIsLoading?: string;
  formatDate?: (date: Date) => string;
  searchAirportFetchDebounceTimeout?: number; // default: 250
  showClearAllButton?: boolean;               // default: false
  datePickerFlightInfoConfig: IDatePickerFlightInfoBasicConfig;
  onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange: (triRequest: IDynaTripRequest) => void;       // note: this triRequest is the UI state, don't use this for Search!
  onClearClick: () => void;
  onSearchClick: (tripRequest: IDynaTripRequest) => void;  // use only the tripRequest for search!
  onNewSearchClick: () => void;
  messages: IDynaSelectAirTripMessages;
  _debug_calendarPickerStoreName?: string;
}

export interface IDatePickerFlightInfoBasicConfig {
  COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: string;
  currency: string;
  loadMonths: number;
  updatedWeeksAgo: number;
  onCurrenciesLoad: () => Promise<ICurrencyRates>;
}

export interface IDynaSelectAirTripMessages {
  roundTripTypeReturnLabel: string;
  roundTripTypeOneWayLabel: string;
  roundTripTypeMultiTripLabel: string;
  originLabel: string;
  originPlaceholder: string;
  originValidationOnEmptyForField: string;
  originValidationOnEmptyForValidationMessages: string;
  destinationLabel: string;
  destinationPlaceholder: string;
  destinationValidationOnEmptyForField: string;
  destinationValidationOnEmptyForValidationMessages: string;
  originDestinationsCannotBeTheSameValidationMessages: string;
  departureDateLabel: string;
  returnDateLabel: string;
  clear: string;
  search: string;
  newSearch: string;
  isLoading: string;
  tripClassEconomy: string;
  tripClassBusiness: string;
  passengerAdultsLabel: string;
  passengerChildrenLabel: string;
  passengerInfantsLabel: string;
  passengerSelectionInfo: string;
  directFlightsOnly: string;
  weekDaysShortNames: string;
  monthLongNames: string;
  datePickerTodayButton: string;
  datePickerCloseButton: string;
  calendarPickerBest: string;
  calendarPickerCheapest: string;
  calendarPickerFastest: string;
  calendarPickerNone: string;
}

export interface IDynaSelectAirTripState {
  validationErrors: string[];
}

interface IStyleMixer {
  roundTripTypeColor: EColor;
  fieldColor: EColor;
  datePickerContainerColor: EDynaDatePickerColor;
  clearButtonColor: EColor;
  searchButtonColor: EColor;
  miscPickerColor: EMiscPickerColor;
  miscFieldsColor: EColor;
  miscCloseButtonColor: EColor;
}

const styleMixer = (product: EProduct): IStyleMixer => {
  switch (product) {
    case EProduct.DIE_TO_FLY:
      return {
        roundTripTypeColor: EColor.BLACK_WHITE,
        fieldColor: EColor.RED_WHITE,
        datePickerContainerColor: EDynaDatePickerColor.GREY_ORANGE_GREEN_CALENDAR_GREEN,
        clearButtonColor: EColor.RED_WHITE,
        searchButtonColor: EColor.RED_WHITE,
        miscPickerColor: EMiscPickerColor.WHITE_RED,
        miscFieldsColor: EColor.RED_WHITE,
        miscCloseButtonColor: EColor.RED_WHITE,
      };
    default:
    case EProduct.IM_HOLIDAY:
      return {
        roundTripTypeColor: EColor.WHITE_BLACK,
        fieldColor: EColor.ORANGE_WHITE,
        datePickerContainerColor: EDynaDatePickerColor.GREY_ORANGE_GREEN_CALENDAR_CYAN,
        clearButtonColor: EColor.ORANGE_WHITE,
        searchButtonColor: EColor.ORANGE_WHITE,
        miscPickerColor: EMiscPickerColor.WHITE_ORANGE,
        miscFieldsColor: EColor.ORANGE_WHITE,
        miscCloseButtonColor: EColor.ORANGE_WHITE,
      };
  }
};

export {
  EFieldSize,
};

export class DynaSelectAirTrip extends React.Component<IDynaSelectAirTripProps, IDynaSelectAirTripState> {
  constructor(props: IDynaSelectAirTripProps) {
    super(props);
    this.state = {
      validationErrors: [],
    };
  }

  public refs: {
    selectRoute: SelectRouteOneWay | SelectRoutesReturn | SelectRoutesMulti,
  };

  private handleOnChange(trip: IDynaTripRequest): void {
    const {onChange} = this.props;
    onChange(trip);
  }

  private handleChangeTripRequestGeneric(partialTripRequest: IDynaTripRequest): void {
    const {tripRequest} = this.props;

    this.handleOnChange({
      ...tripRequest,
      ...partialTripRequest,
    });
  }

  private handleRoundTripChange(newRoundTripType: ERoundTripType): void {
    const requestTrip: IDynaTripRequest = copyTripRequest(this.props.tripRequest);
    const routes: IDynaTripRequestRoute[] = requestTrip.routes;
    const fillRoutes = (routesCount: number): void => {
      while (routes.length < routesCount) routes.push({origin: null, destination: null, departDate: {date: getDate0()}});
    };

    switch (newRoundTripType) {

      case ERoundTripType.ONE_WAY:
        fillRoutes(1);
        break;

      case ERoundTripType.RETURN:
        if (routes.length === 0) {
          routes.push({
            origin: null,
            destination: null,
            departDate: {date: getDate0()},
          });
        }
        if (routes.length === 1) {
          routes.push({
            origin: null,
            destination: null,
            departDate: routes[0].departDate,
          });
        }
        // fill out the return fields is are missing
        routes[1].origin = routes[0].destination;
        routes[1].destination = routes[0].origin;
        break;

      case ERoundTripType.MULTI_TRIP:
        // expect to have two routes, since you comming from Return
        while (routes.length < 2) routes.push({origin: null, destination: null, departDate: {date: getDate0()}});
        if (routes.length === 2) {
          if (!routes[1].origin && !routes[1].destination) {  // no return is defined yet
            routes.splice(1);                            // remove the return
          }
        }
        if (routes.length > 2 && routes[2].origin) {
          routes[1].destination = routes[2].origin;
        }
        break;

    }

    this.handleChangeTripRequestGeneric({
      roundTripType: newRoundTripType,
      routes,
    });
  }

  private renderSelectRoundTrip(): JSX.Element {
    const {
      product,
      mode,
      messages: {
        roundTripTypeReturnLabel,
        roundTripTypeOneWayLabel,
        roundTripTypeMultiTripLabel,
      },
      tripRequest: {
        roundTripType,
      },
    } = this.props;
    const {fieldColor} = styleMixer(product);

    return (
      <SelectRoundTripType
        mode={mode}
        color={fieldColor}
        labelRoundTripTypeReturn={roundTripTypeReturnLabel}
        labelRoundTripTypeOneWay={roundTripTypeOneWayLabel}
        labelRoundTripTypeMultiTrip={roundTripTypeMultiTripLabel}
        roundTripType={roundTripType}
        onChange={(roundTripType: ERoundTripType) => this.handleRoundTripChange(roundTripType)}
      />
    );
  }

  private renderSelectRouteOneWay(): JSX.Element {
    const {
      mode,
      fieldSize,
      product,
      messages,
      tripRequest: {
        roundTripType,
        routes,
      },
      datePickerFlightInfoConfig,
      formatDate,
      searchAirportFetchDebounceTimeout = 250,
      onAirportSearch,
      _debug_calendarPickerStoreName = '',
    } = this.props;
    const colors: IStyleMixer = styleMixer(product);

    if (roundTripType !== ERoundTripType.ONE_WAY) return null;

    return (
      <SelectRouteOneWay
        mode={mode}
        ref="selectRoute"
        fieldsColor={colors.fieldColor}
        fieldDatePickerColor={colors.datePickerContainerColor}
        product={product}
        size={fieldSize}
        messages={messages}
        fetchDebounceTimeout={searchAirportFetchDebounceTimeout}
        routes={routes}
        formatDate={formatDate}
        onAirportSearch={onAirportSearch}
        datePickerFlightInfoConfig={datePickerFlightInfoConfig}
        onChange={(routes: IDynaTripRequestRoute[]) => this.handleChangeTripRequestGeneric({routes})}
        _debug_calendarPickerStoreName={_debug_calendarPickerStoreName}
      />
    );
  }

  private renderSelectRoutesReturn(): JSX.Element {
    const {
      mode,
      fieldSize,
      product,
      messages,
      tripRequest: {
        roundTripType,
        routes,
      },
      datePickerFlightInfoConfig,
      formatDate,
      searchAirportFetchDebounceTimeout,
      onAirportSearch,
      _debug_calendarPickerStoreName = '',
    } = this.props;
    const colors: IStyleMixer = styleMixer(product);

    if (roundTripType !== ERoundTripType.RETURN) return null;

    return (
      <SelectRoutesReturn
        mode={mode}
        ref="selectRoute"
        fieldsColor={colors.fieldColor}
        fieldDatePickerColor={colors.datePickerContainerColor}
        product={product}
        size={fieldSize}
        messages={messages}
        fetchDebounceTimeout={searchAirportFetchDebounceTimeout}
        routes={routes}
        formatDate={formatDate}
        datePickerFlightInfoConfig={datePickerFlightInfoConfig}
        onAirportSearch={onAirportSearch}
        onChange={(routes: IDynaTripRequestRoute[]) => this.handleChangeTripRequestGeneric({routes})}
        _debug_calendarPickerStoreName={_debug_calendarPickerStoreName}
      />
    );
  }

  private renderSelectRoutesMulti(): JSX.Element {
    const {
      mode,
      fieldSize,
      product,
      messages,
      tripRequest: {
        roundTripType,
        routes,
      },
      datePickerFlightInfoConfig,
      formatDate,
      searchAirportFetchDebounceTimeout,
      onAirportSearch,
      _debug_calendarPickerStoreName = '',
    } = this.props;
    const colors: IStyleMixer = styleMixer(product);

    if (roundTripType !== ERoundTripType.MULTI_TRIP) return null;

    return (
      <SelectRoutesMulti
        mode={mode}
        ref="selectRoute"
        fieldsColor={colors.fieldColor}
        fieldDatePickerColor={colors.datePickerContainerColor}
        product={product}
        size={fieldSize}
        messages={messages}
        fetchDebounceTimeout={searchAirportFetchDebounceTimeout}
        routes={routes}
        formatDate={formatDate}
        datePickerFlightInfoConfig={datePickerFlightInfoConfig}
        onAirportSearch={onAirportSearch}
        onChange={(routes: IDynaTripRequestRoute[]) => this.handleChangeTripRequestGeneric({routes})}
        _debug_calendarPickerStoreName={_debug_calendarPickerStoreName}
      />
    );
  }

  private handleNewSearchClick(): void {
    const {onNewSearchClick} = this.props;
    this.setState({validationErrors: []});

    onNewSearchClick();
  }

  private handleClearClick(): void {
    const {onClearClick} = this.props;
    this.setState({validationErrors: []});

    onClearClick();
  }

  private removeUnwantedRoutes(tripRequest: IDynaTripRequest): void {
    const initialRoutesCount: number = tripRequest.routes.length;
    switch (tripRequest.roundTripType) {
      case ERoundTripType.RETURN:
        tripRequest.routes.splice(2);
        break;
      case ERoundTripType.ONE_WAY:
        tripRequest.routes.splice(1);
        break;
    }
    if (initialRoutesCount !== tripRequest.routes.length) {
      this.handleOnChange(tripRequest);
    }
  }

  private validate(tripRequest: IDynaTripRequest): string[] {
    const {messages} = this.props;
    const {routes} = tripRequest;
    const {selectRoute} = this.refs;
    const validationErrors: string[] = [];

    if (selectRoute) selectRoute.validate().forEach(message => validationErrors.push(message));

    routes.forEach(route => {
      const {
        origin: {name: {codeName: origin}},
        destination: {name: {codeName: destination}},
      } = route;
      if (origin === destination) {
        const message = `${messages.originDestinationsCannotBeTheSameValidationMessages} (${origin}-${destination})`;
        if (validationErrors.indexOf(message) === -1) validationErrors.push(message);
      }
    });

    return validationErrors;
  }

  private handleSearchClick(): void {
    const {
      onSearchClick,
    } = this.props;

    const tripRequest: IDynaTripRequest = copyTripRequest(this.props.tripRequest);
    this.removeUnwantedRoutes(tripRequest);

    const validationErrors = this.validate(tripRequest);

    this.setState({validationErrors});

    if (validationErrors.length === 0) {
      onSearchClick(tripRequest);
    }
  }

  private handleMiscChange(data: IMiscData): void {
    const {tripRequest} = this.props;
    this.handleOnChange({
      ...tripRequest,
      ...data,
    });
  }

  private renderFooterLeftContent(): JSX.Element {
    const {
      product,
      mode,
      messages,

      tripRequest: {
        tripClass,
        passengers,
        directOnly,
      },
    } = this.props;
    const {
      fieldColor,
      miscPickerColor,
      miscFieldsColor,
      miscCloseButtonColor,
    } = styleMixer(product);

    return (
      <div className="footer-left-container">
        <SelectTripMisc
          mode={mode}
          color={fieldColor}
          pickerColor={miscPickerColor}
          fieldsColor={miscFieldsColor}
          closeButtonColor={miscCloseButtonColor}
          messages={messages}
          data={{
            tripClass,
            passengers,
          }}
          onChange={this.handleMiscChange.bind(this)}
        />
        <div className="direct-only__container">
          <DynaPlainRadioButton
            mode={mode}
            color={fieldColor}
            label={messages.directFlightsOnly}
            checked={directOnly}
            onChange={(directOnly: boolean) => this.handleChangeTripRequestGeneric({directOnly})}
          />
        </div>
      </div>
    );
  }

  private renderFooter(): JSX.Element {
    const {
      product,
      mode,
      isLoading,
      messages,
      iconIsLoading = "circle-o-notch fa-spin",
      iconClear = "eraser",
      iconSearch = "chevron-circle-right",
      iconNewSearch = "chevron-circle-up",
      showClearAllButton = false,
    } = this.props;
    const {
      validationErrors,
    } = this.state;
    const {
      clearButtonColor,
      searchButtonColor,
    } = styleMixer(product);

    return (
      <SelectAirTripFooter
        mode={mode}
        isLoading={isLoading}
        messages={messages}
        iconIsLoading={iconIsLoading}
        iconClear={iconClear}
        iconSearch={iconSearch}
        iconNewSearch={iconNewSearch}
        clearButtonColor={clearButtonColor}
        searchButtonColor={searchButtonColor}
        validationErrors={validationErrors}
        footerLeftContent={this.renderFooterLeftContent()}
        showClearAllButton={showClearAllButton}
        onClearClick={this.handleClearClick.bind(this)}
        onSearchClick={this.handleSearchClick.bind(this)}
        onNewSearchClick={this.handleNewSearchClick.bind(this)}
      />
    );
  }

  public render(): JSX.Element {
    const className: string = [
      'dyna-select-air-trip',
    ].join(' ').trim();

    return (
      <div className={className}>
        {this.renderSelectRoundTrip()}
        <div className="dyna-travel-select-routes__container">
          {this.renderSelectRouteOneWay()}
          {this.renderSelectRoutesReturn()}
          {this.renderSelectRoutesMulti()}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}
