import * as React from "react";
import {FormattedMessage, injectIntl, InjectedIntl} from "react-intl";
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {DynaButton, EColor, ESize, EStyle} from "dyna-ui-button";

import {appConfig} from "../../../../../settings/app";

import "./CookiesBanner.css";

export interface ICookiesBannerProps {
	intl?: InjectedIntl;
	className?: string;
}

export class CookiesBanner extends React.Component<ICookiesBannerProps> {
	static defaultProps: ICookiesBannerProps = {
		intl: null,
		className: "",
	};

	private get agreedGdprVersion(): number {
		const lsValue: string = localStorage.getItem("app-gdpr-agreed-version");
		return lsValue ? Number(lsValue) : null;
	}

	private set agreedGdprVersion(version: number) {
		localStorage.setItem("app-gdpr-agreed-version", String(version));
	}

	private readonly cs: DynaClassName = dynaClassName("app-cookies-banner");

	private renderMessage(): JSX.Element {
		let messageId: string;
		if (this.agreedGdprVersion === null)
			messageId = "MSG_0800";
		else
			messageId = "MSG_0803";

		return <FormattedMessage id={messageId}/>;
	}

	private handleAgreeClick(): void {
		this.agreedGdprVersion = appConfig.gdprVersion;
		this.forceUpdate();
	}

	public render(): JSX.Element {
		const isAgreed: boolean = this.agreedGdprVersion === appConfig.gdprVersion;

		return (
			<DynaAnimationVerticalContainer
				show={!isAgreed}
			>
				<div className={this.cs.root(this.props)}>
					<div className={this.cs("__message")}>
						{this.renderMessage()}
					</div>
					<div className={this.cs("__button")}>
						<DynaButton
							color={EColor.BLACK_WHITE}
							style={EStyle.FLAT}
							size={ESize.MEDIUM}
							onClick={this.handleAgreeClick.bind(this)}
						>
							<FormattedMessage id="MSG_0804"/>
						</DynaButton>
					</div>
				</div>
			</DynaAnimationVerticalContainer>
		);
	}
}
