import {IDynaTravelModuleConfig} from "../modules/DynaTravel/interfaces";
import {appConfig, EProduct} from "./app";
import {currencies} from "../utils/currenciesConverter";
import {serviceAddress} from "./serviceAddress";


export const dynaTravelModuleConfig: IDynaTravelModuleConfig = {
  currencies: currencies,
  dynaTravelServiceSearchFlightsNodeAddress: serviceAddress("dyna-travel-service-v2"),
  dynaTravelServiceFacilitiesNodeAddress: serviceAddress("dyna-travel-service-v2"),
  listTripProposalsStep: appConfig.product === EProduct.DIE_TO_FLY ? 13 : 10,
  proposalsExpireInMin: 14, // debug, sec: 1 / 60 * 13
  proposalsExpireSnoozeTimeout: 5,
  COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: serviceAddress('flights-price-statistic-service'),
};
