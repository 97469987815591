import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import {RouteComponentProps} from "react-router";

import {appConfig} from "../../../../../settings/app";
import {appRoutes} from "../../../../modulesLoader";
import {IRoute} from "../../../../../interfaces/routes";
import {IAppLayer} from "../../../../../interfaces/app";
import {AppContainer} from "../appContainer/AppContainer";
import {MainMenu} from "../../components/mainMenu";
import {ScrollToTop} from "../../components/scrollToTop";
import {AppSettings} from "../../components/appSettings/AppSettings";

import {Header} from "../../components/header/header";
import {Footer} from "../../components/footer/footer";

import './layout.css';
import './style.css';

export interface IAppWrapperProps {
  appLayer: IAppLayer;
}

export class AppWrapper extends React.Component<IAppWrapperProps> {

  private createPropsForComponent(route: IRoute<any>, routeProps: RouteComponentProps<any>): any {
    let props: any;

    if (route.mapRuntimeRoutePropsToProps)
      props = route.mapRuntimeRoutePropsToProps(route.componentProps, routeProps);
    else
      props = route.componentProps;

    props = {
      ...props,
      ...routeProps.match.params,
      appLayer: this.props.appLayer,
    };

    return props;
  }

  private renderAppContent(): JSX.Element {
    return (
      <div className="app-content">
        <Switch>
          {appRoutes.map((routeConfig: IRoute<any>, index: number) => (
            <Route
              {...routeConfig.routeProps}
              key={index}
              path={routeConfig.path}
              render={
                (routeProps: RouteComponentProps<any>) => {
                  const props: any =
                    typeof routeConfig.component.type === 'function' ?
                      this.createPropsForComponent(routeConfig, routeProps)
                      : {};
                  return React.cloneElement(routeConfig.component, props);
                }
              }
            />
          ))}
        </Switch>
      </div>
    );
  }


  public render(): JSX.Element {
    const {
      appLayer,
    } = this.props;

    return (
      <div className={`app-wrapper product-${appConfig.product}`}>
        <AppContainer>
          <Header/>
          <div className="app-body">
            <div className="app-body-top-bar">
              <div className="app-menu"><MainMenu appLayer={appLayer}/></div>
              <div className="app-tools">
                <AppSettings/>
              </div>
            </div>
            {this.renderAppContent()}
            <ScrollToTop/>
          </div>
          <Footer appLayer={appLayer}/>
        </AppContainer>
      </div>
    );
  }
}
