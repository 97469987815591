import {appConfig, EAppMode} from './app';
import {IClientApiConfig} from '../interfaces/app';

const apiConfig_DEBUG_MODE: IClientApiConfig = {
	address: `/api`,
};

const apiConfig_PRODUCTION_MODE: IClientApiConfig = {
	address: '/api',
};

export const getApiConfigFor = (appMode: EAppMode): IClientApiConfig => {
	switch (appMode) {
		case EAppMode.DEBUG:
			return apiConfig_DEBUG_MODE;
		case EAppMode.PRODUCTION:
			return apiConfig_PRODUCTION_MODE;
	}
};

export const apiConfig: IClientApiConfig = getApiConfigFor(appConfig.mode);
