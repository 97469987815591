import {EProduct} from "./interfaces";
import {EColor as ESliderColor} from "dyna-ui-input-slider";
import {EColor as EButtonColor} from "dyna-ui-button";

export interface IStyleMixer {
	buttonColor: EButtonColor;
	slidersColor: ESliderColor;
}

export const styleMixer = (product: EProduct): IStyleMixer=>{
	switch(product){
		case EProduct.IM_HOLIDAY:
			return {
				buttonColor: EButtonColor.ORANGE_WHITE,
				slidersColor: ESliderColor.WHITE_ORANGE,
			};
		case EProduct.DIE_TO_FLY:
			return {
				buttonColor: EButtonColor.RED_WHITE,
				slidersColor: ESliderColor.WHITE_ORANGE,
			};
	}
};