import * as React from 'react';
import {dynaDebounce} from "dyna-debounce";

import {scrollToTop} from '../../../../../utils/scrollToTop';

import './index.css';

export interface IScrollToTopProps {
  className?: string;
}

export interface IScrollToTopState {
  show: boolean;
}

export class ScrollToTop extends React.Component<IScrollToTopProps, IScrollToTopState> {
  static defaultProps: IScrollToTopProps = {
    className: "",
  };

  constructor(props: IScrollToTopProps, context: any) {
    super(props, context);
    this.state = {
      show: false,
    };

    this.handleDomChange = dynaDebounce(this.handleDomChange.bind(this), 1000, true);
    this.initDomObserverForChanges();
  }

  public componentWillUnmount(): void {
    this.domObserver.disconnect();
  }

  private domObserver: MutationObserver;

  private readonly baseClassName: string = "dyna-scroll-to-top";
  private readonly className = (subClassName: string = "", active: boolean = true): string => active ? `${this.baseClassName}${subClassName}` : "";

  private handleDomChange(): void {
    let has: boolean = this.hasContentToScroll;
    if (has !== this.state.show) this.setState({show: has});
  }

  private initDomObserverForChanges(): void {
    this.domObserver = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
      for (let mutation of mutations) {
        if (mutation.type === 'childList') this.handleDomChange();
      }
    });

    this.domObserver.observe(
      document.body,
      {attributes: true, childList: true, subtree: true},
    );
  }

  private get hasContentToScroll(): boolean {
    return document.body.offsetHeight > window.innerHeight;
  }

  private handleOnClick(): void {
    scrollToTop(250);
  }

  public render(): JSX.Element {
    const {
      className: userClassName,
    } = this.props;
    const {
      show,
    } = this.state;

    const className: string = [
      userClassName,
      this.className(),
      this.className("--show", show),
      this.className("--hide", !show),
    ].join(" ").trim();

    return (
      <div
        className={className}
        onClick={this.handleOnClick}
      >
        <i className="fas fa-arrow-alt-circle-up"/>
      </div>
    );
  }
}
