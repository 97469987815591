import * as React from 'react';
import {DynaCMSViewer} from "../../../../../packages/dyna-cms-viewer/DynaCMSViewer";

import {appTitle} from "../../../../../components/ui/appTitle/appTitle";
import {dynaCMSClient} from "../../../api/dynaCMSClient";

import {scrollToTop} from "../../../../../utils/scrollToTop";

export interface IAboutProps {
	className?: string;
}

const About: React.SFC<IAboutProps> = (props) => {
	scrollToTop();
	return (
		<div>
			{appTitle("About")}
			<DynaCMSViewer
				dynaCMSClient={dynaCMSClient}
				contentName="imholiday_about_enUS"
			/>
		</div>
	);
};

export default About;