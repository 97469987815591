import * as moment from "moment";
import { IFlightMonthInfoApiData } from "../api/getFlightMonthlyInfo";
import { scanDates } from "../../../utils/scanDates";
import { IDynaPrice } from "dyna-travel-interfaces";

const memory: Array<{         // Prices for each day for the requested months, for one adult
  date: string;         // YYYY-MM-DD
  bestPrice: IDynaPrice;
  cheapestPrice: IDynaPrice;
  fastestPrice: IDynaPrice;
  updated: number;
  isLoading: number | null;
}> = [];

export const getTestBCFPrices = (yyyymmdd: string, loadMonths: number): IFlightMonthInfoApiData => {
  const output: IFlightMonthInfoApiData = {
    flightPrices: {prices: []},
  };
  const prices = output.flightPrices.prices;

  scanDates(
    moment(yyyymmdd).startOf('month'),
    moment(yyyymmdd).add(loadMonths, 'month').endOf('month'),
    date => {
      const yyyymmdd = date.format('YYYY-MM-DD');
      if (date.date() === 10) return;
      if (date.date() === 20) return;

      const generatedPrice = memory.find(p => p.date === yyyymmdd);
      if (generatedPrice) {
        prices.push(generatedPrice);
        return;
      }

      const testPrice = getPrice();
      const newDatePrice = {
        date: yyyymmdd,
        bestPrice: {value: testPrice.best, currency: 'eur'},
        cheapestPrice: {value: testPrice.cheapest, currency: 'eur'},
        fastestPrice: {value: testPrice.fastest, currency: 'eur'},
        updated: moment(date).subtract(1.2, 'weeks').valueOf(),
        isLoading:
          [7, 17].includes(date.date())
            ? moment().subtract(3, 'days').valueOf()
            : null,
      };
      prices.push(newDatePrice);
      memory.push(newDatePrice);
    }
  );

  return output;
};

const prices = [
  {best: 1200, cheapest: 800, fastest: 1500},
  {best: 600, cheapest: 800, fastest: 3000},
  {best: 800, cheapest: 400, fastest: 1200},
  {best: 1000, cheapest: 900, fastest: 1100},
  {best: 600, cheapest: 800, fastest: 667},
  {best: 2200, cheapest: 1100, fastest: 3010},
  {best: 700, cheapest: 600, fastest: 2100},
  {best: 782, cheapest: 500, fastest: 1200},
  {best: 900, cheapest: 600, fastest: 800},
  {best: 450, cheapest: 300, fastest: 800},
  {best: 1450, cheapest: 500, fastest: 2800},
];

let pricePointer = -1;

const getPrice = () => {
  pricePointer++;
  if (!prices[pricePointer]) pricePointer = 0;
  return prices[pricePointer];
};
