export enum EProduct {
	IM_HOLIDAY = "IM_HOLIDAY",
	DIE_TO_FLY = "DIE_TO_FLY",
}

export enum EBaggageType {
  BAGGAGE = "BAGGAGE",
  HAND_BAG = "HAND_BAG",
}

