import {InjectedIntl} from "react-intl";
import {formatMessage} from "../../../../utils/formatMessage";
import {IDynaTravelTripVerticalTimelineMessages} from "dyna-travel-ui-trip-vertical-timeline";
import {IDynaTravelListAgentOffersMessages} from "dyna-travel-ui-list-agent-offers";

export interface IFlightProposalDetailsMessages extends
	IDynaTravelTripVerticalTimelineMessages,
	IDynaTravelListAgentOffersMessages {
	// basic, in this app
	detailsHeader: string;
	redirectHeader: string;
	searchingForThisFlight: string;
	pleaseWait: string;
	flightCannotBeFound: string;
	itIsHardToFindThisFlightAgain: string;
	goBack: string;
	goTop: string;
	yourTrip: string;
	theOffers: string;
	transferItTitle: string;
	transferItDescription: string;
	isLoading: string;
	clickHereForSimilarFlights: string;
	redirectionGreeting: string;
	redirectionMessage: string;
	retryToGetRedirectionLink: string;
	offerIsExpired: string;
	refresh: string;
	networkError: string;
	anotherTabHasBeenOpened: string;
	didntOpenOpenHere: string;
	allTimesAreLocal: string;
	youCanBookmarkTitle: string;
	youCanBookmarkDescription: string;
}

let translatedMessages: IFlightProposalDetailsMessages;

export const messages = (intl: InjectedIntl): IFlightProposalDetailsMessages => {
	if (!translatedMessages) translatedMessages = translateMessages(intl);
	return translatedMessages;
};

export const translateMessages = (intl: InjectedIntl): IFlightProposalDetailsMessages => ({
	// basic, in this app
	detailsHeader: formatMessage({intl, id: "MSG_0744"}),
	redirectHeader: formatMessage({intl, id: "MSG_0745"}),
	searchingForThisFlight: formatMessage({intl, id: "MSG_0746"}),
	pleaseWait: formatMessage({intl, id: "MSG_0747"}),
	flightCannotBeFound: formatMessage({intl, id: "MSG_0748"}),
	itIsHardToFindThisFlightAgain: formatMessage({intl, id: "MSG_0749"}),
	goBack: formatMessage({intl, id: "MSG_0750"}),
	goTop: formatMessage({intl, id: "MSG_0751"}),
	yourTrip: formatMessage({intl, id: "MSG_0756"}),
	theOffers: formatMessage({intl, id: "MSG_0757"}),
	transferItTitle: formatMessage({intl, id: "MSG_0778"}),
	transferItDescription: formatMessage({intl, id: "MSG_0779"}),
	isLoading: formatMessage({intl, id: "MSG_0732"}),
	clickHereForSimilarFlights: formatMessage({intl, id: "MSG_0763"}),
	redirectionGreeting: formatMessage({intl, id: "MSG_0764"}),
	redirectionMessage: formatMessage({intl, id: "MSG_0765"}),
	retryToGetRedirectionLink: formatMessage({intl, id: "MSG_0766"}),
	offerIsExpired: formatMessage({intl, id: "MSG_0767"}),
	refresh: formatMessage({intl, id: "MSG_0768"}),
	networkError: formatMessage({intl, id: "MSG_0769"}),
	anotherTabHasBeenOpened: formatMessage({intl, id: "MSG_0770"}),
	didntOpenOpenHere: formatMessage({intl, id: "MSG_0771"}),
	youCanBookmarkTitle: formatMessage({intl, id: "MSG_0780"}),
	youCanBookmarkDescription: formatMessage({intl, id: "MSG_0781"}),

	// vertical timeline component
	operator: formatMessage({intl, id: "MSG_0719"}),
	handler: formatMessage({intl, id: "MSG_0720"}),
	waitWithChangeLabel: formatMessage({intl, id: "MSG_0721"}),
	waitWithChangeTooltip: formatMessage({intl, id: "MSG_0722"}),
	longWait: formatMessage({intl, id: "MSG_0713"}),
	shortDayNames: formatMessage({intl, id: "MSG_0645"}),
	shortMonthNames: formatMessage({intl, id: "MSG_0798"}),
	longDayNames: formatMessage({intl, id: "MSG_0708"}),
	nextDay: formatMessage({intl, id: "MSG_0715"}),
	nextDateTooltip: formatMessage({intl, id: "MSG_0715"}),
	baggageTypeBaggage: formatMessage({intl, id: "MSG_0724"}),
	baggageTypeHandBag: formatMessage({intl, id: "MSG_0725"}),
	perBag: formatMessage({intl, id: "MSG_0726"}),
	noBags: formatMessage({intl, id: "MSG_0727"}),
	unknownBags: formatMessage({intl, id: "MSG_0728"}),
	totalMax: formatMessage({intl, id: "MSG_0729"}),
	overNight: formatMessage({intl, id: "MSG_0714"}),
	connectInAirport: formatMessage({intl, id: "MSG_0752"}),
	changeAirport: formatMessage({intl, id: "MSG_0753"}),
	hours: formatMessage({intl, id: "MSG_0709"}),
	hoursVeryShort: formatMessage({intl, id: "MSG_0709"}),
	minutes: formatMessage({intl, id: "MSG_0710"}),
	minutesVeryShort: formatMessage({intl, id: "MSG_0710"}),
	flightNo: formatMessage({intl, id: "MSG_0754"}),
	transportationNumber: formatMessage({intl, id: "MSG_0755"}),
	allTimesAreLocal: formatMessage({intl, id: "MSG_0772"}),

	// list agent offers
	select: formatMessage({intl, id: "MSG_0704"}),
	more: formatMessage({intl, id: "MSG_0758"}),
	less: formatMessage({intl, id: "MSG_0759"}),
	agentCompany: formatMessage({intl, id: "MSG_0760"}),
	airlineCompany: formatMessage({intl, id: "MSG_0761"}),
	lowCost: formatMessage({intl, id: "MSG_0762"}),
	refreshToUpdateThePrice: formatMessage({intl, id: "MSG_0774"}),
	refreshTheCurrentPricesAreNotValid: formatMessage({intl, id: "MSG_0777"}),
	refreshButton: formatMessage({intl, id: "MSG_0776"}),
});