import * as React from "react";

import {EMode} from "../interfaces";
import {faIcon} from "../utils/faIcon";
import {DynaButton, ESize, EStyle, EColor} from "dyna-ui-button";

import "./SelectAirTripFooter.less";

export interface ISelectAirTripFooterProps {
  mode: EMode;
  isLoading: boolean;
  messages: {
    clear: string;
    search: string;
    newSearch: string;
    isLoading: string;
  };
  footerLeftContent:JSX.Element;
  iconClear: string;
  iconIsLoading: string;
  iconSearch: string;
  iconNewSearch: string;
  clearButtonColor: EColor,
  searchButtonColor: EColor,
  validationErrors: string[];
  showClearAllButton: boolean;
  onClearClick: () => void;
  onSearchClick: () => void;
  onNewSearchClick: () => void;
}

export class SelectAirTripFooter extends React.Component<ISelectAirTripFooterProps> {

  private renderClearButton(): JSX.Element {
    const {
      mode,
      showClearAllButton,
      isLoading,
      messages: {
        clear: labelClear,
      },
      iconClear,
      clearButtonColor,
      onClearClick,
    } = this.props;

    if (!showClearAllButton) return null;

    let buttonDisabled: boolean;

    if (isLoading) {
      buttonDisabled = true;
    }
    else {
      if (mode === EMode.EDIT) {
        buttonDisabled = false;
      }
      else {
        buttonDisabled = true;
      }
    }

    return (
      <DynaButton
        style={EStyle.ROUNDED}
        color={clearButtonColor}
        size={ESize.LARGE}
        disabled={buttonDisabled}
        onClick={onClearClick}
      >{faIcon(iconClear)} {labelClear}</DynaButton>
    );
  }

  private renderSearchButton(): JSX.Element {
    const {
      mode,
      isLoading,
      messages: {
        search: labelSearch,
        newSearch: labelNewSearch,
        isLoading: labelIsLoading,
      },
      iconIsLoading,
      iconSearch,
      iconNewSearch,
      searchButtonColor,
      onSearchClick,
      onNewSearchClick,
    } = this.props;

    let searchButtonDisabled: boolean;
    let searchButtonLabel: JSX.Element;
    let searchButtonLabelGhost: boolean = false;
    let clickHandler: () => void;

    if (isLoading) {
      searchButtonDisabled = true;
      searchButtonLabel = <span>{labelIsLoading} {faIcon(iconIsLoading)}</span>;
      clickHandler = null;
    }
    else {
      if (mode === EMode.EDIT) {
        searchButtonDisabled = false;
        searchButtonLabel = <span>{labelSearch} {faIcon(iconSearch)}</span>;
        clickHandler = onSearchClick;
      }
      else {
        searchButtonDisabled = false;
        searchButtonLabelGhost = true;
        searchButtonLabel = <span>{faIcon(iconNewSearch)} {labelNewSearch}</span>;
        clickHandler = onNewSearchClick;
      }
    }

    return (
      <DynaButton
        style={EStyle.ROUNDED}
        color={searchButtonColor}
        size={ESize.XLARGE}
        disabled={searchButtonDisabled}
        ghost={searchButtonLabelGhost}
        onClick={clickHandler}
      >{searchButtonLabel}</DynaButton>
    );
  }

  public render(): JSX.Element {
    const {
      footerLeftContent,
      validationErrors,
    } = this.props;

    return (
      <div className="dyna-select-air-trip__footer">
        <div className="dyna-select-air-trip__footer__left">
          {footerLeftContent}
        </div>
        <div className="dyna-select-air-trip__footer__right">
          <div className="dyna-select-air-trip__validation-messages">
            {validationErrors.map((message: string, index: number) => (
              <div key={index}><i className="fa fa-exclamation-triangle" aria-hidden="true"/> {message}</div>
            ))}
          </div>
          <div className="dyna-select-air-trip__button-container">
            {this.renderClearButton()}
            {this.renderSearchButton()}
          </div>
        </div>
      </div>
    );
  }

}
