import {EEndPoint, EStartEnd, IDynaTripFilter} from "../interfaces";

export class FilterHolder {
	constructor(private readonly filter: IDynaTripFilter) {
	}

	private readonly cache: { [key: string]: any } = {};

	public getSegmentHour(segmentIndex: number, endPoint: EEndPoint, dateCode: string, startEnd: EStartEnd, defaultValue: number): number {
		const key: string = [segmentIndex, endPoint, dateCode, startEnd, defaultValue].join();
		const cachedValue: number = this.cache[key];

		if (cachedValue !== undefined) return cachedValue;

		try {
			this.cache[key] = this.filter
				.segments[segmentIndex]
				[endPoint.toLowerCase()]
				.dates[dateCode]
				[startEnd.toLowerCase()];
		} catch (err) {
			this.cache[key] = defaultValue;
		}
		return this.cache[key];
	}

}