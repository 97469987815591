exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QualityIndexSwitch-module-root--2xJReZulYTFt {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n.QualityIndexSwitch-module-label--2dvnPMJMN9hX {\n  display: block;\n}\n", ""]);

// exports
exports.locals = {
	"root": "QualityIndexSwitch-module-root--2xJReZulYTFt",
	"label": "QualityIndexSwitch-module-label--2dvnPMJMN9hX"
};