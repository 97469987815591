import {InjectedIntl} from "react-intl";
import {formatMessage} from "./formatMessage";

// https://github.com/yahoo/react-intl/wiki/API#date-formatting-apis
// https://github.com/yahoo/react-intl/wiki/Components#date-formatting-components

export const formatTimeFromString = (intl: InjectedIntl, timeHHMM: string): string => {
  return formatTime(intl, new Date(`2017-12-25T${timeHHMM}`));
};

export const formatTime = (intl: InjectedIntl, time: Date): string => {
  return intl.formatTime(time);
};

export const formatDate = (intl: InjectedIntl, date: Date): string => {
  return intl.formatDate(date, {
    day: "numeric",
    month: "short",
    year: "numeric",
  }).toUpperCase();
};

export const formatDateWithDayNameFromString = (intl: InjectedIntl, dateYYYYMMDD: string): string => {
  return formatDateWithDayName(intl, new Date(dateYYYYMMDD));
};

export const formatDateWithDayName = (intl: InjectedIntl, date: Date): string => {
  return (
    intl.formatDate(
      date, {
        weekday: "short",
      },
    ) +
    ' ' +
    intl.formatDate(
      date, {
        day: "numeric",
        month: "short",
        year: "numeric",
      },
    ).toUpperCase()
  );
};

export const formatDuration = (intl: InjectedIntl, minutes: number): string => {
  const hoursText: string = formatMessage({ intl, id: 'MSG_0709' });
  const minutesText: string = formatMessage({ intl, id: 'MSG_0710' });
  const hours: number = Math.floor(minutes / 60);
  const min: number = Math.round(minutes - (hours * 60));
  let output = "";
  if (hours > 0) output += `${hours}${hoursText}`;
  if (min > 0 && hours > 0) output += " ";
  if (min > 0) output += `${min}${minutesText}`;
  return output;
};
