import * as moment from "moment";

import {DynaNodeClient} from "dyna-node/dist/commonJs/web";
import {DynaCurrencies} from "dyna-currencies";
import {IDynaPlace, IDynaTrip, IDynaTripRequest} from "dyna-travel-interfaces";
import {analytics} from "../../../../libs/analytics";

import {FetchDynaTripsHandler} from "./sections/FetchDynaTripsHandler";

import {searchAirport} from "./sections/DynaPlaceInfoHandler";
import {getAgentTripOfferRedirectionLink} from "./sections/getAgentTripOfferRedirectionLink";

import {copyObject} from "../../utils/copyObject";

export interface IDynaTravelClientAPIConfig {
	currencies: DynaCurrencies;
  dynaTravelServiceSearchFlightsNodeAddress: string;
  dynaTravelServiceFacilitiesNodeAddress: string;
}

// dev note: don't use this to get data, use the logic/dynaTravelModule
// this only the connection with the backend

export class DynaTravelClient {
  private readonly _config: IDynaTravelClientAPIConfig;
  private readonly _nodeDevice: DynaNodeClient;
  private readonly _currencies: DynaCurrencies;

  constructor(config: IDynaTravelClientAPIConfig) {
    this._config = config;
    this._currencies = config.currencies;
    this._nodeDevice = new DynaNodeClient({prefixAddress: 'dynaTravelClientApp'});
  }

	public fetchTrips(tripRequest: IDynaTripRequest, replyTimeoutInMs?: number): FetchDynaTripsHandler {
  	// convert the iso depart dates (with time zone) to string dates (without zone)
		tripRequest = copyObject(tripRequest);
		tripRequest.routes = tripRequest.routes.map((route) => {
			route.departDate.localDate = moment(new Date(route.departDate.date)).format('YYYY-MM-DD');
			return route;
		});

    const analyticsLabel: string =
      tripRequest.routes
        .map(route => {
          return (
            route.origin.name.codeName
            + "->" +
            route.destination.name.codeName
          );
        })
        .join(' && ');

    analytics.sendEvent("Flights", "Search", analyticsLabel);

		return new FetchDynaTripsHandler({
			tripRequest,
			nodeDevice: this._nodeDevice,
			currencies: this._currencies,
			serviceDNA: this._config.dynaTravelServiceSearchFlightsNodeAddress,
			replyTimeoutInMs,
		});
  }

	public searchAirport(searchText: string, language: string, records: number = 10): Promise<IDynaPlace[]> {
    return searchAirport(this._nodeDevice, this._config.dynaTravelServiceFacilitiesNodeAddress, searchText, language, records);
  }

	public getAgentTripOfferRedirectionLink(trip: IDynaTrip): Promise<string> {
		return getAgentTripOfferRedirectionLink(this._config.dynaTravelServiceFacilitiesNodeAddress, this._nodeDevice, trip);
	}

  public closeConnections(): void {
    this._nodeDevice.stopConnections();
  }
}
