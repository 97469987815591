import * as React from 'react';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import {dynaClassName, DynaClassName} from "dyna-class-name";
import {ImHolidayLogo, ELogoType} from "dyna-travel-ui-components";

import {appConfig, EProduct} from "../../../../../settings/app";
import {DieToFlyLogo} from "../logos/DieToFlyLogo";
import {HeaderBanners} from "./components/header-banners";
import {FacebookLikeButton} from "../shareButtons/FacebookLikeButton";
import {ChristmasHat} from "./components/ChristmasHat";

import "./header.css";

const qrBarcodeImholidayMainPage = require('./assets/imholiday-mainpage.svg');

const cs: DynaClassName = dynaClassName("dyna-travel-app-header");

const Logo = (): JSX.Element => {
  switch (appConfig.product) {
    case EProduct.IM_HOLIDAY:
      return (
        <ImHolidayLogo
          className="imholiday-logo"
          type={ELogoType.LANDSCAPE}
        />
      );
    case EProduct.DIE_TO_FLY:
      return (
        <DieToFlyLogo/>
      );
    default:
      return (
        <h4>logo: unknown product</h4>
      );
  }
};

const Moto = (): JSX.Element => {
  return (
    <div className={cs("__moto")}>
      <FormattedMessage id="MSG_0810"/>
    </div>
  );
};


export const Header = (): JSX.Element => {
    return (
      <header className={cs()}>
        <div className={cs("__first-row")}>
          <div className={cs("__first-row__logo-container")}>
            <Link to="/">
              <Logo/>
              <ChristmasHat/>
            </Link>
            <Moto/>
          </div>
          <div className={cs("__first-row__aside-container")}>
            <FacebookLikeButton/>
            <img className={cs('__qrBarcode')} src={qrBarcodeImholidayMainPage}/>
          </div>
        </div>
        <HeaderBanners/>
      </header>
    );
  };
