import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {connect, Dispatch} from "react-redux";
import {IDynaTripProposal} from "dyna-travel-interfaces";

import {EProduct} from "../../../../settings/app";
import {IHostAppStore} from "../..";
import {IAppContainerProps} from "../../../../interfaces/app";

import {FlightProposalDetailsComponent, IFlightProposalDetailsComponentDataProps, IFlightProposalDetailsComponentEventsProps} from './FlightProposalDetailsComponent';
import {ISearchFlightProcess} from "../../state/SearchFlights/reducer";

import {messages} from "./messages";

export interface IFlightProposalDetailsProps extends IAppContainerProps {
	show?: boolean;
	product: EProduct;
	searchFlightProcessId?: string;
	tripProposal?: IDynaTripProposal;
	onClose?: () => void;
	onRefreshClick?: () => void;
}

const mapStateToProps = (state: IHostAppStore, ownProps: IFlightProposalDetailsProps): IFlightProposalDetailsComponentDataProps => {
	const {intl} = ownProps;

	const searchFlightProcess: ISearchFlightProcess = state.dynaTravelModule.searchFlights.searchFlightProcesses[ownProps.searchFlightProcessId];

	return {
		intl,
		product: ownProps.product,
		locale: state.app.locale,
		currency: state.app.currency,
		messages: messages(intl),
		show: ownProps.show,
		isLoading: searchFlightProcess.isLoading,
		loadingProgressPercent: searchFlightProcess.loadingProgressPercent,
		proposalsExpired: searchFlightProcess.tripProposalsExpired,
		tripProposal: ownProps.tripProposal,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: IFlightProposalDetailsProps): IFlightProposalDetailsComponentEventsProps => {
	return {
		onClose: ownProps.onClose,
		onRefreshClick: ownProps.onRefreshClick,
	};
};

const FlightProposalDetails = (withRouter as any)(injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps,
)(FlightProposalDetailsComponent)as any));

export default FlightProposalDetails;