import * as React from 'react';
import {Button} from 'react-bootstrap';

import {appTitle} from "../../../../../components/ui/appTitle/appTitle";
import {IAppContainerProps} from "../../../../../interfaces/app";

export interface IMainPageProps extends IAppContainerProps {
	appLabel?: string;
	isLogged?: boolean;
	onViewCenterClick?: () => void;
	onViewTopClick?: () => void;
}

export const component = (props: IMainPageProps) => (
	<div className="app-main-page">
		{appTitle('Main page')}
		<h1>Welcome!</h1>
		{props.isLogged ? null : <p>To gain more access please login!</p>}
		<div>
			<Button onClick={props.onViewCenterClick}>view center</Button>
			<Button onClick={props.onViewTopClick}>view top</Button>
		</div>
	</div>
);
