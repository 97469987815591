import * as React from "react";
import {CSSProperties} from "react";
import {round} from "dyna-loops";
import {IDynaBaggage} from "dyna-travel-interfaces";
import {DynaTooltip, EColor as EBaggageIconTooltipColor} from "dyna-ui-tooltip";

import {BaggageInfo} from "../baggage-info/BaggageInfo";
import {EBaggageType} from "../interfaces";

import {getBaggageIcon, getBaggageCountLabel, hasBaggage} from "../utils/baggage";

import "./BaggageIcon.less";

export {EBaggageIconTooltipColor}

export interface IBaggagesIconProps {
	className?: string;
	baggage: IDynaBaggage;
	baggageType: EBaggageType;
	tooltipEnabled?: boolean;
	tooltipColor?: EBaggageIconTooltipColor;
	height?: number;
	messages?: {
		baggageTypeBaggage: string;
		baggageTypeHandBag: string;
		perBag: string;
		noBags: string;
		unknownBags: string;
		totalMax: string;
	};
}

export class BaggageIcon extends React.Component<IBaggagesIconProps> {
	static defaultProps: IBaggagesIconProps = {
		className: "",
		baggageType: EBaggageType.BAGGAGE,
		tooltipEnabled: true,
		tooltipColor: EBaggageIconTooltipColor.WHITE_BLACK,
		baggage: null,
		height: 32,
		messages: {
			baggageTypeBaggage: "Baggage",
			baggageTypeHandBag: "Hand bag",
			perBag: "per bag",
			noBags: "no bags",
			unknownBags: "no info",
			totalMax: "total max",
		},
	};

	constructor(props: IBaggagesIconProps) {
		super(props);
	}

	public render(): JSX.Element {
		const {
			className: userClassName,
			baggageType,
			tooltipEnabled,
			tooltipColor,
			baggage,
			height: size,
			messages,
		} = this.props;

		const className: string = [
			userClassName,
			"dyna-transport-baggage-icon",
			hasBaggage(baggage) ?
				""
				: "dyna-transport-baggage-icon__no-baggage",
		].join(' ').trim();

		const baggageIcon: JSX.Element = getBaggageIcon("dyna-transport-baggage-icon__icon", baggageType, size);

		const countStyle: CSSProperties = {
			fontSize: round(size * 0.6, 2) + 'px',
		};
		let countLabel: string | JSX.Element = getBaggageCountLabel(baggage);

		const countClassName: string = [
			"dyna-transport-baggage-icon__icon-count",
			baggageType === EBaggageType.HAND_BAG
				? "dyna-transport-baggage-icon__icon-count--small-bag"
				: "dyna-transport-baggage-icon__icon-count--big-bag",
		].join(' ').trim();

		const weightContainerStyle: CSSProperties = {
			fontSize: round(size * 0.4, 2) + 'px',
		};

		return (
			<DynaTooltip
				color={tooltipColor}
				enabled={tooltipEnabled}
				tooltipContent={<BaggageInfo baggage={baggage} baggageType={baggageType} messages={messages}/>}
			>
				<div className={className}>
					<div className="dyna-transport-baggage-icon__icon-container">
						{baggageIcon}
						<div className={countClassName} style={countStyle}>
							{countLabel}
						</div>
					</div>
					<div className="dyna-transport-baggage-icon__weight-container" style={weightContainerStyle}>
						{baggage && baggage.weightPerBag ? <div>/ {baggage.weightPerBag}<br/>kg <span className="fab fa-cloudscale"/></div> : null}
						{baggage && baggage.maxTotalWeight ? <div>{baggage.maxTotalWeight}kg<br/>{messages.totalMax} <span className="fab fa-cloudscale"/></div> : null}
					</div>
				</div>
			</DynaTooltip>
		);
	}
}
