import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {
	DynaInput0024Slider,
	DynaInputDurationSlider,
	ESize as ESliderSize,
	EMin, IHourRange,
} from "dyna-ui-input-slider";

import {EPlacePoint, EProduct, IDynaTripFilterSegment, IDynaTripFilterSegmentByDate, IDynaTripFilterSegmentPlace} from "../interfaces";
import {IValuesPerDate} from "../DynaTravelFilterTrips";
import {ESize as EPlaceLabelSize, PlaceLabel} from "./PlaceLabel";
import {IDynaTravelFilterTripsMessages} from "../messages";
import {copyObject} from "../utils/copyObject";
import {styleMixer} from "../styleMixer";

import "./SegmentFilter.less";

export interface ISegmentFilterProps {
	className?: string;
	product: EProduct;
	messages: IDynaTravelFilterTripsMessages;
	durationMinutesValues: number[];
	maxDurationInMin: number;
	originHours: IValuesPerDate;
	destinationHours: IValuesPerDate;
	formatDate: (date: Date) => string;
	formatDuration: (minutes: number) => string;
	value: IDynaTripFilterSegment;
	onChange: (value: IDynaTripFilterSegment) => void;
}

export class SegmentFilter extends React.Component<ISegmentFilterProps> {

	private readonly cn: DynaClassName = dynaClassName("dyna-travel-trip-filter-segment");

	private handleChangeSegmentHourlyRange(placePoint: EPlacePoint, filterSegmentByDate: IDynaTripFilterSegmentByDate, hourRange: IHourRange): void {
		const {value, onChange} = this.props;
		const newValue: IDynaTripFilterSegment = copyObject(value);
		const segmentPlace = placePoint === EPlacePoint.FROM ? newValue.origin : newValue.destination;
		const segmentByDate = segmentPlace.dates[filterSegmentByDate.dateLocale];
		segmentByDate.start = hourRange.from;
		segmentByDate.end = hourRange.to;
		onChange(newValue);
	}

	private getHours(placePoint: EPlacePoint, dateLocale: string): number[] {
		const {originHours, destinationHours} = this.props;
		const hours: IValuesPerDate = placePoint === EPlacePoint.FROM ? originHours : destinationHours;
		return hours[dateLocale];
	}

	private renderDate(dateLocale: string, tripProposalsCount: number): JSX.Element {
		const {
			formatDate,
		} = this.props;

		return (
			<div>
				<span className={this.cn("__label-date")}><i className="fas fa-calendar-alt"/> {formatDate(new Date(dateLocale))} </span>
				<span className={this.cn("__label-count")}>({tripProposalsCount})</span>
			</div>
		);
	}

	private renderFilterSegmentByDate(placePoint: EPlacePoint, filterSegmentByDate: IDynaTripFilterSegmentByDate, index: number): JSX.Element {
		const {
			product,
		} = this.props;

		const hoursUsage: number[] = this.getHours(placePoint, filterSegmentByDate.dateLocale) || [];
		const tripProposalsCount: number = hoursUsage.length;
		const disabled =
			filterSegmentByDate.start === 0 &&
			filterSegmentByDate.end === 24 &&
			tripProposalsCount === 0;

		return (
			<div
				key={index}
				className={this.cn("__segment-date-filters")}
			>
				<DynaInput0024Slider
					className={this.cn("/dyna-filter-input", "__hour-range", disabled && "__hour-range--disabled")}
					color={styleMixer(product).slidersColor}
					label={this.renderDate(filterSegmentByDate.dateLocale, tripProposalsCount)}
					hours={hoursUsage}
					value={{from: filterSegmentByDate.start, to: filterSegmentByDate.end}}
					onChange={(name: string, value: IHourRange) => this.handleChangeSegmentHourlyRange(placePoint, filterSegmentByDate, value)}
				/>
			</div>
		);
	}

	private renderSegmentPlace(filterSegmentPlace: IDynaTripFilterSegmentPlace, placePoint: EPlacePoint): JSX.Element {
		return (
			<div className={this.cn("__segment-place")}>
				<PlaceLabel
					place={filterSegmentPlace.place}
					size={EPlaceLabelSize.MEDIUM}
					placePoint={placePoint}
					showArrow
          showDescription
          messages={this.props.messages}
				/>
				{Object.keys(filterSegmentPlace.dates)
					.sort((localeA: string, localeB: string) => Number(new Date(localeA)) - Number(new Date(localeB)))
					.map((dateLocale: string) => filterSegmentPlace.dates[dateLocale])
					.map(this.renderFilterSegmentByDate.bind(this, placePoint))}
			</div>
		)
	}

	private handleChangeMaxDuration = (name: string, maxDurationInMin: number): void => {
		const {
			maxDurationInMin: statsMaxDurationInMin,
			value,
			value: {
				maxDurationInMin: valueMaxDurationInMin,
			},
			onChange,
		} = this.props;
		const newValue: IDynaTripFilterSegment = copyObject(value);
		newValue.maxDurationInMin = maxDurationInMin >= statsMaxDurationInMin ? null : maxDurationInMin;
		if (valueMaxDurationInMin !== maxDurationInMin) onChange(newValue);
	}

	private renderMaxDurationInMin(): JSX.Element {
		const {
			product,
			durationMinutesValues,
			maxDurationInMin: statsMaxDurationInMin,
			value: {
				maxDurationInMin: valueMaxDurationInMin,
			},
			messages: {
				duration,
			},
			formatDuration,
		} = this.props;

		return (
			<div className={this.cn("__max-duration")}>
				<DynaInputDurationSlider
					className={this.cn("/dyna-filter-input")}
					size={ESliderSize.PX32}
					color={styleMixer(product).slidersColor}
					ticksCount={48}
					label={<span><i className="fas fa-clock"/> {duration}</span>}
					minType={EMin.MIN}
					values={durationMinutesValues.length ? durationMinutesValues : [0, 3000]}
					formatValue={formatDuration}
					value={valueMaxDurationInMin !== null ? valueMaxDurationInMin : statsMaxDurationInMin}
					onChange={this.handleChangeMaxDuration}
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {
			value: {
				origin, destination,
			},
      messages,
		} = this.props;

		return (
			<div className={this.cn.root(this.props)}>
				<div className={this.cn("__segment-info")}>
					<PlaceLabel
            size={EPlaceLabelSize.LARGE}
            place={origin.place}
            placePoint={EPlacePoint.FROM}
            showArrow
            messages={messages}
          />
					<PlaceLabel
            size={EPlaceLabelSize.LARGE}
            place={destination.place}
            placePoint={EPlacePoint.TO}
            messages={messages}
          />
				</div>
				{this.renderMaxDurationInMin()}
				<div className={this.cn("__segment-parts")}>
					<div className={this.cn("__segment-parts__origin")}>{this.renderSegmentPlace(origin, EPlacePoint.FROM)}</div>
					<div className={this.cn("__segment-parts__destination")}>{this.renderSegmentPlace(destination, EPlacePoint.TO)}</div>
				</div>
			</div>
		);
	}
}
