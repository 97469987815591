import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {FormattedMessage} from "react-intl";

import "./footer-links.css";

export class FooterLinks extends React.Component<{}> {
  private readonly cn: DynaClassName = dynaClassName("dyna-app-footer-links");

  public render(): JSX.Element {
    return (
      <div>

        <div className={this.cn.root(this.props)}>
          <a href="https://info.imholiday.com/airlines/">
            <div>
              <FormattedMessage id="0" defaultMessage="Airlines"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/airlines-baggage-allowance/">
            <div>
              <FormattedMessage id="0" defaultMessage="Baggage allowance"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/category/travel-blog/">
            <div>
              <FormattedMessage id="0" defaultMessage="Travel blogs"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/airlines/">
            <div>
              <FormattedMessage id="0" defaultMessage="Review airlines"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/airlines-baggage-allowance/">
            <div>
              <FormattedMessage id="0" defaultMessage="Review baggage service"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/category/imholiday-tips">
            <div>
              <FormattedMessage id="0" defaultMessage="imHoliday tips"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/category/travel-tips">
            <div>
              <FormattedMessage id="0" defaultMessage="Travel tips"/>
            </div>
          </a>
        </div>

        <div className={this.cn.root(this.props)}>
          <a href="https://info.imholiday.com/about-imholiday">
            <div>
              <FormattedMessage id="MSG_0613" defaultMessage="About"/>
            </div>
          </a>
          <a
            href="https://info.imholiday.com/contact-imholiday"
          >
            <div>
              <FormattedMessage id="MSG_0611" defaultMessage="Contact"/>
            </div>
          </a>
          <a
            href="https://info.imholiday.com/imholiday-feedback-form"
          >
            <div>
              <FormattedMessage id="MSG_0784" defaultMessage="Feedback"/>
            </div>
          </a>
          <a
            href="https://info.imholiday.com/report-a-problem"
          >
            <div>
              <FormattedMessage id="MSG_0806" defaultMessage="Report a problem"/>
            </div>
          </a>
        </div>

        <div className={this.cn.root(this.props)}>
          <a href="https://info.imholiday.com/business-inquiry">
            <div>
              <FormattedMessage id="MSG_0805" defaultMessage="Work with us"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/privacy-policy">
            <div>
              <FormattedMessage id="MSG_0802" defaultMessage="Privacy Policy"/>
            </div>
          </a>
          <a href="https://info.imholiday.com/terms-and-conditions">
            <div>
              <FormattedMessage id="MSG_0801" defaultMessage="Terms of Use"/>
            </div>
          </a>
        </div>

      </div>
    );
  }
}
