import * as React from 'react';
import {DynaIsLoading} from "dyna-ui-is-loading";

import './AppContainerComponent.css';

export interface IAppContainerComponentProps  {
	children?: any;
	centerAppContent?: boolean;
	isLoadingFullScreen?: boolean;
	isLoadingFullScreenMessage?: string;
}

interface IAppContainerComponentState {
}

export class AppContainerComponent extends React.Component<IAppContainerComponentProps, IAppContainerComponentState> {
	static defaultProps: IAppContainerComponentProps = {
		children: null,
		centerAppContent: true,
		isLoadingFullScreen: false,
		isLoadingFullScreenMessage: null,
	};

	constructor(props: IAppContainerComponentProps, context: any) {
		super(props, context);
	}

	public render(): JSX.Element {
		const {
			children,
			centerAppContent,
			isLoadingFullScreen,
			isLoadingFullScreenMessage,
		} = this.props;

		return (
			<div className="app-container">
				<div className="app-backgrounds-container" />
				<div className={`app-content-container ${centerAppContent ? 'app-content-container--center' : ''}`}>{children}</div>
				<DynaIsLoading
					className="app-full-screen-is-loading"
					show={isLoadingFullScreen}
					fullScreen
					message={isLoadingFullScreenMessage}
				/>
			</div>
		);
	}
}

