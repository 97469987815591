// help: http://goqr.me/api/doc/create-qr-code/

import {DynaNodeClient} from "dyna-node/dist/commonJs/web";
import {
	COMMAND_TinyURL_Get,
	ICOMMAND_TinyURL_Get_Data,
	ICOMMAND_TinyURL_Response_Data,
} from "dyna-tiny-url-service";
import {serviceAddress} from "../../settings/serviceAddress";

const client = new DynaNodeClient({prefixAddress: 'clientGenerateQRBarcode'});

export const getQrBarcodeImageUrl = async (link: string): Promise<string> => {
	const response = await client.sendReceive<null, ICOMMAND_TinyURL_Get_Data, null, ICOMMAND_TinyURL_Response_Data>({
		to: serviceAddress('dyna-tiny-url-service-v1'),
		command: COMMAND_TinyURL_Get,
		data: { url: link },
	});
	return response.data.qrBarcode;
};