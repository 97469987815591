import * as React from "react";

import {DynaSvg} from "dyna-svg";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {dynaClassName, DynaClassName} from "dyna-class-name";
import {DynaButton, ESize} from "dyna-ui-button";
import {DynaLocalStorage} from "dyna-local-storage";
import {EProduct} from "../interfaces";
import {styleMixer} from "../styleMixer";

const icon: string = require('../assets/chat.svg');

import "./FeedbackBannerTransparent.less";

export interface IFeedbackBannerTransparentProps {
	className?: string;
	product: EProduct
	showItAfterLoads: number;
	showIsAfterSecs: number;
	messages?: IFeedbackBannerTransparentMessages;
	onClick: () => void;
}

export interface IFeedbackBannerTransparentMessages {
	feedbackLabel: string;
	yourFeedbackIsPreciousLabel: string;
	tellUsWhatYouThinkLabel: string;
	lessThanAMinuteLabel: string;
	loadButton: string;
}

export interface IFeedbackBannerTransparentState {
	show: boolean;
	animation: boolean;
}

export class FeedbackBannerTransparent extends React.Component<IFeedbackBannerTransparentProps, IFeedbackBannerTransparentState> {
	static defaultProps: IFeedbackBannerTransparentProps = {
		className: "",
		product: EProduct.IM_HOLIDAY,
		showItAfterLoads: 1,
		showIsAfterSecs: 1,
		messages: {
			feedbackLabel: "Feedback",
			yourFeedbackIsPreciousLabel: "Your feedback is precious",
			tellUsWhatYouThinkLabel: "Tell us what you think",
			lessThanAMinuteLabel: "It takes less that a minute!",
			loadButton: "Open",
		},
		onClick: () => undefined,
	};

	constructor(props: IFeedbackBannerTransparentProps) {
		super(props);
		this.state = {
			show: false,
			animation: true,
		};
	}

	public componentDidMount(): void {
		const {showItAfterLoads, showIsAfterSecs} = this.props;

		let loadedTimes: number = this.storage.getItem<number>("loadedTimes", 0);
		loadedTimes++;
		this.storage.setItem("loadedTimes", loadedTimes);

		if (!this.storage.getItem("feedback-batton-clicked") && showItAfterLoads + 1 <= loadedTimes) {
			setTimeout(() => {
				if (!this.isGone) {
					this.setState({show: true, animation: true});
				}
			}, showIsAfterSecs * 1000);
		}
	}

	public componentWillUnmount(): void {
		this.isGone = true;
	}

	private storage: DynaLocalStorage = new DynaLocalStorage("dyna-travel-Feedback-banner-transparent")
	private className: DynaClassName = dynaClassName("dyna-travel-Feedback-banner-transparent");
	private isGone: boolean = false;

	private handleClick = (): void => {
		const {onClick} = this.props;
		this.storage.setItem("feedback-batton-clicked", true);
		this.setState({show: false});
		onClick();
	};

	public render(): JSX.Element {
		const {
			className: userClassName,
			product,
			messages,
		} = this.props;
		const {
			show,
			animation,
		} = this.state;

		const className: string = this.className("", "/" + userClassName);

		return (
			<DynaAnimationVerticalContainer show={show} animationEnabled={animation}>
				<div className={className}>
					<div className={this.className("__feedback")}>
						<DynaSvg className={this.className("__feedback__icon")} src={icon}/>
						<div className={this.className("__feedback__text")}>{messages.feedbackLabel}</div>
					</div>
					<div className={this.className("__text-container")}>
						<div className={this.className("__text-container__header")}>{messages.yourFeedbackIsPreciousLabel}</div>
						<div className={this.className("__text-container__body")}>{messages.tellUsWhatYouThinkLabel}</div>
						<div className={this.className("__text-container__footer")}>{messages.lessThanAMinuteLabel}</div>
					</div>
					<div className={this.className("__load-container")}>
						<DynaButton
							className={this.className("__load-container__button")}
							color={styleMixer(product).primaryColor}
							size={ESize.LARGE}
							onClick={this.handleClick}
						>{messages.loadButton}</DynaButton>
					</div>
				</div>
			</DynaAnimationVerticalContainer>
		);
	}
}
