import {withRouter} from 'react-router';
import {connect, Dispatch} from "react-redux";
import {ICurrency} from "dyna-currencies";
import {IDynaTravelSettings} from "dyna-travel-ui-settings";

import {IHostAppStore} from "../../../index";

import {AppSettingsComponent, IAppSettingsComponentProps} from './AppSettingsComponent';
import {appMainChangeCurrency, appMainChangeLocale} from "../../../state/actions";
import {IDynaTranslationLocaleOption} from "../../../../../packages/dyna-translation-manager/DynaTranslationManager";

export interface IAppSettingsProps {
}

const mapStateToProps = (state: IHostAppStore, ownProps: IAppSettingsProps): IAppSettingsComponentProps => {
	return {
		settings: {
			locale: state.app.locale,
			currency: state.app.currency,
		},
		showEnglishWithCodesLocale: state.app.showEnglishWithCodesOption,
		settingsOptions: {
			languages: state.app.localeOptions.map((localeOption: IDynaTranslationLocaleOption) => ({locale: localeOption.locale, label: localeOption.label})),
			currencies: state.app.currencies.map((currency: ICurrency) => ({currencyCode: currency.code, labelSymbol: currency.symbol, labelName: currency.name})),
		},
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: IAppSettingsProps): IAppSettingsComponentProps => {
	return {
		onChange: (settings: IDynaTravelSettings) => {
			dispatch(appMainChangeCurrency(settings.currency));
			dispatch(appMainChangeLocale(settings.locale)); // the language is the locale, since, locale we pass as language in options of the app settings
		},
	};
};

export const AppSettings = (withRouter as any)(connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppSettingsComponent));
