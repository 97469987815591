import {IDynaPlace} from "dyna-travel-interfaces";

export enum EProduct {
	IM_HOLIDAY = "IM_HOLIDAY",
	DIE_TO_FLY = "DIE_TO_FLY",
}

export enum EMode {
	VIEW="VIEW",
	EDIT="EDIT",
}

export enum EPlacePoint {
	FROM = "FROM",
	TO = "TO",
}

export enum EEndPoint {
	ORIGIN = "ORIGIN",
	DESTINATION = "DESTINATION",
}

export enum EStartEnd {
	START="START",
	END="END",
}

export interface IDynaTripFilter {
	active: boolean;                        // is true when the user has set anything
	maxPrice: number;                       // null means no selection (so max is selected)
	segments: IDynaTripFilterSegment[];
}

export interface IDynaTripFilterSegment {
	[key: string]: any;
	maxDurationInMin: number;
	origin: IDynaTripFilterSegmentPlace;
	destination: IDynaTripFilterSegmentPlace;
}

export interface IDynaTripFilterSegmentPlace {
	place: IDynaPlace;
	dates: {[dateLocale:string]: IDynaTripFilterSegmentByDate};
}

export interface IDynaTripFilterSegmentByDate {
	dateLocale: string; // date in code string format
	start: number;      // 00-24
	end: number;        // 00-24
}
