import {guid} from "dyna-guid";
import {EMode} from "dyna-travel-ui-select-air-trip";
import {EDeviceCategory, hasEnv} from "dyna-detect-env";
import {ETripQualityIndex, IDynaTripProposal, IDynaTripRequest} from "dyna-travel-interfaces";
import {IDynaTripFilter} from "dyna-travel-ui-list-proposals";

import {IReduxAction} from "../../../../redux";
import {IHostAppStore} from "../..";
import {IReduxThunkAction} from "../../../../redux/interfaces";
import {ILoadFlightsCompleteInfo} from "../../api/DynaTravelClient/interfaces";
import {ISearchFlightProcess} from "./reducer";

import {dynaTravelModule} from "../../logic";
import {dynaTravelModuleConfig} from "../../../../settings/dynaTravelModuleConfig";
import {expireProposalsManager} from "./expireProposalsManager";

import {appMainIsLoadingFullScreen, appMainUpdateMainMenuIsLoading, appMainViewCenter} from "../../../Application/state/actions";

export const DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP: string = 'DT_SF_UPDATE_UI_REQUEST_TRIP';
export const DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_CLEAR_FORM: string = 'DT_SF_UPDATE_UI_REQUEST_TRIP_CLEAR_FORM';
export const DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_NEW_SEARCH: string = 'DT_SF_UPDATE_UI_REQUEST_TRIP_NEW_SEARCH';
export const DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_TODAY: string = 'DT_SF_UPDATE_UI_REQUEST_SELECT_TODAY';
export const DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_NEXT_WEEKEND: string = 'DT_SF_UPDATE_UI_REQUEST_SELECT_NEXT_WEEKEND';
export const DynaTravel_SearchFlights_REQUEST_SEARCH_AIR_TRIP: string = 'DT_SF_REQUEST_SEARCH_AIR_TRIP';
export const DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP: string = 'DT_SF_RECEIVE_SEARCH_AIR_TRIP';
export const DynaTravel_SearchFlights_REFRESH_DUE_TO_NEW_FILTERED_PROPOSALS: string = 'DT_SF_REFRESH_DUE_TO_NEW_FILTERED_PROPOSALS';
export const DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_PROGRESS: string = 'DT_SF_RECEIVE_SEARCH_AIR_TRIP_PROGRESS';
export const DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_ERROR: string = 'DT_SF_RECEIVE_SEARCH_AIR_TRIP_ERROR';
export const DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_COMPLETED: string = 'DT_SF_RECEIVE_SEARCH_AIR_TRIP_COMPLETED';
export const DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_CANCEL: string = 'DT_SF_RECEIVE_SEARCH_AIR_TRIP_CANCEL';
export const DynaTravel_SearchFlights_PROPOSALS_EXPIRED: string = 'DT_SF_PROPOSALS_EXPIRED';
export const DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON: string = 'DT_SF_PROPOSALS_SHOW_EXPIRED_BALLOON';
export const DynaTravel_SearchFlights_LIST_VIEW_SORT_BY_TRIP_QUALITY_INDEX: string = 'DT_SF_LIST_ViEW_SORT_BY_TRIP_QUALITY_INDEX';
export const DynaTravel_SearchFlights_LIST_VIEW_LOAD_MORE: string = 'DT_SF_LIST_ViEW_LOAD_MORE';
export const DynaTravel_SearchFlights_IS_LOADING_MORE: string = 'DT_SF_IS_LOADING_MORE';
export const DynaTravel_SearchFlights_LIST_VIEW_PROPOSAL_SELECTED: string = 'DT_SF_LIST_ViEW_PROPOSAL_SELECTED';
export const DynaTravel_SearchFlights_ON_FILTER_TRIPS_CHANGE: string = 'DT_SF__ON_FILTER_TRIPS_CHANGE';

export const dynaTravelSearchFlightsUpdateUIRequestTrip = (searchFlightProcessId: string, requestTrip: IDynaTripRequest): IReduxAction => ({
	type: DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP,
	payload: {searchFlightProcessId, requestTrip},
});

export const dynaTravelSearchFlightsUpdateUINewSearch = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: any) => any, getState: () => IHostAppStore) => {
		const searchFlightProcess: ISearchFlightProcess = getSearchFlightProcess(searchFlightProcessId, getState());

		expireProposalsManager.removeTimer(searchFlightProcess.searchJobId);

		dispatch(dynaTravelSearchFlightsReceiveSearchAirTripCancel(searchFlightProcessId, true));

		dispatch(appMainUpdateMainMenuIsLoading(`flight-search-process-id-${searchFlightProcessId}`, false));
		dispatch(appMainViewCenter(true));
		dispatch({
			type: DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_NEW_SEARCH,
			payload: {searchFlightProcessId},
		});
	};
};

export const dynaTravelSearchFlightsUpdateUIClearTrip = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		dispatch(appMainViewCenter(true));
		dispatch({
			type: DynaTravel_SearchFlights_UPDATE_UI_REQUEST_TRIP_CLEAR_FORM,
			payload: {searchFlightProcessId},
		});
	};
};

export const dynaTravelSearchFlightsUpdateUISelectToday = (searchFlightProcessId: string): IReduxAction => ({
	type: DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_TODAY,
	payload: {searchFlightProcessId},
});

export const dynaTravelSearchFlightsUpdateUISelectNextWeekend = (searchFlightProcessId: string): IReduxAction => ({
	type: DynaTravel_SearchFlights_UPDATE_UI_REQUEST_SELECT_NEXT_WEEKEND,
	payload: {searchFlightProcessId},
});

export const dynaTravelSearchFlightsRequestSearchAirTrip = (searchFlightProcessId: string, tripRequest: IDynaTripRequest, tripRequestIdLastSearched: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		const searchJobId: string = guid();

		expireProposalsManager.addTimer(searchJobId, () => {
			dispatch({
				type: DynaTravel_SearchFlights_PROPOSALS_EXPIRED,
				payload: {
					searchFlightProcessId,
					searchJobId,
				},
			});
			if (dynaTravelModule.searchTripManager.hasTrips(searchFlightProcessId)) {
				dispatch({
					type: DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON,
					payload: {
						searchFlightProcessId,
						searchJobId,
						show: true,
					},
				});
			}
		}, dynaTravelModuleConfig.proposalsExpireInMin * 60 * 1000);

		dispatch(appMainUpdateMainMenuIsLoading(`flight-search-process-id-${searchFlightProcessId}`, true));
		dispatch(appMainViewCenter(false));

		dynaTravelModule.searchTripManager.searchTrip(dispatch, searchFlightProcessId, tripRequest);

		dispatch({
			type: DynaTravel_SearchFlights_REQUEST_SEARCH_AIR_TRIP,
			payload: {
				searchFlightProcessId,
				searchJobId,
				tripRequestIdLastSearched,
			},
		});
	};
};

export const dynaTravelSearchFlightsRefreshLater = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		const searchFlightProcess: ISearchFlightProcess = getSearchFlightProcess(searchFlightProcessId, getState());
		const searchJobId: string = searchFlightProcess.searchJobId;

		expireProposalsManager.removeTimer(searchJobId);
		dispatch({
			type: DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON,
			payload: {
				searchFlightProcessId,
				searchJobId,
				show: false,
			},
		});

		expireProposalsManager.addTimer(searchJobId, () => {
			if (dynaTravelModule.searchTripManager.hasTrips(searchFlightProcessId)) {
				dispatch({
					type: DynaTravel_SearchFlights_PROPOSALS_SHOW_EXPIRED_BALLOON,
					payload: {
						searchFlightProcessId,
						searchJobId,
						show: true,
					},
				});
			}
		}, dynaTravelModuleConfig.proposalsExpireSnoozeTimeout * 60 * 1000);
	};
};

export const dynaTravelSearchFlightsRefreshNow = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		// maybe in future here will show a spinning or something like this
		location.reload();
	};
};

export const dynaTravelSearchFlightsReceiveSearchAirTrip = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction | IReduxThunkAction<void, IHostAppStore, any>) => any, getState: () => IHostAppStore) => {
		dispatch({
			type: DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP,
			payload: {searchFlightProcessId},
		});
	};
};

export const dynaTravelSearchFlightsReceiveSearchAirTripProgress = (searchFlightProcessId: string, progressPercent: number): IReduxAction => ({
	type: DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_PROGRESS,
	payload: {searchFlightProcessId, progressPercent},
});

export const dynaTravelSearchFlightsReceiveSearchAirTripError = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		dispatch(appMainUpdateMainMenuIsLoading(`flight-search-process-id-${searchFlightProcessId}`, false));
		dispatch(appMainViewCenter(true));
		dispatch({
			type: DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_ERROR,
			payload: {searchFlightProcessId},
		});
	};
};

export const dynaTravelSearchFlightsReceiveSearchAirTripCompleted = (searchFlightProcessId: string, loadCompleteInfo: ILoadFlightsCompleteInfo): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		dispatch(appMainUpdateMainMenuIsLoading(`flight-search-process-id-${searchFlightProcessId}`, false));
		dispatch(appMainViewCenter(!hasTripProposals(searchFlightProcessId, getState())));
		dispatch({
			type: DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_COMPLETED,
			payload: {
				searchFlightProcessId,
				loadCompleteInfo,
			},
		});
	};
};

export const dynaTravelSearchFlightsReceiveSearchAirTripCancel = (searchFlightProcessId: string, reset: boolean): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		dispatch(appMainUpdateMainMenuIsLoading(`flight-search-process-id-${searchFlightProcessId}`, false));
		dynaTravelModule.searchTripManager.cancelSearchProcess(searchFlightProcessId);
		if (reset) dynaTravelModule.searchTripManager.reset(searchFlightProcessId);
		dispatch(appMainViewCenter(!hasTripProposals(searchFlightProcessId, getState())));
		dispatch({
			type: DynaTravel_SearchFlights_RECEIVE_SEARCH_AIR_TRIP_CANCEL,
			payload: {searchFlightProcessId},
		});
	};
};

export const dynaTravelSearchFlightsListViewSortByTripQualityIndex = (searchFlightProcessId: string, tripQualityIndex: ETripQualityIndex): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		// dev note: since the mobiles are rendeing slow the dom, show the spinner; some timeouts are needed in order to be shown properly.
		const showFullScreenIsLoading: boolean = !hasEnv(EDeviceCategory.desktop);
		if (showFullScreenIsLoading) dispatch(appMainIsLoadingFullScreen(true));
		setTimeout(() => {
			dispatch({
				type: DynaTravel_SearchFlights_LIST_VIEW_SORT_BY_TRIP_QUALITY_INDEX,
				payload: {
					searchFlightProcessId,
					tripQualityIndex,
				},
			});
		}, showFullScreenIsLoading ? 100 : 0);
		if (showFullScreenIsLoading) setTimeout(() => dispatch(appMainIsLoadingFullScreen(false)), 800);
	};
};

export const dynaTravelSearchFlightsListViewLoadMore = (searchFlightProcessId: string): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		const {listCountProposals: currentListCountProposals} = getSearchFlightProcess(searchFlightProcessId, getState());
		const listCountProposals: number = currentListCountProposals + dynaTravelModuleConfig.listTripProposalsStep;
		dispatch({
			type: DynaTravel_SearchFlights_IS_LOADING_MORE,
			payload: {
				searchFlightProcessId,
				isLoadingMore: true,
			},
		});
		setTimeout(() => { // give it a breath
			dispatch({
				type: DynaTravel_SearchFlights_LIST_VIEW_LOAD_MORE,
				payload: {
					searchFlightProcessId,
					listCountProposals,
				},
			});
		}, 0);
	};
};

export const dynaTravelSearchFlightsFilterChange = (searchFlightProcessId: string, filter: IDynaTripFilter): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		dispatch({
			type: DynaTravel_SearchFlights_ON_FILTER_TRIPS_CHANGE,
			payload: {
				searchFlightProcessId,
				filter,
			},
		});
	};
};

export const dynaTravelSearchFlightsSetFilteredTripProposals = (searchFlightProcessId: string, filteredTripProposals: IDynaTripProposal[]): IReduxThunkAction<void, IHostAppStore, any> => {
	return (dispatch: (action: IReduxAction) => any, getState: () => IHostAppStore) => {
		const {selectTripFormMode} = getSearchFlightProcess(searchFlightProcessId, getState());

		// ignore the filter updates when the form is in edit mode, Bug 1291: (prod) After New Search, the list has items again
		if (selectTripFormMode === EMode.EDIT) return;
		dynaTravelModule.searchTripManager.setFilteredTripProposals(searchFlightProcessId, filteredTripProposals);

		dispatch({
			type: DynaTravel_SearchFlights_REFRESH_DUE_TO_NEW_FILTERED_PROPOSALS,
			payload: {
				searchFlightProcessId,
			},
		});
	};
};

// internals

const hasTripProposals = (searchFlightProcessId: string, appState: IHostAppStore): boolean => {
	return !!getSearchFlightProcess(searchFlightProcessId, appState).tripProposals.length;
};

const getSearchFlightProcess = (searchFlightProcessId: string, appState: IHostAppStore): ISearchFlightProcess => {
	return appState.dynaTravelModule.searchFlights.searchFlightProcesses[searchFlightProcessId];
};