import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {IDynaTrip} from "dyna-travel-interfaces";
import {EProduct} from "../interfaces";

import {defaultMessages, IHeaderMessages} from "./messages";

import "./Header.less";
import {ShortTripOverview} from "../short-trip-overview/ShortTripOverview";

export interface IHeaderProps {
	className?: string;
	messages?: IHeaderMessages;
	product?: EProduct;
	navButton?: EHeaderNavButtonType;
	title?: string;
	trip?: IDynaTrip;
	children?: any;
	showCloseButton?: boolean;
	onBackClick?: () => void;
	onTopClick?: () => void;
	onCloseClick?: () => void;
}

export enum EHeaderNavButtonType {
	GO_BACK = "GO_BACK",
	GO_TOP = "GO_TOP",
}

export {
	IHeaderMessages,
}

export class Header extends React.Component<IHeaderProps> {
	static defaultProps: IHeaderProps = {
		className: "",
		messages: defaultMessages,
		product: EProduct.IM_HOLIDAY,
		navButton: null,
		title: null,
		children: null,
		showCloseButton: false,
		onBackClick: (): void => undefined,
		onTopClick: (): void => undefined,
		onCloseClick: (): void => undefined,
	};

	private readonly className = dynaClassName("dyna-travel-header");

	private renderNavButton(): JSX.Element {
		const {
			navButton,
			messages,
			onBackClick,
			onTopClick,
		} = this.props;

		if (!navButton) return null;

		let navButtonLabel: string;
		let navButtonIcon: JSX.Element;
		let handlerClick: () => void;
		switch (navButton) {
			case EHeaderNavButtonType.GO_BACK:
				navButtonLabel = messages.goBack;
				navButtonIcon = <i className="fas fa-chevron-left"/>;
				handlerClick = onBackClick;
				break;
			case EHeaderNavButtonType.GO_TOP:
				navButtonLabel = messages.goTop;
				navButtonIcon = <i className="fas fa-chevron-up"/>;
				handlerClick = onTopClick;
				break;
		}

		return (
			<div
				className={this.className(`__nav-button`)}
				onClick={handlerClick}
			>
				<span className={this.className(`__nav-button__icon`)}>{navButtonIcon}</span>
				<span className={this.className(`__nav-button__label`)}> {navButtonLabel}</span>
			</div>
		)
	}

	private renderHeaderContent(): JSX.Element {
		const {
			title,
			trip,
			children,
		} = this.props;

		const className = dynaClassName(this.className("__header-content"));

		return (
			<div className={className("", (trip || children) && "--has-children")}>
				<div className={className("__title")}>
					{title}
				</div>
				{trip ? <ShortTripOverview trip={trip}/>:null}
				<div className={className("__children")}>
					{children}
				</div>
			</div>
		)
	}

	private renderCloseButton(): JSX.Element {
		const {
			showCloseButton,
			onCloseClick,
		} = this.props;

		if (!showCloseButton) return null;

		return (
			<div
				className={this.className("__close-button")}
				onClick={onCloseClick}
			>
				<div><i className="fas fa-times"/></div>
			</div>
		)
	}

	public render(): JSX.Element {
		const {
			className: userClassName,
			product,
		} = this.props;

		const className: string = [
			userClassName,
			this.className(),
			this.className(`--product-${product}`),
		].join(' ').trim();

		return (
			<div className={className}>
				{this.renderNavButton()}
				{this.renderHeaderContent()}
				{this.renderCloseButton()}
			</div>
		);
	}
}
