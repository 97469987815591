import * as React from "react";
import {DynaSvg} from "dyna-svg";
import {dynaClassName} from "dyna-class-name";
import {DynaLoadingGhost} from "dyna-ui-loading-ghost";
import {DynaFastClick} from "dyna-ui-fast-click";

import {EProduct} from "../interfaces";

const alarmIcon:string = require('./../assets/old-alarm-clock-orange.svg');
import "./RefreshBalloon.less";

export interface IRefreshBalloonProps {
	className?: string;
	product: EProduct;
	show: boolean;
	title?: string;
	description?: string;
	laterButtonLabel?: string;
	refreshButtonLabel?: string;
	onLaterClick?: () => void;
	onRefreshClick?: () => void;
}

export interface IRefreshBalloonState {
	show: boolean;
	hideCompletelly: boolean;
}

export class RefreshBalloon extends React.Component<IRefreshBalloonProps, IRefreshBalloonState> {
	static defaultProps: IRefreshBalloonProps = {
		className: "",
		product: EProduct.IM_HOLIDAY,
		show: false,
		title: "Refresh your results",
		description: "Refresh to get the latest prices and availability",
		laterButtonLabel: "Later",
		refreshButtonLabel: "Refresh",
		onLaterClick: () => undefined,
		onRefreshClick: () => undefined,
	};

	constructor(props: IRefreshBalloonProps) {
		super(props);
		this.state = {
			show: props.show,
			hideCompletelly: !props.show,
		};
	}

	public componentDidMount(): void {
		this.show(this.props.show);
	}

	public componentWillReceiveProps(nextProps: IRefreshBalloonProps): void {
		if (nextProps.show !== this.props.show) {
			this.show(nextProps.show);
		}
	}

	private show(show: boolean): void {
		if (show) {
			this.setState({hideCompletelly: false	},()=>{
				setTimeout(()=>{
					this.setState({show: true	});
				},10);
			});
		}
		else {
			this.setState({show: false});
			setTimeout(() => {
				if (!this.state.show) this.setState({hideCompletelly: true});
			}, 1000);
		}
	}

	private readonly className = dynaClassName("dyna-travel-refresh-balloon");

	public render(): JSX.Element {
		const {
			className: userClassName,
			product,
			title,
			description,
			laterButtonLabel,
			refreshButtonLabel,
			onLaterClick,
			onRefreshClick,
		} = this.props;
		const {
			show,
			hideCompletelly,
		} = this.state;

		const className: string = this.className(
			"",
			`--product-${product}`,
			userClassName && "/" + userClassName,
			show ? "--show" : "--hide",
			hideCompletelly ? "--hide-completelly" : "",
		);

		return <div className={className}>
			<div className={this.className("__content")}>
				<DynaLoadingGhost className={this.className("__ghost")}/>
				<div className={this.className("__icon")}>
					<DynaSvg src={alarmIcon} className={this.className("__icon__svg")}/>
				</div>
				<div className={this.className("__text-container")}>
					<div className={this.className("__text-container__title")}>{title}</div>
					<div className={this.className("__text-container__description")}>{description}</div>
				</div>
			</div>
			<DynaFastClick nodeType="div" className={this.className("__button")} onClick={onLaterClick}>
				<div><i className="far fa-clock"/></div>
				<div>{laterButtonLabel}</div>
			</DynaFastClick>
			<DynaFastClick nodeType="div" className={this.className("__button")} onClick={onRefreshClick}>
				<div><i className="fas fa-sync"/></div>
				<div>{refreshButtonLabel}</div>
			</DynaFastClick>
		</div>;
	}
}