import * as React from "react";
import {dynaCMSClient} from "../../../api/dynaCMSClient";
import {DynaCMSViewer} from "../../../../../packages/dyna-cms-viewer/DynaCMSViewer";

import {appTitle} from "../../../../../components/ui/appTitle/appTitle";
import {scrollToTop} from "../../../../../utils/scrollToTop";

const TermsOfUse: React.SFC<{}> = (props) => {
	scrollToTop();
	return (
		<div>
			{appTitle("Terms of Use")}
			<DynaCMSViewer
				dynaCMSClient={dynaCMSClient}
				contentName="imholiday_terms_of_use_enUS"
			/>
		</div>
	);
};

export default TermsOfUse;