import {IDynaTripRequest, IDynaTripRequestRoute} from "dyna-travel-interfaces";
import {copyObject} from "./copyObject";

export const copyTripRequest = (tripRequest: IDynaTripRequest) => {
  const output: IDynaTripRequest = copyObject(tripRequest);
  output.routes = copyRoutes(output.routes);
  return output;
};

export const copyRoutes = (routes: IDynaTripRequestRoute[] | undefined): IDynaTripRequestRoute[] | undefined => {
  if (!routes) return undefined;
  return routes.map(copyRoute);
};

export const copyRoute = (route: IDynaTripRequestRoute): IDynaTripRequestRoute => {
  const output: IDynaTripRequestRoute = copyObject(route);
  if (output.departDate) output.departDate.date = new Date(output.departDate.date as any);
  return output;
};
