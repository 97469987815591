import * as React from "react";

import "./SegmentLollipop.less";

export interface ISegmentLollipopProps {
	className?: string;
	orientation?: ESegmentLollipopOrientation;
	mode?: ESegmentLollipopMode;
}

export enum ESegmentLollipopOrientation {
	HORIZONTAL = "HORIZONTAL",
	VERTICAL = "VERTICAL",
}

export enum ESegmentLollipopMode {
	BEGIN = "BEGIN",
	END = "END",
	PASS = "PASS",
	LINE = "LINE",
	NONE = "NONE",
}

export class SegmentLollipop extends React.Component<ISegmentLollipopProps> {
	static defaultProps: ISegmentLollipopProps = {
		className: "",
		orientation: ESegmentLollipopOrientation.VERTICAL,
		mode: ESegmentLollipopMode.LINE,
	};

	private readonly baseClassName: string = "dyna-travel-segment-lollipop";
	private readonly className =
		(subClassNames: string = "", active: boolean = true): string =>
			active
				? subClassNames.split(" ").map((subClassName: string) => `${this.baseClassName}${subClassName}`).join(" ")
				: "";

	public render(): JSX.Element {
		const {
			className: userClassName,
			mode,
			orientation,
		} = this.props;

		const className: string = [
			userClassName,
			this.className(),
			this.className(`--mode-${mode}`),
			this.className(`--orientation-${orientation}`),
		].join(' ').trim();

		return (
			<div className={className}>
				<div className={this.className("__line")}/>
				<div className={this.className("__bullet")}/>
			</div>
		);
	}
}