import * as React from "react";
import {EDynaTransport} from "dyna-travel-interfaces";

export interface ITransportTypeIconProps {
	className?: string;
	transportType: EDynaTransport;
}

export class TransportTypeIcon extends React.Component<ITransportTypeIconProps> {
	static defaultProps: ITransportTypeIconProps = {
		transportType: null,
	};

	private className(className: string): string {
		const {
			className: userClassName,
		} = this.props;
		return ` ${userClassName || ""} ${className}`.trim();
	}

	public render(): JSX.Element {
		const {
			transportType,
		} = this.props;

		switch (transportType){
			case EDynaTransport.WAIT:
				return <i className={this.className("fa fa-clock-o")}/>;
			case EDynaTransport.AIRPLANE:
				return <i className={this.className("fa fa-plane")}/>;
			case EDynaTransport.FERRY:
				return <i className={this.className("fa fa-ship")}/>;
			case EDynaTransport.TRAIN:
				return <i className={this.className("fa fa-train")}/>;
			case EDynaTransport.BIKE:
				return <i className={this.className("fa fa-bicycle")}/>;
			case EDynaTransport.TAXI:
				return <i className={this.className("fa fa-cab")}/>;
			default:
				return <i className={this.className("fa fa-chevron-circle-right")}/>
		}
	}
}
