import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {DynaButton, ESize as EButtonSize} from "dyna-ui-button";
import {dynaDebounce} from "dyna-debounce";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {IDynaPrice, IDynaTrip, IDynaTripProposal, IDynaTripRequest} from "dyna-travel-interfaces";
import {DynaInputPriceSlider, EMin, ESize as ESliderSize} from "dyna-ui-input-slider";

import {EEndPoint, EProduct, EStartEnd, IDynaTripFilter, IDynaTripFilterSegment, IDynaTripFilterSegmentByDate} from "../interfaces";
import {SegmentFilter} from "./SegmentFilter";
import {IDynaTravelFilterTripsMessages} from "../messages";

import {filterTripProposals} from "../utils/filterTripProposals";
import {FilterHolder} from "../utils/FilterHolder";
import {PriceConverter} from "../utils/PriceConverter";
import {copyObject} from "../utils/copyObject";
import {getHour} from "../utils/getHour";
import {styleMixer} from "../styleMixer";
import {hasNotValues} from "../utils/hasValues";

import "./FilterEditor.less";

export interface FilterEditorProps {
	// basics
	className?: string;
	product: EProduct;
	show: boolean;
	currency: string;
	messages: IDynaTravelFilterTripsMessages;
	convertPrice: (price: IDynaPrice, currency: string) => number;
	formatPrice: (price: IDynaPrice, currency: string) => string;
	formatDate: (date: Date) => string;
	formatDuration: (minutes: number) => string;

	// input data
	tripRequest: IDynaTripRequest;
	tripProposals: IDynaTripProposal[];

	// output data
	value: IDynaTripFilter;   // set this to null to reset the filters but outside changes of `value` are ignored

	// events
	onChange: (value: IDynaTripFilter) => void;
	onFilteredTripProposals: (filteredTripProposals: IDynaTripProposal[]) => void;
}

export interface IFilterEditorState {
	statsMaxPrice: number;
	statsPrices: number[];
	statsSegments: IStateSegmentStats[];
}

export interface IStateSegmentStats {
	durationsInMin: number[];
	maxDurationInMin: number;
	originHours: IValuesPerDate;
	destinationHours: IValuesPerDate;
}

export interface IValuesPerDate {
	[dateLocale: string]: number[];
}

export class FilterEditor extends React.Component<FilterEditorProps, IFilterEditorState> {
	constructor(props: FilterEditorProps) {
		super(props);

		this.state = this.emptyState;

		this.updateStatsExportFiltered = dynaDebounce(this.updateStatsExportFiltered.bind(this), 1000, {leading: false, maxWait: 0});
	}

	private readonly internalCurrency: string = "eur";
	private readonly className = dynaClassName("dyna-travel-filter-editor");
	private readonly priceConverter: PriceConverter = new PriceConverter({convertPrice: this.props.convertPrice});

	private get emptyState(): IFilterEditorState {
		return {
			statsMaxPrice: 0,
			statsPrices: [0, 3000],
			statsSegments: Array(10).fill({
				durationsInMin: 300,
				originHours: [],
				destinationHours: [],
			}),
		};
	}

	public componentDidMount(): void {
		this.processProps(this.props);
	}

	public componentWillReceiveProps(nextProps: FilterEditorProps): void {
		this.processProps(nextProps);
	}

	private lastProcessedChangeId: string = "";

	private getChangeId(props: FilterEditorProps): string {
		const {
			show,
			value,
			currency,
			tripProposals,
		} = props;
		return [
			"changeId",
			show,
			JSON.stringify(value),
			currency,
			tripProposals.length,
		].join('-');
	}

	private processProps(props: FilterEditorProps): void {
		const filter = this.updateFilter(props);
		if (filter) this.updateStatsExportFiltered(props, filter);
	}

	private updateFilter(props: FilterEditorProps): IDynaTripFilter {
		const {
			currency,
			value: oldFilter,
			tripRequest,
			tripProposals,
			onChange,
		} = props;

		const {
			statsMaxPrice,
			statsSegments,
		} = this.state;

		const oldFilterHolder: FilterHolder = new FilterHolder(oldFilter);

		// if there is no need to calc, exit
		const changeId: string = this.getChangeId(props);
		if (this.lastProcessedChangeId === changeId) return null;
		this.lastProcessedChangeId = changeId;

		// build the filter
		let filter: IDynaTripFilter = {
			active: false,
			maxPrice: null,
			segments: tripRequest.routes.map(() => ({
				maxDurationInMin: null,
				origin: {
					place: null,
					dates: {},
				},
				destination: {
					place: null,
					dates: {},
				},
			})),
		};

		// apply the filter maxPrice
		filter.maxPrice = oldFilter ? oldFilter.maxPrice : null;
		if (filter.maxPrice != null && Math.ceil(filter.maxPrice) < Math.ceil(statsMaxPrice)) filter.active = true;

		// apply user's values from old filter
		filter.segments.forEach((filterSegment: IDynaTripFilterSegment, segmentIndex: number) => {
			filterSegment.maxDurationInMin =
				oldFilter
					? oldFilter.segments[segmentIndex].maxDurationInMin
					: oldFilter && statsSegments && statsSegments[segmentIndex].maxDurationInMin || null;
			if (hasNotValues(filterSegment.maxDurationInMin, null, statsSegments && statsSegments[segmentIndex].maxDurationInMin)) filter.active = true;
		});

		// filter by maxPrice and maxDurationInMin
		filterTripProposals({
			currency,
			tripProposals,
			convertPrice: this.priceConverter.convert,
			filter,
		})
			.forEach((tripProposal: IDynaTripProposal) => {
				const trip: IDynaTrip = tripProposal.trip;
				trip.segments
					.forEach((tripSegment: IDynaTrip, segmentIndex: number) => {
						const filterSegment: IDynaTripFilterSegment = filter.segments[segmentIndex];
						const originDateLocale: string = tripSegment.start.localDate;
						const destinationDateLocale: string = tripSegment.end.localDate;

						// update the places
						filterSegment.origin.place = tripSegment.segments[0].origin;
						filterSegment.destination.place = tripSegment.segments[tripSegment.segments.length - 1].destination;

						// update the structure of the originSegmentDate if needed and apply values from the old filter
						let originSegmentDate: IDynaTripFilterSegmentByDate = filterSegment.origin.dates[originDateLocale];
						if (!originSegmentDate) {
							originSegmentDate = {
								dateLocale: originDateLocale,
								start: oldFilterHolder.getSegmentHour(segmentIndex, EEndPoint.ORIGIN, originDateLocale, EStartEnd.START, 0),
								end: oldFilterHolder.getSegmentHour(segmentIndex, EEndPoint.ORIGIN, originDateLocale, EStartEnd.END, 24),
							};
							filterSegment.origin.dates[originDateLocale] = originSegmentDate;
						}
						if (originSegmentDate.start !== 0 || originSegmentDate.end !== 24) filter.active = true;

						// update the structure of the originSegmentDate if needed and apply values from the old filter
						let destinationSegmentDate: IDynaTripFilterSegmentByDate = filterSegment.destination.dates[destinationDateLocale];
						if (!destinationSegmentDate) {
							destinationSegmentDate = {
								dateLocale: destinationDateLocale,
								start: oldFilterHolder.getSegmentHour(segmentIndex, EEndPoint.DESTINATION, destinationDateLocale, EStartEnd.START, 0),
								end: oldFilterHolder.getSegmentHour(segmentIndex, EEndPoint.DESTINATION, destinationDateLocale, EStartEnd.END, 24),
							};
							filterSegment.destination.dates[destinationDateLocale] = destinationSegmentDate;
						}
						if (destinationSegmentDate.start !== 0 || destinationSegmentDate.end !== 24) filter.active = true;
					});
			});

		if (JSON.stringify(filter) !== JSON.stringify(oldFilter)) onChange(filter);

		return filter;
	}

	private updateStatsExportFiltered(props: FilterEditorProps, filter: IDynaTripFilter): void {
		const {
			currency,
			tripRequest,
			tripProposals,
			onFilteredTripProposals,
		} = props;

		// build stats
		let statsMaxPrice: number = 0;
		const statsPrices: number[] = [];
		const statsSegments: IStateSegmentStats[] = Array(tripRequest.routes.length)
			.fill(null)
			.map((v: any, routeIndex: number) => ({
				durationsInMin: [],
				maxDurationInMin: 0,
				originHours: {},
				destinationHours: {},
			}as IStateSegmentStats));

		// calc the statsMaxPrice
		tripProposals
			.forEach((tripProposal: IDynaTripProposal) => {
				const price: number = this.priceConverter.convert(tripProposal.trip.price, currency);
				if (statsMaxPrice < price) statsMaxPrice = price;
				statsPrices.push(price);
			});

		// calc the segments duration (filtering out trips by price)
		tripProposals
			.filter((tripProposal: IDynaTripProposal) => {
				return filter.maxPrice === null || this.priceConverter.convert(tripProposal.trip.price, currency) <= filter.maxPrice;
			})
			.forEach((tripProposal: IDynaTripProposal) => {
				tripProposal.trip.segments.forEach((tripSegment: IDynaTrip, segmentIndex: number) => {
					const statsSegment: IStateSegmentStats = statsSegments[segmentIndex];
					statsSegment.durationsInMin.push(tripSegment.stats.durationInMin);
					if (statsSegment.maxDurationInMin < tripSegment.stats.durationInMin) {
						statsSegment.maxDurationInMin = tripSegment.stats.durationInMin;
					}

					const originDateLocale: string = tripSegment.start.localDate;
					const destinationDateLocale: string = tripSegment.end.localDate;
					if (!statsSegment.originHours[originDateLocale]) statsSegment.originHours[originDateLocale] = [];
					if (!statsSegment.destinationHours[destinationDateLocale]) statsSegment.destinationHours[destinationDateLocale] = [];
				});
			});

		// update from filtered trip proposals
		const filteredTripProposals: IDynaTripProposal[] = filterTripProposals({
			currency,
			convertPrice: this.priceConverter.convert,
			tripProposals,
			filter,
		});
		filteredTripProposals.forEach((tripProposal: IDynaTripProposal) => {
			const trip: IDynaTrip = tripProposal.trip;
			trip.segments
				.forEach((tripSegment: IDynaTrip, segmentIndex: number) => {
					const statsSegment: IStateSegmentStats = statsSegments[segmentIndex];
					const originDateLocale: string = tripSegment.start.localDate;
					const destinationDateLocale: string = tripSegment.end.localDate;
					statsSegment.originHours[originDateLocale].push(getHour(tripSegment.start));
					statsSegment.destinationHours[destinationDateLocale].push(getHour(tripSegment.end));
				});
		});

		this.setState({
			statsMaxPrice,
			statsPrices,
			statsSegments,
		});

		onFilteredTripProposals(filteredTripProposals);
	}

	private handleChangeSegment(segmentIndex: number, filterSegment: IDynaTripFilterSegment): void {
		const {value: filter, onChange} = this.props;
		const newValue: IDynaTripFilter = copyObject(filter);
		newValue.segments = filter.segments;
		newValue.segments[segmentIndex] = filterSegment;
		onChange(newValue);
	}

	private renderSegment = (filterSegment: IDynaTripFilterSegment, segmentIndex: number): JSX.Element => {
		const {
			product,
			messages,
			formatDate,
			formatDuration,
		} = this.props;
		const {
			statsSegments,
		} = this.state;
		const statSegment: IStateSegmentStats = statsSegments[segmentIndex];
		return (
			<SegmentFilter
				key={segmentIndex}
				product={product}
				durationMinutesValues={statSegment.durationsInMin}
				originHours={statSegment.originHours}
				maxDurationInMin={statSegment.maxDurationInMin}
				destinationHours={statSegment.destinationHours}
				messages={messages}
				formatDate={formatDate}
				formatDuration={formatDuration}
				value={filterSegment}
				onChange={this.handleChangeSegment.bind(this, segmentIndex)}
			/>
		);
	};

	private renderSegments(): JSX.Element {
		const {value: filter} = this.props;
		return (
			<div>
				{filter.segments.map(this.renderSegment)}
			</div>
		);
	}

	private handleMaxPriceChange = (name: string, value: number): void => {
		const {value: filter, onChange} = this.props;
		const dynaTripFilter: IDynaTripFilter = copyObject(filter);
		dynaTripFilter.maxPrice = value;
		onChange(dynaTripFilter);
	};

	private renderPriceSlider(): JSX.Element {
		const {
			product,
			currency,
			messages: {
				totalAmount,
			},
			formatPrice,
			value: filter,
		} = this.props;
		const {
			statsMaxPrice,
			statsPrices,
		} = this.state;

		return (
			<DynaInputPriceSlider
				className={this.className("/dyna-filter-input")}
				size={ESliderSize.PX32}
				color={styleMixer(product).slidersColor}
				label={<span><i className="fas fa-money-bill"/> {totalAmount}</span>}
				statTicksCount={48}
				prices={statsPrices}
				minType={EMin.MIN}
				formatPrice={(price: number) => formatPrice({value: price, currency: this.internalCurrency}, currency)}
				value={filter.maxPrice === null ? statsMaxPrice : filter.maxPrice}
				onChange={this.handleMaxPriceChange}
			/>
		);
	}

	private handleResetClick = (): void => {
		const {onChange} = this.props;
		this.setState(this.emptyState, () => {
			onChange(null);
		});
	};

	private renderButtonBar(): JSX.Element {
		const {
			product,
			messages: {
				reset,
			}
		} = this.props;
		return (
			<div className={this.className("__button-bar")}>
				<DynaButton
					size={EButtonSize.LARGE}
					color={styleMixer(product).buttonColor}
					onClick={this.handleResetClick}
				>
					{reset}
				</DynaButton>
			</div>
		)
	}

	public render(): JSX.Element {
		const {
			show,
			value: filter,
		} = this.props;

		if (!filter) return null;

		return (
			<DynaAnimationVerticalContainer show={show}>
				<div className={this.className.root(this.props)}>
					{this.renderButtonBar()}
					{this.renderPriceSlider()}
					{this.renderSegments()}
				</div>
			</DynaAnimationVerticalContainer>
		);
	}
}
