import { DynaCurrencies, ICurrencyRates } from "dyna-currencies";
import moment = require("moment");

import {
  createStore,
  dynaduxDebugMiddleware,
} from "dynadux";

import {
  getFlightMonthlyInfo,
  IFlightMonthInfoApiData
} from "./api/getFlightMonthlyInfo";

import {
  IFlightInfoState,
  EPriceQualityIndex,
  IDayFlightsInfo,
  IDaysFlightsInfoDic,
  IDayFlightsViewData,
} from "./interfaces";

import { updatePriceIndex } from "./reducers/updatePriceIndex";
import { getTestBCFPrices } from "./reducers/getTestBCFPPrices";
import { EQualityIndex } from "dyna-travel-ui-components";

import { getPrice } from "./reducers/getPrice";

import { getDayFlightsViewData } from "./state/getDayFlightsViewData";

// Actions

enum EAction {
  SET_QUALITY_INDEX = "SET_QUALITY_INDEX",                        // payload: EQualityIndex
  SET_SHOW_PRICE = "SET_SHOW_PRICE",                              // payload: show: boolean

  GET_FLIGHTS_INFO_REQUEST = "GET_FLIGHTS_INFO_REQUEST",          // payload: IGET_FLIGHTS_INFO_REQUEST_payload
  GET_FLIGHTS_INFO_RESPONSE = "GET_FLIGHTS_INFO_RESPONSE",        // payload: IFlightMonthInfoApiData

  SET_CURRENCY_RATES = "SET_CURRENCY_RATES",                      // payload: ICurrencyRates
  SET_CURRENCY = "SET_CURRENCY",                                  // payload: currency: string
  UPDATE_PRICE_LABELS = "UPDATE_PRICE_LABELS",                    // payload: --none--

  GET_FLIGHTS_INFO_TEST = "GET_FLIGHTS_INFO_TEST",                // payload: IGET_FLIGHTS_INFO_REQUEST_payload
}

export interface IGET_FLIGHTS_INFO_REQUEST_payload {
  yyyymmdd: string;
  origin?: string;
  destination?: string;
  roundTrip: boolean;
  updatedWeeksAgo: number;
}

export interface IGET_FLIGHTS_INFO_RESPONSE_payload {
  processMonthOfDate: string; // yyyymmdd
  flightsInfo: IFlightMonthInfoApiData;
  roundTrip: boolean;
  error: boolean;
}

// Store

export const flightInfoStore = (
  {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
    loadMonths,
    onChange,
    _debug_dynaduxDebugGlobalVarName,
  }: {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: string;
    loadMonths: number;
    onChange: (state: IFlightInfoState) => void;
    _debug_dynaduxDebugGlobalVarName?: string;
  }
) => {
  const store = createStore<IFlightInfoState>({
    onChange,
    initialState: {
      isClientLoading: false,
      currency: 'usd',
      currencies: new DynaCurrencies(),
      origin: '',
      destination: '',
      roundTrip: false,
      qualityIndex: EQualityIndex.NONE,
      showPrices: false,
      daysFlightsInfo: {
        oneWay: {},
        roundTrip: {},
      },
      loadErrors: false,
    },
    middlewares: [
      dynaduxDebugMiddleware({
        debuggerStoreName: _debug_dynaduxDebugGlobalVarName || '',
        consoleDispatch: true,
        consolePayload: true
      }),
    ],
    reducers: {

      [EAction.SET_QUALITY_INDEX]: ({payload: qualityIndex}) => ({qualityIndex}),

      [EAction.SET_SHOW_PRICE]: ({payload: showPrices}) => ({showPrices}),

      [EAction.GET_FLIGHTS_INFO_REQUEST]: ({state, payload, dispatch}) => {
        const {
          yyyymmdd,
          origin,
          destination,
          roundTrip,
          updatedWeeksAgo
        }: IGET_FLIGHTS_INFO_REQUEST_payload = payload;

        if (!origin || !destination) {
          dispatch(
            'GET_FLIGHTS_INFO_REQUEST__ABORTED',
            {message: 'Origin or Destination is not provided', payload},
            {triggerChange: false}
          );
          return;
        }

        if (COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress === '***test***') {
          dispatch(EAction.GET_FLIGHTS_INFO_TEST, payload);
          return;
        }

        if (moment(yyyymmdd).endOf('month').isBefore(moment().startOf('day'))) {
          dispatch(
            'GET_FLIGHTS_INFO_REQUEST__ABORTED',
            {message: 'Requested month is old'},
            {triggerChange: false}
          );
          return;
        }

        const getInfoFromDate: string =
          moment(
            moment(yyyymmdd).month() === moment().month() &&
            moment(yyyymmdd).isBefore(moment())
              ? moment()
              : moment(yyyymmdd).startOf('month')
          ).format('YYYY-MM-DD');

        getFlightMonthlyInfo({
          COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
          yyyymmdd: getInfoFromDate,
          origin,
          destination,
          roundTrip,
          loadMonths,
          updatedWeeksAgo
        })
          .then(response => {
            dispatch<IGET_FLIGHTS_INFO_RESPONSE_payload>(EAction.GET_FLIGHTS_INFO_RESPONSE, {
              processMonthOfDate: yyyymmdd,
              flightsInfo: response,
              roundTrip,
              error: false,
            });
          })
          .catch(error => {
            dispatch<IGET_FLIGHTS_INFO_RESPONSE_payload>(EAction.GET_FLIGHTS_INFO_RESPONSE, {
              processMonthOfDate: yyyymmdd,
              flightsInfo: {flightPrices: {prices: []}},
              roundTrip,
              error: true,
            });
            console.error('Cannot GET_FLIGHTS_INFO_REQUEST', error);
          });

        const newState: Partial<IFlightInfoState> = {
          isClientLoading: true,
          loadErrors: false,
        };

        if (
          state.roundTrip !== roundTrip ||
          state.origin !== origin ||
          state.destination !== destination
        ) {
          newState.roundTrip = roundTrip;
          newState.origin = origin;
          newState.destination = destination;
          // Clear the prices, the route is different
          newState.daysFlightsInfo = {
            oneWay: {},
            roundTrip: {},
          };
        }

        return newState;
      },

      [EAction.GET_FLIGHTS_INFO_TEST]: ({payload, dispatch}) => {
        const {
          yyyymmdd,
          roundTrip,
        }: IGET_FLIGHTS_INFO_REQUEST_payload = payload;

        dispatch<IGET_FLIGHTS_INFO_RESPONSE_payload>(EAction.GET_FLIGHTS_INFO_RESPONSE, {
          processMonthOfDate: yyyymmdd,
          flightsInfo: getTestBCFPrices(yyyymmdd, loadMonths),
          roundTrip,
          error: false,
        });
      },

      [EAction.GET_FLIGHTS_INFO_RESPONSE]: ({state: {daysFlightsInfo, currency, currencies}, payload}) => {
        const {
          processMonthOfDate,
          flightsInfo,
          roundTrip,
          error,
        }: IGET_FLIGHTS_INFO_RESPONSE_payload = payload;
        const roundTripStoreProperty = roundTrip ? 'roundTrip' : 'oneWay';
        const newDaysFlightsInfo =
          roundTrip
            ? daysFlightsInfo.roundTrip
            : daysFlightsInfo.oneWay;

        // create / update the days with the new prices
        flightsInfo.flightPrices.prices.forEach(price => {
          const dayFlightsInfo: IDayFlightsInfo =
            newDaysFlightsInfo[price.date]
            || (newDaysFlightsInfo[price.date] = {
              // default values for new entries
              priceQualityIndex: {
                best: EPriceQualityIndex.CHEAP,
                cheapest: EPriceQualityIndex.CHEAP,
                fastest: EPriceQualityIndex.CHEAP,
              }
            } as Partial<IDayFlightsInfo> as any);

          dayFlightsInfo.bestPrice = getPrice({currency, currencies, price: price.bestPrice});
          dayFlightsInfo.cheapestPrice = getPrice({currency, currencies, price: price.cheapestPrice});
          dayFlightsInfo.fastestPrice = getPrice({currency, currencies, price: price.fastestPrice});
          dayFlightsInfo.updatedAt = new Date(price.updated);
          dayFlightsInfo.isBackendLoading = price.isLoading !== null;
        });

        // export the new partial state, and for the dayFlightsInfo update the Price Index
        return {
          isClientLoading: false,
          daysFlightsInfo: {
            ...daysFlightsInfo,
            [roundTripStoreProperty]: updatePriceIndex({
              processMonthOfDate,
              calcMonths: loadMonths,
              flightsInfoDic: newDaysFlightsInfo,
            })
          },
          loadErrors: !!error,
        };
      },

      [EAction.SET_CURRENCY]: ({payload: currency, dispatch}) => {
        dispatch('__applyCurrency', currency);
        dispatch(EAction.UPDATE_PRICE_LABELS);
      },
      __applyCurrency: ({payload: currency}) => ({currency}),

      [EAction.SET_CURRENCY_RATES]: (
        {
          state: {currencies},
          payload: currencyRates,
          dispatch,
        }
      ) => {
        currencies.update(currencyRates);
        dispatch(EAction.UPDATE_PRICE_LABELS);
      },

      [EAction.UPDATE_PRICE_LABELS]: (
        {
          state:
            {
              currency,
              currencies,
              daysFlightsInfo,
            },
        }
      ) => {
        const updateFlightPrices = (flightsIndo: IDaysFlightsInfoDic): IDaysFlightsInfoDic => {
          const newDayFlightsInfo: IDaysFlightsInfoDic = {};
          Object.keys(flightsIndo)
            .forEach((yyyymmdd: string) => {
              const dayFlightInfo: IDayFlightsInfo = flightsIndo[yyyymmdd] as any;
              newDayFlightsInfo[yyyymmdd] = {
                ...dayFlightInfo,
                bestPrice: getPrice({currency, price: dayFlightInfo.bestPrice.price, currencies}),
                cheapestPrice: getPrice({currency, price: dayFlightInfo.cheapestPrice.price, currencies}),
                fastestPrice: getPrice({currency, price: dayFlightInfo.fastestPrice.price, currencies}),
              };
            });
          return newDayFlightsInfo;
        };

        return {
          daysFlightsInfo: {
            oneWay: updateFlightPrices(daysFlightsInfo.oneWay),
            roundTrip: updateFlightPrices(daysFlightsInfo.roundTrip),
          },
        };
      },

    },
  });

  return {
    state: {
      get qualityIndex() {
        return store.state.qualityIndex;
      },
      get showPriceValues(): boolean {
        return store.state.showPrices;
      },
      getDayFlightsViewData: (yyyymmdd: string, roundTrip: boolean): IDayFlightsViewData => getDayFlightsViewData(store.state, yyyymmdd, roundTrip),
    },
    actions: {
      setCurrency: (currency: string): void => store.dispatch(EAction.SET_CURRENCY, currency),

      setCurrencyRates: (currencyRates: ICurrencyRates) => store.dispatch<ICurrencyRates>(EAction.SET_CURRENCY_RATES, currencyRates),

      setQualityIndex: (qualityIndex: EQualityIndex): void => store.dispatch(EAction.SET_QUALITY_INDEX, qualityIndex),
      setShowPriceValues: (show: boolean): void => store.dispatch(EAction.SET_SHOW_PRICE, show),

      loadPrices: (params: IGET_FLIGHTS_INFO_REQUEST_payload): void =>
        store.dispatch<IGET_FLIGHTS_INFO_REQUEST_payload>(EAction.GET_FLIGHTS_INFO_REQUEST, params),
    },
  };
};
