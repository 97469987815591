(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-debounce"), require("react"), require("dyna-class-name"), require("dyna-ui-fast-click"), require("dyna-animation"), require("dyna-ui-button"), require("dyna-ui-input-slider"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-travel-ui-filter-trips", ["dyna-debounce", "react", "dyna-class-name", "dyna-ui-fast-click", "dyna-animation", "dyna-ui-button", "dyna-ui-input-slider"], factory);
	else if(typeof exports === 'object')
		exports["dyna-travel-ui-filter-trips"] = factory(require("dyna-debounce"), require("react"), require("dyna-class-name"), require("dyna-ui-fast-click"), require("dyna-animation"), require("dyna-ui-button"), require("dyna-ui-input-slider"));
	else
		root["dyna-travel-ui-filter-trips"] = factory(root["dyna-debounce"], root["react"], root["dyna-class-name"], root["dyna-ui-fast-click"], root["dyna-animation"], root["dyna-ui-button"], root["dyna-ui-input-slider"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_debounce__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_dyna_class_name__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_fast_click__, __WEBPACK_EXTERNAL_MODULE_dyna_animation__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_button__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_input_slider__) {
return 