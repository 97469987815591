import * as React from "react";
import {EColor} from "dyna-ui-styles";
import {ERoundTripType} from "dyna-travel-interfaces";
import {DynaPlainRadioButton} from "dyna-ui-radiobutton";

import {EMode} from "../interfaces";

import "./SelectRoundTripType.less";

export interface ISelectRoundTripTypeProps {
  mode: EMode;
  color: EColor,
  roundTripType: ERoundTripType;
  labelRoundTripTypeReturn?: string;
  labelRoundTripTypeOneWay?: string;
  labelRoundTripTypeMultiTrip?: string;
  onChange: (roundTripType: ERoundTripType) => void;
}

export class SelectRoundTripType extends React.Component<ISelectRoundTripTypeProps> {
  private handleRoundTripTypeChange(roundTripType: ERoundTripType): void {
    const {onChange} = this.props;
    onChange(roundTripType);
  }

  public render(): JSX.Element {
    const {
      mode,
      color,
      labelRoundTripTypeReturn,
      labelRoundTripTypeOneWay,
      labelRoundTripTypeMultiTrip,
      roundTripType,
    } = this.props;

    return (
      <div className="round-trip-type-container">
        <DynaPlainRadioButton
          mode={mode}
          color={color}
          className="round-trip-type-item"
          label={labelRoundTripTypeReturn}
          checked={roundTripType === ERoundTripType.RETURN}
          onChange={() => this.handleRoundTripTypeChange(ERoundTripType.RETURN)}
        />
        <DynaPlainRadioButton
          mode={mode}
          color={color}
          className="round-trip-type-item"
          label={labelRoundTripTypeOneWay}
          checked={roundTripType === ERoundTripType.ONE_WAY}
          onChange={() => this.handleRoundTripTypeChange(ERoundTripType.ONE_WAY)}
        />
        <DynaPlainRadioButton
          mode={mode}
          color={color}
          className="round-trip-type-item"
          label={labelRoundTripTypeMultiTrip}
          checked={roundTripType === ERoundTripType.MULTI_TRIP}
          onChange={() => this.handleRoundTripTypeChange(ERoundTripType.MULTI_TRIP)}
        />
      </div>
    );
  }
}
