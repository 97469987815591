import * as React from "react";
import {EDynaTransport, IDynaTime, IDynaTrip} from "dyna-travel-interfaces";

import {SegmentPoint} from "./timeline-content/SegmentPoint";
import {SegmentLine} from "./timeline-content/SegmentLine";
import {ESegmentPlace} from "./entities/PlaceTime";

import {IDynaTravelTripTimelineMessages} from "./messages";

import {EColor, EViewType} from "./styleMixer";
export {EColor, EViewType};

import "./DynaTravelTripTimeline.less";

export const DynaTravelTripTimelineHeight: number = 124;

export interface IDynaTravelTripTimelineProps {
	className?: string;
	viewType: EViewType,
	color: EColor;
	trip: IDynaTrip;
	formatDuration: (minutes: number) => string;
	formatTime: (timeHHMM: string) => string;
	formatFullDate: (dateYYYYMMDD: string) => string;
	messages: IDynaTravelTripTimelineMessages;
}

export class DynaTravelTripTimeline extends React.Component<IDynaTravelTripTimelineProps> {
	private readonly className: string = "dt-trip-timeline";

	constructor(props: IDynaTravelTripTimelineProps) {
		super(props);
	}

	private renderPoint(segment: IDynaTrip, segmentPlace: ESegmentPlace, dayIsChanged: boolean, isFirst: boolean, isLast: boolean, key: number): JSX.Element {
		const {
			viewType,
			color,
			formatTime,
			formatFullDate,
			messages,
		} = this.props;
		return (
			<SegmentPoint
				key={key}
				viewType={viewType}
				color={color}
				segment={segment}
				segmentPlace={segmentPlace}
				dayIsChanged={dayIsChanged}
				isFirst={isFirst}
				isLast={isLast}
				formatTime={formatTime}
				formatFullDate={formatFullDate}
				messages={messages}
			/>
		);
	};

	private renderSegmentLine(segment: IDynaTrip, key: number): JSX.Element {
		const {viewType, trip, color, messages, formatDuration} = this.props;
		const totalTripDurationInMin: number = trip.segments.reduce((acc: number, segment: IDynaTrip) => acc + segment.stats.durationInMin, 0);
		const segmentPercentage: number = 100 * segment.stats.durationInMin / totalTripDurationInMin;

		return (
			<SegmentLine
				key={key}
				viewType={viewType}
				color={color}
				formatDuration={formatDuration}
				messages={messages}
				segment={segment}
				segmentPercentage={segmentPercentage}
			/>);
	};

	private renderTimeline(trip: IDynaTrip): JSX.Element {
		return (
			<div className={`${this.className}__timeline`}>
				{trip.segments.map((segment: IDynaTrip, index: number) => {
					return [
						index === 0 ? this.renderPoint(segment, ESegmentPlace.ORIGIN, false, true, false, 0) : null,
						this.renderSegmentLine(segment, 1),
						this.renderPoint(segment, this.getPointSegmentPlace(index), this.getDayIsChanged(index), false, trip.segments.length - 1 === index, 2),
					]
				})}
			</div>
		);
	}

	private getPointSegmentPlace(index: number): ESegmentPlace {
		const {trip} = this.props;
		const getSegment = (relativeIndex: number) => trip.segments[index + relativeIndex];
		const isSegmentWait = (relativeIndex: number) => getSegment(relativeIndex) && getSegment(relativeIndex).transport.type === EDynaTransport.WAIT;

		// If the segment is wait, show the origin as place
		if (isSegmentWait(0)) return ESegmentPlace.ORIGIN;

		// in all other cases return the destination
		return ESegmentPlace.DESTINATION;
	}

	private getSegmentTime(segment: IDynaTrip, segmentPlace: ESegmentPlace): IDynaTime {
		switch (segmentPlace) {
			case ESegmentPlace.ORIGIN:
				return segment.start;
			case ESegmentPlace.DESTINATION:
				return segment.end;
		}
	}

	private getDayIsChanged(index: number): boolean {
		const {trip} = this.props;
		const getSegment = (relativeIndex: number) => trip.segments[index + relativeIndex];

		const currentSegmentPlace: ESegmentPlace = this.getPointSegmentPlace(index);
		const currentTime: IDynaTime = this.getSegmentTime(getSegment(0), currentSegmentPlace);
		let prevTime: IDynaTime;

		switch (currentSegmentPlace) {
			case ESegmentPlace.ORIGIN:
				prevTime = getSegment(-1) && getSegment(-1).end;
				break;
			case ESegmentPlace.DESTINATION:
				prevTime = getSegment(0) && getSegment(0).start;
				break;
		}

		if (!prevTime) return false;
		return currentTime.localDate !== prevTime.localDate;
	}

	public render(): JSX.Element {
		const {
			className: userClassName,
			color, trip,
		} = this.props;

		const className: string = [
			userClassName || '',
			`${this.className}`,
			`${this.className}--color-${color}`,
		].join(' ').trim();

		if (!trip) return null;

		return (
			<div className={className}>
				{this.renderTimeline(trip)}
			</div>
		);
	}
}
