import * as React from "react";
import { IDynaTime } from "dyna-travel-interfaces";
import { DynaTooltip } from "dyna-ui-tooltip";

import { faIcon } from "../utils/faIcon";

import "./TimeInfo.less";

export interface ITimeInfoProps {
  className?: string;
  time: IDynaTime;
  inOneLine?: boolean;   // default: true
  showNextDayIndication?: boolean;
  formatTime: (timeHHMM: string) => string;
  formatFullDate: (dateYYYYMMDD: string) => string;
  messages?: {
    nextDay: string;
  };
}

export class TimeInfo extends React.Component<ITimeInfoProps> {
  static defaultProps: ITimeInfoProps = {
    className: "",
    time: null,
    showNextDayIndication: false,
    formatTime: time => (new Date('2017-12-25T' + time)).toLocaleTimeString(),
    formatFullDate: date => (new Date(date)).toLocaleDateString(),
    messages: {
      nextDay: "next day",
    }
  };

  constructor(props: ITimeInfoProps) {
    super(props);
  }

  private readonly baseClassName: string = "dyna-travel-time-info";

  private className(subClassName = "", active: boolean = true): string {
    if (!active) return "";
    return `${this.baseClassName}${subClassName || ""}`;
  }

  public render(): JSX.Element {
    const {
      className: userClassName,
      time,
      inOneLine,
      showNextDayIndication,
      formatTime,
      formatFullDate,
      messages: {nextDay},
    } = this.props;
    const dateContainerTooltip: string = showNextDayIndication ? nextDay : null;
    const className: string = [this.className(), userClassName].filter(Boolean).join(' ');
    return (
      <div className={className}>
				<span className={this.className('__time')}>
					{formatTime(time.localTime)}
				</span>
        {inOneLine === false && <br/>}
        <span
          className={`${this.className('__date')} ${this.className('__date--day-is-changed', showNextDayIndication)}`}
        >
					{showNextDayIndication
            ? <DynaTooltip tooltipContent={dateContainerTooltip}> {faIcon('exclamation-circle')}</DynaTooltip>
            : null
          }
          <span> {formatFullDate(time.localDate)}</span>
				</span>
      </div>
    );
  }
}
