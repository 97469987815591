import * as React from 'react';

import {IRoute} from "../../../interfaces/routes";

import {SearchFlights} from "../components/SearchFlights/SearchFlights";

export interface IDynaTravelModuleRoutes {
	[routeName: string]: IRoute<any>;

	DYNA_TRAVEL_SEARCH_FLIGHTS: IRoute<any>;
	DYNA_TRAVEL_MY_TRIPS: IRoute<any>;
}

export const DYNA_TRAVEL_MODULE_ROUTES: IDynaTravelModuleRoutes = {
	DYNA_TRAVEL_SEARCH_FLIGHTS: {
		path: '/flights/search/:serializedRequestTrip?/:viewProposalDetailsTpid?',
		link: (serializedRequestTrip: string, viewProposalDetailsTpid: string) => {
			let link: string = "/flights/search";
			if (serializedRequestTrip) link += `/${serializedRequestTrip}`;
			if (viewProposalDetailsTpid) link += `/${viewProposalDetailsTpid}`;
			return link;
		},
		component: <SearchFlights
			appLayer={null}
			searchFlightProcessId="main"
			serializedRequestTrip={null}
			viewProposalDetailsTpid={null}
		/>,
	},
	DYNA_TRAVEL_MY_TRIPS: {
		path: '/my-trips',
		link: () => `/my-trips`,
		component: <div>my trips. is under construction</div>,
	},
};
