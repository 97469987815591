import * as React  from "react";
import Helmet      from "react-helmet";
import {appConfig} from "../../../settings/app";

export class AppTitle {
  private title: string[] = [];

  public add(title: string): void {
    if (title) this.title.push(title);
  }

  public render(): JSX.Element {
    this.title.push(appConfig.productInfo.name);
    const title: string = this.title.join(' - ');
    const output = <Helmet><title>{title}</title></Helmet>;
    this.title = [];
    return output;
  }
}

export const appTitle = (title: string): JSX.Element => {
  const appTitle = new AppTitle();
  appTitle.add(title);
  return appTitle.render();
};