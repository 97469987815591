import {withRouter} from 'react-router';
import {connect, Dispatch} from "react-redux";

import {AppContainerComponent, IAppContainerComponentProps} from './AppContainerComponent';
import {IHostAppStore} from "../../../index";

import {startSecretAppCommands} from "../../../logic/secretCommands";

export interface IMyFeatureProps {
}

const mapStateToProps = (state: IHostAppStore, ownProps: IMyFeatureProps): IAppContainerComponentProps => {
	return {
		centerAppContent: state.app.viewCenter,
		isLoadingFullScreen: state.app.isLoadingFullScreen,
		isLoadingFullScreenMessage: state.app.isLoadingFullScreenMessage,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: IMyFeatureProps): IAppContainerComponentProps => {
	startSecretAppCommands(dispatch);
	return {};
};

export const AppContainer = (withRouter as any)(connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppContainerComponent));

