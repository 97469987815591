import * as React from "react";
import {random} from "dyna-loops";
import {IError} from "dyna-interfaces";
import {IDynaTrip} from "dyna-travel-interfaces";
import {dynaClassName} from "dyna-class-name";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {DynaModalContainer} from "dyna-ui-modal-container";
import {Header, EHeaderNavButtonType} from "dyna-travel-ui-components";
import {DynaLoadingGhost} from "dyna-ui-loading-ghost";
import {DynaButton, ESize} from "dyna-ui-button";

import {dynaTravelModule} from "../../../logic";
import {appConfig, EProduct} from "../../../../../settings/app";
import {IFlightProposalDetailsMessages} from "../messages";
import {googleAdsSettings} from "../../../../../settings/googleAds";

import {styleMixer} from "../../styleMixer";

import "./FlightProposalRedirectToAgentModal.css";

export interface IFlightProposalRedirectToAgentModalProps {
  product: EProduct;
  className?: string;
  messages: IFlightProposalDetailsMessages;
  show: boolean;
  redirectToTrip: IDynaTrip;
  onRefresh: () => void;
  onClose: () => void;
}

export interface IFlightProposalRedirectToAgentModalState {
  isLoading: boolean;
  redirectToLink: string;
  isRedirecting: boolean;
  isRedirected: boolean;
  retryToGetLink: boolean;
  offerIsExpired: boolean;
}

export class FlightProposalRedirectToAgentModal extends React.Component<IFlightProposalRedirectToAgentModalProps, IFlightProposalRedirectToAgentModalState> {
  constructor(props: IFlightProposalRedirectToAgentModalProps, context: any) {
    super(props, context);
    this.state = {
      isLoading: false,
      redirectToLink: null,
      isRedirecting: false,
      isRedirected: false,
      retryToGetLink: false,
      offerIsExpired: false,
    };
  }

  private className = dynaClassName("dyna-travel-flight-proposal-redirect-to-agent-modal");
  public refs: {
    link: HTMLElement;
  };

  public componentWillReceiveProps(nextProps: IFlightProposalRedirectToAgentModalProps): void {
    if (nextProps.show && nextProps.redirectToTrip !== this.props.redirectToTrip) {
      this.reset();
      if (nextProps.redirectToTrip) {
        this.loadLinkAndRedirect(nextProps.redirectToTrip);
      }
    }
  }

  private reset(): void {
    this.setState({
      redirectToLink: null,
      isLoading: false,
      isRedirected: false,
      isRedirecting: false,
      retryToGetLink: false,
      offerIsExpired: false,
    });
  }

  private loadLinkAndRedirect(redirectToTrip: IDynaTrip): void {
    Promise.resolve()
      .then(() => {
        this.setState({
          isLoading: true,
        });
      })
      .then(() => dynaTravelModule.getAgentTripOfferRedirectionLink(redirectToTrip))
      .then((link: string) => {
        this.setState({
          isLoading: false,
          isRedirecting: true,
          redirectToLink: link,
        });
        return link;
      })
      .then((link: string) => {
        return new Promise((resolve: Function, reject: (error: IError) => void) => {
          setTimeout(() => {
            window.open(link, "_blank");
            resolve();
          }, random(1500, 3000));
        });
      })
      .then(() => {
        googleAdsSettings.sendGoogleAdsConversionEvent();
      })
      .then(() => {
        this.setState({
          isRedirecting: false,
          isRedirected: true,
        });
      })
      .catch((error: IError) => {
        console.error('loadLinkAndRedirect: ', error);
        this.reset();
        if (error.code === 1806220802) {
          this.setState({
            offerIsExpired: true,
          });
        }
        else {
          this.setState({
            retryToGetLink: true,
          });
        }
      });
  }

  public render(): JSX.Element {
    const {
      product,
      className: userClassName,
      messages: {
        redirectHeader,
        redirectionGreeting,
        redirectionMessage,
        retryToGetRedirectionLink,
        offerIsExpired: offerIsExpiredLabel,
        refresh,
        networkError,
        anotherTabHasBeenOpened,
        didntOpenOpenHere,
      },
      redirectToTrip,
      show,
      onRefresh,
      onClose,
    } = this.props;
    const {
      isLoading,
      isRedirecting,
      isRedirected,
      redirectToLink,
      retryToGetLink,
      offerIsExpired,
    } = this.state;

    const className: string = this.className("", userClassName && "/" + userClassName);

    return (
      <DynaModalContainer
        className={className}
        show={show}
      >
        <Header
          className={this.className("__header")}
          title={redirectHeader}
          trip={redirectToTrip}
          navButton={EHeaderNavButtonType.GO_BACK}
          onBackClick={onClose}
        />
        <div className={this.className("__content")}>

          <DynaAnimationVerticalContainer
            className={this.className("__is-redirecting-info")}
            show={isRedirecting}
          >
            <div className={this.className("__is-redirecting-info__greeting")}>
              {redirectionGreeting}
            </div>
            <div className={this.className("__is-redirecting-info__message")}>
              {redirectionMessage}
            </div>
          </DynaAnimationVerticalContainer>

          {redirectToTrip ? <div className={this.className("__agent-info")}>
            {
              redirectToTrip.agent.logoUrl
                ? <div className={this.className("__agent-info__logo")}>
                  {redirectToTrip.agent.logoUrl ? <img src={redirectToTrip.agent.logoUrl}/> : null}
                </div>
                : <div className={this.className("__agent-info__name")}>
                  {redirectToTrip.agent.name.text}
                </div>
            }
          </div> : null}

          <DynaAnimationVerticalContainer
            className={this.className("__is-redirecting")}
            show={isRedirecting}
          >
            {Array(3).fill(null).map((c: any, index: number) => (
              <i key={index} className={this.className("/fas /fa-chevron-right")}/>
            ))}
            <DynaLoadingGhost className={this.className("__is-redirecting__ghost")}/>
          </DynaAnimationVerticalContainer>

          <DynaAnimationVerticalContainer
            className={this.className("__is-loading")}
            show={isLoading}
          >
            <span className={this.className("__big-icon")}>{appConfig.loadingSpinnerComponent}</span>
          </DynaAnimationVerticalContainer>

          <DynaAnimationVerticalContainer
            className={this.className("__is-redirected")}
            show={isRedirected}
          >
            <i className={this.className("__big-icon", "/fas /fa-check-circle")}/>
            <div className={this.className("__is-redirected__label")}>{anotherTabHasBeenOpened}</div>
            <div className={this.className("__is-redirected__link")}>
              <a
                ref="link"
                href={redirectToLink}
                target="_blank"
              >{didntOpenOpenHere}</a>
            </div>
          </DynaAnimationVerticalContainer>

          <DynaAnimationVerticalContainer
            className={this.className("__offer-is-expired")}
            show={offerIsExpired}
          >
            <div><i className={this.className("__big-icon", "/fas /fa-clock")}/></div>
            <div className={this.className("__offer-is-expired__label")}>{offerIsExpiredLabel}</div>
            <div>
              <DynaButton
                className={this.className("__button")}
                color={styleMixer(product).primaryButtonColor}
                size={ESize.XLARGE}
                onClick={onRefresh}
              >{refresh}</DynaButton>
            </div>
          </DynaAnimationVerticalContainer>

          <DynaAnimationVerticalContainer
            className={this.className("__retry")}
            show={retryToGetLink}
          >
            <div className={this.className("__retry__label")}>{networkError}</div>
            <DynaButton
              className={this.className("__button")}
              color={styleMixer(product).primaryButtonColor}
              size={ESize.XLARGE}
              onClick={this.loadLinkAndRedirect.bind(this, this.props.redirectToTrip)}
            >
              <i className="fas fa-redo"/> {retryToGetRedirectionLink}
            </DynaButton>
          </DynaAnimationVerticalContainer>

        </div>
      </DynaModalContainer>
    );
  }
}