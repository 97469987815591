import * as React from "react";

import {EColor} from "../DynaTravelTripTimeline";

import {hasChildren} from "../utils/hasChildren";

import "./SegmentContent.less";

export enum EDirection {
	UP = "UP",
	DOWN = "DOWN",
}

export interface ISegmentContentProps {
	className?: string;
	color: EColor;
	distance: number;
	direction: EDirection;
	showStick: boolean;
	children: any;
}

export class SegmentContent extends React.Component<ISegmentContentProps> {

	private readonly className: string = "dt-trip-timeline__segment-content";

	private renderContent(): JSX.Element[] {
		const {showStick: userShowStick, children} = this.props;

		const showStick: boolean = userShowStick && hasChildren(children);

		const stickClassName: string = [
			`${this.className}__stick`,
			`${this.className}__stick--${showStick ? "show" : "hide"}`,
		].join(' ').trim();

		return [
			<div key={1} className={`${this.className}__content-children`}>{children}</div>,
			<div key={2} className={stickClassName}/>
		];
	}

	public render(): JSX.Element {
		const {
			className,
			color,
			distance,
			direction,
			children,
		} = this.props;

		const containerClassName: string = [
			className || '',
			this.className,
			`${this.className}--color-${color}`,
			`${this.className}--distance-${distance}`,
			`${this.className}--direction-${direction}`,
		].join(' ').trim();

		if (!children) return null;

		return (
			<div className={containerClassName}>
				<div className={`${this.className}__content-container`}>
					<div className={`${this.className}__content`}>
						{this.renderContent()}
					</div>
				</div>
			</div>
		);
	}
}
