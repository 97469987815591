import * as React from "react";

import {EColor} from "dyna-ui-styles";
import {IDynaPlace, IDynaTime, IDynaTripRequestRoute} from "dyna-travel-interfaces";
import {
  DynaDatePickerFlightsInfo,
  EDynaDatePickerColor,
  EDatePickerStyle,
} from "dyna-travel-ui-date-info-pickers";

import {EProduct} from "../interfaces";
import {DirectionExchangeIcon} from "../ui/DirectionExchangeIcon";
import {copyRoute, copyRoutes} from "../utils/copyTripRequest";
import {getDate0} from "../utils/getDate0";
import {renderDay, renderMonthYear} from "../utils/renderCalendarContent";

import {
  EMode,
  ESize,
  DynaTravelSelectAirport,
  EStyle as ESelectAirportStyle,
} from "dyna-travel-ui-select-airport";
import { IDatePickerFlightInfoBasicConfig } from "../DynaSelectAirTrip";

import {getOneFieldSizeUp} from "../utils/getButtonOneSizeUp";

import "./SelectRouteOneWay.less";

export {ESize};

export interface ISelectRouteOneWayProps {
  product: EProduct;
  mode: EMode;
  size: ESize;
  fieldsColor: EColor;
  fieldDatePickerColor: EDynaDatePickerColor;
  formatDate: (date: Date) => string;
  fetchDebounceTimeout: number;
  routes: IDynaTripRequestRoute[];
  datePickerFlightInfoConfig: IDatePickerFlightInfoBasicConfig;
  onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange: (routes: IDynaTripRequestRoute[]) => void;
  messages: {
    originLabel: string;
    originPlaceholder: string;
    originValidationOnEmptyForField: string;
    originValidationOnEmptyForValidationMessages: string;
    destinationLabel: string;
    destinationPlaceholder: string;
    destinationValidationOnEmptyForField: string;
    destinationValidationOnEmptyForValidationMessages: string;
    departureDateLabel: string;
    weekDaysShortNames: string;
    monthLongNames: string;
    datePickerTodayButton: string;
    datePickerCloseButton: string;
    calendarPickerBest: string;
    calendarPickerCheapest: string;
    calendarPickerFastest: string;
    calendarPickerNone: string;
  };
  _debug_calendarPickerStoreName: string;
}

export class SelectRouteOneWay extends React.Component<ISelectRouteOneWayProps> {
  public refs: {
    selectOriginAirport: DynaTravelSelectAirport;
    selectDestinationAirport: DynaTravelSelectAirport;
    directionExchangeIcon: DirectionExchangeIcon;
  };

  private get routes(): IDynaTripRequestRoute[] {
    return copyRoutes(this.props.routes);
  }

  private get route(): IDynaTripRequestRoute {
    const routes: IDynaTripRequestRoute[] = this.routes;
    return copyRoute(routes[0]);
  }

  private handleToggleOriginDestination(): void {
    const {mode, onChange} = this.props;
    if (mode !== EMode.EDIT) return;
    const routes: IDynaTripRequestRoute[] = this.routes;

    this.refs.directionExchangeIcon.flip();

    let helper: IDynaPlace = routes[0].origin;
    routes[0].origin = routes[0].destination;
    routes[0].destination = helper;

    onChange(routes);
  }

  private handleDateChange(name: string, value: Date): void {
    this.handleChange(name, {date: value} as IDynaTime);
  }

  private handleChange(name: string, value: any): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;

    routes[0] = {
      ...this.route,
      [name]: value,
    };

    onChange(routes);
  }

  public validate(): string[] {
    return [].concat(
      this.refs.selectOriginAirport.validate(),
      this.refs.selectDestinationAirport.validate(),
    );
  }

  public render(): JSX.Element {
    const {
      mode,
      size,
      fieldsColor,
      fieldDatePickerColor,
      datePickerFlightInfoConfig,
      formatDate,
      fetchDebounceTimeout,
      onAirportSearch,
      messages: {
        originLabel,
        originPlaceholder,
        originValidationOnEmptyForField,
        originValidationOnEmptyForValidationMessages,
        destinationLabel,
        destinationPlaceholder,
        destinationValidationOnEmptyForField,
        destinationValidationOnEmptyForValidationMessages,
        departureDateLabel,
        weekDaysShortNames,
        monthLongNames,
        datePickerTodayButton,
        datePickerCloseButton,
        calendarPickerBest,
        calendarPickerCheapest,
        calendarPickerFastest,
        calendarPickerNone
      },
      _debug_calendarPickerStoreName,
    } = this.props;

    return (
      <div className="dyna-travel-select-air-trip-one-way  dyna-travel-route-container">
        <DynaTravelSelectAirport
          className="select-origin"
          ref="selectOriginAirport"
          mode={mode}
          size={size}
          name="origin"
          label={originLabel}
          style={ESelectAirportStyle.WIDE_LABEL_ROUNDED}
          color={fieldsColor}
          inputProps={{
            placeholder: originPlaceholder,
          }}
          validationMessageOnEmptyForField={originValidationOnEmptyForField}
          validationMessageOnEmptyForValidationMessages={originValidationOnEmptyForValidationMessages}
          value={this.route.origin}
          fetchDebounceTimeout={fetchDebounceTimeout}
          onChange={this.handleChange.bind(this)}
          onAirportSearch={onAirportSearch}
        />
        <div className="destination-container">
          <div className={`trip-change-directions mode-${mode}`} onClick={this.handleToggleOriginDestination.bind(this)}>
            <DirectionExchangeIcon ref="directionExchangeIcon"/>
          </div>
          <DynaTravelSelectAirport
            className="select-destination"
            ref="selectDestinationAirport"
            mode={mode}
            size={size}
            name="destination"
            label={destinationLabel}
            style={ESelectAirportStyle.WIDE_LABEL_ROUNDED}
            color={fieldsColor}
            inputProps={{
              placeholder: destinationPlaceholder,
            }}
            validationMessageOnEmptyForField={destinationValidationOnEmptyForField}
            validationMessageOnEmptyForValidationMessages={destinationValidationOnEmptyForValidationMessages}
            value={this.route.destination}
            fetchDebounceTimeout={fetchDebounceTimeout}
            onChange={this.handleChange.bind(this)}
            onAirportSearch={onAirportSearch}
          />
        </div>
        <DynaDatePickerFlightsInfo
          className="select-depart-date"
          mode={mode}
          style={EDatePickerStyle.WIDE_LABEL_ROUNDED}
          color={fieldDatePickerColor}
          size={size}
          pickerSize={getOneFieldSizeUp(size)}
          name="departDate"
          min={getDate0()}
          label={departureDateLabel}
          todayButtonLabel={datePickerTodayButton}
          closeButtonLabel={datePickerCloseButton}
          renderPickerWeekDay={(weekDay: number) => renderDay(weekDaysShortNames, weekDay)}
          renderPickerMonthYear={(month: number, year: number) => renderMonthYear(monthLongNames, month, year)}
          renderInputDate={formatDate}
          flightsInfoConfig={{
            ...datePickerFlightInfoConfig,
            roundTrip: false,
            origin: this.route.origin && this.route.origin.name.codeName,
            destination: this.route.destination && this.route.destination.name.codeName,
            messages: {
              best: calendarPickerBest,
              cheapest: calendarPickerCheapest,
              fastest: calendarPickerFastest,
              none: calendarPickerNone,
            },
            _debug_dynaduxDebugGlobalVarName: _debug_calendarPickerStoreName && _debug_calendarPickerStoreName + "__routeOneWay",
          }}
          value={this.route.departDate.date as Date}
          onChange={this.handleDateChange.bind(this)}
        />
      </div>
    );
  }
}
