// app
import * as React from 'react';
import {appConfig, ERouterType} from "../settings/app";
import {IAppLayer} from "../interfaces/app";
import {IAppStore} from "../redux/reducersConfig";

const package_: any = require('../../package.json');

// intl locals / translations
import {ReduxIntlProvider} from "../components/contextWrappers/ReduxIntlProvider";

// redux
import {Provider as ReduxProvider, Store} from "react-redux";
import {createReduxAppStore} from '../redux';

// dynadux
import {Provider as DynaduxProvider} from "react-dynadux";
import {createAppStore, IAppStoreApi} from "../dynadux/appStore";


// router
import {BrowserRouter, HashRouter, Route} from 'react-router-dom';
import {Location, Action, History} from 'history';
import {RouteComponentProps} from "react-router";

const Router: any = appConfig.routerType === ERouterType.BROWSER ? BrowserRouter : HashRouter;

// libs
import {analytics} from "../libs/analytics";

// components
import {AppWrapper} from "../modules/Application/content/app/appWrapper/AppWrapper";

// actions
import {appMainUpdateRouteInfo} from "../modules/Application/state/actions";

// style
import './bootstrap-normilize.css';
import './global.css';

export class App extends React.Component {
  private routerHistory: History;
  private readonly reduxStore: Store<IAppStore> = ((window as any)._temp_reduxStore) = createReduxAppStore();
  private readonly dynaduxStore: IAppStoreApi = createAppStore();

  public componentDidMount(): void {
  }

  private handleNavigation = (): void => {
    this.reduxStore.dispatch(appMainUpdateRouteInfo(this.routerHistory.location.pathname));
    analytics.sendEvent("App", "Page path (2 levels)", this.routerHistory.location.pathname.split('/').slice(0, 3).join('/'));
  };

  private get appLayer(): IAppLayer {
    return {
      appName: package_.name,
      appLabel: package_.label,
      appDescription: package_.description,
      version: package_.version,
      navigate: (path: string) => this.routerHistory.push(path),
      navigateBack: () => this.routerHistory.goBack(),
    };
  }

  private registerRouterHistory(routeProps: RouteComponentProps<any>): void {
    if (this.routerHistory) return null;
    this.routerHistory = routeProps.history;
    this.routerHistory.listen((location: Location, action: Action) => {
      this.handleNavigation();
    });
    this.handleNavigation();
    return null;
  }

  public render(): JSX.Element {
    return (
      <DynaduxProvider appStore={this.dynaduxStore}>
        <ReduxProvider store={this.reduxStore}>
          <ReduxIntlProvider>
            <Router>
              <div>
                <Route render={this.registerRouterHistory.bind(this)}/>
                <AppWrapper appLayer={this.appLayer}/>
              </div>
            </Router>
          </ReduxIntlProvider>
        </ReduxProvider>
      </DynaduxProvider>
    );
  }
}
