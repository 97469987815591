import * as moment from "moment";
import {DynaLocalStorage} from "dyna-local-storage";
import {IDynaTripRequest} from "dyna-travel-interfaces";

import {getDate0, getNextWeekendDepartDate} from "./getDate";

const requestTripVersion: number = 1;

export class DynaTravelLocalStorage extends DynaLocalStorage {
	constructor(namespace: string) {
		super(namespace);
	}

	public getDefaultDepartDate(key: string): Date {
		let date: Date = this.getDate(key, null);
		if (!date || moment(date).isBefore(getDate0())) {
			date = getNextWeekendDepartDate();
		}
		this.setDate(key, date);
		return date;
	}

	public getDefaultReturnDate(key: string, departDate: Date): Date {
		let date: Date = this.getDate(key, null);
		if (!date || moment(date).isBefore(getDate0()) || moment(date).isBefore(departDate)) {
			date = getNextWeekendDepartDate(2);
		}
		this.setDate(key, date);
		return date;
	}

	public getLastRequestTrip(searchFlightProcessId: string, default_: IDynaTripRequest): IDynaTripRequest {
		const savedTripRequest = this.getItem<IDynaTripRequest>(`${searchFlightProcessId}-v${requestTripVersion}`);
		if (!savedTripRequest) return default_;

		const firstDate = moment(savedTripRequest.routes[0].departDate.date);
		if (firstDate.isBefore()) {
			const weeksDiff = moment().diff(firstDate, "weeks") + 1;
			savedTripRequest.routes.forEach(route => {
				route.departDate.date = moment(route.departDate.date).add(weeksDiff, "weeks").toDate();
			});
		}

		return savedTripRequest;
	}

	public setLastRequestTrip(searchFlightProcessId: string, requestTrip: IDynaTripRequest): void {
		this.setItem(`${searchFlightProcessId}-v${requestTripVersion}`, requestTrip);
	}
}

export const dynaTravelLocalStorage = new DynaTravelLocalStorage("dyna-travel--storage");

