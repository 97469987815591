(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-ui-styles"), require("react"), require("dyna-guid"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-ui-picker-container", ["dyna-ui-styles", "react", "dyna-guid"], factory);
	else if(typeof exports === 'object')
		exports["dyna-ui-picker-container"] = factory(require("dyna-ui-styles"), require("react"), require("dyna-guid"));
	else
		root["dyna-ui-picker-container"] = factory(root["dyna-ui-styles"], root["react"], root["dyna-guid"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_ui_styles__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_dyna_guid__) {
return 