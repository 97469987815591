import {Dispatch} from "react-redux";
import {commandsSetup} from "./commandSetup";

export interface IActionsByTypedCommands {
	[command: string]: (dispatch: Dispatch<any>) => void;
}

export class SecretAppCommands {
	constructor(private readonly dispatch: Dispatch<any>, private readonly commands: IActionsByTypedCommands) {
		this.maxLength = Object.keys(commands).reduce((acc: number, command: string) => {
			if (acc < command.length) acc = command.length;
			return acc;
		}, 0);

		document.addEventListener("keyup", (e: KeyboardEvent) => {
			this.lastTypedChars += e.key;
			this.lastTypedChars = this.lastTypedChars.substr(-this.maxLength);
			this.processTypedChars();
		});
	}

	private lastTypedChars: string = "";
	private maxLength: number;

	private processTypedChars(): void {
		Object.keys(this.commands).forEach((command: string) => {
			if (this.lastTypedChars.substr(-command.length) === command) {
				this.commands[command](this.dispatch);
			}
		});
	}
}

let secretAppCommands: SecretAppCommands;

export const startSecretAppCommands = (dispatch: Dispatch<any>): void => {
	if (secretAppCommands) return;
	secretAppCommands = new SecretAppCommands(dispatch, commandsSetup);
};