import * as React from "react";

// dev note: the types are not compatible really, but they works as imported like this
import * as Loadable from "react-loadable";
import {LoadingComponentProps} from "react-loadable";

import {dynaClassName, DynaClassName} from "dyna-class-name";
import {DynaButton, EColor, ESize} from "dyna-ui-button";

import {LoadingInLine} from "../components/ui/loadingInLine";

import "./loadModule.css";

// help: https://github.com/jamiebuilds/react-loadable#loadingcomponent

const cn: DynaClassName = dynaClassName("app-load-module-handler");

export interface ILoadModuleOpts {
  loader: () => any;
  delay?: number;
  timeout?: number;
}

export const loadModule = (opts: ILoadModuleOpts): JSX.Element => {
  const {
    loader,
    delay,
    timeout,
  } = opts;
  return Loadable({
    loader,
    loading: Loading,
    delay: delay || 5000,
    timeout: timeout || 10000,
  }) as any;
};

const Loading: React.SFC<LoadingComponentProps> = (props) => {
  if (props.error) {
    // When the loader has errored
    return <div className={cn()}>
      <div className={cn("__info")}><i className="fas fa-exclamation-circle"/> Error loading</div>
      <RetryButton onClick={props.retry}/>
    </div>;
  }
  else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    return <div className={cn()}>
      <div className={cn("__info")}><i className="fas fa-exclamation-circle"/> Taking a long time...</div>
      <RetryButton onClick={props.retry}/>
    </div>;
  }
  else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    return <div className={cn("__info")}>
      {LoadingInLine("loading...")}
    </div>;
  }
  else {
    // When the loader has just started
    return null;
  }
};

interface IRetryButtonProps {
  onClick: () => void;
}

const RetryButton: React.SFC<IRetryButtonProps> = ({onClick}) => (
  <DynaButton
    size={ESize.LARGE}
    color={EColor.RED_WHITE}
    onClick={onClick}
  ><i className="fas fa-redo"/> Retry</DynaButton>
);
