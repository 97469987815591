import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {IDynaPrice, IDynaTripProposal, IDynaTripRequest} from "dyna-travel-interfaces";
import {DynaFastClick} from "dyna-ui-fast-click";

import {EMode, EProduct, IDynaTripFilter} from "./interfaces";
import {defaultMessages, IDynaTravelFilterTripsMessages} from "./messages";

import {Header} from "./components/Header";
import {FilterEditor} from "./components/FilterEditor";
import {defaultConvertPrice, defaultFormatDate, defaultFormatDuration, defaultFormatPrice} from "./utils/defaultFormaters";

import "./DynaTravelFilterTrips.less";

export interface IDynaTravelFilterTripsProps {
	// basics
	className?: string;
	product: EProduct;
	show: boolean;
	currency: string;             // user's currency
	messages: IDynaTravelFilterTripsMessages;
	convertPrice: (price: IDynaPrice, currency: string) => number;
  showNoFilterLabel: boolean;   // default: false
  formatPrice: (price: IDynaPrice, currency: string) => string;
	formatDate: (date: Date) => string;
	formatDuration: (minutes: number) => string;

	// input data
	tripRequest: IDynaTripRequest;
	tripProposals: IDynaTripProposal[];

	// output data
	value: IDynaTripFilter;   // set this to null to reset the filters but outside changes of `value` are ignored

	// events
	onChange: (value: IDynaTripFilter) => void;
	onFilteredTripProposals: (filteredTripProposals: IDynaTripProposal[]) => void;
	onClose: () => void;
}

export interface IDynaTravelFilterTripsState {
	mode: EMode;
	filteredCount: number;
}

export interface IValuesPerDate {
	[dateIso: string]: number[];
}

export class DynaTravelFilterTrips extends React.Component<IDynaTravelFilterTripsProps, IDynaTravelFilterTripsState> {
	constructor(props: IDynaTravelFilterTripsProps) {
		super(props);
		this.state = {
			mode: EMode.VIEW,
			filteredCount: 0,
		};
	}

	private readonly cn = dynaClassName("dyna-travel-filter-trips");
  private unmount: boolean = false;

	public componentWillReceiveProps(nextProps: IDynaTravelFilterTripsProps): void {
		if (nextProps.show !== this.props.show) {
			this.setState({mode: EMode.VIEW});
		}
	}

  public componentWillUnmount(): void {
    this.unmount = true;
  }

  private handleFilteredTripProposals(tripProposals: IDynaTripProposal[]): void {
		const {onFilteredTripProposals} = this.props;

    if (this.state.filteredCount !== tripProposals.length)
      if (!this.unmount)
        this.setState({filteredCount: tripProposals.length});

		onFilteredTripProposals(tripProposals);
	}

  private handleExpandCollapseClick = (): void => {
		const {onClose} = this.props;
		const {mode} = this.state;
		this.setState({mode: mode === EMode.VIEW ? EMode.EDIT : EMode.VIEW});
		if (mode===EMode.EDIT) onClose();
  };

	public render(): JSX.Element {
		const {
			product,
			currency,
			messages,
			show,
			tripRequest,
			tripProposals,
			convertPrice,
      showNoFilterLabel,
			formatPrice,
			formatDate,
			formatDuration,
			value: filter,
			onChange,
		} = this.props;

		const {
			mode,
			filteredCount,
		} = this.state;

		if (!tripProposals) return null;

		return (
			<div className={this.cn.root(this.props)}>

				<div className={this.cn("__content")}>
					<Header
            show={show}
            messages={messages}
            filter={filter}
            filteredCount={filteredCount}
            totalCount={tripProposals.length}
            showNoFilterLabel={showNoFilterLabel}
            onClick={this.handleExpandCollapseClick}
					/>
					<FilterEditor
						product={product}
						show={show && mode === EMode.EDIT}
						messages={messages}
						currency={currency}
						convertPrice={convertPrice}
						formatPrice={formatPrice}
						formatDate={formatDate}
						formatDuration={formatDuration}
						tripRequest={tripRequest}
						tripProposals={tripProposals}
						value={filter}
						onFilteredTripProposals={this.handleFilteredTripProposals.bind(this)}
						onChange={onChange}/>
				</div>

				<DynaFastClick
          className={this.cn(
						`__button __button--mode-${mode}`,
						`__button--product-${product}`,
						`__button--${show ? "show" : "hide"}`
					)}
          onClick={this.handleExpandCollapseClick}
				>
					<i className="fas fa-caret-down"/>
				</DynaFastClick>

			</div>
		);
	}
}
