import {IDynaTrip} from "dyna-travel-interfaces";

export const dynaTravelDebug_getTripsFromNetworkTripRequestResponse = (tripRequestResponse: any): IDynaTrip[] => {
	return tripRequestResponse
		.responses.filter((r: any) => r.command === "loadTrips/IDynaTrip[]")
		.reduce((acc: IDynaTrip[], r: any) => {
			acc = acc.concat(r.data);
			return acc;
		}, [])
};
