(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-travel-ui-components"), require("react"), require("dyna-ui-fast-click"), require("dyna-animation"), require("dyna-travel-ui-trip-timeline"), require("dyna-class-name"), require("dyna-travel-interfaces"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-travel-ui-trip-vertical-timeline", ["dyna-travel-ui-components", "react", "dyna-ui-fast-click", "dyna-animation", "dyna-travel-ui-trip-timeline", "dyna-class-name", "dyna-travel-interfaces", "moment"], factory);
	else if(typeof exports === 'object')
		exports["dyna-travel-ui-trip-vertical-timeline"] = factory(require("dyna-travel-ui-components"), require("react"), require("dyna-ui-fast-click"), require("dyna-animation"), require("dyna-travel-ui-trip-timeline"), require("dyna-class-name"), require("dyna-travel-interfaces"), require("moment"));
	else
		root["dyna-travel-ui-trip-vertical-timeline"] = factory(root["dyna-travel-ui-components"], root["react"], root["dyna-ui-fast-click"], root["dyna-animation"], root["dyna-travel-ui-trip-timeline"], root["dyna-class-name"], root["dyna-travel-interfaces"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_travel_ui_components__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_fast_click__, __WEBPACK_EXTERNAL_MODULE_dyna_animation__, __WEBPACK_EXTERNAL_MODULE_dyna_travel_ui_trip_timeline__, __WEBPACK_EXTERNAL_MODULE_dyna_class_name__, __WEBPACK_EXTERNAL_MODULE_dyna_travel_interfaces__, __WEBPACK_EXTERNAL_MODULE_moment__) {
return 