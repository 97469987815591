import {IDynaPlace} from "dyna-travel-interfaces";

// dev note! IMPORTANT
// The generate names are big, since the TP API is getting confused with some airports
// Check out the bug Task 1604: TP BUG - "LON London" returns "ROV Platov"

export const airportToString = (airport: IDynaPlace): string => {
  if (!airport) return '';
  return `${airport.name.codeName.toUpperCase()} ${airport.city.name.text}`;
};
