import {EColor as ETooltipColor} from "dyna-ui-tooltip";

export enum EColor {
  ORANGE = "ORANGE",
  RED = "RED",
}

export enum EViewType {
  BASIC = "BASIC",
  DETAILS = "DETAILS",
}

export interface IStyleMixer {
	tooltipColor: ETooltipColor;
}

export const styleMixer = (tripTimelineColor: EColor): IStyleMixer => {
	switch (tripTimelineColor) {
		case EColor.ORANGE:
			return {
				tooltipColor: ETooltipColor.WHITE_ORANGE,
			};
		case EColor.RED:
			return {
				tooltipColor: ETooltipColor.WHITE_RED,
			};
	}
}
