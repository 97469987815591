import {IDynaPrice} from "dyna-travel-interfaces";

export const defaultFormatDuration = (minutes: number): string => {
	const hours: number = Math.floor(minutes / 60);
	const min: number = Math.round(minutes - (hours * 60));
	let output = "";
	if (hours > 0) output += `${hours}h`;
	if (min > 0 && hours > 0) output += " ";
	if (min > 0) output += `${min}m`;
	return output;
};

export const defaultFormatTime = (time: Date): string => time.toLocaleTimeString();

export const defaultFormatTimeString = (timeHHMM: string): string => defaultFormatTime(new Date("2017-12-25 " + timeHHMM));

export const defaultFormatDate = (date: Date) => date.toDateString();

export const defaultFormatDateString = (dateYYYYMMDD: string): string => defaultFormatDate(new Date(dateYYYYMMDD));

export const defaultFormatDateShort = (date: Date) => date.toLocaleDateString();

export const defaultFormatPrice = (price: IDynaPrice, currency: string): string => price.value.toString() + currency;

export const defaultConvertPrice = (price: IDynaPrice, currency: string): number => {
	const usdValueInEur: number = 0.86;
	if (price.currency.toLowerCase() === currency.toLowerCase()) {
		return price.value;
	}
	else if (price.currency.toLowerCase() === "eur" && currency.toLowerCase() === "usd") {
		return price.value * usdValueInEur;
	}
	else if (price.currency.toLowerCase() === "eur" && currency.toLowerCase() === "usd") {
		return price.value / usdValueInEur;
	}
	else {
		console.error('The prop: `convertPrice` is not implemented! The `defaultConvertPrice` is used instead and this is not for production. Only usd/eur conversion is supported only and this for demonstration only; the same value is applied', {price, currency});
		return price.value;
	}
};

