import {DynaNodeClient} from "dyna-node/dist/commonJs/web";
import {DynaCache} from "dyna-cache";

export interface IDynaCMSClientConfig {
	serviceAddress: string;             // the dyna node address
	cacheLimit: number;                 // memory cache
	cacheContentExpiresInMin: number;
}

export class DynaCMSClient {
	private nodeDevice: DynaNodeClient;
	private cache: DynaCache = new DynaCache({cacheLimit: 5000000});

	constructor(private readonly config: IDynaCMSClientConfig) {
		this.nodeDevice = new DynaNodeClient({prefixAddress: 'clientDynaCMS'});
	}

	public fetch(name: string): Promise<string> {
		const cachedContent = this.cache.get<string>(name);
		if (cachedContent) return Promise.resolve(cachedContent);

    return this.nodeDevice.fetch<null, {name: string}, string>({
			to: this.config.serviceAddress,
			command: "fetch",
			data: {name},
		})
			.then((content: string) => {
				this.cache.add(name, content, {expiresIn: `${this.config.cacheContentExpiresInMin}m`});
				return content;
			});
	}
}

