import * as React from 'react';
import {dynaClassName, DynaClassName} from "dyna-class-name";
import {AppShareButtons} from "../shareButtons/ShareButtons";

import {IAppLayer} from "../../../../../interfaces/app";
import {appConfig, EProduct} from "../../../../../settings/app";
import {FooterBanners} from "./components/footer-banners";

import "./footer.css";
import {FooterLinks} from "./components/footer-links";

const className: DynaClassName = dynaClassName('app-footer');

export interface IFooterProps {
  appLayer: IAppLayer;
}

export const Footer: React.SFC<IFooterProps> = ({appLayer}) => {
  return (
    <footer className={className()}>

      <FooterBanners appLayer={appLayer}/>

      <AppShareButtons/>

      <FooterLinks/>

      <div className={className("__copyright")}>
        <span>{appConfig.productInfo.name}</span>
        <span> - </span>
        <span>{appConfig.productInfo.name}</span>
        <span> - </span>
        <span>Copyright &copy; 2018-{(new Date).getFullYear()}</span>
        <span> </span>
        <span>Anel .co</span>
        <span> - </span>
        <span>All Rights Reserved</span>
      </div>

    </footer>
  );
};

