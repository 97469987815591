import {IDynaClientModule} from "../modulesLoader";
import {APP_ROUTES} from "./routes/appRoutes";
import {appReducer, IAppState} from "./state/reducer";
import {IAppStore} from "../../redux";
import {appTranslationMessages} from "./locales/messages";

export interface IHostAppStore extends IAppStore {
	app: IAppState;
}

export const applicationModule: IDynaClientModule = {
	name: 'app',
	routes: APP_ROUTES,
	reducers: {
		app: appReducer,
	},
	translation: appTranslationMessages,
};

