import {createStore, dynaduxDebugMiddleware} from "dynadux";
import {createCurrenciesSection, ICreateCurrenciesSectionState} from "dynadux-currencies-section";

import {appConfig, EAppMode} from "../settings/app";

import {getCurrencies} from "./sections/currencies/api/getCurrencies";
import {serviceAddress} from "../settings/serviceAddress";

export interface IAppStore {
  currenciesSection: ICreateCurrenciesSectionState;
}

export interface IAppStoreApi extends ReturnType<typeof createAppStore> {
}

export const createAppStore = (): any => {
  const store = createStore<IAppStore>({
    middlewares: [
      dynaduxDebugMiddleware({
        debuggerStoreName: appConfig.mode === EAppMode.DEBUG ? '_debug_travelAppStore' : '',
      }),
    ],
  });

  return {
    provider: store.provider,

    currencies: createCurrenciesSection({
      sectionName: 'currenciesSection',
      store,
      defaultCurrency: 'eur',
      updateIfOldenThanMinutes: 60,

      getCurrencies: () => getCurrencies(serviceAddress('financial')),
    }),
  };
};
