import * as React from "react";
import {IDynaTrip, IDynaCompany} from "dyna-travel-interfaces";
import {DynaStarRating} from "dyna-ui-star-rating";

import {TransportTypeIcon} from "../transport-type-icon/TransportTypeIcon";
import {BaggageInfo} from "../baggage-info/BaggageInfo";
import {EBaggageType} from "../interfaces";

import "./FlightInfo.less";

export interface IFlightInfoProps {
  className?: string;
  style?: EFlightInfoStyle;
  segment: IDynaTrip;
  messages?: {
    operator: string;
    handler: string;
    baggageTypeBaggage: string;
    baggageTypeHandBag: string;
    perBag: string;
    noBags: string;
    unknownBags: string;
    totalMax: string;
  };
}

export enum EFlightInfoStyle {
  SHORT_COLUMN = "SHORT_COLUMN",
}

export class FlightInfo extends React.Component<IFlightInfoProps> {
  private readonly className: string = "dt-trip-timeline__flight-tooltip";

  public static defaultProps: IFlightInfoProps = {
    className: "",
    style: EFlightInfoStyle.SHORT_COLUMN,
    segment: null,
    messages: {
      operator: 'Operator',
      handler: 'Handler',
      baggageTypeBaggage: "Baggage",
      baggageTypeHandBag: "Hand bag",
      perBag: "per bag",
      noBags: "no bags",
      unknownBags: "no bag info",
      totalMax: "total max weight",
    },
  };

  private renderTransportTypeAndNumber(): JSX.Element {
    const {segment: {transport}} = this.props;
    const className: string = `${this.className}__transport-type-number`;
    return (
      <div className={className}>
        <TransportTypeIcon
          className={`${className}__transport-type`}
          transportType={transport.type}
        />
        <div className={`${className}__flight-number`}>{(transport.numbers || []).join(' - ')}</div>
      </div>
    );
  }

  private renderCompany(company: IDynaCompany, isHandler: boolean, isOperator: boolean): JSX.Element {
    const {messages: {operator, handler}} = this.props;
    const companyTypeLabel: string = [
      isHandler ? handler : '',
      isOperator ? operator : '',
    ].filter(t => !!t).join(' / ');
    const key: string = `ck-${isHandler}-${isOperator}`;
    const className: string = `${this.className}__company`;
    return (
      <div className={className} key={key}>
        <div className={`${className}__type`}>{companyTypeLabel}</div>
        <div className={`${className}__name`}>{company.name.text}</div>
        <div className={`${className}__logo`}>
          <img className={`${className}__logo-icon`} src={company.logoUrl}/>
        </div>
        <div className={`${className}__alliance-name`}>{company.allianceName.text}</div>
        <DynaStarRating
          className={`${className}___star-rating`}
          value={company.rate.rate}
          ratesCount={company.rate.rates}
        />
      </div>
    );
  }

  private renderCompanies(): JSX.Element[] {
    const output: JSX.Element[] = [];
    const {segment: {transport: {handler, operator}}} = this.props;
    const operatorHandlerIsTheSame: boolean = handler.id === operator.id;
    if (operatorHandlerIsTheSame) {
      output.push(this.renderCompany(operator, true, true));
    } else {
      output.push(this.renderCompany(handler, true, false));
      output.push(this.renderCompany(operator, false, true));
    }
    return output;
  }

  private renderTransportDetails(): JSX.Element {
    const {segment: {transport}} = this.props;
    const className: string = `${this.className}__transport-details`;
    return (
      <div className={className}>
        <div className={`${className}__model`}>{transport.model}</div>
      </div>
    );
  }

  private renderBaggageInfo(): JSX.Element {
    const {
      segment,
      messages,
    } = this.props;
    return (
      <div className={`${this.className}__baggage-info-container`}>
        <BaggageInfo
          className={`${this.className}__baggage-info-item`}
          baggageType={EBaggageType.BAGGAGE}
          baggage={segment.transport.baggage}
          messages={messages}
        />
        {segment.transport.handBags && segment.transport.handBags.bags ? <BaggageInfo
          className={`${this.className}__baggage-info-item`}
          baggageType={EBaggageType.HAND_BAG}
          baggage={segment.transport.handBags}
          messages={messages}
        /> : null}
      </div>
    );
  }

  public render(): JSX.Element {
    const className: string = [
      this.className,
      this.props.className,
    ].join(' ').trim();

    return (
      <div className={className}>
        {this.renderTransportTypeAndNumber()}
        {this.renderTransportDetails()}
        {this.renderCompanies()}
        {this.renderBaggageInfo()}
      </div>
    );
  }
}
