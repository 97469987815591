import * as React from "react";
import {DynaFastClick} from "dyna-ui-fast-click";
import {IDynaTrip} from "dyna-travel-interfaces";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {DynaTravelTripTimeline, EViewType} from "dyna-travel-ui-trip-timeline"

import {SegmentView} from "./SegmentView";

import {EProduct} from "../interfaces";
import {IDynaTravelTripVerticalTimelineMessages} from "../messages";
import {styleMixer} from "../styleMixer";
import {getTime} from "../utils/getTime";

import "./TripView.less";

export interface ITripViewProps {
	product: EProduct,
	segmentNo: number;
	segment: IDynaTrip;
	messages: IDynaTravelTripVerticalTimelineMessages;
	formatTime: (time: Date) => string;
	formatTimeString: (timeHHMM: string) => string;
	formatShortDate: (date: Date) => string;
	formatFullDateString: (dateYYYYMMDD: string) => string;
	formatDuration: (minutes: number) => string;
}

export interface ITripViewState {
	expanded?: boolean;
}

export class TripView extends React.Component<ITripViewProps, ITripViewState> {
	constructor(props: ITripViewProps) {
		super(props);
		this.state = {
			expanded: false,
		};
	}

	private readonly baseClassName: string = "dttvt-trip-view";
	private readonly className = (subClassName: string = "", active: boolean = true): string => active ? `${this.baseClassName}${subClassName}` : "";

	private handlerHeaderClick(): void {
		this.setState({
			expanded: !this.state.expanded,
		});
	}

	public render(): JSX.Element {
		const {
			product,
			segmentNo,
			segment,
			messages,
			messages: {
				allTimesAreLocal,
			},
			formatTime,
      formatTimeString,
			formatShortDate,
			formatDuration,
      formatFullDateString,
		} = this.props;
		const {
			expanded,
		} = this.state;

		const className: string = [
			this.className(),
		].join(' ').trim();

		return (
			<div className={className}>
				<DynaFastClick
					className={this.className("__header-container")}
					nodeType="div"
					onClick={this.handlerHeaderClick.bind(this)}
				>

					<div className={this.className("__header")}>
						<div className={this.className("__header-number")}>
							{segmentNo}
						</div>
						<div className={this.className("__header-depart")}>
							<div className={this.className("__header-depart-place-code-name")}>{segment.segments[0].origin.name.codeName}</div>
							<div className={this.className("__header-depart-time")}>{formatTime(getTime(segment.segments[0].start.localTime))}</div>
							<div className={this.className("__header-depart-date")}>{formatShortDate(new Date(segment.segments[0].start.localDate))}</div>
							<div className={this.className("__header-depart-place-city")}>{segment.segments[0].origin.city.name.text}</div>
							<div className={this.className("__header-depart-place-country")}>{segment.segments[0].origin.country.name.text} <img className={this.className("__header-arrival-place-flag")} src={segment.segments[0].origin.flagUrl}/></div>
						</div>
						<div className={this.className("__header-timeline")}>
							<DynaTravelTripTimeline
								viewType={EViewType.BASIC}
								color={styleMixer(product).horizontalTripTimelineColor}
								trip={segment}
								messages={messages}
								formatDuration={formatDuration}
                formatTime={formatTimeString}
                formatFullDate={formatFullDateString}
							/>
							<div className={this.className("__header-duration")}>
								<span><i className="fas fa-clock"></i> {formatDuration(segment.stats.durationInMin)}</span>
							</div>
						</div>
						<div className={this.className("__header-arrival")}>
							<div className={this.className("__header-arrival-place-code-name")}>{segment.segments[segment.segments.length - 1].destination.name.codeName}</div>
							<div className={this.className("__header-arrival-time")}>{formatTime(getTime(segment.segments[segment.segments.length - 1].end.localTime))}</div>
							<div className={this.className("__header-arrival-date")}>{formatShortDate(new Date(segment.segments[segment.segments.length - 1].end.localDate))}</div>
							<div className={this.className("__header-arrival-place-city")}>{segment.segments[segment.segments.length - 1].destination.city.name.text}</div>
							<div className={this.className("__header-arrival-place-country")}>{segment.segments[segment.segments.length - 1].destination.country.name.text} <img className={this.className("__header-arrival-place-flag")} src={segment.segments[segment.segments.length - 1].destination.flagUrl}/>
							</div>
						</div>
					</div>

					<div className={this.className("__expand-icon")}>
						<i className={this.className("__expand-icon__icon fas fa-chevron-left") + " " + this.className(`__expand-icon__icon--${expanded ? "expanded" : "collapsed"}`)}/>
					</div>

				</DynaFastClick>

				<DynaAnimationVerticalContainer
					className={this.className("__segments")}
					show={expanded}
				>
					<SegmentView
						trip={segment}
						messages={messages}
						formatTime={formatTime}
						formatShortDate={formatShortDate}
						formatDuration={formatDuration}
					/>
				</DynaAnimationVerticalContainer>

				<div className={this.className("__all-time-are-local")}>{allTimesAreLocal}</div>

			</div>
		);
	}
}
