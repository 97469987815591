(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("moment"), require("dyna-ui-field-wrapper"), require("dyna-ui-tooltip"), require("dyna-ui-button"), require("dyna-ui-picker-container"), require("dyna-ui-styles"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-ui-date", ["react", "moment", "dyna-ui-field-wrapper", "dyna-ui-tooltip", "dyna-ui-button", "dyna-ui-picker-container", "dyna-ui-styles"], factory);
	else if(typeof exports === 'object')
		exports["dyna-ui-date"] = factory(require("react"), require("moment"), require("dyna-ui-field-wrapper"), require("dyna-ui-tooltip"), require("dyna-ui-button"), require("dyna-ui-picker-container"), require("dyna-ui-styles"));
	else
		root["dyna-ui-date"] = factory(root["react"], root["moment"], root["dyna-ui-field-wrapper"], root["dyna-ui-tooltip"], root["dyna-ui-button"], root["dyna-ui-picker-container"], root["dyna-ui-styles"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_field_wrapper__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_tooltip__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_button__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_picker_container__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_styles__) {
return 