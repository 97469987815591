import * as React from "react";
import {IDynaTrip, IDynaCompany} from "dyna-travel-interfaces";
import {DynaTooltip, ETooltipDirection} from "dyna-ui-tooltip";

import {EColor, EViewType} from "../DynaTravelTripTimeline";

import {FlightInfo} from "dyna-travel-ui-components";

import {isDesktop} from "../utils/isDesktop";
import {styleMixer} from "../styleMixer";

import "./Flight.less";

export interface IFlightProps {
	viewType: EViewType;
	color: EColor;
	segment: IDynaTrip;
	messages: {
		operator: string;
		handler: string;
		baggageTypeBaggage: string;
		baggageTypeHandBag: string;
		perBag: string;
		noBags: string;
		unknownBags: string;
		totalMax: string;
	};
}

export class Flight extends React.Component<IFlightProps> {
	private readonly className: string = "dt-trip-timeline__flight";

	private renderCompany(company: IDynaCompany, isOperator: boolean): JSX.Element {
		const className: string = [
			`${this.className}__company`,
			`${this.className}__company--${isOperator ? 'operator' : 'handler'}`,
		].join(' ').trim();
		return (
			<div className={className}>
				<img className={`${this.className}__company__logo-icon`} src={company.logoUrl}/>
			</div>
		);
	}

	private renderCompanies(): JSX.Element {
		const {segment: {transport: {handler, operator}}, messages} = this.props;
		const operatorHandlerIsTheSame: boolean = handler.id === operator.id;
		const className: string = [
			`${this.className}__companies`,
			!operatorHandlerIsTheSame ? `${this.className}__companies--double` : '',
		].join(' ').trim();

		return (
			<div className={className}>
				{this.renderCompany(handler, false)}
				{!operatorHandlerIsTheSame ? this.renderCompany(operator, true) : null}
			</div>
		);
	}

	public render(): JSX.Element {
		const {
			viewType,
			color,
			segment,
			messages,
		} = this.props;

		const containerClassName: string = [
			this.className,
			'dt-trip-timeline__label_content',
			`${this.className}--view-type-${viewType}`,
			`${this.className}--color-${color}`,
		].join(' ').trim();

		return (
			<div className={containerClassName}>
				<DynaTooltip
					enabled={isDesktop}
					color={styleMixer(color).tooltipColor}
					delayCreationMs={1000}
					tooltipDirection={ETooltipDirection.SOUTH}
					tooltipContent={<FlightInfo segment={segment} messages={messages}/>}
					_debug_doNotHide={false}
				>
					{this.renderCompanies()}
				</DynaTooltip>
			</div>
		);
	}
}
