import * as React from "react";
import {CSSProperties} from "react";
import {IDynaBaggage} from "dyna-travel-interfaces";
import {DynaFontIcon} from "dyna-font-icon";
import {EBaggageType} from "../interfaces";

export const getBaggageCountLabel = (baggage: IDynaBaggage): string | JSX.Element => {
	switch (baggage && baggage.bags) {
		case 0:
			return <i className="fas fa-ban"/>;
		case null:
		case undefined:
			return "?";
		default:
			return String(baggage.bags);
	}
};

export const hasBaggage = (baggage: IDynaBaggage): boolean => {
	switch (baggage && baggage.bags) {
		case 0:
		case null:
		case undefined:
			return false;
		default:
			return true;
	}
};

export const getBaggageIcon = (className:string, baggageType: EBaggageType, sizeInPx: number): JSX.Element => {
	const baggageIconStyle: CSSProperties = {
		width: sizeInPx,
		height: sizeInPx,
	};

	switch (baggageType){
		case EBaggageType.BAGGAGE:
			return <DynaFontIcon className={className} fontClassName="fa fa-suitcase" style={baggageIconStyle}/>;
		case EBaggageType.HAND_BAG:
			return <DynaFontIcon className={className} fontClassName="fas fa-shopping-bag" style={baggageIconStyle}/>;
	}
};
