import {IError} from "dyna-interfaces";

import {IReduxAction, IReduxThunkAction} from "../../../redux/interfaces";
import {getAppMetadata} from "../api/app";
import {IHostAppStore} from "../index";

export const APP_CHANGE_LOCALE: string = 'app-CHANGE_LOCALE';
export const APP_CHANGE_CURRENCY: string = 'app-CHANGE_CURRENCY';

export const APP_UPDATE_ROUTE_INFO: string = 'app-UPDATE_ROUTE_INFO';

export const APP_REQUEST_METADATA: string = 'app-REQUEST_METADATA';
export const APP_RECEIVE_METADATA: string = 'app-RECEIVE_METADATA';
export const APP_VIEW_CENTER: string = 'app-VIEW_CENTER';
export const APP_MENU_UPDATE_IS_LOADING: string = 'app-APP_MENU_UPDATE_IS_LOADING';
export const APP_SHOW_ENGLISH_WITH_CODES_OPTION: string = 'app-APP_SHOW_ENGLISH_WITH_CODES_OPTION';

export const APP_IS_LOADING_FULL_SCREEN: string = 'app-APP_IS_LOADING_FULL_SCREEN';

export const appMainChangeLocale = (locale: string): IReduxAction => ({
  type: APP_CHANGE_LOCALE,
  payload: {locale},
});

export const appMainChangeCurrency = (currency: string): IReduxAction => ({
  type: APP_CHANGE_CURRENCY,
  payload: {currency},
});

export const appMainUpdateRouteInfo = (currentAppPath: string): IReduxAction => ({
  type: APP_UPDATE_ROUTE_INFO,
  payload: {currentAppPath},
});

export const appMainRequestMetadata = (): IReduxThunkAction<void, IHostAppStore, void> => {
  return (dispatch: (action: IReduxAction) => any) => {
    dispatch({type: APP_REQUEST_METADATA});
    getAppMetadata()
      .catch((error: IError) => {
        console.error('cannot load metadata', error);
      });
  };
};

export const appShowEnglishWithCodesOption = (): IReduxAction => ({
  type: APP_SHOW_ENGLISH_WITH_CODES_OPTION,
});

export const appMainViewCenter = (viewCenter: boolean): IReduxAction => ({
  type: APP_VIEW_CENTER,
  payload: {viewCenter},
});

export const appMainUpdateMainMenuIsLoading = (menuTag: string, isLoading: boolean): IReduxAction => ({
  type: APP_MENU_UPDATE_IS_LOADING,
  payload: {menuTag, isLoading},
});

export const appMainIsLoadingFullScreen = (isLoading: boolean, message?: string): IReduxAction => ({
  type: APP_IS_LOADING_FULL_SCREEN,
  payload: {isLoading, message},
});
