import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {EDynaTransport, IDynaCompany, IDynaPlace, IDynaTime, IDynaTrip} from "dyna-travel-interfaces";
import {
  ESegmentLollipopMode,
  SegmentLollipop,
  TransportTypeIcon
} from "dyna-travel-ui-components";
import {getTime} from "../utils/getTime";
import {IDynaTravelTripVerticalTimelineMessages} from "../messages";

import "./SegmentView.less";

export interface ISegmentViewProps {
	trip: IDynaTrip;
	messages: IDynaTravelTripVerticalTimelineMessages;
	formatTime: (time: Date) => string;
	formatShortDate: (date: Date) => string;
	formatDuration: (minutes: number) => string;
}

export class SegmentView extends React.Component<ISegmentViewProps> {
	private readonly className = dynaClassName("dttvt-segment-view");

	private renderCompany(label: string, company: IDynaCompany): JSX.Element {
		if (!company) return null;
		return (
			<div className={this.className("__company")}>
				<div className={this.className("__company-logo")}>
					<img className={this.className("__company-logo-image")} src={company.logoUrl}/>
				</div>
				<div className={this.className("__company-info")}>
					<span>{label} </span>
					<span className={this.className("__company-info-iata-code")}>{company.iataCode} </span>
					<span className={this.className("__company-info-name")}>{company.name.text} </span>
					{company.allianceName && company.allianceName.text ? <span className={this.className("__company-info-alliance-name")}>{company.allianceName.text} </span> : null}
				</div>
			</div>
		)
	}

	private renderTransportationNumbers(segment: IDynaTrip): JSX.Element {
		const className = dynaClassName(this.className("__transportation-numbers"));
		const {
			messages: {
				flightNo,
				transportationNumber,
			},
		} = this.props;
		const label: string = segment.transport.type === EDynaTransport.AIRPLANE ? flightNo : transportationNumber;
		return (
			<div className={className()}>
				<div className={className("__label")}>
					{label}
				</div>
				<div className={className("__numbers")}>
					{segment.transport.numbers && segment.transport.numbers.length ? segment.transport.numbers.join(" ") : <i className="fas fa-ban"/>}
				</div>
			</div>
		);
	}

	private getGoogleSearchHref(text: string): string {
		if (!text) return null;
		return `https://www.google.com/search?q=${text.replace(/ /g, "+")}`
	}

	private renderAirplaneTransportation(segment: IDynaTrip): JSX.Element {
		const {
			messages: {
				handler,
				operator,
			}
		} = this.props;
		const handlerOperatorIsTheSame: boolean = segment.transport.handler && segment.transport.handler.iataCode === segment.transport.operator.iataCode;
		return (
			<div className={this.className("__transportation-airplane")}>
				<div className={this.className("__transportation-airplane-details")}>
					{handlerOperatorIsTheSame
						? <div className={this.className("__transportation-airplane-handler-operator")}>
							{this.renderCompany(null, segment.transport.handler)}
						</div>
						: <div className={this.className("__transportation-airplane-handler-operator")}>
							{this.renderCompany(handler, segment.transport.handler)}
							{this.renderCompany(operator, segment.transport.operator)}
						</div>
					}
					<div className={this.className("__transportation-airplane-model")}>
						<a href={this.getGoogleSearchHref(segment.transport.model)} target="_blank">{segment.transport.model}</a>
					</div>
				</div>
				<div className={this.className("__transportation-airplane-misc")}>
					{this.renderTransportationNumbers(segment)}
				</div>
			</div>
		);
	}

  private isConnectionInSameAirport(segment: IDynaTrip): boolean {
    return segment.origin.name.codeName === segment.destination.name.codeName;
  }

	private renderWaitTransportation(segment: IDynaTrip): JSX.Element {
		const {
			messages: {
				connectInAirport,
				changeAirport,
			},
		} = this.props;
    const exclamations = Array(3).fill(<i className="fa fa-exclamation-triangle"/>);

		return (
			<div className={this.className("__transportation-wait-info")}>
        {this.isConnectionInSameAirport(segment)
					? <div className={this.className("__transportation-wait-info__in-airport")}>{connectInAirport}</div>
          : <div
            className={this.className("__transportation-wait-info__change-airport")}>{exclamations} {changeAirport} {exclamations}</div>}
			</div>
		);
	}

	private renderTransportation(segment: IDynaTrip): JSX.Element {
		switch (segment.transport.type) {
			case EDynaTransport.AIRPLANE:
				return this.renderAirplaneTransportation(segment);
			case EDynaTransport.WAIT:
				return this.renderWaitTransportation(segment);
			default:
				console.warn(`internal warn: #082462053 renderTransportation: unknown how to render the transportation type [${segment.transport.type}], this type was unexpected`);
				return null;
		}
	}

	private renderFlightTimeInfo(segment: IDynaTrip): JSX.Element {
		if (segment.transport.type !== EDynaTransport.AIRPLANE) return null;
		const {
			formatDuration,
		} = this.props;

		return (
			<div className={this.className("__transportation-flight-time-info")}>
				<div className={this.className("__transportation-flight-time-info--time")}>{formatDuration(segment.stats.durationInMin)}</div>
			</div>
		);
	}

	private renderSegmentWaitInfo(segment: IDynaTrip): JSX.Element {
		const className = dynaClassName(this.className("__transportation-segment-wait"));
		if (segment.transport.type !== EDynaTransport.WAIT) return null;
		const {
			messages: {
				longWait,
				overNight,
			},
			formatDuration,
		} = this.props;

		return (
			<div className={className()}>
				<div className={className("__wait-time")}>{formatDuration(segment.stats.waitTimeInMin)}</div>
				{segment.stats.longWaitTime ? <div className={className("__long-wait")}><i className="far fa-clock"/> {longWait}</div> : null}
				{segment.stats.nightLongWaitingTimes ? <div className={className("__over-night")}><i className="fas fa-moon"/> {overNight}</div> : null}
			</div>
		);
	}

	private renderPlace(place: IDynaPlace): JSX.Element {
		return (
			<div className={this.className("__place-info")}>
				<span className={this.className("__place-info__code-name")}>{place.name.codeName} </span>
				<span className={this.className("__place-info__city")}>{place.city.name.text} </span>
				<span className={this.className("__place-info__country")}>{place.country.name.text} </span>
				<span className={this.className("__place-info__flag")}><img src={place.flagUrl}/> </span>
			</div>
		);
	}

	private lastSegmentDate: IDynaTime = null;

	private renderSegmentTime(time: IDynaTime): JSX.Element {
		const {
			formatTime,
			formatShortDate,
		} = this.props;

		const isDateDifferent: boolean = this.lastSegmentDate && this.lastSegmentDate.localDate !== time.localDate;
		this.lastSegmentDate = time;

		return (
			<div className={this.className("__segment-time")}>
				{isDateDifferent ? <div>{formatShortDate(new Date(time.localDate))}</div> : null}
				<div>{formatTime(getTime(time.localTime))}</div>
			</div>
		);
	}

	private renderSegmentOriginDestinationVerticalLine(segment: IDynaTrip): JSX.Element {
		const cs = dynaClassName(this.className("__segment_lines"));
		return (
      <table className={cs()}>
        <tbody>
				<tr className={cs("__line")}>
          <th><SegmentLollipop mode={ESegmentLollipopMode.BEGIN}/></th>
          <th>{this.renderSegmentTime(segment.start)}</th>
          <th>{this.renderPlace(segment.origin)}</th>
				</tr>
				<tr className={cs("__line")}>
          <th><SegmentLollipop mode={ESegmentLollipopMode.LINE}/></th>
          <th></th>
          <th>{this.renderSegmentWaitInfo(segment)}</th>
				</tr>
				<tr className={cs("__line")}>
          <th><SegmentLollipop mode={ESegmentLollipopMode.LINE}/></th>
          <th></th>
          <th>{this.renderFlightTimeInfo(segment)}</th>
				</tr>
				<tr className={cs("__line")}>
          <th><SegmentLollipop mode={ESegmentLollipopMode.END}/></th>
          <th>{this.renderSegmentTime(segment.end)}</th>
          <th>{this.renderPlace(segment.destination)}</th>
				</tr>
        </tbody>
      </table>
		);
	}

	private renderSegment(segment: IDynaTrip, index: number): JSX.Element {
		const csSegmentTable = dynaClassName(this.className("__segment-table"));
		return (
			<div className={csSegmentTable(` --transportation-type-${segment.transport.type}`)} key={index}>
				<div className={csSegmentTable("-icon-column")}>
          {segment.transport.type === EDynaTransport.WAIT && !this.isConnectionInSameAirport(segment)
            ? <i className={"fas fa-exchange-alt " + this.className("__transportation-icon", "fas fa-exchange-alt")}/>
            : <TransportTypeIcon className={this.className("__transportation-icon")} transportType={segment.transport.type}/>
          }
				</div>
				<div className={csSegmentTable("-details-column")}>
					{this.renderTransportation(segment)}
					{this.renderSegmentOriginDestinationVerticalLine(segment)}
				</div>
			</div>
		);
	}

	public render(): JSX.Element {
		const {
			trip,
		} = this.props;

		this.lastSegmentDate = null;

		return (
			<div className={this.className()}>
				{trip.segments.map(this.renderSegment.bind(this))}
			</div>
		);
	}
}
