import {DynaObjectCompressVersion, IDecompressObjectResult} from "dyna-compress-text";
import {IDynaTripRequest, ERoundTripType} from "dyna-travel-interfaces";

const currentCompressVersion: string = "v1";
const compressor = new DynaObjectCompressVersion({
	v1: {
		objectPattern: {
			"roundTripType": ERoundTripType.RETURN,
			"routes": [
				{
					"origin": {
						"type": "AIRPORT",
						"name": {
							"codeName": "AAH",
							"name": {
								"text": "Aachen/Merzbruck",
							},
						},
						"city": {
							"name": {
								"text": "Aachen",
							},
						},
						"country": {
							"name": {
								"text": "Germany",
							},
						},
					},
					"destination": {
						"type": "AIRPORT",
						"name": {
							"codeName": "AAL",
							"name": {
								"text": "Aalborg Airport",
							},
						},
						"city": {
							"name": {
								"text": "Aalborg",
							},
						},
						"country": {
							"name": {
								"text": "Denmark",
							},
						},
					},
					"departDate": {
						"date": "2018-03-24T23:00:00.000Z",
					},
				},
			],
			"directOnly": false,
			"transports": [
				"AIRPLANE",
			],
			"tripClass": "ECONOMY",
			"passengers": [
				{
					"type": "ADULT",
				},
			],
			"un": "0.2342",
		},
		commonTexts: [
			// dev note: add always new strings at the end ONLY!
			`127.0.0.1"`,
			`127.0.0.1",`,
			`RETURN`,
			`ONE_WAY`,
			`MULTI_TRIP`,
			`AIRPLANE`,
			`AIRPORT`,
			`ECONOMY`,
			`BUSINESS`,
			`ADULT`,
			`CHILD`,
			`INFANT`,
			`Airport`,
			`airport`,
			`International`,
			`international`,
			`United`,
			// if we improve the compressor, we might add the date number as well
			// ...Array(20).fill(null).map((v, i) => String(2018 + i)),
			// ...Array(12).fill(null).map((v, i) => '-' + String(1 + i)),
			// ...Array(31).fill(null).map((v, i) => '-' + String(1 + i)),
		],
	},
});

export const serializeTripRequest = (requestTrip: IDynaTripRequest): string => {
	return encodeURIComponent(compressor.compress(currentCompressVersion, requestTrip));
};

export const deserializeTripRequest = (serializedRequestTrip: string): IDynaTripRequest => {
	if (!serializedRequestTrip) return null;
	const decompressResult: IDecompressObjectResult = compressor.decompress(decodeURIComponent(serializedRequestTrip));
	if (decompressResult.errors.length === 0) {
		return decompressResult.obj;
	}
	else {
		console.error('tripRequestSerialize: cannot decompressed the text', {compressedRequestTrip: serializedRequestTrip, decompressResult});
		return null;
	}
};
