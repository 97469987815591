import {ERequestMethod} from "dyna-interfaces";
import {request} from '../../../network';
import {apiConfig} from '../../../settings/api';

export const getAppMetadata = (): Promise<any> => {
	return request<any>({
		method: ERequestMethod.GET,
		url: `${apiConfig.address}/app/metadata`,
	});
};
