import * as React from 'react';
import {IError} from "dyna-interfaces";
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {DynaCMSClient} from "../dyna-cms-client/DynaCMSClient";

import {LoadingBox} from "../../components/ui/loadingBox/LoadingBox";
import {DynaRenderHTML} from "../dyna-render-html/DynaRenderHTML";

import "./DynaCMSViewer.css";

export interface IDynaCMSViewerProps {
	className?: string;
	dynaCMSClient: DynaCMSClient;
	contentName: string;
}

export interface IDynaCMSViewerState {
	content: string;
	loadError: boolean;
}

export class DynaCMSViewer extends React.Component<IDynaCMSViewerProps, IDynaCMSViewerState> {
	static defaultProps: IDynaCMSViewerProps = {
		className: "",
		dynaCMSClient: null,
		contentName: null,
	};

	constructor(props: IDynaCMSViewerProps, context: any) {
		super(props, context);
		this.state = {
			content: null,
			loadError: false,
		};
	}

	public componentDidMount(): void {
		const {
			dynaCMSClient,
			contentName,
		} = this.props;

		dynaCMSClient.fetch(contentName)
			.then((content: string) => {
				this.setState({content});
			})
			.catch((error: IError) => {
				this.setState({loadError: true});
				console.error(`error loading dyna cms content [${contentName}]`, error);
			});
	}

	private readonly className: DynaClassName = dynaClassName("dyna-cms-viewer");

	private renderContent(): JSX.Element {
		const {
			content,
			loadError,
		} = this.state;

		if (loadError) return <h3>load error, try to refresh</h3>;

		if (!content) return LoadingBox("loading...");

		return <DynaRenderHTML html={content}/>;
	}

	public render(): JSX.Element {
		return (
			<div className={this.className.root(this.props)}>
				{this.renderContent()}
			</div>
		);
	}
}

