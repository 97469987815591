import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";

export interface IDynaRenderHTMLProps {
  className?: string;
  html: string;
}

export class DynaRenderHTML extends React.Component<IDynaRenderHTMLProps> {
  static defaultProps: IDynaRenderHTMLProps = {
    className: "",
    html: "",
  };

  constructor(props: IDynaRenderHTMLProps, context: any) {
    super(props, context);
    this.state = {};
  }

  public shouldComponentUpdate(nextProps: IDynaRenderHTMLProps): boolean {
    return this.props.html !== nextProps.html;
  }

  private readonly className: DynaClassName = dynaClassName("dyna-render-html");

  private sanitizeHtml(htmlText: string): string {
    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = htmlText;
    let scripts: NodeListOf<HTMLScriptElement> = div.querySelectorAll('script');
    let i: number = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  }

  public render(): JSX.Element {
    const {html} = this.props;
    return (
      <div
        className={this.className.root(this.props)}
        dangerouslySetInnerHTML={{__html: this.sanitizeHtml(html)}}
      />
    );
  }
}
