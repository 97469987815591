import * as React from "react";
import {
  IDayFlightsViewData,
} from "../stores/flightInfoStore/interfaces";

import { cn } from "../utils/cn";
import * as styles from "./renderDay.module.less";

export interface IRenderDay {
  inMonth: boolean;
  dayFlightsViewData: IDayFlightsViewData;
}

const fontIconStyle: React.CSSProperties = {
  fontSize: 'inherit', // This fixes the issue where the animated fa-refresh has 3rem size in the App only
};

export const renderDay = (props: IRenderDay): JSX.Element => {
  const {
    inMonth,
    dayFlightsViewData: {
      dateNumber,
      qualityIndex,
      showPriceQualityIndex,
      priceQualityIndex,
      showPriceValues,
      priceValues,
    },
  } = props;

  const showTriangleClassName = inMonth && showPriceQualityIndex && styles.show;
  const showPricesClassName = inMonth && showPriceValues && styles.show;

  const qualityIndexLowerCase = qualityIndex.toLocaleLowerCase();

  const priceValueLabel = (() => {
    const priceValue = priceValues[qualityIndexLowerCase];
    switch (priceValue) {
      case 'isFrontendLoading':
      case 'isBackendLoading':
        return <i className="fa fa-refresh fa-spin fa-3x fa-fw" style={fontIconStyle}/>;
      case 'noData':
        return <i className="fa fa-search" aria-hidden="true" style={fontIconStyle}/>;
      case 'noCurrencies':
        return 'c.n/a';
      default:
        return priceValue;
    }
  })();

  return (
    <div className={styles.root}>

      <div className={cn(styles.priceIndex, showTriangleClassName, styles[`priceIndex${priceQualityIndex[qualityIndexLowerCase]}`])}/>

      <div className={cn(styles.dayNumber, showPricesClassName)}>
        {dateNumber}
      </div>

      <div className={cn(styles.price, showPricesClassName)}>
        {priceValueLabel}
      </div>
    </div>
  );
};
