import createLogger from 'redux-logger';

// import {crashReporter} from './middlewares/crashReporter';

import {appConfig, EAppMode} from "../settings/app";

export const middlewares: any[] = [
  // add here you middlewares
  // crashReporter,
];

if (appConfig.mode !== EAppMode.PRODUCTION && appConfig._debug_ReduxConsole) {
  middlewares.push(createLogger);
}
