export interface IDynaTravelFilterTripsMessages {
	filter: string;
	reset: string;
	totalAmount: string;
	duration: string;
	filtered: string;
	ofTotal: string;
	noFilter: string;
	departure: string;
	arrival: string;
}

export const defaultMessages: IDynaTravelFilterTripsMessages = {
	filter: "Filter",
	reset: "Reset filter",
	totalAmount: "Total amount",
	duration: "Duration (in minutes)",
	filtered: "filtered",
	ofTotal: "of total",
	noFilter: "none",
  departure: 'Departure',
  arrival: 'Arrival',
};
