import * as ReactGA from 'react-ga';
import {appConfig, EAppMode} from "../settings/app";
import {analyticsConfig} from "../settings/analytics";

declare const jest: any;

class Analytics {
  constructor() {
    const options = typeof jest !== 'undefined' ? {testMode: true} : {};
    if (!this.active) return;

    ReactGA.initialize(analyticsConfig.GATrackingCode, options);
    this.pageView();
  }

  public pageView(path?: string): void {
    if (!this.active) return;
    const pathForAnalytics = path || window.location.pathname + window.location.search;
    ReactGA.pageview(pathForAnalytics);
  }

  public sendEvent(category: string, action: string, label: string): void {
    const analyticsEvent = {
      category,
      action,
      label,
    };
    ReactGA.event(analyticsEvent);
  }

  private get active(): boolean {
    if (!analyticsConfig.GATrackingCode) return false;
    if (analyticsConfig.debug_activeOnDevelopment) return true;

    return appConfig.mode === EAppMode.PRODUCTION;
  }
}

export const analytics = new Analytics();
