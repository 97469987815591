import {DynaHighlightText} from "dyna-highlight-text";
import {EDynaPlace, IDynaPlace} from "dyna-travel-interfaces";
import {guid} from "dyna-guid";
import {DynaAutoComplete, EColor, EMode, ESize, EStyle, IAutoCompleteValue} from "dyna-ui-autocomplete";
import * as React from "react";

import {TContent} from "../interfaces";

import {airportToString} from "../utils/airportToString";
import {faIcon} from "../utils/faIcon";

const selectAirportStyles: any = require('./SelectAirportControl.module.less');

export interface ISelectAirportProps {
  className: string;
  name: string;
  mode: EMode;
  style: EStyle,
  color: EColor,
  size: ESize,
  label: TContent;
  value: string;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  suggestedAirports: IDynaPlace[];
  isLoading: boolean;
  validationMessage: TContent;
  onChange: (name: string, value: string, airport: IDynaPlace) => void;
}

export type TContent = string | JSX.Element;

export class SelectAirportControl extends React.Component<ISelectAirportProps> {
  static defaultProps: ISelectAirportProps = {
    className: '',
    name: '',
    mode: EMode.EDIT,
    style: EStyle.INLINE_ROUNDED,
    color: EColor.WHITE_BLACK,
    size: ESize.MEDIUM,
    label: null,
    value: '',
    inputProps: {},
    suggestedAirports: [],
    isLoading: false,
    validationMessage: null,
    onChange: (name: string, value: string, airport: IDynaPlace) => undefined,
  };
  private internalHtmlId: string;

  constructor(props: ISelectAirportProps) {
    super(props);
    this.internalHtmlId = `select-airport-control--${props.name || 'noname'}--${guid()}`
  }

  private handleChange = (name: string, acValue: IAutoCompleteValue<IDynaPlace>): void => {
    this.props.onChange(name, acValue.value, acValue.item);
  };

  private renderHighLightText = (text:string):JSX.Element => {
    return <span className={selectAirportStyles.highlighted}>{text}</span>
  };

  private _cheapGuid = 0;
  private get cheapGuid(): number {
    return this._cheapGuid++;
  }

  private static renderOptionIcon(airport: IDynaPlace): JSX.Element {
    switch (airport.type) {
      case EDynaPlace.AIRPORT:
        return faIcon('plane');
      case EDynaPlace.CITY:
        return faIcon('building');
      default:
        return faIcon('globe');
    }
  }

  private renderAirportOption = (airport: IDynaPlace, isFocused: boolean): JSX.Element => {
    const {
      value,
    } = this.props;
    const className: string = [
      selectAirportStyles.airportDropDownOption,
      isFocused ? selectAirportStyles.isFocused : '',
    ].join(' ').trim();
    const {
      name: {
        codeName: iata,
        name: {text: airportName},
      },
      city: {name: {text: city}},
      country: {name: {text: country}},
    } = airport;
    const key=`${iata}-${airportName}-${city}`;

    return (
      <div key={key} className={className}>

        <div className={selectAirportStyles.icon}>
          {SelectAirportControl.renderOptionIcon(airport)}
        </div>

        <div className={selectAirportStyles.data}>
          <div className={selectAirportStyles.header}>
            <DynaHighlightText
              sourceText={iata.toUpperCase()}
              highlightWords={value}
              onHighlightRender={this.renderHighLightText}
            />
            {" "}
            <DynaHighlightText
              sourceText={airportName}
              highlightWords={value}
              onHighlightRender={this.renderHighLightText}
            />
          </div>
          <div className={selectAirportStyles.footer}>
            {!!airport.flagUrl && <img className={selectAirportStyles.flag} src={airport.flagUrl}/>}
            <DynaHighlightText
              sourceText={city}
              highlightWords={value}
              onHighlightRender={this.renderHighLightText}
            />
            <span style={{whiteSpace: 'pre'}}>, </span>
            <DynaHighlightText
              sourceText={country}
              highlightWords={value}
              onHighlightRender={this.renderHighLightText}
            />
          </div>
        </div>

      </div>
    );
  };

  public render(): JSX.Element {
    const {
      className,
      name,
      mode,
      style, color, size,
      label,
      suggestedAirports,
      value,
      inputProps,
      isLoading,
      validationMessage,
    } = this.props;

    return (
      <DynaAutoComplete
        className={className}
        id={this.internalHtmlId}
        name={name}
        mode={mode}
        style={style}
        color={color}
        size={size}
        label={label}
        isLoading={isLoading}
        value={value}
        selectOnBlur
        inputProps={inputProps}
        items={!!value ? suggestedAirports : []}
        validationMessage={validationMessage}
        getItemValue={(item: IDynaPlace) => airportToString(item)}
        renderItem={this.renderAirportOption}
        onChange={this.handleChange}
      />
    );
  }
}
