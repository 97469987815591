import {IDynaClientModule} from "../modulesLoader";
import {DYNA_TRAVEL_MODULE_ROUTES} from "./routes/dynaTravelModuleRoutes";
import {reducer, IDynaTravelState} from "./state/reducer";
import {IAppStore} from "../../redux";
import {messages} from "./locales";
import {IAppState} from "../Application/state/reducer";

export interface IHostAppStore extends IAppStore {
	dynaTravelModule: IDynaTravelState;
	app: IAppState;
}

export const dynaTravelModule: IDynaClientModule = {
	name: 'dyna-travel',
	routes: DYNA_TRAVEL_MODULE_ROUTES,
	reducers: {
		dynaTravelModule: reducer,
	},
	translation: messages,
	start: () => {
		// app is started from the logic/index.ts as export
	},
};

// dev note: force the webpack to reload a package, asking a export that doesn't exist
// import {xxxx} from "dyna-animation";
// import {xxxx} from "dyna-travel-ui-filter-trips";