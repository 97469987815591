import {EColor as EButtonColor} from "dyna-ui-button";
import {EProduct} from "../../../settings/app";

export interface IStyleMixer {
	primaryButtonColor: EButtonColor;
	secondaryButtonColor: EButtonColor;
}

export const styleMixer = (product: EProduct): IStyleMixer => {
	switch (product) {
		case EProduct.DIE_TO_FLY:
			return {
				primaryButtonColor: EButtonColor.RED_WHITE,
				secondaryButtonColor: EButtonColor.WHITE_RED,
			};
		default:
		case EProduct.IM_HOLIDAY:
			return {
				primaryButtonColor: EButtonColor.ORANGE_WHITE,
				secondaryButtonColor: EButtonColor.WHITE_ORANGE,
			};
	}
};