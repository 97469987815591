import {IRoute} from "../interfaces/routes";
import {IDynaTranslation, IDynaTranslationLocaleOption} from "../packages/dyna-translation-manager/DynaTranslationManager";
import {reducers} from "../redux/reducersConfig";
import {addTranslationMessage, appTranslations, loadAppTranslations} from "../locales/translations";

import {applicationModule} from "./Application";
import {dynaTravelModule} from "./DynaTravel";

export interface IDynaClientModule {
  name: string;
  routes?: { [routeName: string]: IRoute<any> };
  reducers?: {
    [stateName: string]: any;
  };
  start?: () => void;
  translation?: IDynaTranslation;
}

export const appRoutes: IRoute<any>[] = [];

const loadModule = (module: IDynaClientModule) => {
  // load routes
  if (module.routes) {
    const last: IRoute<any> = appRoutes.pop();
    Object.keys(module.routes)
      .map((routerName: string) => module.routes[routerName])
      .forEach((route: IRoute<any>) => appRoutes.push(route));
    if (last) appRoutes.push(last);
  }

  // add reducers
  if (module.reducers) {
    Object.keys(module.reducers).forEach((stateName: string) => {
      reducers[stateName] = module.reducers[stateName];
    });
  }

  // add the translation
  if (module.translation) {
    // locale
    module.translation.locales.forEach((locale: IDynaTranslationLocaleOption) => {
      if (appTranslations.localeOptions.find((localeOption: IDynaTranslationLocaleOption) => localeOption.locale === locale.locale)) {
        appTranslations.localeOptions.push(locale);
      }
    });
    // messages
    Object.keys(module.translation.messages).forEach((key: string) => {
      Object.keys(module.translation.messages[key]).forEach((locale: string) => {
        addTranslationMessage(module.name, key, locale, module.translation.messages[key][locale]);
      });
    });
  }

  // start
  if (module.start) module.start();
};

loadModule(applicationModule);
loadModule(dynaTravelModule);

loadAppTranslations();



