import {round} from "dyna-loops";
import {IDynaPrice} from "dyna-travel-interfaces";

import {currencies} from "./currenciesConverter";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
// https://www.iso.org/iso-4217-currency-codes.html
// https://github.com/globalizejs/globalize#currency-module

export const convertPrice = (price: IDynaPrice, currency?: string): number => {
	// https://github.com/yahoo/react-intl/wiki/API#formatnumber
	if (!price) return -1;
	if (price.value == null) return -1;
	if (price.currency == null) return round(price.value, 2);  // the currency might be unknown, so return draftly the value
	const targetCurrency: string = currency || price.currency;

	return currencies.convert(price.value, price.currency, targetCurrency);
};

