import * as React from 'react';
import * as moment from "moment";
import {InjectedIntl} from 'react-intl';
import {dynaClassName} from "dyna-class-name";
import {EDeviceCategory, hasEnv} from "dyna-detect-env";

import {
  ETripQualityIndex,
  IDynaPlace,
  IDynaPrice,
  IDynaTripProposal,
  IDynaTripRequest,
  IDynaTripRequestRoute,
  ITripQualityData,
} from "dyna-travel-interfaces";

import {
  DynaSelectAirTrip,
  EMode, EFieldSize,
  getDate0,
} from "dyna-travel-ui-select-air-trip";

import {
  DynaTravelListProposals,
  IDynaTripFilter,
} from "dyna-travel-ui-list-proposals";

import {
  DynaNotifications, EDynaNotificationType, IDynaNotification,
} from "../../../../packages/dyna-ui-notifications/DynaNotifications";

import {AppTitle} from "../../../../components/ui/appTitle/appTitle";
import {IAppStoreApi} from "../../../../dynadux/appStore";
import {ISearchFlightMessages} from "./messages";
import {appConfig, EProduct} from "../../../../settings/app";
import {dynaTravelModuleConfig} from "../../../../settings/dynaTravelModuleConfig";
import {FlightProposalDetails} from "../FlightProposalDetails/FlightProposalDetails.loadable";
import {deserializeTripRequest} from "../../utils/tripRequestSerialize";
import {convertPrice} from "../../../../utils/convertPrice";
import {formatMessage} from "../../../../utils/formatMessage";
import {formatPrice} from "../../../../utils/formatPrice";
import {
  formatDate,
  formatDateWithDayName,
  formatDateWithDayNameFromString,
  formatDuration,
  formatTimeFromString,
} from "../../../../utils/formatDate";
import {scrollToElement, scrollToTop} from "../../../../utils/scrollToTop";

import './SearchFlightsComponent.css';

export interface ISearchFlightsComponentProps {
  intl?: InjectedIntl;
  appStore?: IAppStoreApi;

  product?: EProduct;
  searchFlightProcessId?: string;
  currency?: string;
  mode?: EMode;
  messages?: ISearchFlightMessages;

  isLoading?: boolean;
  loadingProgressPercent?: number;
  loadingProgressMessageId?: string;
  tripRequestIdLastSearched?: string;
  tripRequestFromURL?: string;
  tripRequest?: IDynaTripRequest;
  errorMessageId?: string;
  errorMessages?: string[];

  sortByTripQualityIndex?: ETripQualityIndex;
  tripsFilter?: IDynaTripFilter;
  allTripProposals?: IDynaTripProposal[];   // array of the listed proposals (might be not all)
  tripProposals?: IDynaTripProposal[];      // array of the listed proposals (might be not all)
  tripProposalsAllCount?: number;           // count of all loaded proposals
  moreTripProposalsCount?: number;          // count of unlisted (not viewed yet) proposals
  noTripsFound?: boolean;
  isLoadingMore?: boolean;
  requestIsCanceled?: boolean;
  tripProposalsExpired?: boolean;
  showExpiredBalloon?: boolean;
  requestTripDirectFlightsOnly?: boolean;

  tripQualityBest?: ITripQualityData;
  tripQualityCheap?: ITripQualityData;
  tripQualityFast?: ITripQualityData;

  viewProposalDetailsTpid?: string;     // load this proposal for the details to the state
  proposalDetailsContent?: IDynaTripProposal;

  onAirportSearch?: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange?: (tripRequest: IDynaTripRequest) => void;
  onClearForm?: () => void;
  navigateToSearch?: (triRequest: IDynaTripRequest) => void;
  onSearch?: (triRequest: IDynaTripRequest) => void;
  navigateToNewSearch?: () => void;
  onNewSearch?: () => void;
  onCancelTripRequest?: (reset: boolean) => void;
  onSortByQuality?: (qualityTripindex: ETripQualityIndex) => void;
  onLoadMoreProposals?: () => void;
  onSelectToday?: () => void;
  onSelectNextWeekend?: () => void;
  navigateToShowProposalDetails?: (triRequest: IDynaTripRequest, tpid: string) => void;
  navigateToHideProposalDetails?: (triRequest: IDynaTripRequest) => void;
  onRefreshLaterClick?: () => void;
  onRefreshNowClick?: () => void;
  onFilterChange?: (filter: IDynaTripFilter) => void;
  onFilteredTripProposals?: (tripProposals: IDynaTripProposal[]) => void;
}

interface ISearchFlightsComponentState {
  notifications: IDynaNotification[];
}

export class SearchFlightComponent extends React.Component<ISearchFlightsComponentProps, ISearchFlightsComponentState> {
  constructor(props: ISearchFlightsComponentProps, context: any) {
    super(props, context);

    this.state = {
      notifications: [],
    };
  }

  private className = dynaClassName("dyna-travel-app-search-flights");
  private appTitle = new AppTitle();
  private formatPrice = formatPrice.bind(null, this.props.intl);
  private formatTimeFromString = formatTimeFromString.bind(null, this.props.intl);
  private formatDate = formatDate.bind(null, this.props.intl);
  private formatDateWithName = formatDateWithDayName.bind(null, this.props.intl);
  private formatDateWithDayNameFromString = formatDateWithDayNameFromString.bind(null, this.props.intl);
  private formatDuration = formatDuration.bind(null, this.props.intl);

  public componentDidMount(): void {
    const {
      tripRequestFromURL,
      onChange,
      onSearch,
    } = this.props;

    const userTripRequest: IDynaTripRequest = deserializeTripRequest(tripRequestFromURL);

    // if there is a userTripRequest, at this point is coming from the url, save it to the state
    if (userTripRequest) {
      onChange(userTripRequest);
      if (this.validateRequestTripDates(userTripRequest, true)) {
        onSearch(userTripRequest);
        scrollToTop();
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<ISearchFlightsComponentProps>, prevState: Readonly<ISearchFlightsComponentState>, snapshot?: any): void {
    const {
      isLoading,
      tripRequestFromURL,
      tripRequestIdLastSearched,
      onNewSearch,
      onCancelTripRequest,
    } = this.props;

    const userTripRequest: IDynaTripRequest = deserializeTripRequest(tripRequestFromURL);

    if (
      !userTripRequest &&             // there is no trip request (app logo button (go home button) is clicked)
      !!tripRequestIdLastSearched &&  // and previously there was something else
      isLoading                       // and is loading
    ) {
      onCancelTripRequest(true);
      onNewSearch();
      scrollToTop();
    }
  }

  public componentWillUnmount(): void {
    const {
      onNewSearch,
    } = this.props;
    onNewSearch();  // clear the state of the search to be ready when it comes back
  }

  private validateRequestTripDates(requestTrip: IDynaTripRequest, addNotification: boolean): boolean {
    let isValid: boolean = true;
    const firstDateValue: any = requestTrip.routes[0] && requestTrip.routes[0].departDate.date;
    const firstDate: moment.Moment = moment(firstDateValue);

    if (firstDate.isBefore(getDate0())) {
      isValid = false;
      if (addNotification) this.addNotification(EDynaNotificationType.ERROR, "MSG_0738", "The selected dates are old");
    }

    let areDatesSequential: boolean = true;
    let scanDate: Date | number = new Date("1970-01-01");
    requestTrip.routes.forEach((route: IDynaTripRequestRoute) => {
      if (moment(route.departDate.date).isBefore(scanDate)) areDatesSequential = false;
      scanDate = new Date(route.departDate.date);
    });
    if (!areDatesSequential) {
      isValid = false;
      if (addNotification) this.addNotification(EDynaNotificationType.ERROR, "MSG_0741", "The dates are not sequential");
    }

    return isValid;
  }

  private addNotification(type: EDynaNotificationType, messageId: string, defaultMessage: string = null): void {
    const { intl } = this.props;

    const exists: boolean = this.state.notifications.reduce((acc: boolean, notification: IDynaNotification) => {
      if (acc) return acc; // exit, no need to process
      if (notification.type === type && notification.label === formatMessage({
        intl,
        id: messageId,
        defaultMessage,
      })) acc = true;
      return acc;
    }, false);
    if (exists) return; // the notification already exists, exit

    this.setState({
      notifications: this.state.notifications.concat({
          type,
        label: formatMessage({ intl, id: messageId, defaultMessage }),
        },
      ),
    });
  }

  private handleRequestTripChange = (tripRequest: IDynaTripRequest): void => {
    const { onChange } = this.props;
    onChange(tripRequest);
  };

  private handleRequestTripSearchClick = (tripRequest: IDynaTripRequest): void => {
    const { navigateToSearch, onSearch } = this.props;
    if (this.validateRequestTripDates(tripRequest, true)) {
      this.setState({ notifications: [] });
      navigateToSearch(tripRequest);
      onSearch(tripRequest);
      setTimeout(() => scrollToElement(".dtlp-loading-progress-bar", false), 500);
    }
  };

  private handleRequestTripClearClick = (): void => {
    const { onClearForm } = this.props;
    this.setState({ notifications: [] });
    onClearForm();
  };

  private handleRequestTripNewSearchClick = (): void => {
    const {
      navigateToNewSearch,
      onNewSearch,
    } = this.props;
    navigateToNewSearch();
    this.setState({ notifications: [] });
    onNewSearch();
  };

  private handleTripProposalClick = (tripProposal: IDynaTripProposal): void => {
    const {
      tripRequest,
      navigateToShowProposalDetails,
    } = this.props;
    navigateToShowProposalDetails(tripRequest, tripProposal.tpid);
  };

  private handleCloseDetailsProposalClick = (): void => {
    const {
      tripRequest,
      navigateToHideProposalDetails,
    } = this.props;
    navigateToHideProposalDetails(tripRequest);
  };

  private handleFilteredProposals = (tripProposals: IDynaTripProposal[]): void => {
    const {
      requestIsCanceled,
      onFilteredTripProposals,
    } = this.props;
    if (!requestIsCanceled) onFilteredTripProposals(tripProposals);
  };

  private handleFilterClose = (): void => {
    scrollToElement(".dtlp-loading-progress-bar", false);
  };

  private updateAppTitle(): void {
    const {
      isLoading,
      noTripsFound,
      tripRequest,
      tripProposals,
      messages,
    } = this.props;

    if ((isLoading || tripProposals.length) && tripRequest.routes.length) {
      const requestTripTitle: string =
        tripRequest.routes
          .map((route: IDynaTripRequestRoute) => {
            return `${route.origin.name.codeName} - ${route.destination.name.codeName}`;
          })
          .join(", ");
      this.appTitle.add(requestTripTitle);
    }
    if (noTripsFound) this.appTitle.add(messages.noTicketsFound);
    if (isLoading) this.appTitle.add(messages.isLoading);
  }

  public render(): JSX.Element {
    const {
      intl,
      appStore,
      messages,
      currency,

      product,
      searchFlightProcessId,
      mode,

      isLoading,
      loadingProgressMessageId,
      loadingProgressPercent,

      tripRequest,
      errorMessageId,
      errorMessages,

      sortByTripQualityIndex,
      tripsFilter,
      allTripProposals,
      tripProposals,
      tripProposalsAllCount,
      moreTripProposalsCount,
      noTripsFound,
      isLoadingMore,
      tripProposalsExpired,
      showExpiredBalloon,
      requestTripDirectFlightsOnly,

      tripQualityBest,
      tripQualityCheap,
      tripQualityFast,

      viewProposalDetailsTpid,
      proposalDetailsContent,

      onAirportSearch,

      onCancelTripRequest,
      onSortByQuality,
      onLoadMoreProposals,
      onSelectToday,
      onSelectNextWeekend,

      onRefreshLaterClick,
      onRefreshNowClick,

      onFilterChange,
    } = this.props;

    const {
      notifications,
    } = this.state;

    const className: string = this.className(
      "",
      `--product-${product}`,
    );

    const allNotifications = new Array<IDynaNotification>().concat(
      notifications,
      !!errorMessageId && {
        type: EDynaNotificationType.ERROR,
        label: formatMessage({intl, id: errorMessageId, defaultMessage: "network error"}),
      },
      (errorMessages || []).map(errorMessage => ({
        type: EDynaNotificationType.ERROR,
        label: errorMessage,
      })),
    )
      .filter(Boolean);

    this.updateAppTitle();

    return (
      <div className={className}>
        {this.appTitle.render()}
        <DynaSelectAirTrip
          product={product}
          mode={mode}
          messages={messages}
          isLoading={isLoading}
          fieldSize={EFieldSize.SMALL}
          tripRequest={tripRequest}
          formatDate={this.formatDateWithName}
          searchAirportFetchDebounceTimeout={250}
          datePickerFlightInfoConfig={{
            COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: dynaTravelModuleConfig.COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
            currency,
            loadMonths: 2,
            updatedWeeksAgo: 2,
            onCurrenciesLoad: () => appStore.currencies.getCurrencyRates(),
          }}
          onAirportSearch={onAirportSearch}
          onChange={this.handleRequestTripChange}
          onSearchClick={this.handleRequestTripSearchClick}
          onNewSearchClick={this.handleRequestTripNewSearchClick}
          onClearClick={this.handleRequestTripClearClick}
          _debug_calendarPickerStoreName=""
        />
        <DynaNotifications
          className="bottom-notifications"
          notifications={allNotifications}
        />
        <DynaTravelListProposals
          product={product}
          animationEnabled={hasEnv(EDeviceCategory.desktop)}
          currency={currency}
          messages={messages}
          tripRequest={tripRequest}
          passengers={tripRequest.passengers}
          isLoading={isLoading}
          isLoadingProgressMessage={formatMessage({
            intl,
            id: loadingProgressMessageId || errorMessageId,
            values: { tripProposalsCount: tripProposalsAllCount },
          })}
          proposalsExpired={tripProposalsExpired}
          showExpiredBalloon={showExpiredBalloon && !noTripsFound}
          loadProgressPercentValue={loadingProgressPercent}
          inLoadingError={!!errorMessageId}
          sortByTripQuality={sortByTripQualityIndex}
          tripQualityBest={tripQualityBest}
          tripQualityCheap={tripQualityCheap}
          tripQualityFast={tripQualityFast}
          tripsFilter={tripsFilter}
          allTripProposals={allTripProposals}
          tripProposals={tripProposals}
          hasMoreProposals={moreTripProposalsCount}
          requestTripDirectFlightsOnly={requestTripDirectFlightsOnly}
          noTripsFound={noTripsFound}
          isLoadingMore={isLoadingMore}
          formatPrice={this.formatPrice}
          formatTimeFromString={this.formatTimeFromString}
          formatDate={this.formatDate}
          formatFullDateFromString={this.formatDateWithDayNameFromString}
          formatDuration={this.formatDuration}
          convertPrice={(price: IDynaPrice, currency: string) => convertPrice(price, currency)}
          isLoadingIcon={appConfig.loadingSpinnerComponent}
          onLoadingCancelClick={() => onCancelTripRequest(false)}
          onSortByQualityClick={onSortByQuality}
          onLoadMoreProposals={onLoadMoreProposals}
          onTripProposalSelectClick={this.handleTripProposalClick}
          onSelectTodayClick={onSelectToday}
          onSelectNextWeekendClick={onSelectNextWeekend}
          onRefreshLaterClick={onRefreshLaterClick}
          onRefreshNowClick={onRefreshNowClick}
          onFilterTripsChange={onFilterChange}
          onFilteredTripProposals={this.handleFilteredProposals}
          onFiltersClose={this.handleFilterClose}
        />
        <FlightProposalDetails
          product={product}
          show={!!viewProposalDetailsTpid}
          searchFlightProcessId={searchFlightProcessId}
          tripProposal={proposalDetailsContent}
          onClose={this.handleCloseDetailsProposalClick}
          onRefreshClick={onRefreshNowClick}
        />
      </div>
    );
  }
}

