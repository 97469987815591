import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";

import "./ShareButtons.less";

export interface IShareButtonsProps {
  className?: string;

  label?: string | JSX.Element;

  webSiteName: string;
  webSiteDescription: string;

  websiteUrl: string;
  websiteLogoPreview?: string;      // for Pinterest and Wordpress only
  websiteTwitterAccount?: string;   // without the @

  style?: EStyle;

  showFacebook?: boolean;     // default true
  showTwitter?: boolean;      // default true
  showGooglePlus?: boolean;   // default true
  showTumblr?: boolean;
  showPinterest?: boolean;
  showPocket?: boolean;
  showReddit?: boolean;
  showLinkedIn?: boolean;
  showWordpress?: boolean;
  showPinboard?: boolean;
  showEmail?: boolean;
}

export enum EStyle {
  FLAT_BLACK = "FLAT_BLACK",
}

interface IIconStyleFiles {
  [style: string]: IIconFiles;
}

interface IIconFiles {
  email: string;
  facebook: string;
  googlePlus: string;
  linkedIn: string;
  pinboard: string;
  pinterest: string;
  pocket: string;
  reddit: string;
  tumplr: string;
  twitter: string;
  wordpress: string;
}

const icons: IIconStyleFiles = {
  [EStyle.FLAT_BLACK]: {
    email: require("./assets/flat_web_icon_set/black/Email.png"),
    facebook: require("./assets/flat_web_icon_set/black/Facebook.png"),
    googlePlus: require("./assets/flat_web_icon_set/black/Google+.png"),
    linkedIn: require("./assets/flat_web_icon_set/black/LinkedIn.png"),
    pinboard: require("./assets/flat_web_icon_set/black/Pinboard.png"),
    pinterest: require("./assets/flat_web_icon_set/black/Pinterest.png"),
    pocket: require("./assets/flat_web_icon_set/black/Pocket.png"),
    reddit: require("./assets/flat_web_icon_set/black/Reddit.png"),
    tumplr: require("./assets/flat_web_icon_set/black/Tumblr.png"),
    twitter: require("./assets/flat_web_icon_set/black/Twitter.png"),
    wordpress: require("./assets/flat_web_icon_set/black/Wordpress.png"),
  },
};

export class ShareButtons extends React.Component<IShareButtonsProps> {
  static defaultProps: IShareButtonsProps = {
    className: "",

    label: "Share",

    webSiteName: "",
    webSiteDescription: "",
    websiteUrl: "http://www.google.com",
    websiteLogoPreview: "",
    websiteTwitterAccount: "",

    style: EStyle.FLAT_BLACK,

    showFacebook: true,
    showTwitter: true,
    showGooglePlus: true,
    showTumblr: false,
    showPinterest: false,
    showPocket: false,
    showReddit: false,
    showLinkedIn: false,
    showWordpress: false,
    showPinboard: false,
    showEmail: false,
  };

  private readonly className: DynaClassName = dynaClassName("dyna-share-buttons-v001");

  public render(): JSX.Element {
    const {
      label,

      webSiteName,
      webSiteDescription,
      websiteUrl,
      websiteLogoPreview,
      websiteTwitterAccount,

      style,

      showFacebook,
      showTwitter,
      showGooglePlus,
      showTumblr,
      showPinterest,
      showPocket,
      showReddit,
      showLinkedIn,
      showWordpress,
      showPinboard,
      showEmail,
    } = this.props;
    const {} = this.state;

    const encodedWebSiteUrl: string = escape(websiteUrl);
    const encodedName: string = escape(webSiteName);
    const encodedDescription: string = escape(webSiteDescription);
    // based on: https://simplesharingbuttons.com/
    const iconFiles: IIconFiles = icons[style];

    return (
      <div className={this.className.root(this.props, `--style-${style}`)}>

        <div className={this.className("__label")}>{label}</div>

        <ul>
          {showFacebook ? <li>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedWebSiteUrl}&quote=${encodedName}`}
               title="Share on Facebook"
               target="_blank"
            >
              <img alt="Share on Facebook" src={iconFiles.facebook}/>
            </a>
          </li> : null}
          {showTwitter ? <li>
            <a
              href={`https://twitter.com/intent/tweet?source=${encodedWebSiteUrl}&text=${encodedName}:%20${encodedWebSiteUrl}${websiteTwitterAccount ? `&via=${websiteTwitterAccount}` : ""}`}
              target="_blank"
              title="Tweet"
            >
              <img alt="Tweet" src={iconFiles.twitter}/>
            </a>
          </li> : null}
          {showGooglePlus ? <li>
            <a href={`https://plus.google.com/share?url=${encodedWebSiteUrl}`}
               target="_blank"
               title="Share on Google+"
            >
              <img alt="Share on Google+" src={iconFiles.googlePlus}/>
            </a>
          </li> : null}
          {showTumblr ? <li>
            <a href={`http://www.tumblr.com/share?v=3&u=${encodedWebSiteUrl}&quote=${encodedName}&s=`}
               target="_blank"
               title="Post to Tumblr"
            >
              <img alt="Post to Tumblr" src={iconFiles.tumplr}/>
            </a>
          </li> : null}
          {showPinterest ? <li>
            <a
              href={`http://pinterest.com/pin/create/button/?url=${encodedWebSiteUrl}&media=${websiteLogoPreview}&encodedDescription=${encodedDescription}`}
              target="_blank"
              title="Pin it"
            >
              <img alt="Pin it" src={iconFiles.pinterest}/>
            </a>
          </li> : null}
          {showPocket ? <li>
            <a href={`https://getpocket.com/save?url=${encodedWebSiteUrl}&title=${encodedName}`}
               target="_blank"
               title="Add to Pocket"
            ><img alt="Add to Pocket" src={iconFiles.pocket}/>
            </a>
          </li> : null}
          {showReddit ? <li>
            <a href={`http://www.reddit.com/submit?url=${encodedWebSiteUrl}&title=${encodedName}`}
               target="_blank"
               title="Submit to Reddit">
              <img alt="Submit to Reddit" src={iconFiles.reddit}/>
            </a>
          </li> : null}
          {showLinkedIn ? <li>
            <a
              href={`http://www.linkedin.com/shareArticle?mini=true&url=${encodedWebSiteUrl}&title=${encodedName}&summary=${encodedDescription}&source=${encodedWebSiteUrl}`}
              target="_blank"
              title="Share on LinkedIn">
              <img alt="Share on LinkedIn" src={iconFiles.linkedIn}/>
            </a>
          </li> : null}
          {showWordpress ? <li>
            <a
              href={`http://wordpress.com/press-this.php?u=${encodedWebSiteUrl}&quote=${encodedName}&s=${encodedDescription}&i=${websiteLogoPreview}`}
              target="_blank"
              title="Publish on WordPress"
            >
              <img alt="Publish on WordPress" src={iconFiles.wordpress}/>
            </a>
          </li> : null}
          {showPinboard ? <li>
            <a
              href={`https://pinboard.in/popup_login/?url=${encodedWebSiteUrl}&title=${encodedName}&encodedDescription=${encodedDescription}`}
              target="_blank"
              title="Save to Pinboard"
            >
              <img alt="Save to Pinboard" src={iconFiles.pinboard}/>
            </a>
          </li> : null}
          {showEmail ? <li>
            <a href={`mailto:?subject=${encodedName}&body=${encodedDescription}:%20${encodedWebSiteUrl}`}
               target="_blank"
               title="Send email">
              <img alt="Send email" src={iconFiles.email}/>
            </a>
          </li> : null}
        </ul>
      </div>
    );
  }
}
