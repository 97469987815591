import * as moment from "moment";
import {scanDates} from "../../../utils/scanDates";
import {
  IDaysFlightsInfoDic,
  EPriceQualityIndex,
  IPriceViewData,
} from "../interfaces";
import {count} from "dyna-count";

export const updatePriceIndex = (
  {
    processMonthOfDate,
    calcMonths,
    flightsInfoDic,
    _test,
  }: {
    processMonthOfDate: string; // yyyymmdd
    calcMonths: number;         // including the given one
    flightsInfoDic: IDaysFlightsInfoDic;
    _test?: boolean;
  },
): IDaysFlightsInfoDic => {
  const output: IDaysFlightsInfoDic = {...flightsInfoDic};

  count(calcMonths).for(calcMonth => {
    const prices: {
      best: number[];
      cheapest: number[];
      fastest: number[];
    } = {
      best: [],
      cheapest: [],
      fastest: [],
    };

    let scanStart = moment(processMonthOfDate).add(calcMonth, 'month').startOf('month');
    let scanEnd = moment(processMonthOfDate).add(calcMonth, 'month').endOf('month');

    if (!_test) {
      if (scanEnd.isBefore(moment())) return; // The Calendar is showing past months, do not update the values.
      if (scanStart.isBefore(moment())) scanStart = moment();
    }

    scanDates(
      scanStart,
      scanEnd,
      date => {
        const dayInfo = flightsInfoDic[date.format('YYYY-MM-DD')];
        if (!dayInfo) return;
        if (dayInfo.isBackendLoading) return;   // The price is not yet ready

        if (dayInfo.bestPrice.price) prices.best.push(dayInfo.bestPrice.price.value);
        if (dayInfo.cheapestPrice.price) prices.cheapest.push(dayInfo.cheapestPrice.price.value);
        if (dayInfo.fastestPrice.price) prices.fastest.push(dayInfo.fastestPrice.price.value);
      },
    );

    const minMaxPrices: {
      best: { min: number, max: number };
      cheapest: { min: number, max: number };
      fastest: { min: number, max: number };
    } = {
      best: prices.best.reduce(minMaxReducer, {min: 0, max: 0}),
      cheapest: prices.cheapest.reduce(minMaxReducer, {min: 0, max: 0}),
      fastest: prices.fastest.reduce(minMaxReducer, {min: 0, max: 0}),
    };

    scanDates(
      scanStart,
      scanEnd,
      date => {
        const dayInfo = flightsInfoDic[date.format('YYYY-MM-DD')];
        if (!dayInfo) return;
        if (dayInfo.isBackendLoading) return;   // The price is not yet ready

        dayInfo.priceQualityIndex = {...dayInfo.priceQualityIndex};
        dayInfo.priceQualityIndex.best = getPriceIndex(minMaxPrices.best.min, minMaxPrices.best.max, dayInfo.bestPrice);
        dayInfo.priceQualityIndex.cheapest = getPriceIndex(minMaxPrices.cheapest.min, minMaxPrices.cheapest.max, dayInfo.cheapestPrice);
        dayInfo.priceQualityIndex.fastest = getPriceIndex(minMaxPrices.fastest.min, minMaxPrices.fastest.max, dayInfo.fastestPrice);
      },
    );

  });

  return output;
};

const minMaxReducer = (acc: { min: number, max: number }, price: number): { min: number, max: number } => {
  if (acc.min === 0) acc.min = price;
  if (price < acc.min) acc.min = price;
  if (price > acc.max) acc.max = price;
  return acc;
};

export const getPriceIndex = (min: number, max: number, price: IPriceViewData): EPriceQualityIndex => {
  if (!price.price) return EPriceQualityIndex.NO_PRICE_AVAILABLE;
  const percent = getPriceIndexPercent(min, max, price.price.value);
  if (percent < 33) return EPriceQualityIndex.CHEAP;
  if (percent < 66) return EPriceQualityIndex.SO_LA_LA;
  return EPriceQualityIndex.EXPENSIVE;
};

export const getPriceIndexPercent = (min: number, max: number, value: number): number => {
  return (value - min) / (max - min) * 100;
};
