import {IDynaPrice} from "dyna-travel-interfaces";

export interface IPriceConverterConfig {
	convertPrice: (price: IDynaPrice, currency: string) => number
}

export class PriceConverter {
	constructor(private readonly config: IPriceConverterConfig) {
		this.convert = this.convert.bind(this);
	}

	private readonly cache: { [key: string]: number } = {};

	private getKey(price: IDynaPrice, currency: string): string {
		return `k-${price.currency}-${price.value}-${currency}`;
	}

	public convert(price: IDynaPrice, currency: string): number {
		const key: string = this.getKey(price, currency);
		const cached: number = this.cache[key];
		if (cached !== undefined) return cached;
		const output: number = this.config.convertPrice(price, currency);
		this.cache[key] = output;
		return output;
	}
}