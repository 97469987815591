import * as React from "react";
import {IDynaName, IDynaPlace} from "dyna-travel-interfaces";

import "./PlaceInfo.less";

export interface IPlaceInfoProps {
	className?: string;
	style?: EPlaceInfoStyle;
	place: IDynaPlace;
}

export enum EPlaceInfoStyle {
	BASIC_FULL = "BASIC_FULL",
}

export class PlaceInfo extends React.Component<IPlaceInfoProps> {
	static defaultProps: IPlaceInfoProps = {
		className: "",
		style: EPlaceInfoStyle.BASIC_FULL,
		place: null,
	};

	constructor(props: IPlaceInfoProps) {
		super(props);
	}

	private readonly baseClassName: string = "dyna-place-tooltip";

	private className(subClassName = ""): string {
		return `${this.baseClassName}${subClassName || ""}`;
	}

	private renderName(name: IDynaName): JSX.Element {
		if (!name) return <div>#4233 - no name</div>;
		return (
			<div className={this.className("__name")}>
				<div className={this.className("__name__code-name")}>{name.codeName} </div>
				<div className={this.className("__name__full-name")}>{name.name.text}</div>
			</div>
		)
	}

	private renderCity(city: IDynaName): JSX.Element {
		if (!city) return null;
		return (
			<div className={this.className("__city")}>{city.name.text}</div>
		)
	}

	private renderAddressDetails(area: IDynaName, state: IDynaName): JSX.Element {
		return (
			<div className={this.className("__address-details")}>
				{area ? <span className={this.className("__address-details__area")}>{area.name.text}</span> : null}
				{state ? <span className={this.className("__address-details__state")}> {state.name.text}</span> : null}
			</div>
		)
	}

	private renderContactDetails(phone: string, email: string, website: string): JSX.Element {
		return (
			<div className={this.className("__contact-details")}>
				{phone ? <div className={this.className("__contact-details__phone")}>
					<span className={this.className("__contact-details__icon")}><i className="fas fa-phone-square"/> </span>
					<span className={this.className("__contact-details__value")}>{phone}</span>
				</div> : null}
				{email ? <div className={this.className("__contact-details__email")}>
					<span className={this.className("__contact-details__icon")}><i className="fas fa-at"/> </span>
					<span className={this.className("__contact-details__value")}>{email}</span>
				</div> : null}
				{website ? <div className={this.className("__contact-details__website")}>
					<span className={this.className("__contact-details__icon")}><i className="fas fa-globe"/> </span>
					<span className={this.className("__contact-details__value")}>{website}</span>
				</div> : null}
			</div>
		)
	}

	private renderCounty(country: IDynaName, flagUrl: string): JSX.Element {
		if (!country) return null;
		return (
			<div className={this.className("__country")}>
				{flagUrl ? <span><img className={this.className("__country__flag")} src={flagUrl}/> </span> : null}
				<span className={this.className("__country__name")}>{country.name.text}</span>
			</div>
		)
	}

	public render(): JSX.Element {
		if (!this.props.place) return null;

		const {
			className: userClassName,
			place: {
				name,
				city,
				area,
				state,
				country,
				phone,
				email,
				website,
				flagUrl,
			},
		} = this.props;

		const className: string = [
			this.className(),
			userClassName,
		].join(' ').trim();

		return (
			<div className={className}>
				{this.renderName(name)}
				{this.renderCity(city)}
				{this.renderAddressDetails(area, state)}
				{this.renderContactDetails(phone, email, website)}
				{this.renderCounty(country, flagUrl)}
			</div>
		);
	}
}