import {connect, Dispatch} from "react-redux";
import {IAppStore} from "../../../../../redux";

import {component} from './component';
import {IAppContainerProps} from "../../../../../interfaces/app";
import {appMainViewCenter} from "../../../state/actions";

export interface IMainPageContainerProps extends IAppContainerProps {
  showSignIn?: boolean;
  showSignUp?: boolean;
  loginName?: string;
}

const mapStateToProps = (state: IAppStore, ownProps: IMainPageContainerProps): any => ({
  appLabel: ownProps.appLayer.appLabel,
  isLogged: !!state.auth.userState.userId,
});

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: IMainPageContainerProps): any => {
  return {
    onViewCenterClick: () => {
      dispatch(appMainViewCenter(true));
    },
    onViewTopClick: () => {
      dispatch(appMainViewCenter(false));
    },
  };
};

export const MainPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(component);

