import * as React from "react";
import {EProduct} from "../../interfaces";
import {DynaProgressBar} from "dyna-ui-progress-bar";
import {DynaButton, ESize as EButtonSize, EStyle as EButtonStyle} from "dyna-ui-button";

import "./LoadingProgressBar.less";

export interface ILoadingProgressBarProps {
  product: EProduct;
  animationEnabled: boolean;
  show: boolean;
  isLoading: boolean;
  inError: boolean;
  isLoadingMessage: string;
  loadProgressPercentValue: number;
  onCancelClick: () => void;
  messages: {
    cancelLoadButton: string;
  };
}

export class LoadingProgressBar extends React.Component<ILoadingProgressBarProps> {

  public render(): JSX.Element {
    const {
      product,
      animationEnabled,
      show,
      inError,
      isLoading,
      isLoadingMessage,
      loadProgressPercentValue,
      onCancelClick,
      messages: {
        cancelLoadButton,
      },
    } = this.props;

    const className: string = [
      "dtlp-loading-progress-bar",
      `dtlp-loading-progress-bar--${show ? "show" : "hide"}`,
      inError ? "dtlp-loading-progress-bar--error" : "",
      `dtlp--product-${product}`,
    ].join(' ').trim();

    const classNameButtonContainer: string = [
      "dtlp__cancel-button-container",
      `dtlp__cancel-button-container--${isLoading ? "active" : "inactive"}`,
    ].join(' ').trim();

    return (
      <div className={className}>
        <div className="dtlp__internal_wrapper">
          <DynaProgressBar
            className="dtlp__progress-bar"
            animationEnabled={animationEnabled}
            min={0}
            max={100}
            isLoading={isLoading}
            label={isLoadingMessage}
            value={loadProgressPercentValue}
          />
          <div className={classNameButtonContainer}>
            <DynaButton
              size={EButtonSize.LARGE}
              style={EButtonStyle.FLAT}
              onClick={onCancelClick}
            >{cancelLoadButton}</DynaButton>
          </div>
        </div>
      </div>
    );
  }
}
