import { ICurrencyRates } from "dyna-currencies";
import { DynaNodeClient } from "dyna-node/dist/commonJs/web";

import {
    COMMAND_GET_CURRENCY_RATES,
    ICOMMAND_GET_CURRENCY_RATES_response_data,
} from "dyna-travel-services/dist/commonJs/financial/client";

const client = new DynaNodeClient();

export const getCurrencies = async (financialServiceDNA: string): Promise<ICurrencyRates> => {
    const response = await client.fetch<void, void, ICOMMAND_GET_CURRENCY_RATES_response_data>({
        to: financialServiceDNA,
        command: COMMAND_GET_CURRENCY_RATES,
    });
    return response.currencyRates;
};
