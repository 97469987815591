import * as React from 'react';

import {
	DynaTravelSettings,
	IDynaTravelSettings,
	IDynaTravelSettingsOptions,
} from "dyna-travel-ui-settings";

import './AppSettingsComponent.css';

export interface IAppSettingsComponentProps {
	isLoading?: boolean;
	settings?: IDynaTravelSettings;
	showEnglishWithCodesLocale?: boolean;
	settingsOptions?: IDynaTravelSettingsOptions;
	onChange?: (settings: IDynaTravelSettings) => void;
}

interface IAppSettingsComponentState {
}

export class AppSettingsComponent extends React.Component<IAppSettingsComponentProps, IAppSettingsComponentState> {
	public render(): JSX.Element {
		const {
			isLoading,
			showEnglishWithCodesLocale,
			settings,
			settingsOptions,
			onChange,
		} = this.props;

		const hideLanguagesByLocale: string[] = showEnglishWithCodesLocale ? [] : ["enUSCodes"];

		return (
			<DynaTravelSettings
				label="Language / Currency"
				hideLanguagesByLocale={hideLanguagesByLocale}
				settings={settings}
				isLoading={isLoading}
				settingsOptions={settingsOptions}
				onChange={onChange}
			/>
		);
	}
}

