import {connect, Dispatch} from "react-redux";
import {IAppStore} from "../../../../../redux/reducersConfig";

import {MainMenuComponent} from './MainMenuComponent';
import {IAppContainerProps} from "../../../../../interfaces/app";

const mapStateToProps = (state: IAppStore, {appLayer}: IAppContainerProps) => {
  return {
    appPath: state.app.path,
    locale: state.app.locale,
    items: state.app.menus.mainMenu,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {};
};

// @ts-ignore
export const MainMenu = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainMenuComponent);

