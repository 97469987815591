import * as React from 'react';

import {appTitle} from "../../../../../components/ui/appTitle/appTitle";
import {IAppContainerProps} from "../../../../../interfaces/app";

const clientPackage = require('../../../../../../package.json');

export interface IIsAlive777 extends IAppContainerProps {
	appLabel?: string;
	isLogged?: boolean;
}

export const component = (props: IIsAlive777) => (
	<div>
    {appTitle('is alive')}
		<h1>is alive 777 page</h1>
		<p>client version: {clientPackage.version}</p>
		<p>user logged: {props.isLogged ? "yes" : "no"}</p>
	</div>
);
