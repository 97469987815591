import * as React from "react";

import { EColor } from "dyna-ui-styles";
import {
  IDynaPlace,
  IDynaTime,
  IDynaTripRequestRoute
} from "dyna-travel-interfaces";
import {
  DynaDateRangePickerFlightsInfo,
  EDynaDatePickerColor,
  EDatePickerStyle,
  EEditDate,
} from "dyna-travel-ui-date-info-pickers";

import {
  EMode,
  ESize,
  DynaTravelSelectAirport,
  EStyle as ESelectAirportStyle,
} from "dyna-travel-ui-select-airport";

import { EProduct } from "../interfaces";
import { IDatePickerFlightInfoBasicConfig } from "../DynaSelectAirTrip";
import { DirectionExchangeIcon } from "../ui/DirectionExchangeIcon";
import { copyRoutes } from "../utils/copyTripRequest";
import { getDate0 } from "../utils/getDate0";
import {
  renderDay,
  renderMonthYear
} from "../utils/renderCalendarContent";

import "./SelectRoutesReturn.less";

export { ESize };

export interface ISelectRoutesReturnProps {
  product: EProduct;
  mode: EMode;
  size: ESize;
  fieldsColor: EColor;
  fieldDatePickerColor: EDynaDatePickerColor;
  formatDate: (date: Date) => string;
  fetchDebounceTimeout: number;
  routes: IDynaTripRequestRoute[];
  datePickerFlightInfoConfig: IDatePickerFlightInfoBasicConfig;
  onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange: (routes: IDynaTripRequestRoute[]) => void;
  messages: {
    originLabel: string;
    originPlaceholder: string;
    originValidationOnEmptyForField: string;
    originValidationOnEmptyForValidationMessages: string;
    destinationLabel: string;
    destinationPlaceholder: string;
    destinationValidationOnEmptyForField: string;
    destinationValidationOnEmptyForValidationMessages: string;
    departureDateLabel: string;
    returnDateLabel: string;
    weekDaysShortNames: string;
    monthLongNames: string;
    datePickerTodayButton: string;
    datePickerCloseButton: string;
    calendarPickerBest: string;
    calendarPickerCheapest: string;
    calendarPickerFastest: string;
    calendarPickerNone: string;
  };
  _debug_calendarPickerStoreName: string;
}

export class SelectRoutesReturn extends React.Component<ISelectRoutesReturnProps> {
  public refs: {
    selectOriginAirport: DynaTravelSelectAirport;
    selectDestinationAirport: DynaTravelSelectAirport;
    directionExchangeIcon: DirectionExchangeIcon;
  };

  private get routes(): IDynaTripRequestRoute[] {
    const {routes} = this.props;
    // transform from ONE_WAY
    if (routes && routes.length === 1) {
      return [
        {
          origin: routes[0].origin,
          destination: routes[0].destination,
          departDate: routes[0].departDate,
        },
        {
          origin: routes[0].destination,
          destination: routes[0].origin,
          departDate: routes[0].departDate,
        }
      ];
    }

    // from Return (this) or Multi
    return copyRoutes(routes);
  }

  private handleToggleOriginDestination(): void {
    const {mode, onChange} = this.props;
    if (mode !== EMode.EDIT) return;
    const routes: IDynaTripRequestRoute[] = this.routes;
    const outputRoutes: IDynaTripRequestRoute[] = [];

    this.refs.directionExchangeIcon.flip();

    // copy the routes, toggling the 1st two
    outputRoutes.push(routes[1]);
    outputRoutes.push(routes[0]);
    routes.slice(2).forEach((route: IDynaTripRequestRoute) => outputRoutes.push(route));

    // toggle the dates of the 1st two routes
    let helper: IDynaTime = outputRoutes[0].departDate;
    outputRoutes[0].departDate = outputRoutes[1].departDate;
    outputRoutes[1].departDate = helper;

    onChange(outputRoutes);
  }

  private handleOriginChange(name: string, airport: IDynaPlace): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[0].origin = airport;
    routes[1].destination = airport;
    onChange(routes);
  }

  private handleDestinationChange(name: string, airport: IDynaPlace): void {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[0].destination = airport;
    routes[1].origin = airport;
    onChange(routes);
  }

  private handleDepartDateChange = (name: string, start: Date, end: Date): void => {
    const {onChange} = this.props;
    const routes: IDynaTripRequestRoute[] = this.routes;
    routes[0].departDate = {date: start};
    routes[1].departDate = {date: end};
    onChange(routes);
  };

  public validate(): string[] {
    return [].concat(
      this.refs.selectOriginAirport.validate(),
      this.refs.selectDestinationAirport.validate(),
    );
  }

  public render(): JSX.Element {
    const {
      mode,
      size,
      fieldsColor,
      fieldDatePickerColor,
      formatDate,
      fetchDebounceTimeout,
      datePickerFlightInfoConfig,
      onAirportSearch,
      messages: {
        originLabel,
        originPlaceholder,
        originValidationOnEmptyForField,
        originValidationOnEmptyForValidationMessages,
        destinationLabel,
        destinationPlaceholder,
        destinationValidationOnEmptyForField,
        destinationValidationOnEmptyForValidationMessages,
        departureDateLabel,
        returnDateLabel,
        weekDaysShortNames,
        monthLongNames,
        datePickerTodayButton,
        datePickerCloseButton,
        calendarPickerBest,
        calendarPickerCheapest,
        calendarPickerFastest,
        calendarPickerNone
      },
      _debug_calendarPickerStoreName,
    } = this.props;

    return (
      <div className="dyna-travel-select-air-trip-return dyna-travel-route-container">
        <DynaTravelSelectAirport
          className="select-origin"
          ref="selectOriginAirport"
          mode={mode}
          size={size}
          name="origin"
          label={originLabel}
          style={EDatePickerStyle.WIDE_LABEL_ROUNDED}
          color={fieldsColor}
          inputProps={{
            placeholder: originPlaceholder,
          }}
          validationMessageOnEmptyForField={originValidationOnEmptyForField}
          validationMessageOnEmptyForValidationMessages={originValidationOnEmptyForValidationMessages}
          value={this.routes[0].origin}
          fetchDebounceTimeout={fetchDebounceTimeout}
          onChange={this.handleOriginChange.bind(this)}
          onAirportSearch={onAirportSearch}
        />
        <div className="destination-container">
          <div className={`trip-change-directions mode-${mode}`} onClick={this.handleToggleOriginDestination.bind(this)}>
            <DirectionExchangeIcon ref="directionExchangeIcon"/>
          </div>
          <DynaTravelSelectAirport
            className="select-destination"
            ref="selectDestinationAirport"
            mode={mode}
            size={size}
            name="destination"
            label={destinationLabel}
            style={ESelectAirportStyle.WIDE_LABEL_ROUNDED}
            color={fieldsColor}
            inputProps={{
              placeholder: destinationPlaceholder,
            }}
            validationMessageOnEmptyForField={destinationValidationOnEmptyForField}
            validationMessageOnEmptyForValidationMessages={destinationValidationOnEmptyForValidationMessages}
            value={this.routes[0].destination}
            fetchDebounceTimeout={fetchDebounceTimeout}
            onChange={this.handleDestinationChange.bind(this)}
            onAirportSearch={onAirportSearch}
          />
        </div>
        <DynaDateRangePickerFlightsInfo
          className="select-depart-date"
          mode={mode}
          style={EDatePickerStyle.WIDE_LABEL_ROUNDED}
          color={fieldDatePickerColor}
          size={size}
          // BUG 2063 - pickerSize={getOneFieldSizeUp(size)}
          editDate={EEditDate.START}
          name="departDate"
          min={getDate0()}
          label={departureDateLabel}
          todayButtonLabel={datePickerTodayButton}
          closeButtonLabel={datePickerCloseButton}
          renderInputDate={formatDate}
          renderPickerWeekDay={(weekDay: number) => renderDay(weekDaysShortNames, weekDay)}
          renderPickerMonthYear={(month: number, year: number) => renderMonthYear(monthLongNames, month, year)}
          start={this.routes[0].departDate.date as Date}
          end={this.routes[1].departDate.date as Date}
          flightsInfoConfig={{
            ...datePickerFlightInfoConfig,
            roundTrip: true,
            origin: this.routes[0].origin && this.routes[0].origin.name.codeName,
            destination: this.routes[0].destination && this.routes[0].destination.name.codeName,
            messages: {
              best: calendarPickerBest,
              cheapest: calendarPickerCheapest,
              fastest: calendarPickerFastest,
              none: calendarPickerNone,
            },
            _debug_dynaduxDebugGlobalVarName: _debug_calendarPickerStoreName && _debug_calendarPickerStoreName + "__routeReturn__start",
          }}
          onChange={this.handleDepartDateChange}
        />
        <DynaDateRangePickerFlightsInfo
          className="select-return-date"
          mode={mode}
          style={EDatePickerStyle.WIDE_LABEL_ROUNDED}
          color={fieldDatePickerColor}
          size={size}
          // BUG 2063 - pickerSize={getOneFieldSizeUp(size)}
          editDate={EEditDate.END}
          name="returnDate"
          min={getDate0()}
          label={returnDateLabel}
          todayButtonLabel={datePickerTodayButton}
          closeButtonLabel={datePickerCloseButton}
          renderInputDate={formatDate}
          renderPickerWeekDay={(weekDay: number) => renderDay(weekDaysShortNames, weekDay)}
          renderPickerMonthYear={(month: number, year: number) => renderMonthYear(monthLongNames, month, year)}
          start={this.routes[0].departDate.date as Date}
          end={this.routes[1].departDate.date as Date}
          flightsInfoConfig={{
            ...datePickerFlightInfoConfig,
            roundTrip: true,
            origin: this.routes[0].origin && this.routes[0].origin.name.codeName,
            destination: this.routes[0].destination && this.routes[0].destination.name.codeName,
            messages: {
              best: calendarPickerBest,
              cheapest: calendarPickerCheapest,
              fastest: calendarPickerFastest,
              none: calendarPickerNone,
            },
            _debug_dynaduxDebugGlobalVarName: _debug_calendarPickerStoreName && _debug_calendarPickerStoreName + "__routeReturn__end",
          }}
          onChange={this.handleDepartDateChange}
        />
      </div>
    );
  }
}
