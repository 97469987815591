import {IDynaTranslation} from "../../../packages/dyna-translation-manager/DynaTranslationManager";

export const appTranslationMessages: IDynaTranslation = {
	locales: [
		{locale: 'enUS', label: 'English US'},
		{locale: 'frFR', label: 'French'},
		{locale: 'zhCN', label: 'Chinese'},
	],
	messages: {
		MSG_MainApp_ApplicationTitle: {
			enUS: 'Dyna react server client app',
			frFR: 'Dyna réagit à l\'application client du serveur',
		},
		MSG_0610: {
			enUS: 'Main',
			frFR: 'D\'accueil',
		},
		MSG_0611: {
			enUS: 'Contact',
			frFR: 'Contact',
		},
		MSG_0612: {
			enUS: 'Users',
			frFR: 'Utilisateurs',
		},
		MSG_0613: {
			enUS: 'About',
			frFR: 'Sur',
		},
		MSG_0600: {
			enUS: 'Hello world',
			frFR: 'Bonjour le monde',
			zhCN: '你好世界',
		},
		MSG_0614: {
			enUS: 'Flights',
			enUSSlang: 'DieToFlights',
		},
		MSG_0615: {
			enUS: 'Hotels',
		},
		MSG_MAINAPP_MENU_WishList: {
			enUS: 'Wish List',
		},
		MSG_0616: {
			enUS: 'My trips',
		},
	},
};
