import * as React from "react";
import {IDynaTrip} from "dyna-travel-interfaces";

import {EColor as EHorizontalTripTimelineColor} from "dyna-travel-ui-trip-timeline"

import {TripView} from "./components/TripView";

import {EProduct} from "./interfaces";
import {IDynaTravelTripVerticalTimelineMessages} from "./messages";

import "./DynaTravelTripVerticalTimeline.less";

export interface IDynaTravelTripVerticalTimelineProps {
	product: EProduct;
	horizontalTripTimelineColor?: EHorizontalTripTimelineColor,
	trip?: IDynaTrip;
	messages: IDynaTravelTripVerticalTimelineMessages;
	formatTime: (time: Date) => string;
  formatTimeString: (timeHHMM: string) => string;
  formatShortDate: (date: Date) => string;
  formatFullDateString: (dateYYYYMMDD: string) => string;
	formatDuration: (minutes: number) => string;
}

export class DynaTravelTripVerticalTimeline extends React.Component<IDynaTravelTripVerticalTimelineProps> {
	private readonly baseClassName: string = "dyna-travel-trip-vertical-timeline";
	private readonly className = (subClassName: string = "", active: boolean = true): string => active ? `${this.baseClassName}${subClassName}` : "";

	public render(): JSX.Element {
		const {
			product,
			trip,
			messages,
			formatTime,
      formatTimeString,
			formatShortDate,
      formatFullDateString,
			formatDuration,
		} = this.props;

		const className: string = [
			this.className(),
		].join(' ').trim();

		if (!trip) return null;

		return (
			<div className={className}>
				{trip.segments.map((segment: IDynaTrip, index: number) => (
					<TripView
						key={index}
						product={product}
						segmentNo={index + 1}
						segment={segment}
						messages={messages}
						formatTime={formatTime}
            formatTimeString={formatTimeString}
						formatShortDate={formatShortDate}
            formatFullDateString={formatFullDateString}
						formatDuration={formatDuration}
					/>
				))}
			</div>
		);
	}
}
