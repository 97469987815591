import * as React from "react";
import {DynaTooltip, ETooltipDirection} from "dyna-ui-tooltip";
import {EDynaPassengerType, IDynaPassenger, IDynaPrice, IDynaTrip, IDynaTripProposal} from "dyna-travel-interfaces";
import {DynaFastClick} from "dyna-ui-fast-click";

import {EProduct} from "../../interfaces";
import {IDynaTravelListProposalsMessages} from "../../messages";

import {DateTimeTripOverview} from "../DateTimeTripOverview/DateTimeTripOverview";

import {
  DynaButton,
  ESize as EButtonSize,
  EStyle as EButtonStyle,
} from "dyna-ui-button";
import {
  DynaSpeedButtons,
  EStyle as EDynaSpeedButtonStyle,
  EColor as EDynaSpeedButtonsColor,
  ESize as EDynaSpeedButtonsSize,
  IOption as EDynaSpeedButtonOption,
} from "dyna-ui-speed-buttons";
import {
  DynaTravelTripTimeline,
  DynaTravelTripTimelineHeight,
  EViewType,
} from "dyna-travel-ui-trip-timeline";

import {styleMixer} from "../../styleMixer";
import {faIcon} from "../../utils/faIcon";

import "./TripProposalItem.less";

export const calcTripProposalHeight = (tripProposal: IDynaTripProposal): number => {
  const idealHeight: number = (
    0
    + 32 // top button bar
    + (tripProposal.trip.segments.length * (
      DynaTravelTripTimelineHeight
      + (2 * 24) // same value 98249824ß5943
    )) // segments
  );

  return idealHeight >= 222 ? idealHeight : 222;
};

export interface ITripProposalItemProps {
  className?: string;
  currency: string;
  product: EProduct;
  passengers: IDynaPassenger[];
  messages?: IDynaTravelListProposalsMessages;
  tripProposal: IDynaTripProposal;
  proposalsExpired: boolean;
  formatDate: (date: Date) => string;
  formatDuration?: (minutes: number) => string;
  formatPrice: (price: IDynaPrice, currency: string) => string;
  formatTimeFromString: (timeHHMM: string) => string;
  formatFullDateFromString: (dateYYYYMMDD: string) => string;
  onSelectClick: (tripProposal: IDynaTripProposal) => void;
}

export interface ITripProposalItemState {
  // note! for new state props, update and the shouldComponentUpdate method
  tripTimelineViewType: EViewType;
  mouseDown: boolean;                // for the mouse click effect
}

export class TripProposalItem extends React.Component<ITripProposalItemProps, ITripProposalItemState> {
  private readonly classPrefix: string = "dtlp-trip-proposal-list-item";

  constructor(props: ITripProposalItemProps) {
    super(props);
    this.state = {
      tripTimelineViewType: EViewType.BASIC,
      mouseDown: false,
    };
  }

  public shouldComponentUpdate(nextProps: ITripProposalItemProps, nextState: ITripProposalItemState): boolean {
    return (
      this.props.tripProposal.tpid !== nextProps.tripProposal.tpid
      || this.props.currency !== nextProps.currency
      || this.props.tripProposal.trip.price.value !== nextProps.tripProposal.trip.price.value
      || this.props.tripProposal.trip.id !== nextProps.tripProposal.trip.id
      || this.props.tripProposal.trips.length !== nextProps.tripProposal.trips.length
      || this.props.messages.cancelLoadButton !== nextProps.messages.cancelLoadButton
      || this.props.messages.departure !== nextProps.messages.departure
      || this.props.messages.arrival !== nextProps.messages.arrival
      || this.props.proposalsExpired !== nextProps.proposalsExpired
      || this.state.tripTimelineViewType !== nextState.tripTimelineViewType
      || this.state.mouseDown !== nextState.mouseDown
    );
  }

  private handleOnSwitchView = (newViewType: EViewType): void => {
    this.setState({tripTimelineViewType: newViewType});
  };

  private renderSegment = (tripSegment: IDynaTrip, index: number): JSX.Element => {
    const {
      product,
      formatDuration,
      messages,
      formatTimeFromString,
      formatFullDateFromString,
    } = this.props;
    const {tripTimelineViewType} = this.state;
    return (
      <div key={index} className={`${this.classPrefix}__segment`}>
        <div className={`${this.classPrefix}__segment-timeline`}>
          <DynaTravelTripTimeline
            viewType={tripTimelineViewType}
            color={styleMixer(product).tripTimelineColor}
            trip={tripSegment}
            formatDuration={formatDuration}
            messages={messages}
            formatTime={formatTimeFromString}
            formatFullDate={formatFullDateFromString}
          />
        </div>
      </div>
    );
  };

  private handleBlockMouseEvent = (event: any): void => {
    event.stopPropagation();
  };

  private renderExpiredProposalTooltip(): JSX.Element {
    const {
      proposalsExpired,
      messages: {
        refreshBalloonDescription,
      },
    } = this.props;
    if (proposalsExpired)
      return <span><i className="fas fa-sync"/> {refreshBalloonDescription}</span>;
    else
      return null;
  }

  private renderLeftPart(): JSX.Element {
    const {tripProposal} = this.props;
    const {messages: {listTripViewTypeBasic, listTripViewTypeDetails}} = this.props;
    const {tripTimelineViewType} = this.state;
    const viewOptions: EDynaSpeedButtonOption[] = [
      {
        label: <span>{listTripViewTypeBasic}</span>,
        selected: tripTimelineViewType === EViewType.BASIC,
        value: EViewType.BASIC,
      },
      {
        label: <span>{listTripViewTypeDetails}</span>,
        selected: tripTimelineViewType === EViewType.DETAILS,
        value: EViewType.DETAILS,
      },
    ];
    return (
      <div className={`${this.classPrefix}__left-container`}>
        <div
          className={`${this.classPrefix}__timeline_view_type_buttons`}
          onClick={this.handleBlockMouseEvent}
          onMouseDown={this.handleBlockMouseEvent}
        >
          <DynaSpeedButtons
            style={EDynaSpeedButtonStyle.ROUNDED}
            color={EDynaSpeedButtonsColor.ORANGE_WHITE}
            size={EDynaSpeedButtonsSize.MEDIUM}
            options={viewOptions}
            onClick={this.handleOnSwitchView}
          />
        </div>
        <div className={`${this.classPrefix}__segments`}>
          {tripProposal.trip.segments.map(this.renderSegment)}
        </div>
      </div>
    );
  }

  private renderPassengerPriceInfo(): JSX.Element {
    const {
      currency,
      tripProposal,
      proposalsExpired,
      passengers,
      messages: {
        totalPriceForNPassengers,
        pricePerCustomer,
      },
    } = this.props;

    if (!passengers) return null;
    if (!passengers.length) return null;
    if (passengers.length === 1) return null;

    let scanPassengerType: EDynaPassengerType = null;
    let hasDifferentTypes: boolean = false;
    passengers.forEach((passenger: IDynaPassenger) => {
      if (scanPassengerType === null) {
        scanPassengerType = passenger.type;
      }
      else {
        if (scanPassengerType !== passenger.type) {
          hasDifferentTypes = true;
        }
      }
    });

    const csExpired: string = proposalsExpired ? `${this.classPrefix}__price_passenger_label--expired` : "";

    if (hasDifferentTypes) {
      return (
        <div className={`${this.classPrefix}__price_passenger_label ${csExpired}`.trim()}>
          <DynaTooltip tooltipContent={this.renderExpiredProposalTooltip()} tooltipDirection={ETooltipDirection.NORTH}>
            {totalPriceForNPassengers({passengersCount: passengers.length})}
          </DynaTooltip>
        </div>
      );
    }
    else {
      return (
        <div className={`${this.classPrefix}__price_passenger_label ${csExpired}`.trim()}>
          <DynaTooltip tooltipContent={this.renderExpiredProposalTooltip()} tooltipDirection={ETooltipDirection.NORTH}>
            {pricePerCustomer({
              price: {
                value: tripProposal.trip.price.value / passengers.length,
                currency: tripProposal.trip.price.currency,
              },
              currency,
            })}
          </DynaTooltip>
        </div>
      );
    }
  }

  private renderRightPart(): JSX.Element {
    const {
      product,
      messages,
      currency,
      tripProposal,
      proposalsExpired,
      formatTimeFromString,
      formatDate,
      formatDuration,
      formatPrice,
      messages: {
        offers,
      },
    } = this.props;

    const csPriceValueExpired: string = proposalsExpired ? `${this.classPrefix}__price-value--expired` : "";

    return (
      <div className={`${this.classPrefix}__right-container`}>

        <div className={`${this.classPrefix}__dash-border`}/>

        <div className={`${this.classPrefix}__trip-overview-container`}>
          {tripProposal.trip.segments.map((segment: IDynaTrip, index: number) => (
            <DateTimeTripOverview
              key={index}
              className={`${this.classPrefix}__trip-overview-segment`}
              trip={segment}
              formatTimeFromString={formatTimeFromString}
              formatDate={formatDate}
              formatDuration={formatDuration}
              messages={messages}
            />
          ))}
        </div>

        <div className={`${this.classPrefix}__price-container`}>
          <div className={`${this.classPrefix}__price-value ${csPriceValueExpired}`.trim()}>
            <DynaTooltip tooltipContent={this.renderExpiredProposalTooltip()} tooltipDirection={ETooltipDirection.NORTH}>
              {formatPrice(tripProposal.trip.price, currency)}
            </DynaTooltip>
          </div>
          <div>{this.renderPassengerPriceInfo()}</div>
        </div>

        <div className={`${this.classPrefix}__buttons`}>
          <DynaTooltip tooltipContent={this.renderExpiredProposalTooltip()} tooltipDirection={ETooltipDirection.NORTH}>
            <DynaButton
              className={`${this.classPrefix}__select-button`}
              style={EButtonStyle.ROUNDED}
              color={styleMixer(product).selectButtonColor}
              size={EButtonSize.LARGE}
              disabled={proposalsExpired}
            >
              <span className={`${this.classPrefix}__select-button-label`}>{messages.selectTrip} {faIcon('arrow-circle-right')}</span>
            </DynaButton>
          </DynaTooltip>
        </div>

        <div className={`${this.classPrefix}__stats`}>
          <div className={`${this.classPrefix}__stats-offers`}>
            <span>{faIcon('chevron-right')} {tripProposal.trips.length} {offers}</span>
          </div>
        </div>

      </div>
    );
  }

  private handleSelectProposalClick = (): void => {
    const {
      tripProposal,
      onSelectClick,
    } = this.props;
    onSelectClick(tripProposal);
  };

  private handleSelectProposalClickDown = (): void => {
    this.setState({
      mouseDown: true,
    });
  };

  private handleSelectProposalClickUp = (): void => {
    this.setState({
      mouseDown: false,
    });
  };

  public render(): JSX.Element {
    const {
      className: userClassName,
    } = this.props;
    const {
      mouseDown,
    } = this.state;

    const className: string = [
      userClassName,
      this.classPrefix,
      mouseDown ? `${this.classPrefix}--mouse-down` : '',
    ].join(' ').trim();

    // dev note: is not rendering? this component has shouldComponentUpdate(), check it this also

    return (
      <DynaFastClick
        onClick={this.handleSelectProposalClick}
      >
        <div
          className={className}
          onMouseDown={this.handleSelectProposalClickDown}
          onMouseUp={this.handleSelectProposalClickUp}
          onMouseOut={this.handleSelectProposalClickUp}
        >
          {this.renderLeftPart()}
          {this.renderRightPart()}
        </div>
      </DynaFastClick>
    );
  }
}
