import {InjectedIntl} from "react-intl";
import {IDynaSelectAirTripMessages} from "dyna-travel-ui-select-air-trip";
import {IDynaTravelListProposalsMessages} from "dyna-travel-ui-list-proposals";
import {formatMessage} from "../../../../utils/formatMessage";
import {formatPrice} from "../../../../utils/formatPrice";
import {IDynaPrice} from "dyna-travel-interfaces";

export interface ISearchFlightMessages
  extends IDynaSelectAirTripMessages,
    IDynaTravelListProposalsMessages {
}

let translatedMessages: ISearchFlightMessages;
let lastLocale = '';

export const messages = (intl: InjectedIntl): ISearchFlightMessages => {
  if (lastLocale !== intl.locale) {
    translatedMessages = translateMessages(intl);
    lastLocale = intl.locale;
  }
  return translatedMessages;
};

const translateMessages = (intl: InjectedIntl): ISearchFlightMessages => ({
  // search
  roundTripTypeReturnLabel: formatMessage({intl, id: 'MSG_0617'}),
  roundTripTypeOneWayLabel: formatMessage({intl, id: 'MSG_0618'}),
  roundTripTypeMultiTripLabel: formatMessage({intl, id: 'MSG_0619'}),
  originLabel: formatMessage({intl, id: 'MSG_0620'}),
  originPlaceholder: formatMessage({intl, id: 'MSG_0621'}),
  originValidationOnEmptyForField: formatMessage({intl, id: 'MSG_0622'}),
  originValidationOnEmptyForValidationMessages: formatMessage({intl, id: 'MSG_0623'}),
  destinationLabel: formatMessage({intl, id: 'MSG_0624'}),
  destinationPlaceholder: formatMessage({intl, id: 'MSG_0625'}),
  destinationValidationOnEmptyForField: formatMessage({intl, id: 'MSG_0626'}),
  destinationValidationOnEmptyForValidationMessages: formatMessage({intl, id: 'MSG_0627'}),
  originDestinationsCannotBeTheSameValidationMessages: formatMessage({intl, id: 'MSG_0811'}),
  departureDateLabel: formatMessage({intl, id: 'MSG_0628'}),
  returnDateLabel: formatMessage({intl, id: 'MSG_0629'}),
  clear: formatMessage({intl, id: 'MSG_0630'}),
  search: formatMessage({intl, id: 'MSG_0631'}),
  newSearch: formatMessage({intl, id: 'MSG_0632'}),
  isLoading: formatMessage({intl, id: 'MSG_0633'}),
  tripClassEconomy: formatMessage({intl, id: 'MSG_0634'}),
  tripClassBusiness: formatMessage({intl, id: 'MSG_0635'}),
  passengerAdultsLabel: formatMessage({intl, id: 'MSG_0636'}),
  passengerChildrenLabel: formatMessage({intl, id: 'MSG_0637'}),
  passengerInfantsLabel: formatMessage({intl, id: 'MSG_0638'}),
  passengerSelectionInfo: formatMessage({intl, id: 'MSG_0639'}),
  directFlightsOnly: formatMessage({intl, id: 'MSG_0640'}),
  weekDaysShortNames: formatMessage({intl, id: 'MSG_0645'}),
  monthLongNames: formatMessage({intl, id: 'MSG_0646'}),
  datePickerTodayButton: formatMessage({intl, id: 'MSG_0739'}),
  datePickerCloseButton: formatMessage({intl, id: 'MSG_0740'}),
  calendarPickerBest: formatMessage({intl, id: 'MSG_0812'}),
  calendarPickerCheapest: formatMessage({intl, id: 'MSG_0813'}),
  calendarPickerFastest: formatMessage({intl, id: 'MSG_0814'}),
  calendarPickerNone: formatMessage({intl, id: 'MSG_0815'}),

  // refresh balloon
  refreshBalloonTitle: formatMessage({intl, id: 'MSG_0773'}),
  refreshBalloonDescription: formatMessage({intl, id: 'MSG_0774'}),
  refreshBalloonLaterButton: formatMessage({intl, id: 'MSG_0775'}),
  refreshBalloonRefreshButton: formatMessage({intl, id: 'MSG_0776'}),

  // list proposals
  cancelLoadButton: formatMessage({intl, id: 'MSG_0700'}),
  sortByQualityBest: formatMessage({intl, id: 'MSG_0701'}),
  sortByQualityCheap: formatMessage({intl, id: 'MSG_0702'}),
  sortByQualityFast: formatMessage({intl, id: 'MSG_0703'}),
  selectTrip: formatMessage({intl, id: 'MSG_0704'}),
  selectTripDTF: formatMessage({intl, id: 'MSG_0705'}),
  listTripViewTypeBasic: formatMessage({intl, id: 'MSG_0706'}),
  listTripViewTypeDetails: formatMessage({intl, id: 'MSG_0707'}),
  longDayNames: formatMessage({intl, id: 'MSG_0708'}),
  shortDayNames: formatMessage({intl, id: 'MSG_0645'}),
  shortMonthNames: formatMessage({intl, id: 'MSG_0798'}),
  totalTravelDuration: formatMessage({intl, id: 'MSG_0711'}),
  waitDuration: formatMessage({intl, id: 'MSG_0712'}),
  longWait: formatMessage({intl, id: 'MSG_0713'}),
  overNight: formatMessage({intl, id: 'MSG_0714'}),
  nextDay: formatMessage({intl, id: 'MSG_0715'}),
  nextDateTooltip: formatMessage({intl, id: 'MSG_0715'}),
  stops: formatMessage({intl, id: 'MSG_0716'}),
  direct: formatMessage({intl, id: 'MSG_0717'}),
  offers: formatMessage({intl, id: 'MSG_0718'}),
  operator: formatMessage({intl, id: 'MSG_0719'}),
  handler: formatMessage({intl, id: 'MSG_0720'}),
  waitWithChangeLabel: formatMessage({intl, id: 'MSG_0721'}),
  waitWithChangeTooltip: formatMessage({intl, id: 'MSG_0722'}),
  baggageTypeBaggage: formatMessage({intl, id: 'MSG_0724'}),
  baggageTypeHandBag: formatMessage({intl, id: 'MSG_0725'}),
  perBag: formatMessage({intl, id: 'MSG_0726'}),
  noBags: formatMessage({intl, id: 'MSG_0727'}),
  unknownBags: formatMessage({intl, id: 'MSG_0728'}),
  totalMax: formatMessage({intl, id: 'MSG_0729'}),
  avg: formatMessage({intl, id: 'MSG_0730'}),
  departure: formatMessage({intl, id: 'MSG_0808'}),
  arrival: formatMessage({intl, id: 'MSG_0809'}),
  loadMoreProposals: formatMessage({intl, id: 'MSG_0731'}),
  isLoadingMoreProposals: formatMessage({intl, id: 'MSG_0732'}),
  noTicketsFound: formatMessage({intl, id: 'MSG_0733'}),
  noTicketsFoundTip: formatMessage({intl, id: 'MSG_0734'}),
  youRequestedDirectFlightsOnly: formatMessage({intl, id: "MSG_0790"}),
  totalPriceForNPassengers: (values: { passengersCount: number }): string => formatMessage({
    intl,
    id: 'MSG_0735',
    values,
  }),
  pricePerCustomer: (values: { price: IDynaPrice, currency: string }): string => formatMessage({
    intl,
    id: 'MSG_0736',
    values: {price: formatPrice(intl, values.price, values.currency)},
  }),
  selectTodayButton: formatMessage({intl, id: 'MSG_0742'}),
  selectNextWeekendButton: formatMessage({intl, id: 'MSG_0743'}),

  // filter
  filter: formatMessage({intl, id: 'MSG_0791'}),
  reset: formatMessage({intl, id: 'MSG_0792'}),
  totalAmount: formatMessage({intl, id: 'MSG_0793'}),
  duration: formatMessage({intl, id: 'MSG_0794'}),
  filtered: formatMessage({intl, id: 'MSG_0795'}),
  ofTotal: formatMessage({intl, id: 'MSG_0796'}),
  noFilter: formatMessage({intl, id: 'MSG_0797'}),
});