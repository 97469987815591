import * as React from 'react';
import {Redirect} from "react-router";
import {IRoute} from "../../../interfaces/routes";
import {IsAlive777Page} from "../content/pages/isAlive777";

import {MainPage} from "../content/pages/mainPage";
import {About} from "../content/pages/about/About.loadable";
import {TermsOfUse} from "../content/pages/termsOfUse/TermsOfUse.loadable";
import {PrivacyPolicy} from "../content/pages/privacyPolicy/PrivacyPolicy.loadable";
import {NotFound404} from "../content/errorPages/404-notFound";

export interface IAppRoutes {
	[routeName: string]: IRoute<any>;
	MAIN_PAGE: IRoute<any>;
	ABOUT: IRoute<any>;
	TERMS_OF_USE: IRoute<any>;
	PRIVACY_POLICY: IRoute<any>;
	SIGNIN: IRoute<any>;
	SIGNIN_USER: IRoute<any>;
	SIGNUP_USER: IRoute<any>;
  IS_ALIVE_777: IRoute<any>;
	NOT_FOUND: IRoute<any>;
}

export const APP_ROUTES: IAppRoutes = {
	MAIN_PAGE: {
		path: '/',
		link: () => '/',
		routeProps: {exact: true},
		component:  <Redirect to="/flights/search"/>,
	},
	ABOUT: {
		path: 'external link, hardcoded on line',
		link: () => 'external link, hardcoded on line',
		component: <About appLayer={null}/>,
	},
	TERMS_OF_USE: {
    path: 'external link, hardcoded on line',
    link: () => 'external link, hardcoded on line',
		component: <TermsOfUse appLayer={null}/>,
	},
	PRIVACY_POLICY: {
    path: 'external link, hardcoded on line',
    link: () => 'external link, hardcoded on line',
		component: <PrivacyPolicy appLayer={null}/>,
	},
	SIGNIN: {
		path: '/signin',
		link: () => '/signin',
		component: <MainPage appLayer={null} showSignIn/>,
	},
	SIGNIN_USER: {
		path: '/signin-user/:loginName',
		link: (loginName: string) => `/signin-user/${loginName}`,
		component: <MainPage appLayer={null} showSignIn loginName=""/>,
	},
	SIGNUP_USER: {
		path: '/signup-user/:loginName',
		link: (loginName: string) => `/signup-user/${loginName}`,
		component: <MainPage appLayer={null} showSignUp loginName=""/>,
	},
	IS_ALIVE_777: {
		path: '/is-alive-777',
		link: (loginName: string) => `/is-alive-777/${loginName}`,
		component: <IsAlive777Page appLayer={null} />,
	},
	NOT_FOUND: {
		path: null,
		link: () => null,
		routeProps: {exact: true},
		component: <NotFound404 appLayer={null}/>,
	},
};
