import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {IDynaPlace} from "dyna-travel-interfaces";

import "./PlaceLabel.less";
import {EPlacePoint} from "../interfaces";
import {defaultMessages} from "../messages";

export interface IPlaceLabelProps {
	className?: string;
	size: ESize,
	place: IDynaPlace;
	placePoint?: EPlacePoint;
	showArrow?: boolean;
  showDescription?: boolean;  // departure / arrival
  messages?: {
    departure: string;
    arrival: string;
  },
}

export enum ESize {
	SMALL = "SMALL",
	MEDIUM = "MEDIUM",
	LARGE = "LARGE",
}

const cn: DynaClassName = dynaClassName("dyna-travel-filter-place-label");

export const PlaceLabel: React.SFC<IPlaceLabelProps> = (props) => {
	const classNames: string[] = [
		`--size-${props.size}`,
		props.placePoint && `--place-point-${props.placePoint}` || "--place-point-NONE",
		`--show-arrow-${props.showArrow ? "TRUE" : "FALSE"}`,
    `--show-description-${props.showDescription ? "TRUE" : "FALSE"}`,
	];

  const {
    departure,
    arrival,
  } = props.messages || defaultMessages;

	if (!props.place) return null;

	return (
		<div className={cn.root(props, classNames)}>
			<div className={cn("__container")}>
        {props.placePoint === EPlacePoint.TO && (
          <>
            <div className={cn("__container__description __description")}>
              {arrival}
            </div>
            <div className={cn("__container__arrow __arrow")}>
              <i className="fas fa-caret-right"/>
            </div>
          </>
        )}
				<div className={cn("__container__text")}>
					<div className={cn("__code-name")}>{props.place.name.codeName}</div>
					<div className={cn("__city-name")}>{props.place.city.name.text}</div>
				</div>
        {props.placePoint === EPlacePoint.FROM && (
          <>
            <div className={cn("__container__arrow __arrow")}>
              <i className="fas fa-caret-right"/>
            </div>
            <div className={cn("__container__description __description")}>
              {departure}
            </div>
          </>
        )}
			</div>
		</div>
	);
};
