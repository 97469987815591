import {EColor} from "dyna-ui-styles";

import {EProduct} from "./interfaces";

export interface IStyleMixer {
	primaryColor: EColor;
	secondaryColor: EColor;
}

export const styleMixer = (product: EProduct): IStyleMixer =>{
	switch (product){
		case EProduct.DIE_TO_FLY:
			return {
				primaryColor: EColor.RED_WHITE,
				secondaryColor: EColor.WHITE_RED,
			};
		default:
		case EProduct.IM_HOLIDAY:
			return {
				primaryColor: EColor.ORANGE_WHITE,
				secondaryColor: EColor.WHITE_ORANGE,
			};
	}
};