import {EColor as EHorizontalTripTimelineColor} from "dyna-travel-ui-trip-timeline"
import {EProduct} from "./interfaces";

export interface IColorMixer {
	horizontalTripTimelineColor: EHorizontalTripTimelineColor,
}

export const styleMixer = (product: EProduct): IColorMixer => {
	switch (product) {
		case EProduct.DIE_TO_FLY:
			return {
				horizontalTripTimelineColor: EHorizontalTripTimelineColor.RED,
			};

		case EProduct.IM_HOLIDAY:
		default:
			return {
				horizontalTripTimelineColor: EHorizontalTripTimelineColor.ORANGE,
			};
	}
};