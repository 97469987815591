import {IReduxAction} from "../../../redux";

import {ISearchFlightsState, searchFlightInitialState, searchFlightsReducer} from "./SearchFlights/reducer";

export interface IDynaTravelState {
	searchFlights: ISearchFlightsState;
}

const initialState: IDynaTravelState = {
	searchFlights: searchFlightInitialState,
};

export const reducer = (state: IDynaTravelState = initialState, action: IReduxAction): IDynaTravelState => {
	return {
		searchFlights: searchFlightsReducer(state.searchFlights, action),
	};
};
