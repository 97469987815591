import * as React from "react";
import {IDynaPlace} from "dyna-travel-interfaces";
import {EMode, EStyle, EColor, ESize, debounce} from "dyna-ui-autocomplete";

const isEqual: any = require('lodash.isequal');

export {EMode, EColor, EStyle, ESize}

import {TContent} from "./interfaces";
import {SelectAirportControl} from "./SelectAirportControl/SelectAirportControl";

import {airportToString} from "./utils/airportToString";
import {faIcon} from "./utils/faIcon";

export interface IDynaTravelSelectAirportProps {
  className? :string;
  name: string,
  mode?: EMode,
  label: string,
  style?: EStyle;
  color?: EColor;
  size?: ESize;
  fetchDebounceTimeout?: number;
  validationMessageOnEmptyForField?: TContent;
  validationMessageOnEmptyForValidationMessages?: string;
  value: IDynaPlace;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => void;
  onChange: (name: string, airport: IDynaPlace) => void;
}

export interface IDynaTravelSelectAirportState {
  text?: string;
  suggestAirports?: IDynaPlace[];
  validationMessage?: TContent;
  isLoading?: boolean;
}

export class DynaTravelSelectAirport extends React.Component<IDynaTravelSelectAirportProps, IDynaTravelSelectAirportState> {
  static defaultProps: IDynaTravelSelectAirportProps = {
    className: '',
    name: '',
    mode: EMode.EDIT,
    label: '',
    style: EStyle.INLINE_ROUNDED,
    color: EColor.GREY_WHITE,
    size: ESize.MEDIUM,
    fetchDebounceTimeout: 250,
    value: null,
    validationMessageOnEmptyForField: <span>{faIcon('exclamation-triangle')} required</span>,
    validationMessageOnEmptyForValidationMessages: 'Airport selection is required',
    onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => console.error('onAirportSearch is not defined/implemented'),
    onChange: (name: string, airport: IDynaPlace) => undefined,
  };

  constructor(props: IDynaTravelSelectAirportProps) {
    super(props);
    this.state = {
      text: airportToString(this.props.value),
      suggestAirports: [],
      validationMessage: null,
      isLoading: false,
    };
    this.fetchSuggestions = debounce(this.fetchSuggestions.bind(this), props.fetchDebounceTimeout) as any;
  }

  public componentDidUpdate(prevProps: Readonly<IDynaTravelSelectAirportProps>, prevState: Readonly<IDynaTravelSelectAirportState>, snapshot?: any) {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.setState({
        text: airportToString(this.props.value),
      })
    }
  }

  public validate(): string[] {
    const {validationMessageOnEmptyForValidationMessages} = this.props;
    const validationErrors: string[] = [];

    if (this.props.value) {
      this.setState({
        text: airportToString(this.props.value),
        validationMessage: null,
      });
    }
    else {
      validationErrors.push(validationMessageOnEmptyForValidationMessages);
      this.setState({
        text: '',
        validationMessage: this.props.validationMessageOnEmptyForField,
      });
    }

    return validationErrors;
  }

  private fetchSuggestions(enteredText: string): void {
    this.setState({isLoading: true});
    this.props.onAirportSearch(
      enteredText,
      airports => {
        this.setState({
          suggestAirports: airports,
          isLoading: false,
        })
      },
    )
  }

  private handleAirportChange = (name: string, airportValue: string, airportItem: IDynaPlace): void => {
    this.setState({
      text: airportValue,
      validationMessage: null,
    });
    if (airportItem) this.props.onChange(name, airportItem);
    if (!airportItem) this.fetchSuggestions(airportValue);
  };

  private handleAirportBlur = (event: any): void => {
    this.validate();
    if (this.props.inputProps.onBlur) this.props.inputProps.onBlur(event);
  };

  public render(): JSX.Element {
    const {
      className,
      mode,
      style, color, size,
      name, label, inputProps,
    } = this.props;

    const {
      text,
      suggestAirports,
      isLoading,
      validationMessage,
    } = this.state;

    return (
      <SelectAirportControl
        className={`dyna-travel-select-airport ${className}`.trim()}
        mode={mode}
        style={style}
        color={color}
        size={size}
        name={name}
        label={label}
        inputProps={{
          ...inputProps,
          onBlur: this.handleAirportBlur,
        }}
        validationMessage={validationMessage}
        suggestedAirports={suggestAirports}
        value={text}
        isLoading={isLoading}
        onChange={this.handleAirportChange}
      />
    );
  }
}
