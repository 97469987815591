import * as moment from "moment";
import {Moment} from "moment";

export const getDate0 = (date: Date = new Date): Date => moment(date).startOf('day').toDate();

export const getNextWeekendDepartDate = (addDays: number = 0): Date => {
	const nextFriday: Date = getDate0(getNextWeekDay(getDate0(), 5));
	return getDate0(moment(nextFriday).add({days: addDays}).toDate());
};

const getNextWeekDay = (fromDate: Date, weekDay: number): Date => {
	let scanDate: Moment = moment(fromDate || new Date);
	while (scanDate.get('weekDay' as any) !== weekDay) scanDate = scanDate.add({days: 1});
	return scanDate.toDate();
};
