import * as React from "react";

import {IDynaLoggerConfig} from "dyna-logger";

import {isLocalhost} from "../utils/isLocalhost";

// dev settings - begin
const _debug_ReduxConsole: boolean = false;

// dev settings - end

export enum EAppMode {
  PRODUCTION = "PRODUCTION",
  DEBUG = "DEBUG",
}

export enum EProduct {
  IM_HOLIDAY = "IM_HOLIDAY",
  DIE_TO_FLY = "DIE_TO_FLY",
}

export const debugProduct: EProduct = EProduct.IM_HOLIDAY;
// export const debugProduct: EProduct = EProduct.DIE_TO_FLY;

export const defaultLocale: string = "enUS";

const appMode: EAppMode = isLocalhost ? EAppMode.DEBUG : EAppMode.PRODUCTION;
// const appMode: EAppMode = (1 + 1 === 2) ? EAppMode.DEBUG : EAppMode.PRODUCTION;

console.info('AppMode: ', appMode);

export enum ERouterType {
  BROWSER,  // classic paths www.example.com/contact
  HASH,     // hash paths www.example.com/#/contact
}

export interface IAppConfig {
  mode: EAppMode;
  product: EProduct;
  productInfo: IProductInfo;
  routerType: ERouterType;
  mainCurrencies: string[];
  loadingSpinnerComponent: JSX.Element;
  loggerConfig: IDynaLoggerConfig;
  loggerGlobalSecretName: string;
  gdprVersion: number;
  _debug_ReduxConsole: boolean;
}

const hideLogs: boolean = localStorage.getItem('__lookupKernel__') !== "disabled";

interface IProductsInfo {
  [product: string]: IProductInfo;
}

interface IProductInfo {
  addressId: string;
  name: string;
  url: string;
  description: string;
  moto: string;
  logoPreview: string;
  twitterAccount: string;
}

const productsInfo: IProductsInfo = {
  [EProduct.IM_HOLIDAY]: {
    addressId: "www.imholiday.com",
    name: "imHoliday",
    url: "https://www.imholiday.com",
    description: "A flights search engine",
    moto: "simplicity matters",
    logoPreview: "https://www.imholiday.com/imHoliday_320x221_icon.png",
    twitterAccount: "imholidaycom",
  } as IProductInfo,
  [EProduct.DIE_TO_FLY]: {
    addressId: "www.dietofly.com",
    name: "DieToFly",
    url: "https://www.dietofly.com",
    description: "A fucken flights search engine",
    moto: "you die to fly, we die to fly you",
    logoPreview: "https://www.dietofly.com/assets/dietofly-square-320.png",
    twitterAccount: '',
  } as IProductInfo,
};

export const appConfig: IAppConfig = (() => {
  const product: EProduct = (() => {
    if (document.location.hostname === 'www.imholiday.com') return EProduct.IM_HOLIDAY;
    if (document.location.hostname === 'www.dietofly.com') return EProduct.DIE_TO_FLY;
    return debugProduct;
  })();
  const productInfo: IProductInfo = productsInfo[product];

  return {
    mode: appMode,

    product,
    productInfo,

    mainCurrencies: ["USD", "EUR", "GBP"],
    routerType: ERouterType.BROWSER,
    loadingSpinnerComponent: <i className="fa fa-circle-o-notch fa-spin"/>,
    loggerConfig: {
      replaceGlobalLogMethods: appMode === EAppMode.PRODUCTION && hideLogs,
      consoleTimestamp: false,
      consoleLogs: appMode === EAppMode.DEBUG,
      consoleInfoLogs: appMode === EAppMode.DEBUG,
      consoleErrorLogs: appMode === EAppMode.DEBUG,
      consoleWarnLogs: appMode === EAppMode.DEBUG,
      consoleDebugLogs: appMode === EAppMode.DEBUG,
      bufferLimit: 2000,
    },
    loggerGlobalSecretName: "__lookupKernel__",
    gdprVersion: 1,
    _debug_ReduxConsole: appMode === EAppMode.PRODUCTION ? false : _debug_ReduxConsole,
  } as IAppConfig;
})();
