import * as React from "react";
import {CSSProperties} from "react";
import {DynaTooltip} from "dyna-ui-tooltip";

import {EDynaTransport, IDynaTrip} from "dyna-travel-interfaces";

import {Flight} from "../entities/Flight";

import {EColor, EViewType} from "../DynaTravelTripTimeline";
import {EDirection, SegmentContent} from "./SegmentContent";

import {isDesktop} from "../utils/isDesktop";
import {faIcon} from "../utils/faIcon";

import "./SegmentLine.less";

export interface ISegmentLineProps {
	viewType: EViewType;
	color: EColor;
	segment: IDynaTrip;
	segmentPercentage: number;
	formatDuration: (minutes: number) => string;
	messages: {
		operator: string;
		handler: string;
		waitWithChangeLabel: string;
		waitWithChangeTooltip: string;
		longWait: string;
		baggageTypeBaggage: string;
		baggageTypeHandBag: string;
		perBag: string;
		noBags: string;
		unknownBags: string;
		totalMax: string;
		overNight: string;
	};
}

export class SegmentLine extends React.Component<ISegmentLineProps> {
	private readonly className: string = "dt-trip-timeline__segment-line";

	constructor(props: ISegmentLineProps) {
		super(props);
	}

	private renderFlight(): JSX.Element {
		const {viewType, color, messages, segment} = this.props;
		if (segment.transport.type !== EDynaTransport.AIRPLANE) return null;
		return (
			<Flight
				viewType={viewType}
				color={color}
				messages={messages}
				segment={segment}
			/>
		);
	}

	private renderDurationTime(): JSX.Element {
		const {
			segment,
			formatDuration,
			messages: {longWait, overNight},
		} = this.props;
		const className: string = [
			`${this.className}__duration-time`,
			segment.stats.longWaitTime ? `${this.className}__duration-time--long-wait` : "",
		].join(' ').trim();

		const moons: JSX.Element[] =
			Array(segment.stats.nightLongWaitingTimes || 0)
				.fill(null).map((v: any, index: number) => faIcon('moon-o', '', index));

		const tooltip: JSX.Element[] = [
			moons.length ? <div key={0}>{moons}{moons.length ? ` ${overNight}` : ""}</div> : null,
			segment.stats.longWaitTime ? <div key={1}><i className="far fa-clock"/> {longWait}</div> : null,
		].filter(v => !!v);

		return (
			<div className={className}>
				<DynaTooltip
					enabled={isDesktop}
					tooltipContent={tooltip}
					delayCreationMs={1000}
				>
					<div>{moons}</div>
					<div>
						{segment.stats.longWaitTime ? <span>{faIcon('exclamation-circle')} </span> : null}
						{formatDuration(segment.stats.durationInMin)}
					</div>
				</DynaTooltip>
			</div>
		);
	}

	private renderUpContent(): JSX.Element {
		const {color} = this.props;
		return (
			<SegmentContent
				color={color}
				distance={1}
				showStick={false}
				direction={EDirection.UP}
			>
				{this.renderDurationTime()}
			</SegmentContent>
		);
	}

	private isWaitWithDifferentOriginDestination(): boolean {
		const {segment} = this.props;
		return (
			segment.transport.type === EDynaTransport.WAIT &&
			segment.origin.name.codeName !== segment.destination.name.codeName
		);
	}

	private renderWaitWithChange(): JSX.Element {
		const {messages: {waitWithChangeLabel, waitWithChangeTooltip}} = this.props;
		if (!this.isWaitWithDifferentOriginDestination()) return null;
		return (
			<div className={`${this.className}__wait-with-different-origin-destination`}>
				<DynaTooltip
					enabled={isDesktop}
					tooltipContent={waitWithChangeTooltip}
					delayCreationMs={1000}
				>
					<span>{faIcon('exclamation-triangle')} {waitWithChangeLabel}</span>
				</DynaTooltip>
			</div>
		);
	}

	private renderDownContent(): JSX.Element {
		const {color} = this.props;
		return (
			<SegmentContent
				color={color}
				distance={1}
				showStick={true}
				direction={EDirection.DOWN}
			>
				{this.renderFlight()}
				{this.renderWaitWithChange()}
			</SegmentContent>
		);
	}

	private getClassDifferentWaitPlace(): string {
		if (this.isWaitWithDifferentOriginDestination()) {
			return `${this.className}__line--type-WAIT-with-different-places`;
		}
		return "";
	}


	public render(): JSX.Element {
		const {
			color,
			segment,
			segmentPercentage,
		} = this.props;

		const containerClassName: string = [
			this.className,
			`${this.className}--color-${color}`,
		].join(' ').trim();


		const lineClassname: string = [
			`${this.className}__line`,
			`${this.className}__line--type-${segment.transport.type}`,
			this.getClassDifferentWaitPlace(),
		].join(' ').trim();


		const style: CSSProperties = {
			width: `${segmentPercentage}%`,
		};

		return (
			<div className={containerClassName} style={style}>
				<div className={lineClassname}/>
				<div className={`${this.className}__float-content`}>
					{this.renderUpContent()}
					{this.renderDownContent()}
				</div>
			</div>
		);
	}
}
