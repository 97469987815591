import * as React from "react";
import {DynaClassName, dynaClassName} from "dyna-class-name";

import "./FacebookLikeButton.less";

// help https://developers.facebook.com/docs/plugins/like-button

export interface IFacebookLikeButtonProps {
	className?: string;
}

const className: DynaClassName = dynaClassName("dyna-travel-app-facebook-like-button");

export const FacebookLikeButton: React.SFC<IFacebookLikeButtonProps> = (props) => (
	<div className={className.root(props)}>
		<div
			className="fb-like"
			data-href="https://www.facebook.com/imholidaycom"
			data-width="200"
			data-layout="button"
			data-action="like"
			data-size="large"
			data-show-faces="true"
			data-share="true"
		/>
	</div>
);
