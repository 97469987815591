export interface IDebug_requestDevDemoConfig {
  requestDevDemoTripFilename: string;
  requestDevDemoWithResponseDelay: number;
}

export const debug_requestDevDemoConfig: IDebug_requestDevDemoConfig = {
  requestDevDemoTripFilename: null,
  // requestDevDemoTripFilename: "2018-08-22T05-05-16.687Z-VIE-ATH-ATH-VIE-1f120a7f-1h361adc-16005924740647120",    // direct flights only
  // requestDevDemoTripFilename: "2018-07-15T09-07-37.161Z-VIE-LXS-LXS-VIE-1h47b0c8-1gba6d65-38417963949547120",    // bog complex Vienna Milos
  requestDevDemoWithResponseDelay: 20,
};
