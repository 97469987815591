import * as React from "react";
import {useState, useEffect} from "react";
import {FormattedMessage} from 'react-intl';
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {EDeviceCategory, hasEnv} from "dyna-detect-env";

import {hasAdBlock} from "../../../../../utils/hasAdBlock";

import "./AdBlockNotificationBanner.css";

export interface IAdBlockNotificationBannerProps {
  className?: string;
}

const cs: DynaClassName = dynaClassName("dyna-app-ad-block-notification-banner");

export const AdBlockNotificationBanner: React.SFC<IAdBlockNotificationBannerProps> = (props) => {
  const shouldShow: boolean = !hasEnv(EDeviceCategory.desktop) && hasAdBlock();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!shouldShow) return;
    setTimeout(() => setShow(true), 10000);
  }, []);

  if (!show) return null;

  return (
    <div className={cs.root(props)}>
      <div className={cs("__icon")}>
        <i className="fas fa-info-circle"/>
      </div>
      <div className={cs("__message")}>
        <FormattedMessage id="MSG_0782"/>
      </div>
    </div>
  );
};