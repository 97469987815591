import * as React from 'react';
import {Link} from "react-router-dom";
import {dynaClassName, DynaClassName} from "dyna-class-name";

import {IAppContainerProps} from "../../../../../interfaces/app";
import {appTitle} from "../../../../../components/ui/appTitle/appTitle";

import {faIcon} from "../../../../../components/ui/faIcon/faIcon";

import "./index.css";

const cn: DynaClassName = dynaClassName("app-404-error-page");

export const NotFound404 = (props: IAppContainerProps) => (
	<div className={cn()}>
		{appTitle("404 not found")}
		<h1 className={cn("__title")}>{faIcon('chain-broken')} 404</h1>
		<p className={cn("__text")}>sorry, page not found, <Link to="/">go home</Link></p>
	</div>
);
