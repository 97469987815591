import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {guid} from "dyna-guid";

import {connect as reduxConnect, Dispatch} from "react-redux";
import {connect as dynaduxConnect} from "react-dynadux";

import {IDynaTripRequest, IDynaPlace, IDynaTripProposal, ETripQualityIndex} from "dyna-travel-interfaces";
import {IDynaTripFilter} from "dyna-travel-ui-list-proposals";

import {IHostAppStore} from "../..";
import {IAppContainerProps} from "../../../../interfaces/app";

import {messages} from "./messages";
import {SearchFlightComponent, ISearchFlightsComponentProps} from './SearchFlightsComponent';

import {appConfig} from "../../../../settings/app";
import {DYNA_TRAVEL_MODULE_ROUTES} from "../../routes/dynaTravelModuleRoutes";

import {
  dynaTravelSearchFlightsUpdateUIRequestTrip,
  dynaTravelSearchFlightsUpdateUIClearTrip,
  dynaTravelSearchFlightsUpdateUINewSearch,
  dynaTravelSearchFlightsRequestSearchAirTrip,
  dynaTravelSearchFlightsReceiveSearchAirTripCancel,
  dynaTravelSearchFlightsListViewSortByTripQualityIndex,
  dynaTravelSearchFlightsListViewLoadMore,
  dynaTravelSearchFlightsUpdateUISelectToday,
  dynaTravelSearchFlightsUpdateUISelectNextWeekend,
  dynaTravelSearchFlightsRefreshLater,
  dynaTravelSearchFlightsRefreshNow,
  dynaTravelSearchFlightsFilterChange,
  dynaTravelSearchFlightsSetFilteredTripProposals,
} from "../../state/SearchFlights/actions";

import {ISearchFlightProcess} from "../../state/SearchFlights/reducer";
import {dynaTravelModule} from "../../logic";
import {serializeTripRequest} from "../../utils/tripRequestSerialize";

export interface ISearchFlightsProps extends IAppContainerProps {
  searchFlightProcessId?: string;
  serializedRequestTrip?: string;
  viewProposalDetailsTpid?: string;
}

const mapStateToProps = (state: IHostAppStore, ownProps: ISearchFlightsProps): ISearchFlightsComponentProps => {
  const {intl} = ownProps;

  const searchFlightProcess: ISearchFlightProcess = state.dynaTravelModule.searchFlights.searchFlightProcesses[ownProps.searchFlightProcessId];

  return {
    intl,
    product: appConfig.product,
    searchFlightProcessId: ownProps.searchFlightProcessId,
    currency: state.app.currency,
    messages: messages(intl),
    mode: searchFlightProcess.selectTripFormMode,
    isLoading: searchFlightProcess.isLoading,
    requestIsCanceled: searchFlightProcess.requestIsCanceled,
    tripRequestIdLastSearched: searchFlightProcess.tripRequestIdLastSearched,
    loadingProgressPercent: searchFlightProcess.loadingProgressPercent,
    loadingProgressMessageId: searchFlightProcess.loadingProgressMessageId,
    noTripsFound: searchFlightProcess.noTripsFound,
    isLoadingMore: searchFlightProcess.isLoadingMore,
    tripProposalsExpired: searchFlightProcess.tripProposalsExpired,
    showExpiredBalloon: searchFlightProcess.showExpiredBalloon,
    requestTripDirectFlightsOnly: searchFlightProcess.requestTrip.directOnly,

    tripQualityBest: searchFlightProcess.tripQualityBest,
    tripQualityCheap: searchFlightProcess.tripQualityCheap,
    tripQualityFast: searchFlightProcess.tripQualityFast,

    tripRequestFromURL: ownProps.serializedRequestTrip,
    tripRequest: searchFlightProcess.requestTrip,
    errorMessageId: searchFlightProcess.errorMessageId,
    errorMessages: searchFlightProcess.errorMessages,

    viewProposalDetailsTpid: ownProps.viewProposalDetailsTpid,
    proposalDetailsContent: dynaTravelModule.searchTripManager.getTripProposalByTpid(ownProps.searchFlightProcessId, ownProps.viewProposalDetailsTpid),

    tripsFilter: searchFlightProcess.tripsFilter,
    sortByTripQualityIndex: searchFlightProcess.sortByTripQualityIndex,
    // Dev note for tripProposals property
    // Map and copy them, otherwise the List Proposals componentShouldUpdate doesn't work properly, the this.props.tripProposal has the new tripProposal!
    // Also this should be done on render and not in the data holder (where it build the proposals) because the build happens only once on load time and this is not triggering any render!
    tripProposals: searchFlightProcess.tripProposals.map((tp: IDynaTripProposal) => ({...tp})),
    allTripProposals: dynaTravelModule.searchTripManager.getAllTripProposals(ownProps.searchFlightProcessId), // used for stats
    tripProposalsAllCount: searchFlightProcess.tripProposalsAllCount,
    moreTripProposalsCount: searchFlightProcess.moreTripProposalsCount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: ISearchFlightsProps): ISearchFlightsComponentProps => {
  const {
    intl,
    appLayer,
    searchFlightProcessId,
  } = ownProps;

  return {
    onAirportSearch: (enteredText: string, cbLoad: (airports: IDynaPlace[]) => void) => {
      // Todo: BUG: here the intl.locale is always 'en' regrdless of the choosen language!
      dynaTravelModule.searchAirport(enteredText, intl.locale)
        .then((airports: IDynaPlace[]) => cbLoad(airports))
        .catch(error => console.error('Cannot search airport', error));
    },
    onChange: (tripRequest: IDynaTripRequest) => {
      dispatch(dynaTravelSearchFlightsUpdateUIRequestTrip(searchFlightProcessId, tripRequest));
    },

    navigateToSearch: (tripRequest: IDynaTripRequest) => {
      // hack: since we cannot force the react router to re-render the same link, change the link a little bit
      tripRequest.id = guid(0);
      const serializedRequestTrip: string = serializeTripRequest(tripRequest);
      appLayer.navigate(DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_SEARCH_FLIGHTS.link(serializedRequestTrip));
    },
    onSearch: (tripRequest: IDynaTripRequest) => {
      tripRequest.language = intl.locale;
      const reduxStore = (window as any)._temp_reduxStore;
      tripRequest.currency = reduxStore.getState().app.currency;
      const serializedRequestTrip: string = serializeTripRequest(tripRequest);
      dispatch(dynaTravelSearchFlightsRequestSearchAirTrip(searchFlightProcessId, tripRequest, serializedRequestTrip));
    },

    navigateToNewSearch: () => {
      appLayer.navigate(DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_SEARCH_FLIGHTS.link());
    },
    onNewSearch: () => {
      dispatch(dynaTravelSearchFlightsUpdateUINewSearch(searchFlightProcessId));
    },

    onClearForm: () => {
      dispatch(dynaTravelSearchFlightsUpdateUIClearTrip(searchFlightProcessId));
    },
    onCancelTripRequest: (reset: boolean) => {
      dispatch(dynaTravelSearchFlightsReceiveSearchAirTripCancel(searchFlightProcessId, reset));
    },
    onSortByQuality: (qualityTripindex: ETripQualityIndex) => {
      dispatch(dynaTravelSearchFlightsListViewSortByTripQualityIndex(searchFlightProcessId, qualityTripindex));
    },
    onLoadMoreProposals: () => {
      dispatch(dynaTravelSearchFlightsListViewLoadMore(searchFlightProcessId));
    },
    onSelectToday: () => {
      dispatch(dynaTravelSearchFlightsUpdateUISelectToday(searchFlightProcessId));
    },
    onSelectNextWeekend: () => {
      dispatch(dynaTravelSearchFlightsUpdateUISelectNextWeekend(searchFlightProcessId));
    },

    navigateToShowProposalDetails: (tripRequest: IDynaTripRequest, tpid: string) => {
      const serializedRequestTrip: string = serializeTripRequest(tripRequest);
      appLayer.navigate(DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_SEARCH_FLIGHTS.link(serializedRequestTrip, tpid));
    },
    navigateToHideProposalDetails: (tripRequest: IDynaTripRequest) => {
      const serializedRequestTrip: string = serializeTripRequest(tripRequest);
      appLayer.navigate(DYNA_TRAVEL_MODULE_ROUTES.DYNA_TRAVEL_SEARCH_FLIGHTS.link(serializedRequestTrip));
    },

    onRefreshLaterClick: () => {
      dispatch(dynaTravelSearchFlightsRefreshLater(searchFlightProcessId));
    },
    onRefreshNowClick: () => {
      dispatch(dynaTravelSearchFlightsRefreshNow(searchFlightProcessId));
    },

    onFilterChange: (filter: IDynaTripFilter) => {
      dispatch(dynaTravelSearchFlightsFilterChange(searchFlightProcessId, filter));
    },

    onFilteredTripProposals: (filteredTripProposals: IDynaTripProposal[]): void => {
      dispatch(dynaTravelSearchFlightsSetFilteredTripProposals(searchFlightProcessId, filteredTripProposals));
    },
  };
};

export const SearchFlights =
  (withRouter as any)(
    injectIntl(
      reduxConnect(mapStateToProps, mapDispatchToProps)
      (dynaduxConnect(SearchFlightComponent)) as any,
    ),
  );


