import * as React from 'react';
import {Link} from "react-router-dom";

export interface ICompanyLogoProps {
	className?: string;
	title?: string;
	imgLink?: string;
	children?: JSX.Element | string;
	companyLink?: string;
}

export const CompanyLogo = (props: ICompanyLogoProps) =>
	<div className={props.className || ''} itemScope itemType="http://schema.org/Attorney">
		<Link className="clear-link" itemProp="url" to={props.companyLink || '/'} title={props.title}>
			{props.imgLink ? <img itemProp="logo" src={props.imgLink} alt={props.title}/> : null}
			{props.children ? <div itemProp="logo">{props.children}</div> : null}
		</Link>
	</div>;
