import {connect, Dispatch} from "react-redux";
import {IAppStore} from "../../../../../redux";

import {component} from './component';
import {IAppContainerProps} from "../../../../../interfaces/app";

export interface IIsAlive777ContainerProps extends IAppContainerProps {
}

const mapStateToProps = (state: IAppStore, ownProps: IIsAlive777ContainerProps): any => ({
	appLabel: ownProps.appLayer.appLabel,
	isLogged: !!state.auth.userState.userId,
});

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: IIsAlive777ContainerProps): any => {
	return {
	};
};

export const IsAlive777Page = connect(
	mapStateToProps,
	mapDispatchToProps,
)(component);

