import {DynaAnimationVerticalContainer} from "dyna-animation";
import * as React from "react";
import {ETripQualityIndex, IDynaPrice, ITripQualityData} from "dyna-travel-interfaces";

import {EProduct} from "../../interfaces";
import {faIcon} from "../../utils/faIcon";

import "./SortByTripQualityIndex.less";

export interface ISortByTripQualityIndexProps {
  product: EProduct;
  currency: string;
  messages: {
    sortByQualityBest: string;
    sortByQualityCheap: string;
    sortByQualityFast: string;
    avg: string;
  };
  formatDuration?: (minutes: number) => string;
  show: boolean;
  sortByTripQuality: ETripQualityIndex;
  tripQualityBest: ITripQualityData;
  tripQualityCheap: ITripQualityData;
  tripQualityFast: ITripQualityData;
  formatPrice: (price: IDynaPrice, currency: string) => string;
  onClick: (qualityTripIndex: ETripQualityIndex) => void;
}

export class SortByTripQualityIndex extends React.Component<ISortByTripQualityIndexProps> {

  private renderATab(qualityTripIndex: ETripQualityIndex, icon: string, label: string, tripQualityData: ITripQualityData, selected: boolean): JSX.Element {
    const classPrefix: string = "dtlp-sort-by-trip-quality-index";
    const className: string = [
      `${classPrefix}__item`,
      selected ? `${classPrefix}__item--selected` : "",
    ].join(' ').trim();
    const {
      currency,
      formatPrice,
      formatDuration,
      onClick,
      messages: {
        avg,
      },
    } = this.props;

    if (!tripQualityData) return null;

    return (
      <div className={className} onClick={onClick.bind(null, qualityTripIndex)}>
        <div className={`${classPrefix}__item-icon`}>{faIcon(icon)}</div>
        <div className={`${classPrefix}__item-info`}>
          <div className={`${classPrefix}__item-info-label`}>{label}</div>
          <div className={`${classPrefix}__item-info-price`}>{formatPrice(tripQualityData.topProposal && tripQualityData.topProposal.trip.price, currency)}</div>
          <div className={`${classPrefix}__item-info-avg-price`}>
            <span><i className="far fa-money-bill-alt"/> {avg} {formatPrice(tripQualityData.avgPrice, currency)}</span>
          </div>
          <div className={`${classPrefix}__item-info-avg-duration`}>
            <span><i className="far fa-clock"/> {avg} {formatDuration(tripQualityData.avgDurationInMin)}</span>
          </div>
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    const {
      product,
      show,
      messages: {
        sortByQualityBest,
        sortByQualityCheap,
        sortByQualityFast,
      },
      sortByTripQuality,
      tripQualityBest,
      tripQualityCheap,
      tripQualityFast,
    } = this.props;

    const className: string = [
      "dtlp-sort-by-trip-quality-index",
      `dtlp-sort-by-trip-quality-index--product-${product}`,
      `dtlp-sort-by-trip-quality-index--${show ? "show" : "hide"}`,
    ].join(' ').trim();

    return (
      <DynaAnimationVerticalContainer
        show={show}
      >
        <div className={className}>
          {this.renderATab(ETripQualityIndex.BEST, 'thumbs-up', sortByQualityBest, tripQualityBest, sortByTripQuality === ETripQualityIndex.BEST)}
          {this.renderATab(ETripQualityIndex.CHEAP, 'money', sortByQualityCheap, tripQualityCheap, sortByTripQuality === ETripQualityIndex.CHEAP)}
          {this.renderATab(ETripQualityIndex.FAST, 'rocket', sortByQualityFast, tripQualityFast, sortByTripQuality === ETripQualityIndex.FAST)}
        </div>
      </DynaAnimationVerticalContainer>
    );
  }
}
