import { IDynaPrice } from "dyna-interfaces";
import { DynaCurrencies } from "dyna-currencies";
import { roundToString } from "dyna-loops";

import { IPriceViewData } from "../interfaces";

export const getPrice = (
  {
    currency,
    currencies,
    price,
  }
    : {
    currency: string;
    currencies: DynaCurrencies;
    price: IDynaPrice | null;
  }
): IPriceViewData => {
  if (!price) {
    return {
      price,
      label: '-',
    };
  }
  return {
    price,
    label: getPriceLabel(currencies.convert(price.value, price.currency, currency)),
  };
};


export const getPriceLabel = (value: number): string => {
  if (value > 1000000) return `${roundToString(value / 1000000, 1)}M`;
  if (value > 1000) return `${roundToString(value / 1000, 1)}k`;
  return `${roundToString(value, 0)}`;
};
