import * as moment from "moment";
import * as React from "react";
const christmasHat = require('./christmas-hat.png');
import "./ChristmasHat.less";

export const ChristmasHat = (): JSX.Element | null => {
  const shouldShowChristmasHat = (() => {
    const year = (new Date()).getFullYear();
    return moment().isBetween(`${year}-11-01`, `${year + 1}-01-14`);
  })();

  if (!shouldShowChristmasHat) return null;
  return (
    <div className="christmas-hat">
      <img src={christmasHat}/>
    </div>
  );
};
