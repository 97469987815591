import * as React from "react";
import {DynaButton, ESize} from "dyna-ui-button";

import {EProduct} from "../../interfaces";
import {styleMixer} from "../../styleMixer";
import {IDynaTravelListProposalsMessages} from "../../messages";

import "./NoTripFound.less";

export interface INoTripFoundProps {
  className?: string;
  product: EProduct;
  requestTripDirectFlightsOnly: boolean;
  messages: IDynaTravelListProposalsMessages;
  onSelectTodayClick: () => void;
  onSelectNextWeekendClick: () => void;
}

export class NoTripFound extends React.Component<INoTripFoundProps> {
  private readonly baseClassName: string = "dtlp-no-trip-found";

  private className(subClassName = ""): string {
    return `${this.baseClassName}${subClassName || ""}`;
  }

  public render(): JSX.Element {
    const {
      className: userClassName,
      product,
      requestTripDirectFlightsOnly,
      messages: {
        noTicketsFound,
        noTicketsFoundTip,
        selectTodayButton,
        selectNextWeekendButton,
        youRequestedDirectFlightsOnly,
      },
      onSelectTodayClick,
      onSelectNextWeekendClick,
    } = this.props;

    const className: string = [
      this.className(),
      userClassName,
    ].join(' ').trim();

    return (
      <div className={className}>
        <div className={this.className("__icon-message-container")}>
          <div className={this.className("__icon-message-container__icon")}>
            <i className="fas fa-cloud"/>
          </div>
          <div className={this.className("__icon-message-container__label")}>
            <div className={this.className("__icon-message-container__label__main")}>{noTicketsFound}</div>
            <div className={this.className("__icon-message-container__label__tip")}>{noTicketsFoundTip}</div>
            {requestTripDirectFlightsOnly
              ? <div className={this.className("__icon-message-container__label__direct")}><i className="fas fa-exclamation-circle"/> {youRequestedDirectFlightsOnly}</div>
              : null}
          </div>
        </div>
        <div className={this.className("__buttons-container")}>
          <DynaButton
            size={ESize.LARGE}
            color={styleMixer(product).selectTodayButton}
            onClick={onSelectTodayClick}
          >{selectTodayButton}</DynaButton>
          <DynaButton
            size={ESize.LARGE}
            color={styleMixer(product).selectNextWeekendButton}
            onClick={onSelectNextWeekendClick}
          >{selectNextWeekendButton}</DynaButton>
        </div>
      </div>
    );
  }
}
