import * as React from "react";
import {faIcon} from "../utils/faIcon";

import './DeirectionExchangeIcon.less';

export interface IDirectionExchangeIconProps {
}

export interface IDirectionExchangeIconState {
  flip: boolean;
}

export class DirectionExchangeIcon extends React.Component<IDirectionExchangeIconProps, IDirectionExchangeIconState> {
  static defaultProps:IDirectionExchangeIconProps={
  };
  constructor(props: IDirectionExchangeIconProps) {
    super(props);
    this.state = {
      flip: false,
    }
  }

  public flip(): void {
    this.setState({flip: !this.state.flip});
  }

  public render(): JSX.Element {
    const {
      flip,
    } = this.state;

    const className: string = [
      'exchange', // fa font name, should be first!
      'direction-exchange-icon',
      flip ? 'direction-exchange-icon--flip' : '',
    ].join(' ').trim();

    return faIcon(className)
  }
}
