import * as React from "react";
import { EQualityIndex } from "../interfaces";
import { DynaFastClick } from "dyna-ui-fast-click";
import { cn } from "../../utils/cn";

import * as styles from "./Option.module.less";

export interface IOptionProps {
  qualityIndex: EQualityIndex;
  label: string;
  selected: boolean;
  onClick: (index: EQualityIndex) => void;
}

export const Option = (props: IOptionProps): JSX.Element => {
  const {
    qualityIndex,
    label,
    selected,
    onClick,
  } = props;

  const handleClick = (): void => onClick(qualityIndex);

  return (
    <div className={cn(styles.container)}>
      <DynaFastClick onClick={handleClick}>
        <div className={cn(styles.root, selected && styles.selected)}>
          <div className={styles.icon}>
            {getIcon(qualityIndex)}
          </div>
          <div className={styles.label}>
            {label}
          </div>
        </div>
      </DynaFastClick>
    </div>
  );
};

const getIcon = (qualityIndex: EQualityIndex): JSX.Element => {
  switch (qualityIndex) {
    case EQualityIndex.NONE:
      return <i className="fa fa-circle-o" aria-hidden="true"/>;
    case EQualityIndex.BEST:
      return <i className="fa fa-thumbs-up" aria-hidden="true"/>;
    case EQualityIndex.CHEAPEST:
      return <i className="fa fa-money" aria-hidden="true"/>;
    case EQualityIndex.FASTEST:
      return <i className="fa fa-rocket" aria-hidden="true"/>;
  }
};
