(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dyna-guid"), require("react"), require("react-autocomplete"), require("dyna-ui-field-wrapper"), require("dyna-ui-picker-container"));
	else if(typeof define === 'function' && define.amd)
		define("dyna-ui-autocomplete", ["dyna-guid", "react", "react-autocomplete", "dyna-ui-field-wrapper", "dyna-ui-picker-container"], factory);
	else if(typeof exports === 'object')
		exports["dyna-ui-autocomplete"] = factory(require("dyna-guid"), require("react"), require("react-autocomplete"), require("dyna-ui-field-wrapper"), require("dyna-ui-picker-container"));
	else
		root["dyna-ui-autocomplete"] = factory(root["dyna-guid"], root["react"], root["react-autocomplete"], root["dyna-ui-field-wrapper"], root["dyna-ui-picker-container"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_dyna_guid__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_autocomplete__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_field_wrapper__, __WEBPACK_EXTERNAL_MODULE_dyna_ui_picker_container__) {
return 