import * as React from "react";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {DynaButton, EColor, ESize} from "dyna-ui-button";

import "./LoadMoreButton.less";

export interface ILoadMoreButtonProps {
  className?: string;
  show: boolean;
  color: EColor;
  isLoading: boolean;
  labelLoadMore: string | JSX.Element;
  labelIsLoading: string | JSX.Element;
  isLoadingIcon: JSX.Element;
  listedProposals: number;
  hasMoreProposals: number;
  onClickLoadMore: () => void;
}

export class LoadMoreButton extends React.Component<ILoadMoreButtonProps> {
  private readonly baseClassName: string = "dtlp-load-more-button";

  private className(subClassName = ""): string {
    return `${this.baseClassName}${subClassName || ""}`;
  }

  public render(): JSX.Element {
    const {
      className: userClassName,
      show,
      color,
      isLoading,
      labelIsLoading,
      labelLoadMore,
      isLoadingIcon,
      listedProposals,
      hasMoreProposals,
      onClickLoadMore,
    } = this.props;

    const className: string = [
      this.className(),
      userClassName,
      show ? "" : this.className("--hide"),
    ].join(' ').trim();

    const label: string | JSX.Element =
      isLoading
        ? labelIsLoading
        : <span>{labelLoadMore} <span className={this.className("__stats")}><span className="fa fa-eye"/> {listedProposals}/{hasMoreProposals}</span></span>;

    return (
      <DynaAnimationVerticalContainer
        show={show}
        className={className}
      >
        <DynaButton
          className={this.className("__button")}
          size={ESize.XXLARGE}
          color={color}
          disabled={isLoading}
          onClick={onClickLoadMore}
        >
          {isLoading ? <span>{isLoadingIcon} </span> : null}
          <span>{label}</span>
        </DynaButton>
      </DynaAnimationVerticalContainer>
    );
  }
}
