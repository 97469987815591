import * as React from "react";
import { DynaFastClick } from "dyna-ui-fast-click";

import { cn } from "../../utils/cn";

import * as stylesOption from "./Option.module.less";
import * as stylesCheckbox from "./ShowPriceCheckBox.module.less";

export interface IShowPriceCheckBoxProps {
  disabled: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const ShowPriceCheckBox = (props: IShowPriceCheckBoxProps): JSX.Element => {
  const {
    disabled,
    value,
    onChange,
  } = props;

  const handleClick = (): void => {
    if (disabled) return;
    onChange(!value);
  };

  return (
    <div className={cn(stylesOption.container, stylesCheckbox.root, disabled && stylesCheckbox.disabled )}>
      <DynaFastClick onClick={handleClick}>
        <div
          className={cn(stylesOption.root, value && stylesOption.selected, stylesCheckbox.iconContainer)}
        >
          <i className="fas fa-money-bill-wave"/>
        </div>
      </DynaFastClick>
    </div>
  );
};
