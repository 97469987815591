import {EErrorType, IError} from "dyna-interfaces";
import {DynaNodeClient} from "dyna-node/dist/commonJs/web";
import {IDynaPlace} from "dyna-travel-interfaces";
import {DynaCache} from "dyna-cache";

import {
  COMMAND_searchAirport,
  ICOMMAND_searchAirport_args,
  COMMAND_searchAirportResults,
  ICOMMAND_searchAirportResults_data,
} from "dyna-travel-services/dist/esNext/searchFlights/client";

const cache = new DynaCache();

export const searchAirport = (nodeDevice: DynaNodeClient, serviceDNA: string, searchText: string, language: string, count: number = 10): Promise<IDynaPlace[]> => {
  let cacheKey = searchText.trim().toLowerCase();

  const cachedData: IDynaPlace[] = cache.get(cacheKey);
  if (cachedData) return Promise.resolve(cachedData);

  return new Promise((resolve: (airports: IDynaPlace[]) => void, reject: (error: IError) => void) => {
    nodeDevice.send<ICOMMAND_searchAirport_args, null, null, ICOMMAND_searchAirportResults_data>({
      to: serviceDNA,
      command: COMMAND_searchAirport,
      args: {
        searchText,
        count,
        language,
      },
      onReply: (reply) => {
        switch (reply.command) {
          case COMMAND_searchAirportResults:
            resolve(reply.data.airports);
            cache.add(cacheKey, reply.data.airports);
            break;
          case 'error':
            reject({
              code: 1801120818,
              errorType: EErrorType.APP,
              section: 'DynaPlaceInfoHandler(client)/searchAirport',
              message: 'Server error',
              error: reply.data,
            } as IError);
            break;
          default:
            reject({
              code: 1801120819,
              errorType: EErrorType.APP,
              section: 'DynaPlaceInfoHandler(client)/searchAirport',
              message: `Unknown server command/error [${reply.command}]`,
              error: {
                errorCommand: reply.command,
                data: reply.data,
              },
            } as IError);
            break;
        }
      },
    })
      .catch((error: IError) => {
        reject({
          code: 1801120817,
          errorType: EErrorType.HW,
          section: 'DynaPlaceInfoHandler(client)/searchAirport',
          message: 'Network error',
          error,
        } as IError);
      });
  });
};
