exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Option-module-container--3KDHGGzRYfSk {\n  -ms-flex: 1 1;\n      flex: 1 1;\n}\n.Option-module-root--fRvKylUTJt1q {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  border: 1px solid #993f00;\n  border-radius: 40px;\n  padding: 6px 16px;\n  margin: 2px;\n  color: black;\n  background-color: white;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  cursor: pointer;\n  -webkit-transition: background-color 250ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out;\n  -o-transition: background-color 250ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out;\n  transition: background-color 250ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out;\n}\n.Option-module-root--fRvKylUTJt1q:hover {\n  opacity: 0.7;\n}\n.Option-module-selected--efwJ94I2zfMG {\n  color: white;\n  background-color: #FF6900;\n}\n.Option-module-icon--eNsi2FNt5wCK {\n  background-color: transparent;\n}\n.Option-module-label--2HcDEH7SIQtU {\n  padding-left: 6px;\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"container": "Option-module-container--3KDHGGzRYfSk",
	"root": "Option-module-root--fRvKylUTJt1q",
	"selected": "Option-module-selected--efwJ94I2zfMG",
	"icon": "Option-module-icon--eNsi2FNt5wCK",
	"label": "Option-module-label--2HcDEH7SIQtU"
};