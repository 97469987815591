import * as React from 'react';

import {appConfig} from "../../../settings/app";

import './LoadBox.css';

export const LoadingBox = (title: string = ''): JSX.Element => (
	<div className="loading-box">
		<div className="loading-box-content">
			<div className="loading-box-content-spinner">{appConfig.loadingSpinnerComponent}</div>
			<div className="loading-box-content-title">{title ? `${title}...` : null}</div>
		</div>
	</div>
);
