import * as React from "react";
import {DynaFastClick} from "dyna-ui-fast-click";
import {DynaAnimationVerticalContainer} from "dyna-animation";
import {DynaClassName, dynaClassName} from "dyna-class-name";

import {IDynaTripFilter} from "../interfaces";
import {IDynaTravelFilterTripsMessages} from "../messages";

import "./Header.less";

export interface IHeaderProps {
	className?: string;
	messages: IDynaTravelFilterTripsMessages;
	show: boolean;
	filter: IDynaTripFilter;
	filteredCount: number;
	showNoFilterLabel: boolean;
	totalCount: number;
	onClick: () => void;
}

export class Header extends React.Component<IHeaderProps> {
	private readonly cn: DynaClassName = dynaClassName("dyna-travel-filter-trip-header");

	private renderCounters(): JSX.Element {
		const {
			filter,
			filteredCount,
			totalCount,
			messages,
      showNoFilterLabel,
		} = this.props;
		const cn: DynaClassName = dynaClassName(this.cn("__counters"));

		if (filter === null || !filter.active) {
			return (
				<div className={cn()}>
          {!!showNoFilterLabel &&
            <span className={cn("__no-filter")}>{messages.noFilter}</span>
          }
				</div>
			);
		}

		return (
			<div className={cn()}>
				<table>
					<tbody>
					<tr>
						<td><span className={cn("__filtered-message")}>{messages.filtered} </span></td>
						<td><span className={cn("__filtered-counter")}>{filteredCount}</span></td>
					</tr>
					<tr>
						<td><span className={cn("__total-message")}>{messages.ofTotal} </span></td>
						<td><span className={cn("__total-counter")}>{totalCount}</span></td>
					</tr>
					</tbody>
				</table>
			</div>
		)
	}

	public render(): JSX.Element {
		const {
			show,
			messages: {
				filter,
			},
			onClick,
		} = this.props;

		return (
			<DynaAnimationVerticalContainer show={show}>
				<DynaFastClick
					className={this.cn.root(this.props)}
					onClick={onClick}
				>
					<div className={this.cn("__icon-container")}><i className="fas fa-filter"/> {filter}</div>
					{this.renderCounters()}
				</DynaFastClick>
			</DynaAnimationVerticalContainer>
		);
	}
}
