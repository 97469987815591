import * as React from "react";
import {FormattedMessage} from 'react-intl';
import {DynaClassName, dynaClassName} from "dyna-class-name";
import {EBrowser, hasEnv} from "dyna-detect-env";

import "./IE11IsNotSupportedBanner.css";

export interface IIE11IsNotSupportedBannerProps {
	className?: string;
}

const cs: DynaClassName = dynaClassName("dyna-app-ie11-sucks-banner");

export const IE11IsNotSupportedBanner: React.SFC<IIE11IsNotSupportedBannerProps> = (props) => {
	const show: boolean = hasEnv(EBrowser.ie);
	if (!show) return null;

	return (
		<div className={cs.root(props)}>
			<div className={cs("__icon")}>
				<i className="fab fa-internet-explorer" />
			</div>
			<div className={cs("__message")}>
				<FormattedMessage id="MSG_0783"/>
			</div>
		</div>
	);
};