import {IDynaPrice, IDynaTrip, IDynaTripProposal} from "dyna-travel-interfaces";
import {EEndPoint, EStartEnd, IDynaTripFilter} from "../interfaces";
import {FilterHolder} from "./FilterHolder";
import {getHour} from "./getHour";

export interface IFilterTripProposalsConfig {
	currency: string;
	tripProposals: IDynaTripProposal[];
	filter: IDynaTripFilter;
	convertPrice: (price: IDynaPrice, currency: string) => number;
}

export const filterTripProposals = (config: IFilterTripProposalsConfig): IDynaTripProposal[] => {
	const {
		currency,
		tripProposals,
		filter,
		convertPrice,
	} = config;

	if (!filter.active) return tripProposals;

	const filterHolder: FilterHolder = new FilterHolder(filter);

	return tripProposals
		.filter((tripProposal: IDynaTripProposal) => {
			// implementation of the filter
			let valid: boolean = true;
			valid = valid && (filter.maxPrice === null || convertPrice(tripProposal.trip.price, currency) <= filter.maxPrice);
			valid && tripProposal.trip.segments
				.forEach((segment: IDynaTrip, segmentIndex: number) => {
					if (!valid) return valid;
					if (filter.segments[segmentIndex].maxDurationInMin !== null) valid = valid && segment.stats.durationInMin <= filter.segments[segmentIndex].maxDurationInMin;

					const segmentOrigin: IDynaTrip = segment.segments[0];
					const segmentDestination: IDynaTrip = segment.segments[segment.segments.length - 1];
					const originStartHour:number =getHour(segmentOrigin.start);
					const destinationEndHour:number=getHour(segmentDestination.end);

					valid = valid && originStartHour >= filterHolder.getSegmentHour(segmentIndex, EEndPoint.ORIGIN, segmentOrigin.start.localDate, EStartEnd.START, 0);
					valid = valid && originStartHour < filterHolder.getSegmentHour(segmentIndex, EEndPoint.ORIGIN, segmentOrigin.start.localDate, EStartEnd.END, 24);
					valid = valid && destinationEndHour >= filterHolder.getSegmentHour(segmentIndex, EEndPoint.DESTINATION, segmentDestination.end.localDate, EStartEnd.START, 0);
					valid = valid && destinationEndHour < filterHolder.getSegmentHour(segmentIndex, EEndPoint.DESTINATION, segmentDestination.end.localDate, EStartEnd.END, 24);
				});
			return valid;
		});
};