export enum EProduct {
  IM_HOLIDAY = "IM_HOLIDAY",
  DIE_TO_FLY = "DIE_TO_FLY",
}

export enum EMode {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

