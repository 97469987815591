import * as React from "react";
import {IDynaTrip} from "dyna-travel-interfaces";

import {EColor, EViewType} from "../DynaTravelTripTimeline";

import {EDirection, SegmentContent} from "./SegmentContent";
import {ESegmentPlace, PlaceTime} from "../entities/PlaceTime";


import "./SegmentPoint.less";

export interface ISegmentPointProps {
	viewType: EViewType,
	color: EColor;
	segment: IDynaTrip;
	segmentPlace: ESegmentPlace;
	dayIsChanged: boolean;
	isFirst: boolean;
	isLast: boolean;
	formatTime: (timeHHMM: string) => string;
	formatFullDate: (dateYYYYMMDD: string) => string;
	messages: {
		nextDay: string;
		nextDateTooltip: string;
	};
}

export class SegmentPoint extends React.Component<ISegmentPointProps> {
	private readonly className: string = "dt-trip-timeline__segment-point";

	public render(): JSX.Element {
		const {
			viewType,
			color,
			segment,
			segmentPlace,
			dayIsChanged,
			isFirst, isLast,
			messages,
      formatTime,
      formatFullDate,
		} = this.props;

		const containerClassName: string = [
			this.className,
			`${this.className}--color-${color}`,
		].join(' ').trim();

		const bulletClassName: string = [
			`${this.className}__bullet`,
		].join(' ').trim();

		return (
			<div className={containerClassName}>
				<div className={`${this.className}__float-content`}>
					<SegmentContent
						color={color}
						distance={1}
						showStick
						direction={EDirection.UP}
					>
						{segmentPlace ?
							<PlaceTime
								viewType={viewType}
								color={color}
								segment={segment}
								segmentPlace={segmentPlace}
								dayIsChanged={dayIsChanged}
								isFirst={isFirst}
								isLast={isLast}
								formatTime={formatTime}
								formatFullDate={formatFullDate}
								messages={messages}
							/>
							: null}
					</SegmentContent>
				</div>
				<div className={bulletClassName}/>
			</div>
		);
	}
}
