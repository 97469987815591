import * as React from 'react';
import {InjectedIntl, injectIntl} from "react-intl";
import {dynaClassName, DynaClassName} from "dyna-class-name";
import {FeedbackBannerTransparent} from "dyna-travel-ui-components";

import {CookiesBanner} from "../../../banners/cookiesBanner/CookiesBanner";
import {IAppLayer} from "../../../../../../interfaces/app";

import {formatMessage} from "../../../../../../utils/formatMessage";

import {appConfig} from "../../../../../../settings/app";

import "./footer-banners.css";

export interface IFooterBannersProps {
	intl?: InjectedIntl;
	appLayer: IAppLayer;
}

const className: DynaClassName = dynaClassName("footer-banners");

const _FooterBanners: React.SFC<IFooterBannersProps> = ({intl, appLayer}) => (
	<div className={className()}>

		<CookiesBanner/>

		<FeedbackBannerTransparent
			product={appConfig.product}
			showItAfterLoads={100}
			showIsAfterSecs={0}
			messages={{
				feedbackLabel: formatMessage({intl, id: "MSG_0784"}),
				yourFeedbackIsPreciousLabel: formatMessage({intl, id: "MSG_0785"}),
				tellUsWhatYouThinkLabel: formatMessage({intl, id: "MSG_0786"}),
				lessThanAMinuteLabel: formatMessage({intl, id: "MSG_0787"}),
				loadButton: formatMessage({intl, id: "MSG_0788"}),
			}}
			onClick={() => window.open("https://info.imholiday.com/imholiday-feedback-form", "_blank")}
		/>

	</div>
);

export const FooterBanners = injectIntl(_FooterBanners);
