import * as React from "react";
import {useEffect} from "react";
import * as moment from "moment";
import {ICurrencyRates} from "dyna-currencies";
import {
  DynaDatePicker,
  IDynaDatePickerProps,
  ERangePointMode,
} from "dyna-ui-date";
import {
  QualityIndexSwitch,
  EQualityIndex,
} from "dyna-travel-ui-components";

import {useFlightInfoStore} from "../stores/flightInfoStore/useFLightInfoStore";
import {renderDay} from "../components/renderDay";

import * as styles from "../components/qualityIndexButtons.module.less";

export interface IDynaDatePickerFlightsInfoProps extends IDynaDatePickerProps {
  flightsInfoConfig: IDynaDatePickerFlightsInfoConfig;
}

export interface IDynaDatePickerFlightsInfoConfig {
  COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: string;
  currency: string;
  loadMonths: number;
  origin: string;
  destination: string;
  roundTrip: boolean;
  updatedWeeksAgo: number;
  messages: {
    none: string;
    best: string;
    cheapest: string;
    fastest: string;
  };
  onCurrenciesLoad: () => Promise<ICurrencyRates>;
  _debug_dynaduxDebugGlobalVarName?: string;
}

export const DynaDatePickerFlightsInfo = (props: IDynaDatePickerFlightsInfoProps): JSX.Element => {
  const {
    value,
    flightsInfoConfig,
    onShowPicker,
    onViewportChange,
    flightsInfoConfig: {
      currency,
      origin,
      destination,
      roundTrip,
      updatedWeeksAgo,
      messages,
      onCurrenciesLoad,
    },
  } = props;

  const {
    state: {
      qualityIndex,
      getDayFlightsViewData,
      showPriceValues,
    },
    actions: {
      setCurrency,
      loadPrices,
      setQualityIndex,
      setCurrencyRates,
      setShowPriceValues,
    },
  } = useFlightInfoStore(flightsInfoConfig);

  useEffect(() => setCurrency(currency), [currency]);

  useEffect(() => {
    onCurrenciesLoad()
      .then(setCurrencyRates)
      .catch(error => console.error('Cannot get currency rates', error));
  }, []);

  const refreshMonthlyInfoApply = (dateValue: any): void => {
    loadPrices({
      yyyymmdd: moment(dateValue).format('YYYY-MM-DD'),
      origin,
      destination,
      roundTrip,
      updatedWeeksAgo,
    });
    if (onShowPicker) onShowPicker();

  };

  const handleShowPicker = (): void => {
    refreshMonthlyInfoApply(value);
  };

  const renderDayItem = (date: Date, dayNumber: number, dayInWeek: number, inRange: ERangePointMode, hovered: ERangePointMode, inMonth: boolean): JSX.Element => {
    dayNumber;
    dayInWeek;
    inRange;
    hovered;
    return renderDay({
      inMonth,
      dayFlightsViewData: getDayFlightsViewData(moment(date).format('YYYY-MM-DD'), roundTrip),
    });
  };

  const handleViewportChange = (name: string, date: Date): void => {
    refreshMonthlyInfoApply(date);
    onViewportChange && onViewportChange(name, date);
  };

  const handleQualityIndexChange = (change: { qualityIndex: EQualityIndex, showPriceValues: boolean }): void => {
    setQualityIndex(change.qualityIndex);
    setShowPriceValues(change.showPriceValues);
  };

  return (
    <DynaDatePicker
      {...{
        ...props,
        onViewportChange: handleViewportChange,
        flightsInfoConfig: undefined,
      }}
      renderPickerDay={renderDayItem}
      pickerHeader={
        <QualityIndexSwitch
          className={styles.qualityIndexButtons}
          value={{qualityIndex, showPriceValues}}
          messages={messages}
          onChange={handleQualityIndexChange}
        />
      }
      onShowPicker={handleShowPicker}
    />
  );
};
