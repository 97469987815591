import {
  useState,
  useRef,
} from "react";

import {flightInfoStore} from "./flightInfoStore";

export const useFlightInfoStore = (
  {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
    loadMonths,
    _debug_dynaduxDebugGlobalVarName,
  }
    : {
    COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress: string;
    loadMonths: number;
    _debug_dynaduxDebugGlobalVarName?: string;
  },
) => {
  const [s, forceUpdate] = useState({});
  s;
  const appStoreRef = useRef<any>();

  return (
    appStoreRef.current
    || (
      appStoreRef.current = flightInfoStore({
        onChange: () => forceUpdate({}),
        COMMAND_FPS_LOAD_FLIGHT_MONTHLY_BCFP_serviceAddress,
        _debug_dynaduxDebugGlobalVarName,
        loadMonths,
      })
    )
  ) as ReturnType<typeof flightInfoStore>;
};
