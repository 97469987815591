import * as React from "react";
import {dynaClassName} from "dyna-class-name";
import {DynaFastClick} from "dyna-ui-fast-click";

import {IFlightProposalDetailsMessages} from "../messages";

import "./FlightProposalsDetailsNotFound.css";

export interface IFlightProposalsDetailsNotFoundProps {
	className?: string;
	messages: IFlightProposalDetailsMessages;
	onClickForSimilarFlights: () => void;
}

export class FlightProposalsDetailsNotFound extends React.Component<IFlightProposalsDetailsNotFoundProps> {
	private readonly className = dynaClassName("dyna-travel-flight-proposal-flight-not-found");

	public render(): JSX.Element {
		const {
			className: userClassName,
			messages: {
				flightCannotBeFound,
				itIsHardToFindThisFlightAgain,
				clickHereForSimilarFlights,
			},
			onClickForSimilarFlights,
		} = this.props;

		const className: string = this.className("", userClassName && '/' + userClassName);

		return (
			<div className={className}>
				<div className={this.className("__label-flight-cannot-be-found")}>{flightCannotBeFound}</div>
				<div className={this.className("__icon")}><i className="far fa-frown"/></div>
				<DynaFastClick className={this.className("__label-click-back")} onClick={onClickForSimilarFlights}>{clickHereForSimilarFlights}</DynaFastClick>
				<div className={this.className("__label-is-hard")}>{itIsHardToFindThisFlightAgain}</div>
			</div>
		);
	}
}