import {
  DynaCurrencies
} from "dyna-currencies";
import { IDynaPrice } from "dyna-travel-interfaces";
import { EQualityIndex } from "dyna-travel-ui-components";

// Store

export interface IFlightInfoState {
  currency: string;
  currencies: DynaCurrencies;
  origin: string;
  destination: string;
  roundTrip: boolean;
  qualityIndex: EQualityIndex;
  showPrices: boolean;
  isClientLoading: boolean;
  daysFlightsInfo: {
    oneWay: IDaysFlightsInfoDic;
    roundTrip: IDaysFlightsInfoDic;
  };
  loadErrors: boolean;
}

export interface IDaysFlightsInfoDic {
  [yyyymmss: string]: IDayFlightsInfo | undefined;
}

export interface IDayFlightsInfo {
  bestPrice: IPriceViewData;
  cheapestPrice: IPriceViewData;
  fastestPrice: IPriceViewData;
  updatedAt: Date;
  isBackendLoading: boolean;
  priceQualityIndex: {
    best: EPriceQualityIndex;
    cheapest: EPriceQualityIndex;
    fastest: EPriceQualityIndex;
  };
}

export interface IPriceViewData {
  price: IDynaPrice | null;
  label: string;
}

export enum EPriceQualityIndex {
  NO_PRICE_AVAILABLE = "NO_PRICE_AVAILABLE",
  CHEAP = "CHEAP",
  SO_LA_LA = "SO_LA_LA",
  EXPENSIVE = "EXPENSIVE",
}

// State

export interface IDayFlightsViewData {
  dateNumber: number;
  qualityIndex: EQualityIndex;

  showPriceQualityIndex: boolean;
  priceQualityIndex: {
    best: EPriceQualityIndex | null;
    cheapest: EPriceQualityIndex| null;
    fastest: EPriceQualityIndex| null;
  };

  showPriceValues: boolean;
  priceValues: {
    best: string | "isBackendLoading" | "isFrontendLoading" | "noData" | "noCurrencies";
    cheapest: string | "isBackendLoading" | "isFrontendLoading" | "noData" | "noCurrencies";
    fastest: string | "isBackendLoading" | "isFrontendLoading" | "noData" | "noCurrencies";
  };
}
