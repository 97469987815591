import {IError, IInfo} from "dyna-interfaces";
import {EDeviceCategory, hasEnv} from "dyna-detect-env";
import {dynaDebounce} from "dyna-debounce";
import {DynaJobQueue} from "dyna-job-queue";
import {IDynaTrip, IDynaTripProposal, IDynaTripRequest} from "dyna-travel-interfaces";

import {DynaTravelClient} from "../api/DynaTravelClient/DynaTravelClient";
import {ILoadFlightsCompleteInfo} from "../api/DynaTravelClient/interfaces";
import {FetchDynaTripsHandler} from "../api/DynaTravelClient/sections/FetchDynaTripsHandler";
import {IGetTripProposalsConfig, IGetTripProposalsResults, SearchTripsDataHolder} from "./SearchTripsDataHolder";

import {appConfig, EAppMode} from "../../../settings/app";

import {
  dynaTravelSearchFlightsReceiveSearchAirTrip,
  dynaTravelSearchFlightsReceiveSearchAirTripCompleted,
  dynaTravelSearchFlightsReceiveSearchAirTripError,
  dynaTravelSearchFlightsReceiveSearchAirTripProgress,
} from "../state/SearchFlights/actions";

interface ISearchProcesses {
  [searchProcessId: string]: {
    fetchHandler: FetchDynaTripsHandler;
    tripsDataHolder: SearchTripsDataHolder;
  };
}

const SMALL_DEVICE: boolean = !hasEnv([EDeviceCategory.desktop]);
const DELAY_LOADING: number = SMALL_DEVICE ? 30 : 10;
const ADD_TRIPS_DEBOUNCE_TIMEOUT: number = SMALL_DEVICE ? 2300 : 500;

export class SearchTripsManager {
  constructor(private readonly dynaTravelClient: DynaTravelClient) {
  }

  private searchProcesses: ISearchProcesses = {};
  private jobQueue: DynaJobQueue = new DynaJobQueue();

  public searchTrip(dispatch: any, searchProcessId: string, tripRequest: IDynaTripRequest): void {
    this.cancelSearchProcess(searchProcessId);
    const debouncedDispatch: any = dynaDebounce(dispatch, ADD_TRIPS_DEBOUNCE_TIMEOUT, true);

    this.searchProcesses[searchProcessId] = {
      fetchHandler: this.dynaTravelClient.fetchTrips(tripRequest),
      tripsDataHolder: new SearchTripsDataHolder(),
    };
    // shorthands
    const fetchHandler: FetchDynaTripsHandler = this.searchProcesses[searchProcessId].fetchHandler;
    const tripsDataHolder: SearchTripsDataHolder = this.searchProcesses[searchProcessId].tripsDataHolder;

    fetchHandler.on(fetchHandler.events.load, (trips: IDynaTrip[]) => {
      this.jobQueue.addJobCallback((done: Function) => {
        if (appConfig.mode === EAppMode.DEBUG) console.log('(only on debug) addTrips ', trips.length);
        tripsDataHolder.addTrips(trips);
        debouncedDispatch(dynaTravelSearchFlightsReceiveSearchAirTrip(searchProcessId));
        setTimeout(done, DELAY_LOADING);
      });
    });

    fetchHandler.on(fetchHandler.events.progress, (progressInfo: IInfo) => {
      this.jobQueue.addJobCallback((done: Function) => {
        if (progressInfo.progress) {
          dispatch(dynaTravelSearchFlightsReceiveSearchAirTripProgress(searchProcessId, progressInfo.progress));
        }
        setTimeout(done, DELAY_LOADING);
      });
    });

    fetchHandler.on(fetchHandler.events.loadCompleted, (loadCompleteInfo: ILoadFlightsCompleteInfo) => {
      this.jobQueue.addJobCallback((done: Function) => {
        dispatch(dynaTravelSearchFlightsReceiveSearchAirTripProgress(searchProcessId, 100));
        dispatch(dynaTravelSearchFlightsReceiveSearchAirTripCompleted(searchProcessId, loadCompleteInfo));
        done();
      });
    });

    fetchHandler.on(fetchHandler.events.error, (error: IError) => {
      this.jobQueue.addJobCallback((done: Function) => {
        dispatch(dynaTravelSearchFlightsReceiveSearchAirTripError(searchProcessId));
        console.error('SearchFlight component (container) network/backend error loading flights', {error});
        setTimeout(done, DELAY_LOADING);
      });
    });
  }

  public cancelSearchProcess(searchProcessId: string): void {
    this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].fetchHandler.cancelTripRequest();
  }

  public cancelAllSearchProcess(): void {
    Object.keys(this.searchProcesses).forEach(this.cancelSearchProcess);
  }

  public hasTrips(searchProcessId: string): boolean {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.hasTrips;
  }

  public setFilteredTripProposals(searchProcessId: string, filteredTripProposals: IDynaTripProposal[]): void {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.setFilteredTripProposals(filteredTripProposals);
  }

  public reset(searchProcessId: string): void {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.reset();
  }

  public getAllTripProposals(searchProcessId: string): IDynaTripProposal[] {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.getAllTripProposals() || null;
  }

  public getTripProposals(searchProcessId: string, getConfig: IGetTripProposalsConfig): IGetTripProposalsResults {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.getTripProposals(getConfig) || null;
  }

  public getTripProposalByTpid(searchProcessId: string, tpid: string): IDynaTripProposal {
    return this.searchProcesses[searchProcessId] && this.searchProcesses[searchProcessId].tripsDataHolder.getTripProposalByTpid(tpid) || null;
  }

}