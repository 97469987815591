import * as React from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from 'react-intl';

import {IMenu} from "../../../../../interfaces/menu";
import {LoadingInLine} from "../../../../../components/ui/loadingInLine/index";

import './MainMenuComponent.css';

export interface IMainMenuComponentProps {
  appPath: string;
  locale: string;
  items: IMenu[];
}

export class MainMenuComponent extends React.Component<IMainMenuComponentProps, null> {

  private isMenuSelected(appPath: string, menu: IMenu): boolean {
    return (appPath === menu.path) || (appPath.indexOf(menu.path) === 0 && menu.path.length > 1);
  }

  private renderItem(menu: IMenu, index: number): JSX.Element {
    const {appPath} = this.props;
    const {path, caption, isLoading} = menu;
    return (
      <Link
        key={index}
        className={`app-menu-link ${this.isMenuSelected(appPath, menu) ? 'selected' : ''}`.trim()}
        to={path}
      >
        <FormattedMessage id={caption} defaultMessage={caption.replace(/_/g, ' ')}/>
        {isLoading ? LoadingInLine() : null}
      </Link>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="app-main-menu">
        <nav>
          {this.props.items
            .filter((menu: IMenu) => true)
            .map(this.renderItem.bind(this))}
        </nav>
      </div>
    );
  }

}
