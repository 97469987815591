import {InjectedIntl} from "react-intl";

// https://github.com/yahoo/react-intl/wiki/API#formatmessage

export const formatMessage = (params: {
	intl: InjectedIntl;
	id: string;
	defaultMessage?: string;
	values?: any;
}): string => {
	const {
		intl,
		id,
		defaultMessage,
		values,
	} = params;

	if (!id || !id.trim()) return "";

	return (
		intl.formatMessage({id, defaultMessage}, values)
		|| defaultMessage
		|| `xmes-${id}`
	);
};