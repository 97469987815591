import * as React from 'react';
import {IntlProvider} from 'react-intl';
import {DynaTranslationManager, IDynaTranslationLocaleOption, TLocale} from "../../../packages/dyna-translation-manager/DynaTranslationManager";

import {defaultLocale} from "../../../settings/app";
import {appTranslations} from "../../../locales/translations";

export interface IReduxIntlProviderProps {
  locale: string; // format: llCC <languageCOUNTRY> as supported by the DynaTranslationManager
  onUpdateLocales?: (localeOptions: IDynaTranslationLocaleOption[]) => void;
  children?: any;
}

export class ReduxIntlProviderComponent extends React.Component <IReduxIntlProviderProps> {
  private translationManager: DynaTranslationManager;

  constructor(props: IReduxIntlProviderProps, context: any) {
    super(props, context);
    this.translationManager = new DynaTranslationManager();
    this.translationManager.loadTranslations(appTranslations);
  }

  public render(): JSX.Element {
    const {locale, children} = this.props;
    let useLocale: TLocale = locale;
    if (!this.translationManager.isLocaleSupported(useLocale)) useLocale = defaultLocale;

    return (
      <IntlProvider
        locale={useLocale.substr(0, 2)}
        messages={this.translationManager.getIntlMessages(useLocale)}
      >{children}</IntlProvider>
    );
  }
}

export default ReduxIntlProviderComponent;
