import {IDynaTranslation} from "../../../packages/dyna-translation-manager/DynaTranslationManager";

export const messages: IDynaTranslation = {
	locales: [
		{locale: 'enUS', label: 'English US'},
		{locale: 'frFR', label: 'French'},
		{locale: 'zhCN', label: 'Chinese'},
	],
	messages: {
		MSG_0617: {
			enUS: 'return',
		},
		MSG_0618: {
			enUS: 'one way',
		},
		MSG_0619: {
			enUS: 'multi trip',
		},
		MSG_0620: {
			enUS: 'From',
		},
		MSG_0621: {
			enUS: 'fly from',
		},
		MSG_0622: {
			enUS: 'required',
		},
		MSG_0623: {
			enUS: 'From airport is not selected',
		},
		MSG_0624: {
			enUS: 'To',
		},
		MSG_0625: {
			enUS: 'fly to',
		},
		MSG_0626: {
			enUS: 'required',
		},
		MSG_0627: {
			enUS: 'To airport is not selected',
		},
		MSG_0628: {
			enUS: 'Depart',
		},
		MSG_0629: {
			enUS: 'Return',
		},
		MSG_0630: {
			enUS: 'Clear',
		},
		MSG_0631: {
			enUS: 'Search',
		},
		MSG_0632: {
			enUS: 'New search',
		},
		MSG_0633: {
			enUS: 'loading',
		},
		MSG_0634: {
			enUS: 'Economy',
		},
		MSG_0635: {
			enUS: 'Business',
		},
		MSG_0636: {
			enUS: 'Adults',
		},
		MSG_0637: {
			enUS: 'Children',
		},
		MSG_0638: {
			enUS: 'Infants',
		},
		MSG_0639: {
			enUS: 'Your age at time of travel must be valid for the age category booked. Airlines have restrictions on under 18s travelling alone.\n\nAge limits and policies for travelling with children may vary so please check with the airline before booking.',
		},
		MSG_0640: {
			enUS: 'direct flights only',
			frFR: 'vols directs uniquement',
		},
	},
};
