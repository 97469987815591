import {Store} from "react-redux";
import {appConfig, EAppMode} from "../settings/app";

declare const window: any;
import {createStore, combineReducers, applyMiddleware} from "redux";
import thunkMiddleware from 'redux-thunk';

import {IAppStore, reducers} from './reducersConfig';
import {middlewares} from './middlewaresConfig';

export const createReduxAppStore = (): Store<IAppStore> => {
  return createStore<IAppStore>(
    combineReducers(reducers),
    appConfig.mode === EAppMode.DEBUG && window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(
      thunkMiddleware,
      ...middlewares,
    ),
  );
};
